import { Form, Formik } from "formik";
import React, { useState } from "react";
import { string, StringSchema } from "yup";
import { IApiResponse, tenantsAddNew } from "../../api/Api";
import { ITenantCreateRequest } from "../../api/ApiRequests";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import useUserUtil from "../../hooks/useUserUtil";
import { Locale } from "../../locale/Locale";
import { IRole, ITenant } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import CheckBox from "../formik/CheckBox";
import FieldWithLabel from "../formik/FormikField";
import ModalForm from "../modal/ModalForm";
import RoleBanner from "../user/RoleBanner";
import Flex from "../container/Flex";
import { useTenants } from "../../state/swr/tenant/useTenants";
import { useUser } from "../../state/swr/user/useUser";

export default function TenantCreateForm() {

    const {
        user
    } = useUser();

    const { reloadTenants } = useTenants();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <ModalForm 
            title={Locale.pages.tenantManagement.addTenant} 
            button={<Button icon="plus-circle" text={Locale.pages.tenantManagement.addTenant} color="success" />}
            initialValues={{
                mailAddress: "",
                onboardingIsManaged: false
            } as ITenantCreateRequest}
            onSubmit={async (values, actions) => {
                const result = await callApi(tenantsAddNew(values), true);
                if (!result) return false;
                await reloadTenants();
                return true;
            }}
            sidebar={formik => <Button loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />}
        >
            {
                (formik) => (
                    <Flex className="w-100">
                        <FieldWithLabel label="E-Mail für den Einladungslink" placeholder="E-Mail des Tenant-Admins..." name="mailAddress" />
                        <CheckBox label="Onboarding für Tenant wird von aktuellem Nutzer durchgeführt" name="onboardingIsManaged" />
                    </Flex>
                )
            }
        </ModalForm>    
    )
}