import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { useTenant } from "../../state/swr/tenant/useTenant";

export default function Logo() {

    const { tenant } = useTenant();

    return (
        <div className="text-start d-flex flex-column">
            <h1 className="display-5 fw-bold m-0 p-0">
                {
                    tenant?.name || "tiggi - Das Ticketsystem"
                }
            </h1>
        </div>
    )
}