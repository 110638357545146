import React from "react";
import { IMailTemplateContent } from "../../types/ApiTypes";
import Flex from "../container/Flex";
import { useMailTemplateElements } from "../../hooks/useMailTemplateElements";
import WithLabel from "../text/WithLabel";
import Typography from "../text/Typography";
import ResponsiveText from "../text/ResponsiveText";

export default function TemplatePreview({content}: {content: IMailTemplateContent}) {
    const {
        getStringified
    } = useMailTemplateElements();

    return (
        <Flex fullWidth>
            {
                content && content.subject && !!content.subject.length && (
                    <Flex>
                        <Typography bold size={20}>Betreff</Typography>
                        <Typography wrap >{getStringified(content.subject)}</Typography>
                    </Flex>
                )
            }
            {
                content && content.body && !!content.subject.length && (
                    <Flex fullWidth>
                        <Typography bold size={20}>Inhalt</Typography>
                        <ResponsiveText content={getStringified(content.body)} />
                    </Flex>
                )
            }
        </Flex>
    )
}