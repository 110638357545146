import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { ITicket, ITicketMessage, TicketState } from "../../../types/ApiTypes";
import { formatDate, formatDateTiggiStyle } from "../../../util/formatter";
import Card from "../../card/Card";
import TicketStateBanner from "../banner/TicketStateBanner";
import UserRoleIndicator from "../../user/RoleBanner";
import Icon from "../../icons/Icon";
import { useNavigate } from "react-router-dom";
import TicketTypeBanner from "../banner/TicketTypeBanner";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import TeamBanner from "../../teams/TeamBanner";
import Flex from "../../container/Flex";
import TicketFriendlyIdBanner from "../banner/TicketFriendlyIdBanner";
import TicketChatMessagePreview from "../ticketChat/TicketChatMessagePreview";
import Typography from "../../text/Typography";
import { AppColor } from "../../../app/AppStyles";
import useTicketUtil from "../../../hooks/useTicketUtil";
import TicketLastReplyStateIcon from "../banner/TicketLastReplyStateIcon";
import TicketCardHeader from "./TicketCardHeader";
import useUserUtil from "../../../hooks/useUserUtil";
import "./TicketOverviewItem.css";
import TicketMessagesCount from "../TicketMessagesCount";
import TableRow from "../../tables/TableRow";
import TableCell from "../../tables/TableCell";
import TicketSourceBanner from "../banner/TicketSourceBanner";
import Button from "../../buttons/Button";
import Banner from "../../pills/Banner";
import { ticketsAddMessage } from "../../../api/Api";
import { getDateInXFromNow } from "../../../util/util";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../../state/swr/user/useUser";
import { useTicket } from "../../../state/swr/tickets/useTicket";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import { useTicketClientContact } from "../../../state/swr/tickets/useTicketClientContact";
import { useTicketCurrentUserAssignment } from "../../../state/swr/tickets/useTicketCurrentUserAssignment";
import useFilteredTickets from "../../../hooks/useFilteredTickets";

export interface ITicketOverviewItem {
    initialTicket: ITicket,
    renderAsTableRow?: boolean,
    isSelected: boolean,
    hasAnySelection: boolean,
    canSelect?: boolean,
    toggleTicketSelected: (ticket: ITicket) => void
}

export default function TicketOverviewItem({initialTicket, canSelect = true, hasAnySelection, renderAsTableRow, isSelected, toggleTicketSelected}: ITicketOverviewItem) {

    const {
        query
    } = useFilteredTickets();

    const [hover, setHover] = React.useState<boolean>(false);

    const {
        getName
    } = useUserUtil();

    const {
        getDisplayId
    } = useTicketUtil();

    const { loadingTicketAssignee, reloadTicketAssignee, ticketAssignee } = useTicketAssignee(initialTicket);
    const { loadingTicketClientContact, reloadTicketClientContact, ticketClientContact } = useTicketClientContact(initialTicket);

    const { role } = useCurrentTenantRole();
    const { user } = useUser();

    const ticket = initialTicket;
    
    const navigate = useNavigate();

    const isClient = !(role && !role.isClient) && !(!!user && user.isSuperAdmin);

    const select = canSelect && (
        <div className="d-flex flex-column check-box align-items-start p-2" onClick={() => toggleTicketSelected(ticket)}>
            <div className="d-flex flex-row align-items-center" >
                <input readOnly type="checkbox" style={{height: "16px", width: "16px"}} checked={isSelected} className="form-check-input m-0 p-0" />
            </div>
        </div>
    )

    const subject = isClient ? ticket.subject : (ticket.internalSubject || ticket.subject);

    const isNew = () => {
        if (!ticket) return false;
        if (!ticket.messages || !ticket.messages.length) return true;
        return new Date(ticket.lastUpdate || "") > getDateInXFromNow({hours: -1});
    }

    const lastMessage = (ticket && ticket.messages && !!ticket.messages.length) ? ticket.messages[ticket.messages.length - 1] : null;
    const lastMessageText = (lastMessage && lastMessage.content && !!lastMessage.content.length) ? lastMessage.content[lastMessage.content.length - 1] : null;

    const openTicket = async () => {
        const url = `/tickets/${ticket._id}?${query.toString()}`;
        navigate(url);
    }

    if (renderAsTableRow) return (
        <TableRow color={isNew() ? "error" : undefined}>
            <TableCell>
                { select }
            </TableCell>
            <TableCell>
                <Typography bold color="primary">{getDisplayId(ticket)}</Typography>
            </TableCell>
            <TableCell>
                <Flex gap={0} className="w-100" style={{maxWidth: "150px"}}>
                    <Typography bold className="w-100">{ subject }</Typography>
                    { lastMessageText && <Typography size={12} className="w-100"> { lastMessageText.text } </Typography>}
                </Flex>
            </TableCell>
            <TableCell>
                <TicketTypeBanner ticket={ticket}/>
            </TableCell>
            <TableCell>
                <TicketLastReplyStateIcon ticket={ticket} />
            </TableCell>
            {
                !isClient && (
                    <TableCell>
                        {
                            ticket.team && <TeamBanner team={ticket.team} />
                        }
                    </TableCell>
                )
            }
            <TableCell>
                {
                    loadingTicketClientContact 
                    ? <LoadingSpinner />
                    : (
                        <Flex gap={0}>
                            {
                                ticketClientContact && ticketClientContact.client && <Typography bold >{ticketClientContact.client.name}</Typography> 
                            }
                            {
                                ticketClientContact && ticketClientContact.user && <Typography size={12}>{getName(ticketClientContact.user)}</Typography> 
                            }
                        </Flex>
                    )
                }
            </TableCell>
            <TableCell>
                <Flex align="center">
                    <TicketMessagesCount ticket={ticket} />
                </Flex>
            </TableCell>
            <TableCell>
                <TicketStateBanner ticket={ticket} textOnly />
            </TableCell>
            <TableCell>
                <Typography size={12}>{formatDateTiggiStyle(ticket.lastUpdate)}</Typography>
            </TableCell>
            <TableCell>
                <Button variant="text" onClick={openTicket}>Öffnen</Button>
            </TableCell>
        </TableRow>
    );

    return (
        <Card   
            color={isNew() ? "error" : undefined}
            noPadding
            header={<TicketCardHeader ticket={ticket} />}
            wrapperClass="gap-2 w-100 h-100" 
            onClick={openTicket}
        >
            <Flex className="w-100 p-0" gap={4} >
                <Flex gap={1} className="w-100 p-3 overflow-hidden">
                    <Typography size={20} color="primary" bold>{ subject ? (subject.length > 30 ? subject.substring(0, 30) + "..." : subject) : "" }</Typography> 
                    <Flex row justify="between" className="w-100">
                        {
                            loadingTicketClientContact 
                            ? <LoadingSpinner />
                            : (
                                <Flex row wrap className="w-100">
                                    {
                                        ticketClientContact && <Typography size={14} bold color="primary" >{ticketClientContact.client ? ticketClientContact.client.name : "Kein Mandant"}</Typography> 
                                    }
                                    {
                                        ticketClientContact && ticketClientContact.user && <Typography size={14} color="primary">{ticketClientContact.user ? getName(ticketClientContact.user) : "Kein Kontakt"}</Typography> 
                                    }
                                </Flex>
                            )
                        }

                        
                        <Icon size={20} icon="chevron-right" color="primary" />
                    </Flex>
                </Flex>
                <Flex row wrap className="w-100 ps-3 pb-3" style={{height: "fit-content"}}  align="start" justify="between" >
                    {
                        !isClient && (
                            <Flex align="start" justify="end" >
                                {
                                    loadingTicketAssignee 
                                    ? <LoadingSpinner />
                                    : <UserRoleIndicator user={ticketAssignee?.user} displayNameAsValue showOnEmptyText="Kein Bearbeiter"/>
                                }
                                {
                                    loadingTicketClientContact
                                    ? <LoadingSpinner />
                                    : <ClientTypeBanner client={ticketClientContact?.client} showClientName textOnEmpty="Kein Mandant" />
                                }
                                <TeamBanner team={ticket.team} textOnEmpty="Kein Team"/>
                            </Flex>

                        )
                    }
                    <Flex justify="between" className="ms-auto" align="end">
                        <TicketMessagesCount ticket={ticket} wrapperClass="pe-3"/>
                        <TicketStateBanner ticket={ticket} />
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}
