import React from "react";
import Flex from "../container/Flex";
import { ITask } from "../../types/ApiTypes";
import Typography from "../text/Typography";
import { formatDate, formatDateTiggiStyle } from "../../util/formatter";
import Card from "../card/Card";
import TaskForm from "./TaskForm";
import Editor from "../richText/Editor";
import RichTextViewer from "../richText/RichTextViewer";
import CompleteTaskButton from "./CompleteTaskButton";

export interface ITaskItemProps {
    task: ITask
}

export default function TaskItem({task}: ITaskItemProps) {
    return (
        <Card 
            color="bright"
            header={
                <Flex row justify="between">
                    <Typography bold color="primary">{task.subject}</Typography>
                    <Typography>Fällig: {formatDateTiggiStyle(task.dueDate)}</Typography>
                </Flex>
            }
        >
            <Flex fullWidth>
                <RichTextViewer content={task.description} canShowMore />
                <Flex row justify="between" fullWidth>
                    <CompleteTaskButton task={task} />
                    <TaskForm task={task} />
                </Flex>
            </Flex>
        </Card>
    )
}