import useSWR from "swr";
import { IClient, ICoupon, ISignature, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useTenantSignature = () => {
    const { data, isLoading, mutate } = useSWR<ISignature>(ApiRoutes.Signatures.All);

    return {
        tenantSignature: data,
        loadingTenantSignature: isLoading,
        reloadTenantSignature: mutate
    }
}