import useSWR from "swr";
import { IMailLog, ITicket, ITicketActivity } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useTicketMessageMails = (ticketMessageId: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IMailLog>>(ApiRoutes.TicketMessages.Mails.getValue({
        param: RouteParam.TicketMessageId,
        value: ticketMessageId
    }));

    return {
        ticketMessageMails: data,
        loadingTicketMessageMails: isLoading,
        reloadTicketMessageMails: mutate
    }
}