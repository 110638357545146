import React from "react";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { ITicket, TicketState } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import "./TicketStateBanner.css";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import Typography from "../../text/Typography";
import { getTextColorFromBackground } from "../../../util/util";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";

export interface ITicketStateBannerProps {
    ticket: ITicket,
    align?: "left" | "right",
    className?: string,
    textOnly?: boolean
}

export default function TicketStateBanner({ticket, className, align = "right", textOnly}: ITicketStateBannerProps) {

    const { loadingTicketAssignee, ticketAssignee } = useTicketAssignee(ticket);

    const { role } = useCurrentTenantRole();

    if (!ticket || !ticket.state) return null;

    const state = ticket.state || TicketState.Open;

    const isWaitingForClientResponse = ticket && ticket.waitingForClientResponse;

    const isClient = role && role.isClient;

    const getTicketColor = (): string => {
        switch (state) {
            case TicketState.Solved: return "#88DC8E";
            case TicketState.Archived: return "#BADFF8";
            case TicketState.Closed: return "#F55E53";
        }

        if (isWaitingForClientResponse && !isClient) return "#002E7F";

        if (state === TicketState.Open) return "#E9846A";

        if (!!ticketAssignee && !!ticketAssignee.deputy) return "#F0E2F5";

        return "#FFFFFF"
    }

    const getText = (): string => {
        switch (state) {
            case TicketState.Solved: 
            case TicketState.Archived:
            case TicketState.Closed: 
            
                return state;
        }

        if (isWaitingForClientResponse && !isClient) return "Auf Mandant warten";

        if (state === TicketState.Open) return state;

        if (!!ticketAssignee && !!ticketAssignee.deputy) return "In Vertretung";

        return "Offen";
    }

    if (textOnly) return <strong style={{color: getTicketColor(), fontSize: "12px", textTransform: "uppercase"}} >{getText()}</strong>

    const style = generateStyle({
        name: "backgroundColor",
        value: getTicketColor()
    });

    const realClass = generateClassName(className, "ticket-state-banner pe-3 ps-3 d-flex flex-row align-items-center", {
        value: align,
        base: "ticket-state-banner-start-"
    });

    return (
        <div style={style} className={realClass}>
            <span className="text-nowrap" style={generateStyle({
                name: "color",
                value: getTextColorFromBackground(getTicketColor())
            })}>
                { getText() }                
            </span>
        </div>
    )
}