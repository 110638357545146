import React from "react";
import useApi from "../../hooks/useApi";
import { teamsCreateForAreasOfResponsibility } from "../../api/Api";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Button from "../buttons/Button";
import { Status } from "../../config/Status";
import useModal from "../../hooks/useModal";
import { ModalType } from "../../config/ModalTypes";
import { useAreasOfResponsibility } from "../../state/swr/areasOfResponsibilities/useAreasOfResponsibility";
import { useTeams } from "../../state/swr/teams/useTeams";
import WithPermissions from "../permissions/WithPermissions";

export default function TeamsCreateFromResponsibilityButton() {
    const callApi = useApi();
    const dispatch = useAppDispatch();

    const showModal = useModal();

    const { reloadTeams, loadingTeams } = useTeams();
    const { areasOfResponsibility, loadingAreasOfResponsibility } = useAreasOfResponsibility();


    const clickHandler = async () => {
        const res = await callApi(teamsCreateForAreasOfResponsibility());
        if (!res || !res.success) return;        
        await reloadTeams();
        showModal({
            text: "Teams wurden erfolgreich überprüft. Es wurden keine Teams für Zuständigkeiten ohne zugewiesene Mitarbeiter erstellt.",
            type: ModalType.Success
        });
    }

    return (
        <WithPermissions permissions={["interfaces.datev.update"]}>
            <Button 
                text="Teams aus DATEV erstellen" 
                loading={loadingTeams || loadingAreasOfResponsibility} 
                loadingText="Zuständigkeiten werden abgerufen" 
                disabled={!areasOfResponsibility || !areasOfResponsibility.length}
                disabledText="Keine Zuständigkeiten importiert"
                onClick={clickHandler}
                icon="person-lines-fill"
            />
        </WithPermissions>
    )
}