import React from "react";
import { useSearchParams } from "react-router-dom";
import { ITabData } from "../components/tabswitcher/TabSwitcher";
import { Status } from "../config/Status";
import Tickets from "../pages/tickets/Tickets";
import { ITicket, TicketState, TicketType, UserRole } from "../types/ApiTypes";
import { useAppSelector } from "./reduxHooks";
import useUserUtil from "./useUserUtil";
import { usersGetClientContacts } from "../api/Api";
import useClientUtil from "./useClientUtil";
import { useUser } from "../state/swr/user/useUser";
import { useCurrentTenantRole } from "../state/swr/user/useCurrentTenantRole";
import { useTeams } from "../state/swr/teams/useTeams";
import { useAllTickets } from "../state/swr/tickets/useAllTickets";
import { SortDirection } from "../components/buttons/SortDirectionToggle";
import useFilterQuery from "./useFilterQuery";
import { getDateInXFromNow } from "../util/util";
import { ISelectItem } from "../components/comboBox/Select";
import useTenantSettings from "./useTenantSettings";

export enum TicketSort {
    CreatedAt = "created",
    UpdatedAt = "updated",
    Status = "status",
    Subject = "subject",
    Type = "type"
}

export enum TicketSubcategory {
    All = "all",
    WaitingForClient = "waitingForClient",
    ClosedPastWeek = "closedPastWeek",
    Escalated = "escalated",
    Unanswered = "unanswered",
    Intern = "internal",
    External = "external"
}

export enum TicketNotAssignedType {
    Assignee = "assignee",
    ClientContact = "clientContact",
    Client = "client",
    Team = "team"
}

export enum TicketFilter {
    NotAssigned = "na",
    ForMe = "mine",
    MyTeam = "team",
    All = "all",
    Closed = "closed",
    Archive = "archived"
}

export interface ITicketFilter {
    show?: TicketFilter,
    client?: string,
    subcategory?: TicketSubcategory,
    team?: string,
    text?: string,
    sort?: TicketSort,
    notAssignedType?: TicketNotAssignedType,
    direction?: SortDirection,
    max?: number
}

export interface ITicketFilterUpdate extends ITicketFilter {
    view?: TicketView,
}

export enum TicketView {
    List = "list",
    Grid = "grid"
}

export default function useFilteredTickets() {

    const [notAssignedType, setNotAssignedType] = React.useState<TicketNotAssignedType>(TicketNotAssignedType.Assignee);
    const [subcategory, setSubcategory] = React.useState<TicketSubcategory>(TicketSubcategory.All);
    const [view, setView] = React.useState<TicketView>(TicketView.Grid);
    const [team, setTeam] = React.useState<string>();
    const [client, setClient] = React.useState<string>();
    const [filterText, setFilterText] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [sort, setSort] = React.useState<TicketSort | undefined>(TicketSort.UpdatedAt)
    const [direction, setDirection] = React.useState<SortDirection>(SortDirection.Descending);
    const [filter, setFilter] = React.useState<TicketFilter>(TicketFilter.ForMe);
    const [matchingTickets, setMatchingTickets] = React.useState<ITicket[]>([]);

    const { updateQueryFromFilter } = useFilterQuery();

    const [query, setQuery] = useSearchParams();

    const { role } = useCurrentTenantRole();

    const isClient = !!role && role.isClient;

    const { loadingTeams } = useTeams();

    const { loadingTickets } = useAllTickets(filter === TicketFilter.Closed || filter === TicketFilter.Archive);

    React.useEffect(() => {
        if (!query) return;

        const c = query.get("subcategory");

        if (!c) setSubcategory(TicketSubcategory.All);
        else setSubcategory(c as TicketSubcategory);

        const f = query.get("show");
        
        if (!f) setFilter(TicketFilter.ForMe);
        else setFilter(f as TicketFilter);

        const s = query.get("sort");

        if (!s) setSort(TicketSort.UpdatedAt);
        else setSort(s as TicketSort);

        const d = query.get("direction");

        if (!d) setDirection(SortDirection.Descending);
        else setDirection(d as SortDirection);

        const n = query.get("notAssignedType");

        if (!n) setNotAssignedType(TicketNotAssignedType.Assignee);
        else setNotAssignedType(n as TicketNotAssignedType);

        const t = query.get("text");
        setFilterText(t || "");

        const te = query.get("team");
        setTeam(te || "");

        const v = query.get("view");

        if (!v) setView(TicketView.Grid);
        else setView(v as TicketView);

        const cl = query.get("client");
        setClient(cl || "");

    }, [query]);
    
    React.useEffect(() => {
        if (filter === TicketFilter.MyTeam) return;
        else setTeam(undefined);
    }, [filter]);
    
    React.useEffect(() => {
        if (!loadingTeams && !loadingTickets) setLoading(false);
        else setLoading(true);
    }, [loadingTeams, loadingTickets]);
    
    const updateFilter = (update: ITicketFilterUpdate) => {
        const result: ITicketFilterUpdate = {
            direction: update.direction || SortDirection.Descending,
            show: update.show || filter,
            client: update.client || "",
            team: update.team || "",
            sort: update.sort || sort,
            text: update.text !== undefined ? update.text : filterText,
            notAssignedType: update.notAssignedType || notAssignedType,
            view: update.view || view,
            subcategory: update.subcategory || subcategory
        };

        updateQueryFromFilter(result);
    }

    const getStatisticsTabs = (): Array<ITabData> => {

        if (role && role.isClient) return [];

        const result: Array<ITabData> = [];

        result.push({data: TicketSubcategory.All, label: "Alle"});
        result.push({data: TicketSubcategory.Unanswered, label: "Unbeantwortet", permissions: [ "statistics.own.read" ]});
        result.push({data: TicketSubcategory.WaitingForClient, label: "Auf Mandant warten", permissions: [ "statistics.own.read" ]});
        result.push({data: TicketSubcategory.Escalated, label: "Eskaliert", permissions: [ "statistics.own.read" ]});

        if (filter !== TicketFilter.ForMe) {
            result.push({data: TicketSubcategory.External, label: "Extern", permissions: [ "tickets.external.read" ]});
            result.push({data: TicketSubcategory.Intern, label: "Intern", permissions: [ "tickets.internal.read" ]});
        }

        if (filter === TicketFilter.ForMe) result.push({data: TicketSubcategory.ClosedPastWeek, label: "Abgeschlossen aktuelle Woche", permissions: [ "statistics.own.read" ]});

        return result;
    }

    const getFilterTabs = (): Array<ITabData> => {
        const result: Array<ITabData> = [];

        result.push({data: TicketFilter.Closed, label: "Geschlossen", priority: 2});
        result.push({data: TicketFilter.Archive, label: "Archiv", priority: 2});
        result.push({data: TicketFilter.NotAssigned, permissions: [ "tickets.unassigned.read" ], label: "Ohne Zuweisung", first: true, priority: 1, hidden: !role || (role.displayName === UserRole.Clerk || role.displayName === UserRole.Client)});
        result.push({data: TicketFilter.ForMe, first: true, priority: 1, label: isClient ? "Offen" : "Für Mich"});
        result.push({data: TicketFilter.MyTeam, hidden: isClient, priority: 1, label: "Team"});
        result.push({data: TicketFilter.All, label: "Alle", hidden: isClient, priority: 1});

        return result;
    }

    const getNotAssignedTypeTabs = (): Array<ITabData> => {
        const result: Array<ITabData> = [];

        result.push({data: TicketNotAssignedType.Assignee, permissions: [ "tickets.unassigned.read" ], label: "Kein Bearbeiter"});
        result.push({data: TicketNotAssignedType.ClientContact, permissions: [ "tickets.unassigned.read" ], label: "Kein Mandantenkontakt"});
        result.push({data: TicketNotAssignedType.Client, permissions: [ "tickets.unassigned.read" ], label: "Kein Mandant"});
        result.push({data: TicketNotAssignedType.Team, permissions: [ "tickets.unassigned.read" ], label: "Kein Team"});

        return result;
    }

    const getViewTabs = (): Array<ITabData> => [
        {
            icon: "grid-fill",
            data: TicketView.Grid
        },
        {
            icon: "list",
            data: TicketView.List,
            iconSize: 28
        }
    ]

    const getSortOptions = (): Array<ISelectItem<string>> => {
        const result: Array<ISelectItem<string>> = [];

        if (!isClient) result.push({ label: "Typ", data: TicketSort.Type });

        result.push({ label: "Erstellt am", data: TicketSort.CreatedAt });
        result.push({ label: "Status", data: TicketSort.Status });
        result.push({ label: "Betreff", data: TicketSort.Subject });
        result.push({ label: "Aktualisiert am", data: TicketSort.UpdatedAt });
        
        return result;
    }

    const getFilterForRoute = (options: ITicketFilter = {}): ITicketFilter => {
        return {
            direction: direction || SortDirection.Descending,
            show: filter,
            client: client,
            notAssignedType: notAssignedType,
            subcategory: subcategory,
            sort: sort,
            max: options?.max || 100,
            team: team,
            text: filterText
        }
    }

    return {
        subcategory,
        getStatisticsTabs,
        getSortOptions,
        loading,
        filter,
        client,
        sort,
        team,
        view,
        filterText,
        getViewTabs,
        direction,
        matchingTickets,
        updateFilter,
        getFilterTabs,
        getNotAssignedTypeTabs,
        query,
        isShowingClosedTickets: filter === TicketFilter.Closed || filter === TicketFilter.Archive,
        getFilterForRoute: getFilterForRoute
    }
}
