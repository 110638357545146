import React from "react";
import Flex from "../container/Flex";
import { useMailTemplates } from "../../state/swr/templates/useMailTemplates";
import LoadingSpinner from "../loader/LoadingSpinner";
import MailTemplateListItem from "./MailTemplatesListItem";
import { useGlobalMailTemplates } from "../../state/swr/templates/useGlobalMailTemplates";
import Typography from "../text/Typography";
import { IGlobalMailTemplate } from "../../types/ApiTypes";
import MailTemplateTypeBanner from "./MailTemplateTypeBanner";
import UpdateMailTemplateButton from "./UpdateMailTemplateButton";
import WithPermissions from "../permissions/WithPermissions";

export default function TenantMailTemplatesList() {

    const [availableGlobalTemplates, setAvailableGlobalTemplates] = React.useState<IGlobalMailTemplate[]>([]);

    const { globalMailTemplates, loadingGlobalMailTemplates } = useGlobalMailTemplates();
    const { loadingMailTemplates, mailTemplates } = useMailTemplates();

    React.useEffect(() => {
        if (!globalMailTemplates || !globalMailTemplates.length) return setAvailableGlobalTemplates([]);
        setAvailableGlobalTemplates(globalMailTemplates.filter(t => !mailTemplates?.find(mt => mt.baseTemplate?.templateType === t.templateType)));
    }, [globalMailTemplates, mailTemplates]);

    return (
        <WithPermissions permissions={["templates.mails.read"]}>
            <Flex fullWidth gap={5}>
                <Flex row wrap fullWidth>
                    {
                        loadingMailTemplates
                        ? <LoadingSpinner />
                        : (
                            mailTemplates && mailTemplates.length
                            ? mailTemplates.map(t => <MailTemplateListItem key={t._id} template={t} />)
                            : <span>Noch keine Vorlagen</span>

                        )
                    }
                </Flex>
                <Flex fullWidth>
                    <Typography color="primary" bold >Globale Vorlagen</Typography>
                    <Flex row wrap fullWidth>
                        {
                            loadingGlobalMailTemplates 
                            ? <LoadingSpinner />
                            : (
                                availableGlobalTemplates && !!availableGlobalTemplates.length
                                ? availableGlobalTemplates.map(t => (
                                    <UpdateMailTemplateButton
                                        disabled={!t.canBeCustomized}
                                        baseTemplate={t}
                                        isCreate
                                        openButton={<MailTemplateTypeBanner clickable={t.canBeCustomized} key={t._id} template={t.templateType} />}
                                    />
                                )
                                )
                                : <span>Keine verfügbaren Vorlagen</span>
                            )
                        }
                    </Flex>
                </Flex>
            </Flex>
        </WithPermissions>
    )
}