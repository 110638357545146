import React from "react";
import { useAppDispatch } from "../../hooks/reduxHooks";
import Button from "../buttons/Button";
import { AppColor } from "../../app/AppStyles";
import { useMailLog } from "../../state/swr/log/useMailLogs";

export default function RefreshMailLogButton() {

    const { reloadMailLogs } = useMailLog();

    return <Button onClick={async () => await reloadMailLogs()} text="Aktualisieren" icon="arrow-clockwise" color="success" />
}