import React from "react";
import Button, { IButtonProps } from "../buttons/Button";

export interface IFileSelectButtonProps extends IButtonProps {
    handleSelectedFiles: (files: FileList) => void,
    accept: string
}

export default function FileSelectButton(props: IFileSelectButtonProps) {

    const {
        accept,
        handleSelectedFiles,
        disabled
    } = props;

    const [canSelect, setCanSelect] = React.useState<boolean>(false);

    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (!inputRef || !inputRef.current) setCanSelect(false);
        else setCanSelect(true);
    }, []);

    const resetValue = () => {
        if (!inputRef || !inputRef.current) return;
        inputRef.current.value = "";
    }

    const preventDefaults = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }
    
    const inputClick = (e: any) => {
        preventDefaults(e);
        if (!e || !e.target || !e.target.files || !e.target.files.length) return;
        handleSelectedFiles(e.target.files);
        resetValue();
    }

    const selectFile = async () => {
        if (!inputRef || !inputRef.current) return;
        resetValue();
        inputRef.current.click();
    }
    
    return (
        <>
            <Button onClick={selectFile} variant="outline" disabled={disabled || !canSelect} color={props.color} className={props.className} icon={props.icon} text={props.text} />
            <input ref={inputRef} name="file-select-button-hiden-input" accept={accept} multiple style={{visibility: "hidden", display: "none"}} type="file" onChange={inputClick} />
        </>
    )
}