import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Status } from "../../config/Status";
import PageLoader from "../loader/PageLoader";
import CouponGroup from "./CouponGroup";
import { useCoupons } from "../../state/swr/subscriptions/useCoupons";
import { useUser } from "../../state/swr/user/useUser";

export default function CouponOverview() {

    const {
        user
    } = useUser();

    const { coupons, loadingCoupons, reloadCoupons} = useCoupons();

    if (!user || !user.isSuperAdmin) return null;
    if (loadingCoupons) return <PageLoader />

    if (!coupons || !coupons.length) return <span>Keine Rabattgruppen. Legen Sie eine Rabattgruppe an, um Gutscheincodes zu erstellen.</span>

    return (
        <div className="d-flex flex-column gap-3">
            {
                coupons.map(c => <CouponGroup key={c._id} coupon={c} />)
            }
        </div>
    )
}