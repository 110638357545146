import React from "react";
import Pill from "../../pills/Pill";
import Icon from "../../icons/Icon";
import { ITicket } from "../../../types/ApiTypes";
import Flex from "../../container/Flex";
import useTicketUtil from "../../../hooks/useTicketUtil";
import Typography, { TypographySize } from "../../text/Typography";

export interface ITicketFriendlyIdBannerProps { 
    size?: TypographySize,
    bold?: boolean,
    ticket: ITicket
}

export default function TicketFriendlyIdBanner({ticket, bold, size = 24}: ITicketFriendlyIdBannerProps) {
    const {
        getDisplayId
    } = useTicketUtil();

    if (!ticket) return null;

    return <Typography size={size} wrap={false} truncate={false} bold={bold} color="primary">{getDisplayId(ticket)}</Typography>
}