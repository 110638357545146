import useSWR from "swr";
import { IClient, IDatevEmployeeResponsibility, IEmployee, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useEmployeeResponsibilities = () => {
    const { data, isLoading, mutate } = useSWR<Array<IDatevEmployeeResponsibility>>(ApiRoutes.Clients.GetEmployeeResponsibilities);

    return {
        employeeResponsibilities: data,
        loadingEmployeeResponsibilities: isLoading,
        reloadEmployeeResponsibilities: mutate
    }
}