import React from "react";
import { MailLogType, MailRejectReason } from "../../types/ApiTypes";
import Pill from "../pills/Pill";
import Icon from "../icons/Icon";

export default function MailRejectReasonBanner({reason}: {reason: MailRejectReason}) {
    const getText = (): string => {
        switch (reason) {
            case MailRejectReason.BlacklistedRecipient: return "Blacklist (Empfänger)";
            case MailRejectReason.BlacklistedSender: return "Blacklist (Sender)";
            case MailRejectReason.Other: return "Anderer";
            case MailRejectReason.ErrorWhileSending: return "Sendefehler";
            case MailRejectReason.MailSenderDisconnected: return "Sender getrennt";
            case MailRejectReason.NoContent: return "Kein Inhalt";
            case MailRejectReason.NoRecipient: return "Kein Empfänger";
            case MailRejectReason.NoSender: return "Kein Absender";
            case MailRejectReason.NoSubject: return "Kein Betreff";
            case MailRejectReason.SentToDuplicateNotifier: return "Doppeltes Event";
            case MailRejectReason.ExistsInLog: return "Existiert bereits im Log";
            case MailRejectReason.ExistsInQueue: return "Wird bereits verarbeitet";
            case MailRejectReason.HigherPrioritySystemRecipientsExist: return "Höher priorisierter System-Empfänger gefunden";
        }
    }

    const getColor = (): string => {
        switch (reason) {
            case MailRejectReason.BlacklistedRecipient: return "#48220D";
            case MailRejectReason.BlacklistedSender: return "#2A1A47";
            case MailRejectReason.ErrorWhileSending: return "#FA5258";
            case MailRejectReason.Other: return "#101010";
            case MailRejectReason.MailSenderDisconnected: return "#FF6522";
            case MailRejectReason.NoContent: return "#075BF5";
            case MailRejectReason.NoRecipient: return "#5E2DED";
            case MailRejectReason.HigherPrioritySystemRecipientsExist: return "#F5D007";
            case MailRejectReason.NoSender: return "#00CAE0";
            case MailRejectReason.NoSubject: return "#F2A083";
            case MailRejectReason.SentToDuplicateNotifier: return "#E2F7D9";
            case MailRejectReason.ExistsInLog: return "#88FABE";
            case MailRejectReason.ExistsInQueue: return "#44FAB4";
        }
    }
    
    const getIcon = (): string => {
        switch (reason) {
            case MailRejectReason.BlacklistedRecipient: 
            case MailRejectReason.BlacklistedSender: return "slash-circle-fill";

            case MailRejectReason.ErrorWhileSending: return "x";

            case MailRejectReason.MailSenderDisconnected: return "wifi-off";

            case MailRejectReason.Other: return "question-circle-fill";

            case MailRejectReason.NoContent: return "chat";

            case MailRejectReason.NoRecipient: return "person-fill-exclamation";
            case MailRejectReason.NoSender: return "person-fill-exclamation";

            case MailRejectReason.NoSubject: return "tag";

            case MailRejectReason.SentToDuplicateNotifier: return "clipboard-x";

            case MailRejectReason.HigherPrioritySystemRecipientsExist: return "patch-exclamation-fill";

            case MailRejectReason.ExistsInLog: return "stopwatch";
            case MailRejectReason.ExistsInQueue: return "stoplights";
        }
    }

    if (!reason) return null;
    
    return (
        <Pill color={getColor()} >
            <Icon icon={getIcon()} />
            <strong className="text-nowrap text-uppercase">{getText()}</strong>
        </Pill>
    )
}