import useSWR from "swr";
import { IPointChampApiKey, ITenant, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useTenant = () => {
    const { data, isLoading, mutate } = useSWR<ITenant>(ApiRoutes.Tenant.Active);

    return {
        tenant: data!,
        loadingTenant: isLoading,
        reloadTenant: mutate
    }
}