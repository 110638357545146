import React from "react"; 
import { usersDelete } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { IUserDeleteRequest } from "../../api/ApiRequests";
import Button from "../buttons/Button";

interface IDeleteButtonProps extends IUserDeleteRequest {
    afterDelete: () => void
}

export default function DeleteUserButton({userId, afterDelete}: IDeleteButtonProps) {

    const callApi = useApi();

    const deleteUser = async () => {
        const res = await callApi(usersDelete({userId: userId}), true);
        if (res) afterDelete();
    }

    return <Button type="button" icon="trash" color="error" loadingText="Wird gelöscht..." text="Nutzer löschen" onClick={deleteUser} /> 
}