import React from "react";
import { IPlan, IService } from "../../types/ApiTypes";
import Card from "../card/Card";
import PlanFeaturesContainer from "../subscription/PlanFeaturesContainer";
import PlanPrice from "../subscription/PlanPrice";
import IElementProps from "../../types/ElementProps";

export interface IEditablePlanCardProps extends IElementProps {
    plan: IPlan, 
    service: IService
}

export default function EditablePlanCard({plan, service, children}: IEditablePlanCardProps) {
    if (!plan || !service) return null;

    return (
        <Card className="d-flex text-center flex-column justify-content-between gap-2 h-100" style={{border: "1px solid var(--backgroundDarker)", minWidth: "150px"}}>
            <div className="d-flex flex-column gap-2">
                <h6 className="w-100 fw-bold m-0 p-0 text-uppercase">{plan.name}</h6>
                <PlanPrice plan={plan} />
                <PlanFeaturesContainer plan={plan} />
            </div>
            <div className="d-flex flex-column gap-1">
                {
                    children
                }
            </div>
        </Card>
    )
}