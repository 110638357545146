import React from "react";
import { IDocumentClearanceLog } from "../../types/ApiTypes";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";

export default function ClientDocumentClearanceLogItem({log}: {log: IDocumentClearanceLog}) {
    return (
        <TableRow>
            <TableCell date={log.createdAt} />
            <TableCell value={log.success} labelOnTrue="Erfolgreich versendet" labelOnFalse="Fehler" />
            <TableCell content={log.message || "Keine Nachricht"} />
            <TableCell content={log.reminder.timesReminded + 1} />
            <TableCell date={log.reminder.nextReminderOn} />
        </TableRow>
    )
}