import React from "react";
import { Permission } from "../types/ApiTypes";
import { useCurrentTenantRole } from "../state/swr/user/useCurrentTenantRole";
import usePermissions from "./usePermissions";
import { useSession } from "../state/swr/session/useSession";
import { useUser } from "../state/swr/user/useUser";

export default function useAuthorized(permissions: Array<Permission> | undefined) {
    const [isAuthorized, setIsAuthorized] = React.useState<boolean>(false);

    const {
        hasPermissions
    } = usePermissions();

    const {
        user,
        loadingUser
    } = useUser();

    const {
        loading,
        session
    } = useSession();

    const {
        role,
        loadingRole
    } = useCurrentTenantRole();

    React.useEffect(() => {
        setIsAuthorized(false);

        if (!permissions) {
            setIsAuthorized(true);
            return;
        }

        if (!role || !role.permissions || loadingRole) return;

        setIsAuthorized(hasPermissions(role.permissions, permissions));
    }, [permissions, role, session, loadingUser, user, loading, loadingRole])

    return {
        isAuthorized,
        loading: loadingRole || loading || loadingUser
    }
}