import React from "react";
import { ITicket } from "../../types/ApiTypes";
import Icon from "../icons/Icon";
import { useNavigate } from "react-router-dom";
import Pill from "../pills/Pill";
import Flex from "../container/Flex";
import TicketFriendlyIdBanner from "./banner/TicketFriendlyIdBanner";

export default function TicketLink({ticket}: {ticket: ITicket}) {

    const navigate = useNavigate();

    return (
        <Pill onClick={() => navigate(`/tickets/${ticket._id}`)} color="#D0D0E0">
            <Flex row>
                <Icon icon="link" size={20} hexColor="#00AAFF" />
                <TicketFriendlyIdBanner ticket={ticket} />
            </Flex>
        </Pill>
    )
}