import useSWR from "swr";
import { IDocumentClearanceReminder, ILogEntry, IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useDocumentClearanceReminder = (id: string) => {
    const { data, isLoading, mutate } = useSWR<IDocumentClearanceReminder>(ApiRoutes.DocumentClearance.ById.getValue({param: RouteParam.DocumentClearanceReminder, value: id}));

    return {
        documentClearanceReminder: data,
        loadingDocumentClearanceReminder: isLoading,
        reloadDocumentClearanceReminder: mutate
    }
}