import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IPropsWithStyle } from "../../types/ElementProps";
import { generateClassName } from "../../hooks/useAttributes";
import "./Editor.css";

export interface IEditorProps extends IPropsWithStyle {
    content?: string,
    onChange: (val: string) => void,
    disabled?: boolean,
    onBlur?: ((event: any, editor: ClassicEditor) => void) | undefined,
    onFocus?: ((event: any, editor: ClassicEditor) => void) | undefined,
    useFieldStyle?: boolean
}

export default function Editor({content, useFieldStyle, onChange, onBlur, onFocus, className, disabled, style}: IEditorProps) {

    const editorClass = generateClassName("editor", className, {
        value: useFieldStyle,
        onTrue: "editor-field"
    });

    return (
        <div className={editorClass} style={style}>
            <CKEditor 
                editor={ ClassicEditor }
                data={content}
                disabled={disabled}
                onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    onChange(data);
                }}
                onBlur={onBlur}
                onFocus={onFocus}
            />
        </div>
    )
}