import React from "react";
import Stripe from "stripe";
import Card from "../card/Card";
import PaymentMethodIcon from "./PaymentMethodIcon";
import Button from "../buttons/Button";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { tenantsDeletePaymentMethod, tenantsSetDefaultPaymentMethod } from "../../api/Api";
import { useTenant } from "../../state/swr/tenant/useTenant";
import { usePaymentMethods } from "../../state/swr/tenant/usePaymentMethods";
import WithPermissions from "../permissions/WithPermissions";

export default function PaymentMethod({paymentMethod}: {paymentMethod: Stripe.PaymentMethod}) {
    const { tenant } = useTenant();
    const { reloadPaymentMethods } = usePaymentMethods(tenant?._id || "");
    
    const callApi = useApi();

    if (!tenant) return null;

    const settingAsDefaultHandler = async () => await callApi(tenantsSetDefaultPaymentMethod({id: tenant._id, paymentMethodId: paymentMethod.id}), true);

    const deleteHandler = async () => {
        const res = await callApi(tenantsDeletePaymentMethod({id: tenant._id, paymentMethodId: paymentMethod.id}));
        if (!res || !res.success) return;
        await reloadPaymentMethods();
    }

    return (
        <WithPermissions permissions={[ "billing.tenant.read" ]}>
            <Card className="d-flex flex-row align-items-center justify-content-between">
                <div className="d-flex flex-column">
                    {
                        paymentMethod.card && <h6 className="fw-bold m-0 p-0">**** **** **** {paymentMethod.card.last4}</h6>
                    }
                    <PaymentMethodIcon paymentMethod={paymentMethod} />
                </div>
                <div className="d-flex flex-row align-items-center gap-2">
                    <Button color="secondary" onClick={settingAsDefaultHandler} text="Als Standard markieren" loadingText="Wird aktualisiert..." icon="star" />
                    <Button text="Löschen" color="error" onClick={deleteHandler} loadingText="Wird gelöscht..." icon="trash" />
                </div>
            </Card>
        </WithPermissions>
    )
}