
const hexToRgb = (hex: string): {r: number, b: number, g: number} | null => {
    if (!hex || typeof hex !== "string") return null;
    var result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex.trim());
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

export const hexWithOpacity = (color: string, opacity: number, isVar: boolean = false) => {
    try {
        const realInitialColor = isVar ? parseVarColorToHex(color) : color;
        const realOpacity = opacity < 0 ? 0 : (opacity > 1 ? 1 : opacity);
        const opacityHex = Math.floor(realOpacity * 255).toString(16).padStart(2, "0");

        const realColor = realInitialColor ? realInitialColor.replace("#", "").substring(0, 6) : "000000";

        return `#${realColor}${opacityHex}`;
    }
    catch { }

    return color;
}

export const generatePleasantBlueHex = () => {
    const hasMoreGreen = Math.random() > 0.5;
    const r = Math.floor(Math.random() * 50);
    const g = (hasMoreGreen ? 50 : 0) + Math.floor(Math.random() * 100);
    const b = 110 + Math.floor(Math.random() * 106); // 150 to 255

    const toHex = (value: number) => {
        let hex = value.toString(16);
        return hex.length < 2 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const getColorOrShade = (color?: string) => {
    if (!color) return generatePleasantBlueHex();
    if (color === "#F0F0F0") return generatePleasantBlueHex();
    return color;
}


export const parseVarColorToHex = (color?: string, standard: string = "#FFFFFF"): string => {

    if (!color) return parseVarColorToHex(standard);
    if (color.includes("#") && (color.length === 7 || color.length === 4 || color.length === 9)) return color;
    
    const computedValue = getComputedStyle(document.documentElement).getPropertyValue(`--${color}`);
    
    if (!computedValue) return "#FFFFFF";

    return computedValue;
}

export const getTextColorFromBackground = (color?: string, standard: string = "#000000"): string => {

    const realColor = parseVarColorToHex(color, standard);

    if (!realColor) return standard;

    const rgb = hexToRgb(realColor);

    if (!rgb) return standard;

    const brightness = (rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114);

    if (brightness > 186) return "#000000";
    
    return "#FFFFFF";

}