import React from "react";
import Page from "../../layout/Page";
import StartAzureLogInButton from "../../components/azure/LinkOfficeAccountButton";
import LinkedAccountsOverview from "../../components/azure/LinkedAccountsOverview";
import { OfficeAccountType } from "../../types/ApiTypes";
import RoundMailButton from "../../components/mail/RoundMailButton";

export default function MailAddressManagement() {
    return (
        <Page 
            header="Integrierte Postfächer verwalten" 
            actionButtons={[<RoundMailButton key="send-round-mail-button" />, <StartAzureLogInButton key="start-azure-log-in-button" type={OfficeAccountType.Tenant} />]}
        >
            <LinkedAccountsOverview />
        </Page>
    )
}