import React from "react";
import ModalForm from "../modal/ModalForm";
import { ISendRoundMailRequest, RoundMailTargetType } from "../../api/ApiRequests";
import Flex from "../container/Flex";
import Button from "../buttons/Button";
import { useCurrentTenantAuthority } from "../../state/swr/user/useCurrentTenantAuthority";
import { useActionData } from "react-router-dom";
import { useUser } from "../../state/swr/user/useUser";
import Typography from "../text/Typography";
import FormikField from "../formik/FormikField";
import Editor from "../richText/Editor";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import { useAllTickets } from "../../state/swr/tickets/useAllTickets";
import { useClients } from "../../state/swr/clients/useClients";
import RoundMailTargetList from "./RoundMailTargetList";
import ClientTypeBanner from "../clients/ClientTypeBanner";
import useApi from "../../hooks/useApi";
import { roundMailsSend } from "../../api/Api";

export default function RoundMailButton() {

    const callApi = useApi();
    const { user } = useUser();
    const { authority } = useCurrentTenantAuthority();

    if ((!user || !user.isSuperAdmin) && (!authority || !authority.isTenantAdmin)) return null;

    return (
        <ModalForm 
            enableReinitialize
            submitText="Rundmail senden"
            submitIcon="send"
            button={<Button  text="Rundmail senden" icon="envelope" color="secondary" />}
            title="Neue Rundmail" 
            initialValues={{
                content: "",
                subject: "",
                targets: [],
                targetType: RoundMailTargetType.Clients
            } as ISendRoundMailRequest}
            onSubmit={async (values) => {
                const res = await callApi(roundMailsSend(values));
                if (!res || !res.success) return false;
                return true;  
            }}
        >
            {
                formik => (
                    <Flex fullWidth gap={3}>
                        <FormikField className="w-100" name="subject" label="Betreff" bold labelColor="primary" />
                        <Flex fullWidth>
                            <Typography bold color="primary">Inhalt</Typography>
                            <Editor
                                onChange={content => formik.setFieldValue("content", content)}
                                content={formik.values.content}
                                useFieldStyle
                                disabled={formik.isSubmitting} 
                            />
                        </Flex>
                        <RoundMailTargetList 
                            saveIds={ids => formik.setFieldValue("targets", ids)}
                            values={formik.values.targets}
                            saveTargetType={type => formik.setFieldValue("targetType", type)}
                        />
                    </Flex>
                )
            }
        </ModalForm>
    )
}