import useSWR from "swr";
import { IPointChampApiKey } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const usePointChampApiKey = () => {
    const { data, isLoading, mutate } = useSWR<IPointChampApiKey>(ApiRoutes.Integrations.PointChamp.GetPointChampApiKey);

    return {
        pointChampApiKey: data,
        loadingPointChampApiKey: isLoading,
        reloadPointChampApiKey: mutate
    }
}