import React from "react";
import Button from "../buttons/Button";
import ModalForm from "../modal/ModalForm";
import { Locale } from "../../locale/Locale";
import { Field, Form, Formik } from "formik";
import { AppColor } from "../../app/AppStyles";
import { ICouponCreateRequest, IFeatureCreateRequest, IPromotionCodeCreateRequest, IServiceCreateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import FieldWithLabel from "../formik/FormikField";
import CheckBox from "../formik/CheckBox";
import useApi from "../../hooks/useApi";
import { couponsCreate, featureCreate, promotionCodesCreate, servicesCreate } from "../../api/Api";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { CouponType, ICoupon, ITenant, TiggiFeature } from "../../types/ApiTypes";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import { useTenants } from "../../state/swr/tenant/useTenants";
import { usePromotionCodes } from "../../state/swr/subscriptions/usePromotionCodes";
import { useUser } from "../../state/swr/user/useUser";

export interface IPromotionCodeCreateValues extends IPromotionCodeCreateRequest {
    tenant?: ITenant
}

export default function CreatePromotionCodeButton({coupon}: {coupon: ICoupon}) {
    const callApi = useApi();

    const {
        user
    } = useUser();

    const { tenants } = useTenants();

    const { reloadPromotionCodes } = usePromotionCodes(coupon._id);

    function createCode() {
        const length = 6;
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    if (!user || !user.isSuperAdmin) return null;
    
    
    return (
        <ModalForm 
            title={Locale.pages.services.addPromotionCode} 
            button={<Button variant="text" text={Locale.pages.services.addPromotionCode} icon="plus" />}
            initialValues={{
                code: "",
                couponId: coupon._id,
                limitToTenant: "",
                tenant: undefined,
                maximumRedemptions: 0,
                minimumAmount: 0,
                onlyForFirstTimeTransactions: false
            } as IPromotionCodeCreateValues}
            onSubmit={async (values) => {
                const res = await callApi(promotionCodesCreate(values));

                if (!res) return false;

                await reloadPromotionCodes();
                return true;
            }}
            sidebar={formik => <Button type="submit" text="Gutscheincode erstellen" icon="plus" loading={formik.isSubmitting} />}
        >
            {
                (formik) => (
                    <Flex className="w-100">
                        <div className="d-flex flex-row align-items-center gap-2">
                            <FieldWithLabel bold noMargin name="code" label="Gutscheincode" inline placeholder="ABCDEFG..." className="w-100" />
                            <Button text="Code erstellen" onClick={async () => formik.setFieldValue("code", createCode())} icon="dice" />
                        </div>
                        {
                            tenants && !!tenants.length && (
                                <SearchableComboBox 
                                    className="w-100"
                                    label="Auf Tenant begrenzen" 
                                    values={tenants} 
                                    itemToId={v => v._id} 
                                    itemToString={v => v.name} 
                                    onItemClick={(v) => {
                                        formik.setFieldValue("tenant", v);
                                        formik.setFieldValue("limitToTenant", v._id);
                                    }}
                                />
                            )
                        }
                        <div className="d-flex flex-column gap-1">
                            <label htmlFor="promotion-codes-usages-field">Maximale Verwendungen (0 = unbegrenzt)</label>
                            <Field id="promotion-codes-usages-field" name="maximumRedemptions" type="number" min="0" step="1" className="form-control" placeholder="0" />
                        </div>
                        <div className="d-flex flex-column gap-1">
                            <label htmlFor="promotion-codes-minimum-amount-field">Minimaler Rechnungsbetrag in Cents für Anwendung des Codes</label>
                            <Field id="promotion-codes-minimum-amount-field" name="minimumAmount" type="number" min="0" step="1" className="form-control" placeholder="0" />
                        </div>
                        <CheckBox name="onlyForFirstTimeTransactions" label="Nur für Erstkunden" />
                    </Flex>
                )
            }
        </ModalForm>
    )
}