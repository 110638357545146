import React from "react";
import { ITask, Permission } from "../../types/ApiTypes";
import ModalForm from "../modal/ModalForm";
import { AppColor } from "../../app/AppStyles";
import Button from "../buttons/Button";
import { ICreateTaskRequest, IUpdateTaskRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { tasksCreate, tasksUpdate } from "../../api/Api";
import { useTicketTasks } from "../../state/swr/tickets/useTicketTasks";
import { useTasks } from "../../state/swr/tasks/useTasks";
import Flex from "../container/Flex";
import FormikField from "../formik/FormikField";
import Editor from "../richText/Editor";
import UserPicker from "../user/UserPicker";
import usePermissions from "../../hooks/usePermissions";
import useAuthorized from "../../hooks/useAuthorized";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import { useUser } from "../../state/swr/user/useUser";
import { useTeams } from "../../state/swr/teams/useTeams";
import useTeamsUtil from "../../hooks/useTeamsUtil";
import TicketSearchBox from "../ticketing/filter/TicketSearchBox";
import CheckBox from "../formik/CheckBox";

export interface ITaskFormProps {
    task?: ITask,
    ticket?: string
}

export default function TaskForm({task, ticket = ""}: ITaskFormProps) {
    
    const {
        user
    } = useUser();

    const {

    } = useTeams();


    const {
        isUserTeamMember
    } = useTeamsUtil();

    const callApi = useApi();

    const getPermissionsForTask = (): Array<Permission> => {
        if (!task) return [ `tasks.all.create`, `tasks.team.create`, `tasks.own.create` ];

        const result: Array<Permission> = [ "tasks.all.update" ];
    
        if (task.createdBy && user && user._id === task.createdBy._id) result.push("tasks.own.update");
        else if (isUserTeamMember(task.createdBy)) result.push("tasks.team.update");

        return result;
    }

    const {
        isAuthorized: accessToAllTasks
    } = useAuthorized([ `tasks.all.${task ? "update" : "create"}`]);

    const {
        isAuthorized: accessToTeamTasks
    } = useAuthorized([ `tasks.team.${task ? "update" : "create"}`]);

    const {
        reloadTicketTasks
    } = useTicketTasks(ticket || "");

    const {
        reloadTasks
    } = useTasks();
    
    const title = task ? "Aufgabe bearbeiten" : "Neue Aufgabe anlegen";
    const buttonText = task ? "Bearbeiten" : "Neue Aufgabe";
    const submitText = task ? "Speichern" : "Erstellen";
    const icon = task ? "pen" : "plus";
    const color: AppColor = task ? "primary" : "success";

    const getValues = () => {
        if (!task) return {
            assignedTo: (accessToAllTasks || accessToTeamTasks) ? [] : ( user && user._id ? [ user._id ] : []),
            dueDate: new Date(),
            subject: "",
            ticket: ticket,
            sendReminder: true,
            description: ""
        } as ICreateTaskRequest;

        return {
            assignedTo: task.assignedTo,
            dueDate: task.dueDate,
            id: task._id,
            sendReminder: task.sendReminder,
            subject: task.subject,
            ticket: task.ticket,
            description: task.description
        } as IUpdateTaskRequest;
    }   
    
    return (
        <WithPermissions permissions={getPermissionsForTask()} >
            <ModalForm
                button={<Button variant="text" icon={icon} text={buttonText} color={color} />}
                initialValues={getValues()}
                onSubmit={async (values) => {
                    const res = await callApi(task 
                    ? tasksUpdate(values as IUpdateTaskRequest) 
                    : tasksCreate(values as ICreateTaskRequest));
                    
                    if (ticket) await reloadTicketTasks();
                    else await reloadTasks();

                    return !!res && !!res.success;
                }}
                title={title}
                submitText={submitText}
                submitIcon={icon}
            >
                {
                    formik => {

                        const getIds = () => {
                            if (!formik.values.assignedTo || !formik.values.assignedTo.length) return [];
                            if (task) return formik.values.assignedTo.map((t: any) => t._id);
                            else return formik.values.assignedTo;
                        }

                        return (
                            <Flex>
                                <Flex row fullWidth>
                                    <FormikField name="subject" label="Titel" className="w-100" />
                                    <FormikField type="date" label="Fällig am" min={new Date().toLocaleDateString("fr-ca")} name="dueDate" className="w-100" />
                                </Flex>
                                <CheckBox name="sendReminder" label="Erinnerungsmails versenden" />
                                <Typography color="primary">Beschreibung</Typography>
                                <Editor 
                                    useFieldStyle
                                    onChange={(val: string) => formik.setFieldValue("description", val)}
                                    content={formik.values.description}
                                />
                                {
                                    (accessToAllTasks || accessToTeamTasks) && (
                                        <UserPicker
                                            displayed={accessToAllTasks ? "employees" : "teamMembers"}
                                            label="Zuweisung"
                                            saveValues={val => formik.setFieldValue("assignedTo", val)}
                                            values={getIds()}
                                        />
                                    )
                                }
                            </Flex>
                        );
                    }
                }
            </ModalForm>
        </WithPermissions>
    )
}