import useSWR from "swr";
import { IClient, IEmployee, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useDatevEmployees = () => {
    const { data, isLoading, mutate } = useSWR<Array<IEmployee>>(ApiRoutes.Employees.All);

    return {
        datevEmployees: data,
        loadingDatevEmployees: isLoading,
        reloadDatevEmployees: mutate
    }
}