import React from "react";
import { ILogEntry, LogEntryType } from "../../types/ApiTypes";
import Pill from "../pills/Pill";
import Icon from "../icons/Icon";

export const getColorFromLogEntryType = (type: LogEntryType) => {
    switch (type) {
        case LogEntryType.Error: return "#FF0000";
        case LogEntryType.General: return "#0E294B";
        case LogEntryType.Event: return "#F3A505";
        case LogEntryType.Schedule: return "#6C6960";
    }
}

export const getIconFromLogEntryType = (type: LogEntryType) => {
    switch (type) {
        case LogEntryType.Error: return "bug";
        case LogEntryType.General: return "info-circle";
        case LogEntryType.Event: return "lightning-charge";
        case LogEntryType.Schedule: return "stopwatch";
    }
}

export default function LogEntryTypeBanner({log}: {log: ILogEntry}) {

    const getText = () => {
        switch (log.type) {
            case LogEntryType.Error: return "Error";
            case LogEntryType.General: return "Info";
            case LogEntryType.Event: return "Event";
            case LogEntryType.Schedule: return "Zeitplan";
        }
    }

    return (
        <Pill color={getColorFromLogEntryType(log.type)}>
            <Icon icon={getIconFromLogEntryType(log.type)} />
            <span>{getText()}</span>
        </Pill>
    )
}