import React, { useState } from "react";
import { ModalType } from "../../config/ModalTypes";
import { generateClassName } from "../../hooks/useAttributes";
import useModal from "../../hooks/useModal";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import "./ImageSelect.css";

interface IImageSelectProps {
    image?: string,
    saveImage: (img: any) => void
}

export default function ImageSelect({saveImage, image}: IImageSelectProps) {

    const showModal = useModal();

    const [dragOver, setDragOver] = useState(false);
    const [hover, setHover] = useState(false);
    const [uploading, setUploading] = useState(false);

    const imageInputRef = React.useRef<HTMLInputElement>(null);

    const preventDefaults = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }
    
    const changeDragOverStatus = (e: React.MouseEvent<HTMLDivElement>, val: boolean) => {
        preventDefaults(e);
        setDragOver(val);
    }

    const handleSelectedFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        preventDefaults(e);
        if (!e || !e.target || !e.target.files || !e.target.files.length) return;
        handleFiles(e.target.files);
    }

    const handleDroppedFiles = (e: React.DragEvent<HTMLDivElement>) => {
        
        preventDefaults(e);
        setDragOver(false);

        if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length) return;

        handleFiles(e.dataTransfer.files);
    }

    const handleFiles = (files: FileList) => {
        if (files.length > 1) {
            showModal({text: "Bitte nur eine Datei auswählen.", type: ModalType.Error});
            return;
        }

        uploadFile(files[0]);
    }

    const uploadFile = (file: Blob) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => saveImage(reader.result);
    }

    const uploadFileInstructions = (
        <div className="position-relative w-100 d-flex flex-column align-items-center justify-content-center">
            <Icon icon="image" className="image-select-icon" />
            <span>Datei per Drag & Drop hierherziehen oder</span><span  className="ms-1 image-select-upload-button"> Klicken, um eine Datei auszuwählen</span>
        </div>
    )

    const imageSelectContainerClass = generateClassName("d-flex position-relative flex-column justify-content-center fw-bold align-items-center image-select", {
        value: dragOver,
        onTrue: "image-select-drag-active"
    });

    const imageSelectInstructionsContainer = generateClassName("position-absolute top-0 left-0 image-select-instruction-overlay h-100 w-100", {
        value: hover,
        standard: "image-select-instruction-overlay-hidden" 
    })

    const imageSelectInstructionsContainerBackground = generateClassName("image-select-instruction-overlay-background h-100 w-100 position-absolute top-0 left-0", {
        value: hover,
        standard: "image-select-instruction-overlay-background-hidden"
    });

    return (
        <div className={imageSelectContainerClass} 
            onDragEnter={(e) => changeDragOverStatus(e, true)}
            onDragOver={(e) => changeDragOverStatus(e, true)}
            onDragLeave={(e) => changeDragOverStatus(e, false)}
            onDrop={(event) => handleDroppedFiles(event)}
            onClick={() => imageInputRef && imageInputRef.current && imageInputRef.current.click()}
            onMouseEnter={() => setHover(true)}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {
                image 
                ? (
                    <div className="position-relative w-100 h-100 d-flex flex-row align-items-center justify-content-center">
                        <img className="image-select-image p-2" src={image} />
                        <div className={imageSelectInstructionsContainer}>
                            <div className="position-relative h-100 w-100 d-flex flex-column justify-content-center align-items-center ">
                                <div className={imageSelectInstructionsContainerBackground} />
                                {
                                    uploadFileInstructions
                                }
                            </div>
                        </div>
                    </div>
                )
                : (
                    uploading 
                    ? <LoadingSpinner />
                    : uploadFileInstructions
                )
            }
            <input ref={imageInputRef} className="image-select-file-input" type="file" onChange={(e) => handleSelectedFiles(e)} />
        </div>
    )
}