import useSWR from "swr";
import { IAlias, IClient, ITeam, IUserClients, IUserDefaultTeams, IUserProfilePicture } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useAnyUserDefaultTeam = (id: string) => {
    const { data, isLoading, mutate } = useSWR<IUserDefaultTeams>(ApiRoutes.User.Teams.Default.getValue({ param: RouteParam.UserId, value: id}));

    return {
        defaultTeam: data,
        loadingDefaultTeam: isLoading,
        reloadDefaultTeam: mutate
    }
}