import React from "react";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../components/formik/CheckBox";
import UserList from "../../components/user/UserList";
import UserUpdateForm from "../../components/user/UserUpdateForm";
import { AppRoutes } from "../../config/AppRoutes";
import { AppColor } from "../../app/AppStyles";
import { ModalType } from "../../config/ModalTypes";
import { useAppSelector } from "../../hooks/reduxHooks";
import useModal from "../../hooks/useModal";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import useUserUtil from "../../hooks/useUserUtil";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUsers } from "../../state/swr/user/useUsers";

export default function UserManagement() {

    const { users } = useUsers();

    const getTitle = () => {
        if (!users || !users.length) return Locale.titles.userManagement;

        return `${users.length} ${Locale.titles.userManagement.replace("Alle", "")}`;
    }

    return (
        <Page header={getTitle()} actionButtons={<UserUpdateForm />}>
            <UserList />
        </Page>
    )
}