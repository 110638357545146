import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes"
import IElementProps from "../../types/ElementProps"
import { getTextColorFromBackground } from "../../util/util";
import "./Pill.css";
import Typography from "../text/Typography";
import LoadingSpinner from "../loader/LoadingSpinner";
import Icon from "../icons/Icon";

interface IPillProps extends IElementProps {
    color?: string,
    text?: string,
    icon?: string,
    textColor?: string,
    rounded?: boolean,
    childPosition?: "start" | "end",
    onClickAsync?: () => Promise<any>,
}

export default function Pill({children, icon, childPosition = "end",  onClickAsync, style, rounded = false, textColor, onClick, text, color = "#FFFFFF", className, onMouseEnter, onMouseLeave, onMouseOver}: IPillProps) {

    const [loading, setLoading] = React.useState<boolean>(false);

    const clickHandler = async () => {
        if (onClick) return onClick();
        if (!onClickAsync) return;

        setLoading(true);
        try {
            await onClickAsync();
        }
        catch { }
        setLoading(false);
    }

    const pillStyle = generateStyle({
        name: "backgroundColor",
        value: color,
        isVar: !!color && !color.includes("#")
    }, {
        name: "color",
        value: textColor || getTextColorFromBackground(color, "#FFFFFF"),
        important: true
    });

    const pillClass = generateClassName("pill", className, {
        value: onClick !== undefined || onClickAsync != undefined,
        onTrue: "pill-clickable"
    }, {
        value: className && className.includes("d-flex"),
        standard: "d-flex align-items-center justify-content-center gap-2 flex-row"
    }, {
        value: rounded,
        onTrue: "pill-rounded"
    });

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseOver={onMouseOver} className={pillClass} style={style ? {...pillStyle, ...style} : pillStyle} onClick={clickHandler}>
            {
                loading && <LoadingSpinner size={12} />
            }
            {
                icon && <Icon icon={icon} />
            }
            {
                children || (
                    text && <Typography basedOnThisBackground={textColor ? undefined : color} color={textColor}>{text}</Typography>
                )
            }
        </div>
    )
}