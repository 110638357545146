import { Formik, Form, Field } from "formik";
import React from "react";
import { ticketAddNote } from "../../../api/Api";
import { ModalType } from "../../../config/ModalTypes";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { generateClassName } from "../../../hooks/useAttributes";
import useModal from "../../../hooks/useModal";
import useUserUtil from "../../../hooks/useUserUtil";
import { ITicket } from "../../../types/ApiTypes";
import { formatDate, formatDateTiggiStyle } from "../../../util/formatter";
import Button from "../../buttons/Button";
import Icon from "../../icons/Icon";
import "./TicketNotes.css";
import Typography from "../../text/Typography";
import Flex from "../../container/Flex";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";

export default function TicketNotes({ticket}: {ticket: ITicket}) {

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const [isAddingNote, setIsAddingNote] = React.useState<boolean>(false);

    const showModal = useModal();

    const { role } = useCurrentTenantRole();

    const {
        getName
    } = useUserUtil();

    if (!role || role.isClient) return null;

    const handleClass = generateClassName("ticket-notes-expander-handle d-flex gap-2 flex-row align-items-center justify-content-center", {
        base: "ticket-notes-expander-handle-",
        value: isExpanded,
        onTrue: "expanded"
    })
    
    const notesContainerClass = generateClassName("ticket-notes-container ", {
        base: "ticket-notes-container-",
        value: isExpanded,
        onTrue: "expanded p-3",
        standard: "hidden"
    })

    const iconClass = generateClassName("me-2 ticket-expander-icon", {
        value: isExpanded,
        onTrue: "ticket-expander-icon-expanded"
    })

    const formClass = generateClassName("ticket-notes-new-note-form d-flex flex-column align-items-start p-1", {
        value: isAddingNote,
        onTrue: "ticket-notes-new-note-form-expanded justify-content-between"
    })
    
    const formIconClass = generateClassName("ticket-notes-new-note-icon", {
        value: isAddingNote,
        onTrue: "ticket-notes-new-note-icon-expanded"
    });

    const formContentClass = generateClassName("ticket-notes-new-note-form-content d-flex flex-column", {
        value: isAddingNote,
        onTrue: "ticket-notes-new-note-form-content-expanded"
    })

    const formIconContainerClass = generateClassName("ticket-notes-new-note-icon-container d-flex align-items-center p-0 m-0", {
        value: isAddingNote,
        onTrue: "justify-content-end",
        standard: "justify-content-center"
    })

    const ticketNoteSaveButtonClass = generateClassName("ticket-notes-save-button", {
        value: isAddingNote,
        onTrue: "ticket-notes-save-button-expanded"
    })

    return (
        <div className="ticket-notes">
            <div className={handleClass} onClick={() => setIsExpanded(!isExpanded)}>
                <Typography bold size={14} color="primary">{isExpanded ? "Notizen verstecken" : "Notizen anzeigen"}</Typography>
                <Icon className={iconClass} icon="chevron-up" color="primary" />
            </div>
            <div className={notesContainerClass}>
                <div className="d-flex flex-row flex-wrap justify-content-start gap-3">
                    {
                        ticket && ticket.notes && !!ticket.notes.length 
                        ? (
                            ticket.notes.map((n, index) => {

                                const date = formatDate(n.createdAt); 

                                return (
                                    <div className="ticket-note d-flex flex-column p-3" key={n._id || `ticket-note-${index}`} >
                                        <Typography size={14} basedOnThisBackground="primary">
                                            {n.text}
                                        </Typography>
                                        <Flex row>
                                            <Typography size={9} element="em" basedOnThisBackground="primary">
                                                {typeof n.user !== "string" ? getName(n.user) : undefined}
                                            </Typography>
                                            <Typography size={9} element="em" basedOnThisBackground="primary">
                                                {date && formatDate(date)}
                                            </Typography>
                                        </Flex>
                                    </div>
                                )
                            })
                        )
                        : (
                            <div className="text-muted p-1" >
                                <Icon icon="info-circle" className="me-1" size={12} />
                                <span>Noch keine Notizen</span>
                            </div>
                        )
                    }
                    <Formik
                        initialValues={{
                            text: ""   
                        }}
                        onSubmit={async (values, actions) => {
                            if (!values || !values.text) return;

                            const res = await ticketAddNote({text: values.text, ticketId: ticket._id});

                            if (!res) {
                                showModal({
                                    text: "Die Notiz konnte nicht hinzugefügt werden.",
                                    type: ModalType.Error
                                });

                                return;
                            }

                            actions.resetForm();
                            setIsAddingNote(false);
                            
                        }}
                        >
                            {
                                (formik) => (
                                    <Form >
                                        <Flex gap={0}>
                                            <Flex className={formClass} >
                                                <div className="d-flex flex-row align-items-start w-100 h-100 justify-content-between">
                                                    <div className={formContentClass}>
                                                        <Field as="textarea" name="text" className="ticket-notes-note-text-field p-1" placeholder="Notiztext eingeben..." />
                                                    </div>
                                                    <div className={formIconContainerClass}>
                                                        <Icon className={formIconClass} icon="x" label={isAddingNote ? "" : "Neue Notiz"} size={18} onClick={() => setIsAddingNote(!isAddingNote)} />
                                                    </div>
                                                </div>
                                            </Flex>
                                            <Button type="submit" variant="text" loading={formik.isSubmitting} text="Speichern" className={ticketNoteSaveButtonClass}/>
                                        </Flex>
                                    </Form>
                                )
                            }
                    </Formik>
                </div>
            </div>
        </div>
    )
}