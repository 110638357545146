import React from "react";
import Button from "../buttons/Button";
import ModalForm from "../modal/ModalForm";
import { Locale } from "../../locale/Locale";
import { Field, Form, Formik } from "formik";
import { AppColor } from "../../app/AppStyles";
import { IPlanCreateRequest, IPlanUpdateRequest, IServiceCreateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import FieldWithLabel from "../formik/FormikField";
import CheckBox from "../formik/CheckBox";
import useApi from "../../hooks/useApi";
import { plansCreate, plansUpdate, servicesCreate } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { IPlan, IService } from "../../types/ApiTypes";
import LabelButton from "../buttons/LabelButton";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import useBillingInterval from "../../hooks/useBillingInterval";
import PlanFeaturesArray from "./PlanFeaturesArray";
import Flex from "../container/Flex";
import { useSortedFeatures } from "../../hooks/useSortedFeatures";
import { useServices } from "../../state/swr/subscriptions/useServices";
import { useUser } from "../../state/swr/user/useUser";

export default function UpdatePlanButton({service, plan}: {service: IService, plan: IPlan}) {

    const callApi = useApi();

    const {
        user
    } = useUser();

    const {
        billingIntervals
    } = useBillingInterval();

    const {
        convertPlanFeaturesToPlanRequestFeatures
    } = useSortedFeatures();

    const { reloadServices } = useServices();

    const planFeatures = convertPlanFeaturesToPlanRequestFeatures(plan.features);
    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <ModalForm 
            title={Locale.pages.services.updatePlan} 
            button={<Button icon="pen" text={Locale.pages.services.updatePlan} />}
            initialValues={{
                billingInterval: plan.billingInterval,
                name: plan.name,
                description: plan.description,
                price: plan.price || 100,
                serviceId: service._id,
                features: planFeatures,
                planId: plan._id
            } as IPlanUpdateRequest}
            onSubmit={async (values) => {
                const res = await callApi(plansUpdate(values));
                if (!res) return false;
                await reloadServices();
                return true;
            }}
            sidebar={formik => <Button type="submit" text="Plan speichern" icon="save" loading={formik.isSubmitting} />}
        >
            {
                (formik) => (
                    <Flex className="w-100" >
                        <strong>Metadaten</strong>
                        <FieldWithLabel name="name" noMargin label="Name des Plans" placeholder="Premium, Basis, Eigene Domain, 100 Tickets pro Tag..." />
                        <FieldWithLabel name="description" noMargin label="Beschreibung" placeholder="Zugriff auf das Ticketsystem, erweiterte Features für professionelle Anwender..." />
                        <SearchableComboBox 
                            label="Zahlungsintervall"
                            values={billingIntervals}
                            value={billingIntervals.find(b => b.value === formik.values.billingInterval)}
                            itemToId={v => v.value}
                            itemToString={v => v.key}
                            onItemClick={v => formik.setFieldValue("billingInterval", v.value)}
                        />
                        <div className="d-flex flex-column gap-1">
                            <label htmlFor="plan-price-field">Preis in Cent</label>
                            <Field id="plan-price-field" name="price" type="number" min="100" step="1" className="form-control" placeholder="0" />
                        </div>
                        <PlanFeaturesArray formik={formik} />
                    </Flex>
                )
            }
        </ModalForm>
    )
}