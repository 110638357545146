import useSWR from "swr";
import { IDatevEmployeeResponsibility, IDocumentClearanceReminder, ILogEntry, IOfficeAccount, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useClientUsers = (id: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IUser>>(ApiRoutes.Clients.ClientContacts.getValue({param: RouteParam.ClientId, value: id}), { revalidateOnFocus: false });
    
    return {
        clientUsers: data,
        loadingClientUsers: isLoading,
        reloadClientUsers: mutate
    }
}