import React from "react";
import {  } from "../../../api/ApiRequests";
import { IAttachment, ITicketMessage } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import useAttachments from "../../../hooks/useAttachments";
import TicketChatAttachmentIcon from "./TicketChatAttachmentIcon";
import Icon from "../../icons/Icon";
import { formatFileSize } from "../../../util/formatter";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { ticketMessagesDownloadAttachments } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import useDownloads from "../../../hooks/useDownloads";
import { hexWithOpacity } from "../../../util/color";
import { getTextColorFromBackground } from "../../../util/util";
import Flex from "../../container/Flex";

export default function TicketChatMessageAttachment({attachment, message, fullWidth, size = "small"}: {attachment: IAttachment, message: ITicketMessage, fullWidth?: boolean, size?: "small" | "regular"}) {
    
    const [hover, setHover] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const {
        downloadFile    
    } = useDownloads();

    const {
        getIcon
    } = useAttachments();

    const callApi = useApi();

    const clickHandler = async () => {
        setLoading(true);

        const res = await callApi(ticketMessagesDownloadAttachments({attachments: [attachment], id: message._id}));
        if (res && res.success) downloadFile(attachment, res.data.content);
        setLoading(false);
    }

    const containerClass = generateClassName("p-0 pe-2 overflow-hidden d-flex flex-row align-items-center gap-2", {
        value: fullWidth,
        onTrue: "w-100"
    })

    const fontContainerStyle = generateStyle({
        applyCondition: size === "small",
        name: "fontSize",
        value: "0.9",
        unit: "em"
    })

    const fileSizeStyle = generateStyle({
        name: "fontSize",
        value: "0.8",
        unit: "em",
        applyCondition: size === "small"
    }, {
        name: "marginTop",
        value: "-3",
        unit: "px"
    })

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onMouseOver={() => setHover(true)}>
            <Pill 
                rounded
                color={hexWithOpacity(hover ? "muted" : "bright", 1, true)}
                textColor={hexWithOpacity("primary", 1, true)}
                onClick={clickHandler} 
                className={containerClass}
            >
                <Flex className="p-2 ps-3">
                    <Icon size={size === "small" ? 24 : 26} loading={loading} color="primary" icon={hover ? "download" : getIcon(attachment)} />
                </Flex>
                <div className="pe-2 d-flex flex-column justify-content-center gap-0 pt-1 pb-1" style={fontContainerStyle}>
                    {
                        size === "regular"
                        ? <strong className="text-truncate text-break w-100">{attachment.name}</strong>
                        : <span>{attachment.name}</span>
                    }
                    <span style={fileSizeStyle} >{formatFileSize(attachment.size, true)}</span>
                </div>
            </Pill>
        </div>
    )
}