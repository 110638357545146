import React from "react";
import { useFetcher, useParams, useSearchParams } from "react-router-dom";
import { clientImportLogsDeleteById, clientImportLogsGetById, clientImportLogsGetLatest, clientsImportFromDatev, IApiResponse } from "../../api/Api";
import { QueryParams } from "../../config/AppRoutes";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { IClientImportLogEntry } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import Card from "../card/Card";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import "./ImportClientsForm.css";
import { setClientImport } from "../../state/slices/clientImportSlice";
import useDatevImport from "../../hooks/useDatevImport";

export default function ClientImportProgress() {


    const [searchParams, setSearchParams] = useSearchParams();
    const [hasImport, setHasImport] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    const callApi = useApi();

    const {
        clientImportLog
    } = useAppSelector(state => state.clientImport);

    const dispatch = useAppDispatch();

    const removeImport = () => {
        setSearchParams(QueryParams.ImportId.getWithValueRemoved(searchParams));
        setHasImport(false);
    }

    const fetchImportStatus = async (abortController: AbortController, importId?: string) => {

        if (importId) setHasImport(true);

        setLoading(true);

        let currentImport: IApiResponse<IClientImportLogEntry> | null;

        if (importId) currentImport = await callApi(clientImportLogsGetById({importId: importId}, abortController));
        else currentImport = await callApi(clientImportLogsGetLatest(abortController));

        if (abortController.signal.aborted) return;

        setLoading(false);
        
        if (!currentImport) {
            setSearchParams(QueryParams.ImportId.getWithValueRemoved(searchParams));
            if (clientImportLog) return;

            setHasImport(false);
            return;
        }

        if (!importId) {
            if (!currentImport.data || !currentImport.data._id) return;
            setSearchParams(QueryParams.ImportId.createNewValue(currentImport.data._id, searchParams));
            return;
        }

        setHasImport(true);
        
        dispatch(setClientImport(currentImport.data));
    }

    React.useEffect(() => {
        const importId = QueryParams.ImportId.getCurrentValue(searchParams);
        const abortController = new AbortController();
        
        fetchImportStatus(abortController, importId);

        return () => abortController.abort();
    }, [searchParams]);

    React.useEffect(() => {
        if (clientImportLog && !hasImport) setHasImport(true);
    }, [clientImportLog]);

    if (!hasImport) return null;
    if (!loading && !clientImportLog) return null;

    if (clientImportLog && clientImportLog.completedAt) {
        return (
            <Card>
                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                    <div className="d-flex flex-row align-items-center">
                        <Icon icon="check" color="success" size={30} />
                        <span>Import abgeschlossen!</span>
                    </div>
                    <DeleteClientImportLogButton log={clientImportLog} afterDelete={removeImport} />
                </div>
            </Card>
        )
    }

    if (clientImportLog && clientImportLog.failed) {
        return (
            <Card>
                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                    <div className="d-flex flex-row align-items-center">
                        <Icon icon="x" color="error" size={30} />
                        <span>Es ist ein Fehler aufgetreten!</span>
                    </div>
                    <DeleteClientImportLogButton log={clientImportLog} afterDelete={removeImport} text="Okay" color="error" />
                </div>
            </Card>
        )
    }

    return (
        <Card className="gap-2 w-100">
            <strong>Aktueller Import</strong>
            {
                loading 
                ? <LoadingSpinner text="Lädt Import..." centered={false} />
                : (
                    clientImportLog 
                    ? (
                        <div className="w-100">
                            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                <div className="d-flex flex-column gap-2">
                                    <LoadingSpinner size={18} text={clientImportLog.message} centered={false} />
                                    {
                                        clientImportLog.allErrors && !!clientImportLog.allErrors.length && <span>{clientImportLog.allErrors.length} Fehler</span>
                                    }
                                </div>
                                <DeleteClientImportLogButton log={clientImportLog} afterDelete={removeImport} text="Ausblenden" color="error" />
                            </div>
                        </div>
                    )
                    : <span>Beim Laden des Imports ist ein Fehler aufgetreten.</span>
                )
            }
        </Card>
    )
}

function DeleteClientImportLogButton({log, text = "Aktualisieren", color = "success", afterDelete}: {log: IClientImportLogEntry, text?: string, color?: AppColor, afterDelete: () => void}) {

    const { reloadDatevImport } = useDatevImport();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    const deleteClientImportLog = async () => {
        await callApi(clientImportLogsDeleteById({importId: log._id}));
        dispatch(setClientImport(null));
        
        await reloadDatevImport();
        
        afterDelete();
    }

    return (
        <Button className="align-self-end" text={text} onClick={deleteClientImportLog} color={color} loadingText="Bitte warten..." />
    )
}