import DOMPurify from "dompurify";
import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import LabelButton from "../../buttons/LabelButton";
import "./TicketChatHtmlMessageContent.css";
import IElementProps from "../../../types/ElementProps";

export interface ITicketChatHtmlMessageContentProps extends IElementProps {
    message: string,
    isEditForm: boolean,
    isPreview: boolean
}

export default function TicketChatHtmlMessageContent({isEditForm, message, isPreview, onClick, className}: ITicketChatHtmlMessageContentProps) {

    const replaceAllNewLineCharsWithHtmlLineBreaks = (text?: string) => text ? text.replace(/\n/g, "<br />") : "";
    const usableMessage = DOMPurify.sanitize(replaceAllNewLineCharsWithHtmlLineBreaks(message));

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const [cleanContent, setCleanContent] = React.useState<string>("");
    const [hasMore, setHasMore] = React.useState<boolean>(false);
    const [showEvenMore, setShowEvenMore] = React.useState<boolean>(false);
    const [signatureContent, setSignatureContent] = React.useState<string>("");

    const separateMessageFromEmailSignature = (message: string) => {
        const signatureRegex = /(<div[^>]*(x_){0,}gmail_signature[^>]*>(.*?)<\/div>.*)|(<div[^>]*(x_){0,}gmail_quote[^>]*>(.*?)<\/div>.*)|(<div[^>]*divRplyFwdMsg[^>]*>(.*?)<\/div>.*)|(<b[^>]*[^>]*>Von:(.*?)<\/b>.*)/g;
        const signature = message.match(signatureRegex);
        
        if (!signature) {
            setHasMore(false);
            return setCleanContent(message);
        } 

        const newMessage = message.replace(signatureRegex, "");

        setHasMore(true);
        setSignatureContent(signature[0]);
        setCleanContent(newMessage);
    }

    React.useEffect(() => {
        if (isEditForm) return setCleanContent(usableMessage);
        separateMessageFromEmailSignature(usableMessage);
    }, [usableMessage, isEditForm])

    const actualClass = generateClassName(className, "w-100 h-100 text-break text-wrap", {
        value: isPreview,
        onTrue: "ticket-chat-html-message-content-preview"
    }, {

    });

    if (isPreview) return <div dangerouslySetInnerHTML={{__html: cleanContent}} className={actualClass} onClick={onClick || undefined} />

    return (
        <div className="w-100 h-100 text-break text-wrap d-flex flex-column" style={{overflowX: "auto", maxHeight: "300px"}} onClick={onClick || undefined}>
            <div dangerouslySetInnerHTML={{__html: cleanContent}} className={actualClass} />
            {
                showEvenMore && <div dangerouslySetInnerHTML={{__html: signatureContent}} className={actualClass} />
            }
            {
                hasMore  && <LabelButton text={showEvenMore ? "Weniger anzeigen" : "Mehr anzeigen"} onClick={async () => setShowEvenMore(!showEvenMore)} />
            }
        </div>

    )
}