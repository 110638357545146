import useSWR from "swr";
import { IDatevEmployeeResponsibility, IDocumentClearanceReminder, ILogEntry, IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useClientEmployeeResponsibilities = (id: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IDatevEmployeeResponsibility>>(ApiRoutes.Clients.Responsibilities.ById.getValue({param: RouteParam.ClientId, value: id}), {
        revalidateOnFocus: false
    });

    return {
        clientEmployeeResponsibilities: data,
        loadingClientEmployeeResponsibilities: isLoading,
        reloadClientEmployeeResponsibilities: mutate
    }
}