import React from "react";
import { ticketAssignToTeam } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { generateClassName } from "../../../hooks/useAttributes";
import useElementBounds from "../../../hooks/useElementBounds";
import useUserUtil from "../../../hooks/useUserUtil";
import { ITeam, ITicket, IUser } from "../../../types/ApiTypes";
import Icon from "../../icons/Icon";
import "./AssignTicketToOtherTeamButton.css";
import AssignTicketToOtherTeamMemberButton from "./AssignTicketToOtherTeamMemberButton";
import Expandable from "../../comboBox/Expandable";
import Button from "../../buttons/Button";
import { useTicket } from "../../../state/swr/tickets/useTicket";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import WithPermissions from "../../permissions/WithPermissions";

interface IAssignTicketToOtherTeamButtonProps {
    ticket: ITicket,
    team: ITeam,
}

export default function AssignTicketToOtherTeamButton({team, ticket}: IAssignTicketToOtherTeamButtonProps) {

    const permissions = useTicketPermission("update", ticket);
    
    const { reloadTicketAssignee } = useTicketAssignee(ticket);
    const { reloadTicket } = useTicket(ticket._id);

    const hasMembers = team && team.members && !!team.members.length;

    return (
        <WithPermissions permissions={permissions}>
            <Expandable
                disabled={!hasMembers}
                expander={<Button disabled={!hasMembers} variant="text" icon="chevron-right" className="w-100" iconPosition="end">{team.name}</Button>}
            >
                {
                    team.members.map(u => (
                        <AssignTicketToOtherTeamMemberButton 
                            key={u._id} 
                            afterAssigningTicket={() => {
                                reloadTicket()
                                reloadTicketAssignee();
                            }} 
                            team={team} 
                            ticket={ticket} 
                            user={u} 
                        />
                    ))
                }
            </Expandable>
        </WithPermissions>
    );
}