import useSWR from "swr";
import { IMailLog, ITicket, ITicketActivity } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { useMutation } from "@tanstack/react-query";

export const useAllTickets = (closedOnly: boolean = false) => {
    const { data, isLoading, isValidating, mutate } = useTiggiQuery<Array<ITicket>>(closedOnly ? ApiRoutes.Tickets.Closed : ApiRoutes.Tickets.All);


    return {
        tickets: data,
        loadingTickets: isLoading,
        validatingTickets: isValidating,
        reloadTickets: mutate
    }
}