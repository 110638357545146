import useSWR from "swr";
import { IMailLog, ITicket, ITicketActivity } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useTicketMails = (ticketId: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IMailLog>>(ApiRoutes.Tickets.MailsForTicket.getValue({
        param: RouteParam.TicketId,
        value: ticketId
    }));

    return {
        ticketMails: data,
        loadingTicketMails: isLoading,
        reloadTicketMails: mutate
    }
}