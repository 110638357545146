import React from "react";
import ModalForm from "../modal/ModalForm";
import Flex from "../container/Flex";
import Button from "../buttons/Button";
import { ILogEntry } from "../../types/ApiTypes";
import { formatDate } from "../../util/formatter";
import LogEntrySystemBanner from "./LogEntrySystemBanner";
import LogEntryTypeBanner from "./LogEntryTypeBanner";
import LogEntryEventTypeBanner from "./LogEntryEventTypeBanner";
import LogEntryServiceBanner from "./LogEntryServiceBanner";
import CopyToClipboardButton from "../buttons/CopyToClipboardButton";
import ModalDialog from "../modal/ModalDialog";

export default function LogEntryForm({log}: {log: ILogEntry}) {

    return (
        <ModalDialog 
            title={`Log-Eintrag vom ${formatDate(log.updatedAt, true)}`}  
            button={<Button variant="icon" icon="eye" />}
        >
            {
                close => (
                    <Flex gap={3} className="w-100">
                        <Flex row>
                            <LogEntrySystemBanner log={log} />
                            <LogEntryTypeBanner log={log} />
                            <LogEntryEventTypeBanner log={log} />
                            <LogEntryServiceBanner log={log} />
                        </Flex>
                        <Flex gap={1} className="w-100">
                            <strong>Tenant</strong>
                            {
                                log.tenant 
                                ? <span>{log.tenant.name}</span>
                                : <span>-</span>
                            }
                        </Flex>
                        <Flex gap={1} className="w-100">
                            <Flex row justify="between" className="w-100">
                                <strong>Inhalt</strong>
                                <CopyToClipboardButton value={log.content} />
                            </Flex>
                            <span className="w-100 text-wrap text-break">{log.content}</span>
                        </Flex>
                        <Flex gap={1} className="w-100">
                            <Flex row justify="between" className="w-100">
                                <strong>Weitere Informationen</strong>
                                {
                                    log.additionalInfo && <CopyToClipboardButton value={log.additionalInfo} />
                                }
                            </Flex>
                            {
                                log.additionalInfo 
                                ? <span className="w-100 text-wrap text-break">{log.additionalInfo}</span>
                                : <span>-</span>
                            }
                        </Flex>
                        <Flex gap={1} className="w-100">
                            <Flex row justify="between" className="w-100">
                                <strong>Event-ID</strong>
                                {
                                    log.eventId && <CopyToClipboardButton value={log.eventId} />
                                }
                            </Flex>
                            {
                                log.eventId 
                                ? <span className="w-100 text-wrap text-break">{log.eventId}</span>
                                : <span>-</span>
                            }
                        </Flex>
                        <Flex gap={1} className="w-100">
                            <strong>Aufruf</strong>
                            {
                                log.methodName 
                                ? <span className="w-100 text-wrap text-break">{log.methodName}</span>
                                : <span>-</span>
                            }
                        </Flex>
                        <Button className="align-self-end" onClick={async () => close()} text="Schließen" icon="x" />
                    </Flex>
                )
            }
        </ModalDialog>
    )
}