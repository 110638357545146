import { Form, Formik } from "formik";
import React from "react";
import { usersUpdateCurrentSettings } from "../../api/Api";
import { IUserUpdateSettingsRequest } from "../../api/ApiRequests";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import useUserUtil from "../../hooks/useUserUtil";
import Button from "../buttons/Button";
import CheckBox from "../formik/CheckBox";
import FieldWithLabel from "../formik/FormikField";
import VerifiedPhoneNumber from "./phoneNumber/VerifiedPhoneNumber";
import { useUser } from "../../state/swr/user/useUser";
import Typography from "../text/Typography";
import Editor from "../richText/Editor";
import Flex from "../container/Flex";

export default function UserSettings() {

    const { user, reloadUser } = useUser();

    const {
        normalizeUser
    } = useUserUtil();


    const callApi = useApi();

    const dispatch = useAppDispatch();

    const normalizedUser = normalizeUser(user);

    return (
        <div className="d-flex flex-column w-100 h-100 gap-3">
            <VerifiedPhoneNumber user={normalizedUser} />
            <Formik
                enableReinitialize
                initialValues={normalizedUser.settings as IUserUpdateSettingsRequest}
                onSubmit={async (values) => {
                    const res = await callApi(usersUpdateCurrentSettings(values));

                    if (!res) return;

                    await reloadUser();
                }}
            >
                {
                    (formik) => (
                        <Form className="w-100">
                            <Flex fullWidth gap={3}>
                                <Flex fullWidth>
                                    <Typography color="primary" bold>Mail-Einstellungen</Typography>
                                    <CheckBox name="mails.receiveSystemNotifications" label="System-Emails erhalten" />
                                </Flex>
                                <Flex fullWidth>
                                    <Typography color="primary" bold>Abwesenheit</Typography>
                                    <CheckBox name="absence.sendAbsenceNotifications" label="Abwesenheitsbenachrichtigung aktivieren" />
                                    {
                                        formik.values.absence.sendAbsenceNotifications && ( 
                                            <>
                                                <em>Folgender Text wird versandt, wenn eine Mail an Sie erhalten wird, oder eine Aktivität für ein an Sie zugewiesenes Ticket aufgezeichnet wird und Sie <strong>nicht</strong> die Option der Weiterleitung von Tickets an einen Vertreter aktiviert haben.</em>
                                                <Editor
                                                    onChange={(value) => formik.setFieldValue("absence.absenceNotificationMessage", value)}
                                                    content={formik.values.absence.absenceNotificationMail} 
                                                />
                                            </>
                                        )
                                    }
                                    <CheckBox name="absence.assignTicketsToDeputy" label="Tickets während Abwesenheit an Stellvertreter zuweisen" />
                                </Flex>
                                <Button disabled={!formik.dirty} loading={formik.isSubmitting} type="submit" icon="save" text="Speichern" color="success" />
                            </Flex>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}