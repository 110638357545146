import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../components/card/Card";
import DeleteAllDatevImportsButton from "../components/clients/DeleteAllDatevImportsButton";
import GlobalSettings from "../components/settings/GlobalSettings";
import VerifiedPhoneNumber from "../components/user/phoneNumber/VerifiedPhoneNumber";
import UserSettings from "../components/user/UserSettings";
import { AppRoutes } from "../config/AppRoutes";
import { useAppSelector } from "../hooks/reduxHooks";
import useUserUtil from "../hooks/useUserUtil";
import Page from "../layout/Page";
import { Locale } from "../locale/Locale";
import TabSwitcher from "../components/tabswitcher/TabSwitcher";
import TenantUpdateForm from "../components/tenant/settings/TenantUpdateForm";
import ConnectorSettings from "../components/connectors/ConnectorSettings";
import { useTenant } from "../state/swr/tenant/useTenant";
import { useUser } from "../state/swr/user/useUser";
import useTenantSettings from "../hooks/useTenantSettings";

enum SettingsTab {
    User = "user",
    Portal = "portal",
    Interfaces = "interfaces",
    Tenant = "tenant"
}
export default function Settings() {
    const {
        titles
    } = useTenantSettings(true);

    const [tab, setTab] = React.useState<SettingsTab>(SettingsTab.User);

    const { tenant } = useTenant();
    const { user } = useUser();

    const {
        canManageTenant
    } = useUserUtil();

    const isTenantAdmin = canManageTenant(user);

    const isSuperAdmin = user && user.isSuperAdmin;

    if (!user) return null;
    
    const getContent = () => {
        switch (tab) {
            case SettingsTab.Interfaces: return <ConnectorSettings />;
            case SettingsTab.Portal: return <GlobalSettings />;
            case SettingsTab.Tenant: return tenant ? <TenantUpdateForm tenant={tenant} canDelete={false} /> : null;
            case SettingsTab.User: return <UserSettings />
        }
    }
    return (
        <Page header={Locale.titles.settings} >
            <TabSwitcher tabQueryParamKey="details" saveActiveTab={(t) => setTab(t as SettingsTab)} tabs={[
                    {
                        data: SettingsTab.User,
                        label: "Ihr Konto",
                        icon: "person-badge-fill"
                    },
                    {
                        data: SettingsTab.Tenant,
                        label: titles?.tenantWord || "Ihre Kanzlei",
                        icon: "building",
                        hidden: !isTenantAdmin && !!tenant
                    }, 
                    {
                        data: SettingsTab.Portal,
                        icon: "gear-wide-connected",
                        label: "Systemeinstellungen",
                        hidden: !isTenantAdmin
                    },
                    {
                        data: SettingsTab.Interfaces,
                        icon: "router-fill",
                        label: "Schnittstellen",
                        hidden: !isTenantAdmin
                    },
                ]} 
            />
            <div className="h-100" style={{overflowY: "auto"}}>
                <Card>
                    {getContent()}
                </Card>
            </div>
        </Page>
    )
}