import useSWR from "swr";
import { IPointChampApiKey, ITenant, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useTenants = () => {
    const { data, isLoading, mutate } = useSWR<Array<ITenant>>(ApiRoutes.Tenant.All);

    return {
        tenants: data,
        loadingTenants: isLoading,
        reloadTenants: mutate
    }
}