import React from "react";
import Flex from "../container/Flex";
import FormikField from "../formik/FormikField";
import Typography from "../text/Typography";
import LoadingSpinner from "../loader/LoadingSpinner";
import { IAlias } from "../../types/ApiTypes";
import Card from "../card/Card";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { aliasDelete } from "../../api/Api";
import WithPermissions from "../permissions/WithPermissions";

export interface IAliasFormProps {
    isLoading: boolean,
    data: Array<IAlias> | undefined,
    mutate: () => Promise<any>
}

export default function AliasForm({data, isLoading, mutate}: IAliasFormProps) {
    
    const callApi = useApi();
    
    return (
        <WithPermissions permissions={["alias.all.read"]}>
            <Flex className="w-100">
                <WithPermissions permissions={[ "alias.all.create", "alias.own.create" ]}>
                    <FormikField className="w-100" label="Neues Alias speichern" name="aliasMailAddress" placeholder="Alias-Mailadresse eingeben..." />
                </WithPermissions>
                <Typography bold color="primary">Existierende Alias</Typography>
                {
                    isLoading 
                    ? <LoadingSpinner />
                    : (
                        data && data.length
                        ? data.map(a => (
                            <Card key={a._id}>
                                <Flex row justify="between" className="w-100">
                                    <Typography color="primary" bold size={14}>{a.aliasMailAddress}</Typography>
                                    <Button 
                                        variant="text" 
                                        onClick={async () => {
                                            await callApi(aliasDelete({id: a._id}));
                                            await mutate();
                                        }} 
                                        text="Löschen" 
                                        color="error" 
                                    />
                                </Flex>
                            </Card>
                        ))
                        : <Typography>Keine Alias vorhanden.</Typography>
                    )
                }
            </Flex>
        </WithPermissions>
    )
}