import React from "react";
import Page from "../../layout/Page";
import UpdateMailTemplateButton from "../../components/templates/UpdateMailTemplateButton";
import Flex from "../../components/container/Flex";
import PageHeader from "../../components/label/PageHeader";
import useTenantSettings from "../../hooks/useTenantSettings";
import { useUser } from "../../state/swr/user/useUser";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import TenantMailTemplatesList from "../../components/templates/TenantMailTemplatesList";
import GlobalMailTemplatesList from "../../components/templates/GlobalMailTemplatesList";

export enum MailTemplateTab {
    Tenant = "tenant",
    Global = "global"
}


export default function MailTemplatesPage() {
    const { titles } = useTenantSettings(true);

    const [tab, setTab] = React.useState<MailTemplateTab>(MailTemplateTab.Tenant);

    const { user } = useUser();
    
    const getContent = () => {
        if (!user || !user.isSuperAdmin || tab === MailTemplateTab.Tenant) return <TenantMailTemplatesList />
        return <GlobalMailTemplatesList />
    }

    return (
        <Page>
            <Flex row justify="between" className="w-100">
                <PageHeader text="Mailvorlagen" />
                {
                    tab === MailTemplateTab.Tenant && <UpdateMailTemplateButton isCreate />
                }
            </Flex>
            {
                user && user.isSuperAdmin && (
                    <TabSwitcher
                        tabQueryParamKey="show"
                        tabs={[
                            {
                                data: MailTemplateTab.Tenant,
                                label: `${titles?.tenantWord || "Tenant"}-Vorlagen`,
                                first: true
                            },
                            {
                                data: MailTemplateTab.Global,
                                label: "Globale Vorlagen"
                            }
                        ]}
                        saveActiveTab={(t) => setTab(t as MailTemplateTab)}
                    />
                )
            }
            {
                getContent()
            }
        </Page>
    )
}