import useSWR from "swr";
import { ITask } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useTasks = () => {
    const { data, isLoading, mutate } = useSWR<Array<ITask>>(ApiRoutes.Tasks.All);

    return {
        tasks: data,
        loadingTasks: isLoading,
        reloadTasks: mutate
    }
} 