import useSWR from "swr";
import { IClient, IUserClients } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useUserClients = () => {
    const { data, isLoading, mutate } = useSWR<IUserClients>(ApiRoutes.User.Authority.CurrentUserClients);
    
    return {
        currentUserClients: data,
        loadingCurrentUserClients: isLoading,
        reloadCurrentUserClients: mutate
    }
} 