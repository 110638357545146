import React from "react";
import "./ComboBoxItem.css";

export interface IComboBoxItemProps<T> {
    item: T,
    onHover?: (value: T) => void,
    onBlur?: () => void,
    getTitleFromItem: (value: T) => string,
    onClick: (value: T) => void
    renderItem?: (value: T) => React.ReactElement
}

export default function ComboBoxItem<T>({item, onHover, renderItem, onBlur, getTitleFromItem, onClick}: IComboBoxItemProps<T>) {
    const content = renderItem ? renderItem(item) : getTitleFromItem(item);
    
    if (!content) return null;

    return (
        <div 
            className="combo-box-item p-2 w-100 text-start" 
            onMouseEnter={onHover ? () => onHover(item) : undefined} 
            onMouseOver={onHover ? () => onHover(item) : undefined} 
            onMouseLeave={onBlur ? () => onBlur() : undefined} 
            onClick={() => onClick(item)}
        >
            <span className="w-100 h-100">
                {
                    content
                }
            </span>
            <div className="combo-box-item-background" />
        </div>
    )
}