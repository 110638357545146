import React from "react"; 
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { IAppRoute } from "../../config/AppRoutes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { getTextColorFromBackground } from "../../util/util";
import Icon from "../icons/Icon";
import "./SideNavLink.css";
import { NavGroup, NavTab } from "./SideNav";
import { setActiveGroup, setActiveTab, setSidebarExpanded } from "../../state/slices/sidebarSlice";
import SideNavElement from "./SideNavElement";
import { hexWithOpacity } from "../../util/color";
import { Permission } from "../../types/ApiTypes";
import WithPermissions from "../permissions/WithPermissions";

interface ISideNavLinkProps {
    tab: NavTab,
    parentGroup?: NavGroup,
    externalLink?: boolean,
    openInNewTab?: boolean,
    to: IAppRoute | string,
    displayCondition?: boolean,
    icon?: string,
    text?: string,
    otherValidPaths?: IAppRoute[],
    permissions?: Array<Permission>
}

export default function SideNavLink({externalLink, permissions, openInNewTab, to, icon, text, otherValidPaths, tab, parentGroup, displayCondition = true}: ISideNavLinkProps) {
    
    const {
        isExpanded,
        activeTab,
        activeGroup
    } = useAppSelector(state => state.sidebar);

    const [isActive, setIsActive] = React.useState<boolean>(activeTab === tab);
    const [hover, setHover] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        const tabIsActive = activeTab === tab;

        setIsActive(tabIsActive);

        if (!tabIsActive) return;

    
    }, [activeTab]);

    React.useEffect(() => {

        const otherValidPathsMatch = !!otherValidPaths && !!otherValidPaths.length && otherValidPaths.reduce((prev, curr) => {
            if (prev) return true;
            return !!matchPath(curr.path, location.pathname);
        }, false);

        const active = typeof to !== "string" && !!matchPath(location.pathname, to.path) || otherValidPathsMatch;

        setIsActive(active);

        if (active) activateTab();

    }, [location]);

    const activateTab = () => {

        dispatch(setActiveTab(tab));

        if (activeGroup === parentGroup) return;
        
        if (!!parentGroup) dispatch(setActiveGroup(parentGroup));
        else dispatch(setActiveGroup(NavGroup.None));
    }

    const clickHandler = () => {
        dispatch(setSidebarExpanded(false));
        activateTab();

        const link = typeof to === "string" ? to : to.path;
        
        if (!externalLink) {
            navigate(link);
            return;
        }
        
        if (openInNewTab) {
            window.open(link, "_blank");
            return;
        }

        window.location.href = link;
    }

    const className = generateClassName("side-nav-link d-flex flex-row align-items-center w-100 position-relative", {
        value: isActive,
        onTrue: "side-nav-link-active"
    });

    if (!displayCondition) return null;
    
    const isInsideGroup = parentGroup !== undefined;

    const content = (
        <div 
            style={generateStyle({
                name: "backgroundColor",
                value: hexWithOpacity("secondary", 0.2, true),
                applyCondition: (hover || isActive) && !isInsideGroup
            }, {
                name: "color",
                applyCondition: isInsideGroup,
                value: (hover || isActive) ? "white" : "var(--secondary)"
            })}
            className={className} 
            onClick={clickHandler}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)} 
        >
            <SideNavElement isInsideGroup={isInsideGroup} icon={icon || (to && typeof to !== "string" ? to.icon : "")} label={text || (typeof to !== "string" ? to.title : "")} />
        </div>
    )

    if (!permissions || !permissions.length) return content;

    return (
        <WithPermissions permissions={permissions}>
            {content}
        </WithPermissions>
    )
}