import React from "react";
import { IRole, Permission } from "../../types/ApiTypes";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "./WithPermissions";
import Button from "../buttons/Button";
import { IRoleCreateRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { rolesCreate, rolesUpdate } from "../../api/Api";
import Flex from "../container/Flex";
import FormikField from "../formik/FormikField";
import CheckBox from "../formik/CheckBox";
import Typography from "../text/Typography";
import PermissionGroup from "./PermissionGroup";
import PermissionCheckboxes from "./PermissionCheckboxes";
import { useTenant } from "../../state/swr/tenant/useTenant";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Icon from "../icons/Icon";
import RoleBanner from "../user/RoleBanner";

export interface IUpdateRoleFormProps {
    role?: IRole
}

export default function UpdateRoleForm({role}: IUpdateRoleFormProps) {
    
    const {
        tenant
    } = useTenant();

    const callApi = useApi();

    const permission: Permission = role 
    ? "roles.all.update"
    : "roles.all.create";

    const title = role ? "Rolle ändern" : "Rolle erstellen";
    const buttonText = role ? "Ändern" : "Erstellen";
    const buttonIcon = role ? "pen" : "plus";

    return (
        <WithPermissions permissions={[ permission ]}>
            <ModalForm
                title={title}
                button={<Button text={buttonText} icon={buttonIcon} />}
                initialValues={{
                    accessLevel: role?.accessLevel || 0,
                    permissions: role?.permissions || [],
                    displayName: role?.displayName || "",
                    isClient: role?.isClient || false,
                    initialRoleId: role?.initialRoleId || "",
                    isInitialRole: role?.isInitialRole || false,
                } as IRoleCreateRequest}
                onSubmit={async (values) => {
                    const res = role
                    ? await callApi(rolesUpdate({...values, id: role._id}))
                    : await callApi(rolesCreate(values));

                    if (!res || !res.success) return false;

                    return true;
                }}
            >   
                {
                    (formik, close) => (
                        <Flex fullWidth gap={3}>
                            <Flex row fullWidth >
                                <FormikField name="displayName" label="Name" className="w-100" />
                                <FormikField name="accessLevel" label="Level (0-99)" className="w-25" min="0" max="99" type="number" />
                                <Flex>
                                    <Typography color="primary">Banner</Typography>
                                    <RoleBanner role={formik.values} />
                                </Flex>
                            </Flex>
                            <Flex fullWidth>
                                <Flex fullWidth row justify="between">
                                    <Flex gap={0}>
                                        <Typography bold color="primary" size={20}>Berechtigungen</Typography>
                                        { role?.isInitialRole && <Typography italic>Diese Rolle wird vom System verwaltet. Bitte teilen Sie uns Anpassungswünsche mit oder erstellen Sie Ihre eigene Rolle, um Anpassungen vorzunehmen.</Typography>}
                                    </Flex>
                                    {
                                        formik.values.isClient
                                        ? <Icon icon="person" labelColor="secondary" label="Mandantenrolle" labelClass="fw-bold" color="muted" />
                                        : <Icon icon="person-fill" labelColor="secondary" label="Mitarbeiterrolle" labelClass="fw-bold" color="muted" />
                                    }
                                </Flex>
                                <PermissionGroup label="Tickets" >
                                    <PermissionCheckboxes name="tickets.all" label="Alle" disabled={formik.values.isClient} readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="tickets.own" label="Eigene" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="tickets.external" label="Extern" disabled={formik.values.isClient} readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="tickets.internal" label="Intern" disabled={formik.values.isClient} readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="tickets.unassigned" label="Ohne Zuweisung" disabled={formik.values.isClient} readOnly={role?.isInitialRole}  description="Erlaubt die Einsicht oder Verwaltung von Tickets ohne Zuweisung sowie die Verwaltung der Ticketzuweisung."  />
                                </PermissionGroup>
                                <PermissionGroup label="Benutzer" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="users.clientContacts" label="Mandantenkontakte" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="users.employees" label="Mitarbeiter" readOnly={role?.isInitialRole} />
                                </PermissionGroup>
                                <PermissionGroup label="Mandanten" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="clients.all" label="Alle" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="clients.own" label="Eigene" readOnly={role?.isInitialRole} />
                                </PermissionGroup>
                                <PermissionGroup label="Addressaten" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="addressees.all" label="Alle" readOnly={role?.isInitialRole} />
                                </PermissionGroup>
                                <PermissionGroup label="Mitarbeiterzuständigkeiten" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="employeeResponsibilities.all" readOnly={role?.isInitialRole} label="Alle" description="Erlaubt das Einsehen von Zuständigkeiten der Mitarbeiter für Mandanten."/>
                                </PermissionGroup>
                                <PermissionGroup label="Mandantenzuständigkeiten" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="clientResponsibilities.all" readOnly={role?.isInitialRole} label="Alle" description="Erlaubt das Einsehen von Zuständigkeiten zwischen Mandant und etwa wirtschaftlich Berechtigten."/>
                                </PermissionGroup>
                                <PermissionGroup label="Addressatenbeziehungen" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="addresseeRelationships.all" readOnly={role?.isInitialRole} label="Alle" description="Erlaubt das Einsehen von Zuständigkeiten zwischen verschiedenen Addressaten."/>
                                </PermissionGroup>
                                <PermissionGroup label="Postfächer" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="inboxes.all" label="Alle" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="inboxes.own" label="Eigene" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="inboxes.teams" label="Teams" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="inboxes.tenant" label="Kanzlei / Info" readOnly={role?.isInitialRole} />
                                </PermissionGroup>
                                <PermissionGroup label="Teams" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="teams.all" label="Alle" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="teams.own" label="Eigene" readOnly={role?.isInitialRole} />
                                </PermissionGroup>
                                <PermissionGroup label="Aufgaben & Termine" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="tasks.all" label="Alle" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="tasks.own" label="Eigene" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="tasks.team" label="Teams" readOnly={role?.isInitialRole} />
                                </PermissionGroup>
                                <PermissionGroup label="Statistiken" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="statistics.tenant" label="Kanzlei" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="statistics.own" label="Eigene" readOnly={role?.isInitialRole} />
                                    <PermissionCheckboxes name="statistics.team" label="Teams" readOnly={role?.isInitialRole} />
                                </PermissionGroup>
                                <PermissionGroup label={tenant?.settings?.titles?.documentClearanceWord || "Belegfreigabe"} disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="documentClearance.all" label="Alle" readOnly={role?.isInitialRole} description="Erlaubt den Zugriff auf unterschiedliche Mandate. Die Bestätigung kann auch ohne Berechtigung erfolgen, Mandanten haben immer Zugriff."/>
                                </PermissionGroup>
                                <PermissionGroup label="Alias-Mails" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="alias.all" label="Alle" readOnly={role?.isInitialRole} description="Erlaubt das Verwalten von Mandats-Alias ."/>
                                    <PermissionCheckboxes name="alias.own" label="Eigene" readOnly={role?.isInitialRole} description="Nutzer können eigene Alias-Mails festlegen."/>
                                </PermissionGroup>
                                <PermissionGroup label="Kanzlei" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="tenant.own" label="Kanzleiverwaltung" readOnly={role?.isInitialRole} description="Erlaubt es, Einstellungen wie Öffnungszeiten oder Eskalation für die Kanzlei festzulegen." />
                                </PermissionGroup>
                                <PermissionGroup label="Abo & Zahlung" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="billing.tenant" label="Kanzlei" readOnly={role?.isInitialRole} description="Erlaubt den Zugriff auf das Rechnungswesen." />
                                </PermissionGroup>
                                <PermissionGroup label="Domains" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="domains.tenant" label="Kanzlei" readOnly={role?.isInitialRole} description="Erlaubt den Zugriff auf die Domainverwaltung und kontrolliert die Erreichbarkeit Ihres Systems." />
                                </PermissionGroup>
                                <PermissionGroup label="Vorlagen" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="templates.mails" label="Mailvorlagen" readOnly={role?.isInitialRole} description="Erlaubt den Zugang zur Verwaltung der Mailvorlagen für die externe Kommunikation." />
                                </PermissionGroup>
                                <PermissionGroup label="Abwesenheiten & Fehlzeiten" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="absence.all" label="Alle" readOnly={role?.isInitialRole} description="Erlaubt den Zugang zur Fehlzeitenverwaltung aller Mitarbeiter." />
                                    <PermissionCheckboxes name="absence.own" label="Eigene" readOnly={role?.isInitialRole} />
                                </PermissionGroup>
                                <PermissionGroup label="Rollenverwaltung" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="roles.all" label="Alle"readOnly={role?.isInitialRole} description="Erlaubt den Zugang zur Berechtigungsverwaltung, wie etwa das Erstellen von neuen Rollen oder das Zuweisen von Rollen. Mitarbeitern mit dieser Kompetenz sollten Sie vertrauen können." />
                                </PermissionGroup>
                                <PermissionGroup label="Schnittstellen" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="interfaces.pointchamp" readOnly={role?.isInitialRole} label="PointChamp" description="Erlaubt das Verwalten der PointChamp-Integration." />
                                    <PermissionCheckboxes name="interfaces.cloudGateway" readOnly={role?.isInitialRole} label="CloudGateway" description="Erlaubt das Hinterlegen von Zugangsdaten für den Abruf von DATEV-Mandanteninformationen über CloudGateway." />
                                    <PermissionCheckboxes name="interfaces.datev" readOnly={role?.isInitialRole} label="DATEV" description="Erlaubt den direkten Zugriff auf unsere Schnittstellen zu DATEV, etwa den JSON-Import." />
                                </PermissionGroup>
                                <PermissionGroup label="Logbuch" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="logs.api" label="API" readOnly={role?.isInitialRole} description="Erlaubt den Zugriff auf das Logbuch des Servers. Dies ist sehr technisch und für wenige Nutzer von wirklichem Interesse, kann aber im Fall der Fälle aufschlussreich sein. Keine Sorge, wir schauen uns das sowieso an." />
                                    <PermissionCheckboxes name="logs.email" label="Mailversand" readOnly={role?.isInitialRole} description="Erlaubt den Zugriff auf das Mail-Log. Hier sehen Sie genau, wann eine Mail gesendet und empfangen wurde. Wir speichern den Inhalt Ihrer Nachrichten nicht." />
                                </PermissionGroup>
                                <PermissionGroup label="Sonstige" disabled={formik.values.isClient} >
                                    <PermissionCheckboxes name="countryCodes.all" label="Ländercodes" readOnly={role?.isInitialRole} description="Erlaubt das Einsehen von Ländercodes."/>
                                    <PermissionCheckboxes name="legalForms.all" label="Rechtsformen" readOnly={role?.isInitialRole} description="Erlaubt das Einsehen von Rechtsformen."/>
                                </PermissionGroup>
                            </Flex>
                        </Flex>
                    )
                }
            </ModalForm>
        </WithPermissions>    
    )
}