import React from "react";
import Page from "../../layout/Page";
import TicketOverview from "../../components/ticketing/lists/TicketOverview";

export default function Tickets() {

    return (
        <Page limitMaxHeight={false}>
            <TicketOverview />
        </Page>
    )
}