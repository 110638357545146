import React from "react";
import TemplatePlaceholderChip, { ITemplatePlaceholderActions } from "./TemplatePlaceholderChip";
import { IMailTemplateContent, TemplatePlaceholder } from "../../types/ApiTypes";
import { useFormikContext } from "formik";
import Flex from "../container/Flex";
import Typography from "../text/Typography";

export interface MailTemplateEditElementPlaceholdersProps extends ITemplatePlaceholderActions {
    selectedPlaceholder?: TemplatePlaceholder,
    currentText?: string,
    saveText: (newText: string) => void,
    availablePlaceholders?: TemplatePlaceholder[]
}

export default function MailTemplateEditElementPlaceholders({selectedPlaceholder, currentText, saveText, availablePlaceholders, onAdd, onRemove}: MailTemplateEditElementPlaceholdersProps) {
    const selectablePlaceholders = availablePlaceholders || [
        TemplatePlaceholder.TicketLink,
        TemplatePlaceholder.TaskSubject,
        TemplatePlaceholder.UserAdditionalPrefix,
        TemplatePlaceholder.UserTitle,
        TemplatePlaceholder.ClientName,
        TemplatePlaceholder.TicketId,
        TemplatePlaceholder.TicketMessage,
        TemplatePlaceholder.TicketSubject,
        TemplatePlaceholder.UserFirstName,
        TemplatePlaceholder.UserLastName,
        TemplatePlaceholder.DocumentClearanceLink,
        TemplatePlaceholder.SenderFirstName,
        TemplatePlaceholder.SenderLastName,
        TemplatePlaceholder.TenantName
    ];

    if (!selectablePlaceholders || !selectablePlaceholders.length) return null;
    
    return (
        <Flex className="w-100">
            <Typography color="primary">{selectedPlaceholder ? "Ausgewählter Platzhalter für dieses Element" : "Verfügbare Platzhalter"}</Typography>
            <Flex row wrap className="w-100">
                {
                    selectedPlaceholder
                    ? (
                        <TemplatePlaceholderChip 
                            placeholder={selectedPlaceholder} 
                            currentText={currentText} 
                            onAdd={onAdd}
                            onRemove={onRemove}
                            saveText={(newText: string) => saveText(newText)}
                        />
                    )
                    : (
                        selectablePlaceholders.map(p => (
                            <TemplatePlaceholderChip 
                                key={p} 
                                placeholder={p} 
                                currentText={currentText} 
                                onAdd={onAdd}
                                onRemove={onRemove}
                                saveText={(newText: string) => saveText(newText)}
                            />
                        ))
                    )
                }
            </Flex>
        </Flex>
    )
}