import React from "react";
import useTenantSettings from "../../hooks/useTenantSettings";
import Hyperlink from "../links/Hyperlink";
import { AppRoutes } from "../../config/AppRoutes";
import { useAppSelector } from "../../hooks/reduxHooks";
import { TenantLogoStyle } from "../../types/ApiTypes";
import { generateClassName } from "../../hooks/useAttributes";
import "./SideNavLogo.css";
import { useTenant } from "../../state/swr/tenant/useTenant";

export default function SideNavLogo() {
    const { tenant } = useTenant();

    const {
        theme,
        ui,
        titles
    } = useTenantSettings();

    const {
        sidebar
    } = ui;

    const {
        logoStyle
    } = sidebar;

    const logoClass = generateClassName("side-nav-logo", {
        value: logoStyle === TenantLogoStyle.LogoOnly,
        onTrue: "side-nav-logo-logo-only"
    })

    return (
        <Hyperlink to={AppRoutes.Home.path} className="navbar-brand pt-3 d-flex flex-row align-items-center w-100 justify-content-center gap-2">
            {
                logoStyle !== TenantLogoStyle.TextOnly && theme.logo && <img className={logoClass} src={theme.logo} />
            }
            {
                logoStyle !== TenantLogoStyle.LogoOnly && (
                    <h4 className="fw-bold text-wrap">
                        {titles.siteHeader || tenant?.name || "Ticketsystem"}
                    </h4>
                )
            }
        </Hyperlink>
    );

}