import useSWR from "swr";
import { ITicketStatistics } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useAnyTeamTicketStatistics = (id: string) => {
    const { data, isLoading, mutate } = useSWR<ITicketStatistics>(ApiRoutes.Tickets.Statistics.ForTeam.getValue({
        param: RouteParam.TeamId,
        value: id
    }), { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        teamTicketStatistics: data,
        loadingTeamTicketStatistics: isLoading,
        reloadTeamTicketStatistics: mutate
    }
}