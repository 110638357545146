import React from "react";
import {  } from "../../../api/ApiRequests";
import { IAttachment, IFileMetadata, IOfficeAccount, ITicketMessage } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import useAttachments from "../../../hooks/useAttachments";
import Icon from "../../icons/Icon";
import { formatFileSize } from "../../../util/formatter";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { officeAccountsGetAttachmentContent, ticketMessagesDownloadAttachments } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import useDownloads from "../../../hooks/useDownloads";
import { FileAttachment } from "@microsoft/microsoft-graph-types";
import TicketChatAttachmentIcon from "../../ticketing/ticketChat/TicketChatAttachmentIcon";
import Flex from "../../container/Flex";
import { hexWithOpacity } from "../../../util/color";

export default function MailAttachment({attachment, account, mailId, size = "small"}: {attachment: FileAttachment, size?: "small" | "regular", mailId: string, account: IOfficeAccount}) {
    
    const [hover, setHover] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const convertToFileAttachment = (): IFileMetadata => ({
        name: attachment.name!,
        size: attachment.size!,
        type: attachment.contentType!
    });

    const {
        downloadFile    
    } = useDownloads();

    const {
        getColor,
        getIcon
    } = useAttachments();

    const callApi = useApi();

    const clickHandler = async () => {
        setLoading(true);

        const content = await callApi(officeAccountsGetAttachmentContent({attachmentId: attachment.id!, id: account._id, mailId: mailId}));
        if (content && content.success) downloadFile(convertToFileAttachment(), content.data.contentBytes);
        setLoading(false);
    }

    const containerClass = generateClassName("p-0 pe-2 overflow-hidden d-flex h-100 flex-row align-items-center gap-2")

    const iconClass = generateClassName("d-flex flex-row align-items-center justify-content-center", {
        value: size === "small",
        base: "p-",
        onTrue: "1",
        standard: "2"
    })

    const iconStyle = generateStyle({
        name: "backgroundColor",
        value: "var(--muted)",
    }, {
        name: "width",
        value: size === "small" ? 35 : 45,
        unit: "px"
    }, {
        name: "height",
        value: size === "small" ? 35 : 45,
        unit: "px"
    })

    const fontContainerStyle = generateStyle({
        applyCondition: size === "small",
        name: "fontSize",
        value: "0.9",
        unit: "em"
    })

    const fileSizeStyle = generateStyle({
        name: "fontSize",
        value: "0.8",
        unit: "em",
        applyCondition: size === "small"
    }, {
        name: "marginTop",
        value: "-3",
        unit: "px"
    })

    const name = attachment.name ? attachment.name.length > 50 ? attachment.name.substring(0, 50) + "..." : attachment.name : "";

    return (
        <Pill 
            color={hexWithOpacity("muted", hover ? 1 : 0.3, true)}
            textColor={hexWithOpacity("primary", 1, true)}
            onClick={clickHandler} 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)} 
            onMouseOver={() => setHover(true)} 
            className={containerClass}
        >
            <Flex className="p-2 ps-3">
                <Icon size={size === "small" ? 24 : 26} loading={loading} color="primary" icon={hover ? "download" : getIcon(convertToFileAttachment())} />
            </Flex>
            <div className="pe-2 d-flex flex-column justify-content-center gap-0 pt-1 pb-1" style={fontContainerStyle}>
                {
                    size === "regular"
                    ? <strong className="text-truncate text-break w-100">{name}</strong>
                    : <span>{name}</span>
                }
                <span style={fileSizeStyle} >{formatFileSize(convertToFileAttachment().size, true)}</span>
            </div>
        </Pill>
    )
}