import React from "react"; 
import { tenantsDelete, tenantsRequestDelete, usersDelete } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { ITenantDeleteRequest } from "../../api/ApiRequests";
import Button from "../buttons/Button";
import { ITenant, TenantDeleteReason } from "../../types/ApiTypes";
import useModal from "../../hooks/useModal";
import { ModalType } from "../../config/ModalTypes";
import { generateClassName } from "../../hooks/useAttributes";
import "./TenantDeleteButton.css";
import { useUser } from "../../state/swr/user/useUser";

interface IDeleteTenantProps {
    tenant: ITenant,
    afterDelete: () => void
}

export default function TenantDeleteButton({tenant, afterDelete}: IDeleteTenantProps) {

    const {
        user
    } = useUser();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [isRefreshingToken, setIsRefreshingToken] = React.useState<boolean>(false);
    const [canBeDeleted, setCanBeDeleted] = React.useState<boolean>((tenant.tenantCanBeDeleted && tenant.deleteReason === TenantDeleteReason.Manual) || false);
    const [tenantDeleteToken, setTenantDeleteToken] = React.useState<string>("");

    const callApi = useApi();

    const showModal = useModal();

    const deleteTenant = async () => {

        if (!canBeDeleted) {
            showModal({
                text: "Bitte fordern Sie zuerst ein Token an, um diesen Tenant zu löschen.",
                type: ModalType.Error
            });
            return;
        }

        setLoading(true);

        const res = await callApi(tenantsDelete({tenantId: tenant._id, deleteTenantToken: tenantDeleteToken}), true);
        setLoading(false);
        if (res) afterDelete();
    }

    const requestTenantDeletionToken = async () => {
        if (canBeDeleted) setIsRefreshingToken(true);
        else setLoading(true);
        
        const res = await callApi(tenantsRequestDelete({tenantId: tenant._id}), true, "Das angeforderte Token zum Löschen des Tenants wurde erfolgreich an Ihre E-Mail-Addresse versandt.");

        if (canBeDeleted) setIsRefreshingToken(false);
        else setLoading(false);

        if (res) setCanBeDeleted(true);
    }

    const inputContainerClass = generateClassName("tenant-delete-token-container d-flex flex-column align-items-start", {
        base: "tenant-delete-token-container-",
        value: canBeDeleted,
        standard: "hidden",
        onTrue: "expanded"
    })

    const loadingText = canBeDeleted ? "Wird gelöscht..." : "Token wird angefragt...";
    const buttonText = tenant.isGlobalBaseTenant ? "Tenant ist unlöschbar" : (!canBeDeleted ? "Manuelle Löschung anfragen" : "Bestätigen und zur Löschung freigeben");
    
    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <div className="d-flex flex-column w-100 gap-2">
            {
                canBeDeleted && (
                    <div className={inputContainerClass}>
                        <label className="form-label m-0 mb-1">Token zum Löschen des Tenants</label>
                        <input className="form-control w-100" value={tenantDeleteToken} placeholder="Bitte kopieren Sie hier das Token zum Löschen des Tenants ein" onChange={(e) => setTenantDeleteToken(e.target.value)} />
                    </div>
                )
            }
            <div className="d-flex flex-row align-items-center justify-content-end gap-2">
                {
                    canBeDeleted && <Button type="button" color="secondary" icon="arrow-clockwise" loading={isRefreshingToken} loadingText="Neues Token wird angefragt..." text="Neues Token anfragen" onClick={requestTenantDeletionToken} /> 
                }
                <Button type="button" disabled={tenant.isGlobalBaseTenant} color="error" icon="trash" loading={loading} loadingText={loadingText} text={buttonText} onClick={canBeDeleted ? deleteTenant : requestTenantDeletionToken} /> 
            </div>
        </div>
    )
}