import React from "react";
import useTenantUtil from "../../hooks/useTenantUtil";
import { AppColor } from "../../app/AppStyles";
import { useAppSelector } from "../../hooks/reduxHooks";
import useTenantSettings from "../../hooks/useTenantSettings";
import { useTenant } from "../../state/swr/tenant/useTenant";

export default function TenantThemeProvider() {
    const { tenant } = useTenant();

    const settings = useTenantSettings();

    React.useEffect(() => {
        if (!settings) return;

        const theme = settings?.theme;

        if (!theme) return;
        if (theme.primaryColor === "#000000") return;
        
        document.documentElement.style.setProperty(`--$"primary"`, theme.primaryColor);

    }, [settings]);

    return <></>
}