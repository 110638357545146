import useSWR from "swr";
import { IPointChampApiKey, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ITenantUpdateBillingDataRequest } from "../../../api/ApiRequests";
import Stripe from "stripe";

export const usePaymentMethods = (tenantId: string) => {
    const { data, isLoading, mutate } = useSWR<Array<Stripe.PaymentMethod>>(ApiRoutes.Tenant.GetPaymentMethods.getValue({
        param: RouteParam.TenantId,
        value: tenantId
    }));

    return {
        paymentMethods: data,
        loadingPaymentMethods: isLoading,
        reloadPaymentMethods: mutate
    }
}