import React from "react";
import useClientResponsibility from "../../hooks/useClientResponsibility";
import Page from "../../layout/Page";
import PageLoader from "../loader/PageLoader";
import Table from "../tables/Table";
import ClientResponsibilityItem from "./ClientResponsibilityItem";
import { useAppSelector } from "../../hooks/reduxHooks";
import WithPermissions from "../permissions/WithPermissions";

export default function ClientResponsibilityList() {

    const {
        loading,
        sortedClientResponsibility
    } = useClientResponsibility();

    if (loading) return <PageLoader />

    return (
        <WithPermissions permissions={["clientResponsibilities.all.read"]}>
            <Table 
                headers={[{label: "Mandantennummer"}, {label: "Mandant"}, {label: "Zuständigkeiten"}]}
                items={sortedClientResponsibility}
                renderItem={s => <ClientResponsibilityItem key={s.client._id} responsibilities={s} />}
            />
        </WithPermissions>
    )
}