import React from "react";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { azureGetSignInUrl, officeAccountDelete } from "../../api/Api";
import { IOfficeAccount } from "../../types/ApiTypes";
import { AppColor } from "../../app/AppStyles";
import useInboxPermissions from "../../hooks/useInboxPermissions";
import WithPermissions from "../permissions/WithPermissions";

export default function RemoveOfficeAccountButton({account}: {account: IOfficeAccount}) {

    const {
        getAccountPermissions
    } = useInboxPermissions();

    const callApi = useApi();

    const permissions = getAccountPermissions("delete", account);

    return (
        <WithPermissions permissions={permissions} >
            <Button 
                text="Konto entfernen" 
                color="error"
                onClick={async () => {
                    const res = await callApi(officeAccountDelete({id: account._id}));
    
                    if (!res || !res.success) return;
    
                    window.location.href = res.data.url;
                }}
                icon="trash"
            />
        </WithPermissions>
    )
}