import React from "react";
import useSWR, { SWRConfig, SWRConfiguration, SWRResponse, unstable_serialize } from "swr";
import { swrFetch } from "../api/Api";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxHooks";

export default function SWRProvider({children}: {children: any}) {
    return (
        <SWRConfig
            value={{
                fetcher: swrFetch,
                revalidateOnFocus: false,
                revalidateIfStale: false,
                revalidateOnReconnect: false
            }}
        >
            {
                children
            }
        </SWRConfig> 
    )
}