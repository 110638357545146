import React from "react";
import { Status } from "../../config/Status";
import { useAppSelector } from "../../hooks/reduxHooks";
import useUserUtil from "../../hooks/useUserUtil";
import { ITenant, IUser, UserRole } from "../../types/ApiTypes";
import { formatDate } from "../../util/formatter";
import LoadingSpinner from "../loader/LoadingSpinner";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserRoleIndicator from "../user/RoleBanner";
import TenantStatusBanner from "./TenantStatusBanner";
import TenantUpdateForm from "./settings/TenantUpdateModal";
import { useUsers } from "../../state/swr/user/useUsers";


export default function TenantListItem({tenant}: {tenant: ITenant}) {

    const { users } = useUsers();

    const {
        getUsersForRole,
        getTenantManagers
    } = useUserUtil();

    const partners = getUsersForRole(users || [], [UserRole.Partner], undefined, tenant);
    const tenantManagers = getTenantManagers(users || [], undefined, tenant);

    return (
        <TableRow>
            <TableCell className="d-flex flex-column align-items-start gap-1">
                <strong>{tenant.name}</strong>
                <table style={{fontSize: "0.9em"}}>
                    <tbody>
                        <TenantUsersWithElevatedPrivilegues tenant={tenant} users={partners} title="Partner" />
                        <TenantUsersWithElevatedPrivilegues tenant={tenant} users={tenantManagers} title="Tenant-Manager" />
                        <tr>
                            <td>
                                <span>Domains</span>
                            </td>
                            <td>
                                <div className="d-flex flex-row align-items-center flex-wrap gap-1">
                                    {
                                        tenant.domains && !!tenant.domains.length
                                        ? tenant.domains.map(d => typeof d === "string" ? null : <Pill key={d._id} text={d.domain} color="#AFB1FA" />)
                                        : <span>Keine Domains.</span>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </TableCell>
            <TableCell content={formatDate(tenant.createdAt)} />
            <TableCell content={formatDate(tenant.updatedAt)} />
            <TableCell>
                <TenantStatusBanner tenant={tenant} />
            </TableCell>
            <TableCell justify="end">
                <TenantUpdateForm tenant={tenant} />
            </TableCell>
        </TableRow>
    )
}

function TenantUsersWithElevatedPrivilegues({title, tenant, users}: {title: string, tenant: ITenant, users: IUser[]}) {
    const { loadingUsers } = useUsers();

    return (
        <tr>
            <td>
                <span className="me-3">{title}</span>
            </td>
            <td>
                <div className="d-flex flex-row gap-2 flex-wrap mb-1">
                    {
                        loadingUsers
                        ? <LoadingSpinner size={12} text="Lädt Nutzer..." />
                        : (
                            users && !!users.length 
                            ? users.map(u => <UserRoleIndicator key={u._id} tenant={tenant} user={u} displayNameAsValue showMail />)
                            : <span>Keine {title}.</span>
                        )
                    }
                </div>
            </td>
        </tr>
    )
}