import useSWR from "swr";
import { ITicket, ITicketAccessHistory, ITicketActivity, ITicketAssignment, ITicketEscalationLog } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTicketCurrentUserAssignment = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useTiggiQuery<ITicketAssignment>(ApiRoutes.Tickets.Assignments.ForCurrentUser.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketCurrentUserAssignment: data,
        loadingTicketCurrentUserAssignment: isLoading,
        reloadTicketCurrentUserAssignment: mutate
    }
} 