import React from "react";
import { MailLogType } from "../../types/ApiTypes";
import Pill from "../pills/Pill";
import Icon from "../icons/Icon";

export default function MailLogTypeBanner({type}: {type: MailLogType}) {

    const getColor = (): string => {
        switch (type) {
            case MailLogType.Incoming: return "#7733F0";
            case MailLogType.Outgoing: return "#F03377";
        }
    }

    return (
        <Pill color={getColor()}>
            {
                type === MailLogType.Outgoing && <Icon icon="arrow-left" />
            }
            {
                type === MailLogType.Incoming ? "EIN" : "AUS"
            }
            {
                type === MailLogType.Incoming && <Icon icon="arrow-right" />
            }
        </Pill>
    )
}