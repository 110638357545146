import React from "react";
import { IDocumentClearanceReminder, IDocumentClearanceSettings } from "../../types/ApiTypes";
import useApi from "../../hooks/useApi";
import { documentClearanceSetDone } from "../../api/Api";
import Button from "../buttons/Button";
import { AppColor } from "../../app/AppStyles";

export interface IGiveDocumentClearanceButtonProps {
    reminderId: string,
    isDone?: boolean,
    afterClearanceGiven?: () => void
}

export default function GiveDocumentClearanceButton({afterClearanceGiven, isDone, reminderId}: IGiveDocumentClearanceButtonProps) {
    const [hasClearance, setHasClearance] = React.useState<boolean>(isDone || false);

    const callApi = useApi();
    
    const giveClearance = async () => {
        const res = await callApi(documentClearanceSetDone(reminderId));
        if (!res || !res.success) return;
        setHasClearance(true);
        if (afterClearanceGiven) afterClearanceGiven();
    }
    
    if (!reminderId) return null;

    return (
        <Button onClick={giveClearance} text={hasClearance ? "Freigabe erteilt" : "Freigabe erteilen"} disabled={hasClearance} icon="check" color="success"/>
    )
}