import React from "react";
import { AppColor } from "../../app/AppStyles";
import Card from "../card/Card";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import Typography from "../text/Typography";
import { formatDuration } from "../../util/formatter";
import LoadingSpinner from "../loader/LoadingSpinner";
import { useNavigate } from "react-router-dom";


export interface IProductivityCardProps {
    color: AppColor,
    title: string,
    icon: string,
    value: number,
    loading: boolean,
}

export default function ProductivityCard({color, title, icon, value, loading}: IProductivityCardProps) {

    const hoursOfDuration = Math.floor(value / 60);
    const minutesOfDuration = Math.floor(value % 60).toString().padStart(2, "0");

    return (
        <Card 
            wrapperClass="w-100"
            strongColor
            color={color}
            header={
                <Flex row>
                    <Icon icon={icon} size={17} invertThisColor={color} />
                    <Typography basedOnThisBackground={color} upper size={20}>{title}</Typography>
                </Flex>
            }
        >
            <Flex row gap={2} align="end">
                {
                    loading
                    ? <LoadingSpinner invertThisColor={color} size={50} />
                    : (
                        <>
                            <Typography basedOnThisBackground={color} lineHeight={48} size={50}>{hoursOfDuration}</Typography>
                            <Typography basedOnThisBackground={color} upper size={20}>h</Typography>
                            <Typography basedOnThisBackground={color} lineHeight={48} size={50}>{minutesOfDuration}</Typography>
                            <Typography basedOnThisBackground={color} upper size={20}>min</Typography>
                        </>
                    )
                }
            </Flex>
        </Card>
    )
}