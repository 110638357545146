import useSWR from "swr";
import { IClient, ICoupon, IFeature, IService, ITeam, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useTeams = () => {
    const { data, isLoading, mutate } = useSWR<Array<ITeam>>(ApiRoutes.Teams.All);

    return {
        teams: data,
        loadingTeams: isLoading,
        reloadTeams: mutate
    }
} 