import React from "react";
import { MailTemplate } from "../../types/ApiTypes";
import Pill from "../pills/Pill";
import { useMailTemplateElements } from "../../hooks/useMailTemplateElements";

export default function MailTemplateTypeBanner({template, clickable, disabled}: {template: MailTemplate, disabled?: boolean, clickable?: boolean}) {
    
    const { getDisplayValueForType } = useMailTemplateElements();

    const name = getDisplayValueForType(template);

    if (!name) return null;

    return <Pill onClick={clickable ? () => {} : undefined} text={`${name}${disabled ? " (nicht anpassbar)" : ""}`} color={disabled ? "background" : "#432ECD"} textColor={disabled ? "backgroundDarkest" : undefined}/>
}