import React, { useEffect } from "react";
import { Status } from "../../config/Status";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Error from "../../pages/Error";
import { IClient, IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import ClientImportProgress from "./ClientImportProgress";
import ClientListItem from "./ClientListItem";
import useClientUtil from "../../hooks/useClientUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { useClients } from "../../state/swr/clients/useClients";
import { useEmployeeResponsibilities } from "../../state/swr/employees/useEmployeeResponsibilities";
import WithPermissions from "../permissions/WithPermissions";

export default function ClientList() {

    const { clients, loadingClients } = useClients();

    const {
        getTypeNameOfClient,
    } = useClientUtil();

    const {
        getName
    } = useUserUtil();

    return (
        <WithPermissions permissions={[ "clients.all.read" ]}>
            {
                loadingClients 
                ? <LoadingSpinner centered={false} />
                : (
                    <div className="d-flex flex-column h-100 gap-3">
                                <ClientImportProgress />
                                {
                                    clients
                                    ? (
                                        <Table 
                                            border 
                                            className="w-100" 
                                            headers={[
                                                {
                                                    label: "Nummer",
                                                    valueKey: "number"
                                                },
                                                {
                                                    label: "Name",
                                                    valueKey: "name",
                                                }, 
                                                {
                                                    label: "Rechtsform",
                                                    filterItem: (c, filter) => getTypeNameOfClient(c)?.toLowerCase().includes(filter.toLowerCase())
                                                }, 
                                                {
                                                    label: "Addressaten",
                                                    filterItem: (c, filter) => {
                                                        if (!c.naturalPerson && !c.legalPerson) return false;
                                                        if (c.naturalPerson && c.naturalPerson.actualName && c.naturalPerson.actualName.toLowerCase().includes(filter.toLowerCase())) return true;
                                                        if (c.legalPerson && c.legalPerson.actualName && c.legalPerson.actualName.toLowerCase().includes(filter.toLowerCase())) return true;
                                                        return false;
                                                    }
                                                }, 
                                                {
                                                    label: "Ansprechpartner",
                                                    filterItem: (c, filter) => {
                                                        if (!c.clientManager) return false;
                                                        const name = getName(c.clientManager);

                                                        if (name && name.toLowerCase().includes(filter.toLowerCase())) return true;
                                                        if (c.clientManager.mailAddress && c.clientManager.mailAddress.toLowerCase().includes(filter.toLowerCase())) return true;

                                                        return false;
                                                    }
                                                }, 
                                                { label: "Verantwortungen" }, 
                                                { label: "Alias" }, 
                                                { label: "Erstellungsdatum" }, 
                                                { label: "" }
                                            ]}
                                            renderItem={(c: IClient) => <ClientListItem key={c._id} client={c} />}
                                            items={clients}
                                        />
                                    )
                                    : <span>Keine Mandate</span>
                                }
                            </div>
                )
            }
        </WithPermissions>
        
    )
}