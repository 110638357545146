import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Status } from "../../config/Status";
import { IClient, IClientImportLogEntry } from "../../types/ApiTypes";

export interface IClientImportSlice {
    clientImportLog: IClientImportLogEntry | null
}

export const ClientImportSlice = createSlice({
    name: "clientImport",
    initialState: {
        clientImportLog: null
    } as IClientImportSlice,
    reducers: {
        setClientImport: (state, action: PayloadAction<IClientImportLogEntry | null>) => {
            state.clientImportLog = action.payload;
        }
    }
});

export const { setClientImport } = ClientImportSlice.actions;

export default ClientImportSlice.reducer;