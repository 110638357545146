import useSWR from "swr";
import { IAlias, IClient, IUserClients, IUserProfilePicture } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useAnyUserAlias = (id: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IAlias>>(ApiRoutes.User.Alias.getValue({ param: RouteParam.UserId, value: id}));

    return {
        userAlias: data,
        loadingUserAlias: isLoading,
        reloadUserAlias: mutate
    }
}