import React from "react";
import ModalForm from "../../modal/ModalForm";
import { ITicketAssignmentAddRequest, ITicketAssignmentUpdateRequest } from "../../../api/ApiRequests";
import { ITicket, ITicketAssignment, IUser } from "../../../types/ApiTypes";
import Flex from "../../container/Flex";
import useApi from "../../../hooks/useApi";
import { ticketAssignmentsAdd, ticketAssignmentsUpdate } from "../../../api/Api";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import UserSearchableComboBox from "../../comboBox/UserSearchableComboBox";
import { useUsers } from "../../../state/swr/user/useUsers";
import ClientSelect from "../../clients/ClientSelect";
import Button from "../../buttons/Button";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import { useTicketClientContact } from "../../../state/swr/tickets/useTicketClientContact";
import { useTicketAssignments } from "../../../state/swr/tickets/useTicketAssignments";
import WithPermissions from "../../permissions/WithPermissions";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import FormikField from "../../formik/FormikField";
import { AppColor } from "../../../app/AppStyles";
import Icon from "../../icons/Icon";
import useRoleUtil from "../../../hooks/useRoleUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import CheckBox from "../../formik/CheckBox";
import Typography from "../../text/Typography";
import Pill from "../../pills/Pill";

export interface IUpdateTicketAssignmentFormProps {
    ticket: ITicket,
    assignment?: ITicketAssignment
}

export default function UpdateTicketAssignmentForm({ ticket, assignment }: IUpdateTicketAssignmentFormProps) {

    const permissions = useTicketPermission("update", ticket);

    const { reloadTicketAssignee } = useTicketAssignee(ticket);
    const { reloadTicketClientContact } = useTicketClientContact(ticket);
    const { reloadTicketAssignments } = useTicketAssignments(ticket);

    const { users } = useUsers();

    const {
        getCurrentTenantRole
    } = useUserUtil();
    
    const callApi = useApi();

    const getValues = (): ITicketAssignmentAddRequest | ITicketAssignmentUpdateRequest => {
        if (!assignment) return {
            clientId: "",
            isCc: true,
            isActive: true,
            isMainAssignee: false,
            isMainClientContact: false,
            ticketId: ticket._id,
            userId: "",
            mailAddress: ""
        } as ITicketAssignmentAddRequest;
        else return {
            clientId: assignment.client?._id,
            isCc: assignment.isCc,
            isMainAssignee: assignment.isMainAssignee,
            isMainClientContact: assignment.isMainClientContact,
            ticketId: ticket._id,
            isActive: assignment.isActive,
            userId: assignment.user?._id,
            mailAddress: assignment.mailAddress,
            id: assignment._id
        } as ITicketAssignmentUpdateRequest
        
    }

    const text = assignment ? "Zuweisung bearbeiten" : "Neue Zuweisung";
    const buttonText = assignment ? "Bearbeiten" : "Neue Zuweisung";
    const buttonIcon = assignment ? "pen" : "plus";
    const buttonColor: AppColor = assignment ? "primary" : "success";

    if (assignment && !assignment.isActive) return null;
    
    return (
        <WithPermissions permissions={permissions}>
            <ModalForm 
                initialValues={getValues()}
                title={text}
                button={<Button variant="text" icon={buttonIcon} text={buttonText} color={buttonColor} />}
                onSubmit={async (values) => {
                    const res = assignment 
                    ? await callApi(ticketAssignmentsUpdate(values as ITicketAssignmentUpdateRequest))
                    : await callApi(ticketAssignmentsAdd(values as ITicketAssignmentAddRequest));

                    if (!res || !res.success) return false;
                    
                    await reloadTicketAssignee();
                    await reloadTicketAssignments();
                    await reloadTicketClientContact();
                    
                    return true;
                }}
            >
                {
                    formik => {

                        const getUserRole = () => {
                            if (!users || !users.length) return undefined;
                            if (!formik.values.userId) return undefined;
                            const user = users.find(u => u._id === formik.values.userId);
                            if (!user) return undefined;
                            return getCurrentTenantRole(user);
                        }

                        const changeUser = (u: IUser | undefined) => {
                            formik.setFieldValue("userId", u ? u._id : undefined);
                            formik.setFieldValue("clientId", undefined);
                            formik.setFieldValue("mailAddress", u ? u.mailAddress : undefined);
                        }

                        const role = getUserRole();

                        const isClient = role && role.isClient;

                        return (
                            <Flex fullWidth>
                                {
                                    !formik.values.userId && (
                                        <FormikField 
                                            label="E-Mail"
                                            name="mailAddress"
                                            className="w-100"
                                        />
                                    )
                                }
                                <UserSearchableComboBox
                                    employeesOnly={formik.values.isMainAssignee}
                                    onItemClick={changeUser}
                                    value={formik.values.userId}
                                    label="Nutzer"
                                    className="w-100"
                                    onlyCurrentTenant
                                    users={users || []}
                                />
                                <Flex row gap={4}>
                                    {
                                        (assignment?.isMainClientContact || (formik.values.userId && isClient)) && <CheckBox name="isMainClientContact" readOnly={assignment?.isMainClientContact} label="Mandantenkontakt des Tickets" className="w-100" />
                                    }
                                    {
                                        (assignment?.isMainAssignee || (formik.values.userId && !isClient)) && <CheckBox name="isMainAssignee" readOnly={assignment?.isMainAssignee} label="Bearbeiter des Tickets" className="w-100" />
                                    }
                                    {
                                        !formik.values.isMainAssignee && !formik.values.isMainClientContact && (
                                            <Pill color="#F0F0F0">
                                                <Icon color="primary" icon="info-circle" />
                                                <Typography italic>Zuweisung wird als CC-Zuweisung erstellt.</Typography>
                                            </Pill>
                                        )
                                    }
                                </Flex>
                                {
                                    !formik.values.isMainAssignee && formik.values.userId && isClient && (
                                        <ClientSelect 
                                            clientContactId={formik.values.userId}
                                            hideIfEmpty
                                            clientId={formik.values.clientId}
                                            saveClient={(client) => formik.setFieldValue("clientId", client._id)}
                                        />
                                    )
                                }
                            </Flex>
                        )
                    }
                }
            </ModalForm>
        </WithPermissions>
    )
}