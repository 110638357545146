import React from "react";
import { ticketAssignToTeam } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import useUserUtil from "../../../hooks/useUserUtil";
import { ITeam, ITicket, IUser } from "../../../types/ApiTypes";
import LoadingSpinner from "../../loader/LoadingSpinner";
import AssignmentUserItem from "./AssignmentUserItem";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import WithPermissions from "../../permissions/WithPermissions";

export default function AssignTicketToOtherTeamMemberButton({user, team, ticket, afterAssigningTicket}: {user: IUser, team: ITeam, ticket: ITicket, afterAssigningTicket?: () => void}) {
    
    const permissions = useTicketPermission("update", ticket);
    const callApi = useApi();

    const switchTeamsForTicket = async (team: ITeam, assignee: IUser) => {
        const res = await callApi(ticketAssignToTeam({teamId: team._id, assignee: assignee._id, ticketId: ticket._id}));
        if (!res) return;
        if (afterAssigningTicket) afterAssigningTicket();
    }

    return (
        <WithPermissions permissions={permissions}>
            <AssignmentUserItem user={user} onClick={async () => await switchTeamsForTicket(team, user)}/>
        </WithPermissions>
    )
}