import React from "react";
import { ITicket } from "../../../types/ApiTypes";
import { useAppSelector } from "../../../hooks/reduxHooks";
import Icon from "../../icons/Icon";
import { AppColor } from "../../../app/AppStyles";
import { useUser } from "../../../state/swr/user/useUser";

export interface ITicketLastReplyStateIconProps {
    ticket: ITicket
}

export default function TicketLastReplyStateIcon({ticket}: ITicketLastReplyStateIconProps) {

    const { user } = useUser();

    if (!user) return null;
    if (!ticket || !ticket.messages || !ticket.messages.length) return null;

    const lastMessage = ticket.messages[ticket.messages.length - 1];

    if (!lastMessage || !lastMessage.sentBy) return null;

    const isByUser = typeof lastMessage.sentBy === "string" ? lastMessage.sentBy === user._id : lastMessage.sentBy._id === user._id;

    return (
        <Icon
            icon={isByUser ? "arrow-right-circle-fill" : "arrow-left-circle-fill"}
            color={isByUser ? "primary" : "secondary"}        
        />
    )
}