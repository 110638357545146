import useSWR from "swr";
import { IClient, IUserClients, IUserProfilePicture } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useAnyUserAvatar = (id: string) => {
    const { data, isLoading, mutate } = useSWR<IUserProfilePicture>(ApiRoutes.User.Avatar.getValue({ param: RouteParam.UserId, value: id}));

    return {
        userAvatar: data,
        loadingUserAvatar: isLoading,
        reloadUserAvatar: mutate
    }
}