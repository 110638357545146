import React, { MouseEventHandler } from "react";
import "./TableCell.css";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import IElementProps from "../../types/ElementProps";
import { formatDate } from "../../util/formatter";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Icon from "../icons/Icon";
import Flex from "../container/Flex";
import { hexWithOpacity } from "../../util/color";
import { Permission } from "../../types/ApiTypes";
import useAuthorized from "../../hooks/useAuthorized";

export enum TableDensity {
    Condensed = "condensed",
    Standard = "standard",
    Comfortable = "comfortable"
}

interface ITableCellProps extends IElementProps {
    isHeader?: boolean,
    content?: any,
    hidden?: boolean,
    bold?: boolean,
    value?: boolean,
    date?: Date | string | number,
    canFilter?: boolean,
    onFilter?: (val: string) => void,
    labelOnTrue?: string,
    fillAvailableSpace?: boolean,
    density?: TableDensity,
    labelOnFalse?: string,
    align?:  "top" | "middle" | "bottom",
    justify?: "end" | "center" | "start",
    colSpan?: number,
    permissions?: Array<Permission>,
    showTime?: boolean,
    rowSpan?: number
}

export default function TableCell({isHeader, align = "top", permissions, justify = "start", value, hidden, onFilter, canFilter, showTime = false, density = TableDensity.Standard, date, fillAvailableSpace, labelOnFalse, labelOnTrue, className, bold, colSpan, rowSpan, content, children}: ITableCellProps) {
    
    const {
        isAuthorized,
        loading
    } = useAuthorized(permissions);

    const [currentResizeStart, setCurrentResizeStart] = React.useState<number>(0);
    const [currentResizeWidth, setCurrentResizeWidth] = React.useState<number>(0);
    const [headerCellRef, setHeaderCellRef] = React.useState<HTMLTableCellElement>();
    const [hover, setHover] = React.useState<boolean>(false);
    const [filterVisible, setFilterVisible] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState<string>("");

    const headerCell = React.createRef<HTMLTableCellElement>();

    React.useEffect(() => {
        if (!headerCell || !headerCell.current) return;
        setHeaderCellRef(headerCell.current);
        setCurrentResizeWidth(getCurrentColumnWidth());
    }, [headerCell]);

    if ((isHeader && hidden) || !isAuthorized) return null;
    
    const cellClass = generateClassName("table-cell", className, {
        base: "align-",
        value: align
    }, {
        base: "table-cell-density-",
        value: isHeader ? "header" : density,
    }, {
        base: "text-",
        value: justify
    }, {
        value: bold,
        onTrue: "fw-bold"
    }, 
    {
        value: fillAvailableSpace,
        onTrue: ""
    }, {
        value: isHeader,
        onTrue: "table-cell-header text-nowrap"
    });

    const contentWrapperClass = generateClassName("w-100 h-100", {
        base: "table-cell-density-",
        value: density,

    });
    
    const contentClass = generateClassName({
        value: isHeader,
        onTrue: "text-nowrap"
    });

    const getCurrentColumnWidth = (): number => {
        if (!headerCellRef) return 0;

        try {
            return parseInt(window.getComputedStyle(headerCellRef).width, 10);
        }
        catch { }
        
        return 0;
    }
    
    const handleResizerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setCurrentResizeWidth(getCurrentColumnWidth());
        setCurrentResizeStart(e.clientX);

        document.addEventListener("mousemove", handleResize);
        document.addEventListener("mouseup", handleMouseUp);
    }
    
    const handleResize = (e: any) => {
        try {
            const leftMouseIsPressed = detectLeftButton(e);

            if (!leftMouseIsPressed) return handleMouseUp(e);
            if (!headerCellRef) return;
            if (currentResizeStart === 0 || currentResizeWidth === 0) return handleResizerClick(e);

            const xDelta = e.clientX - currentResizeStart;

            headerCellRef.style.width = `${currentResizeWidth + xDelta}px`;
        }
        catch {}
    }
    
    function detectLeftButton(evt: any) {
        evt = evt || window.event;
        if ("buttons" in evt) return evt.buttons == 1;
        var button = evt.which || evt.button;
        return button == 1;
    }

    const handleMouseUp = (e: any) => {
        document.removeEventListener("mousemove", handleResize);
        document.removeEventListener("mouseup", handleMouseUp);
    }


    if (value !== undefined) children = <CheckmarkIcon value={value} trueLabel={labelOnTrue} falseLabel={labelOnFalse} />
    else if (date !== undefined) children = <span>{formatDate(date, showTime)}</span>
    else if (isHeader && content !== undefined) children = <span style={{fontSize: "1.0em"}} className="fw-bold">{content}</span>
    else if (!children && content !== undefined) children = <span className={contentClass}>{content}</span>
    
    const element = isHeader ? "th" : "td";

    const cellContent = (
        <div className={contentWrapperClass}>
            {children}
        </div>
    );

    const cell = (isHeader) ? (
        <Flex gap={0} className={generateClassName({value: filterVisible, onTrue: "mb-1"})} >
            <div className="d-flex flex-row m-0 p-0 h-100 w-100 align-items-center" onMouseEnter={() => setHover(true)} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className="d-flex flex-row align-items-center w-100 justify-content-between">
                    {cellContent} 
                    {
                        (filter || (canFilter && onFilter)) && <Icon className="me-1" icon={filterVisible ? "x" : (filter ? "filter" : (hover ? "search" : ""))} onClick={() => setFilterVisible(!filterVisible)}/>
                    }       
                </div>
                <div className="resizer" onMouseDown={handleResizerClick} />
            </div>
            {
                filterVisible && onFilter && canFilter && (
                    <Flex>
                        <input 
                            name="table-filter" 
                            className="text-input form-control w-100" 
                            placeholder="Suchen..." 
                            onChange={e => {
                                setFilter(e.target.value);
                                onFilter(e.target.value);
                            }} 
                            value={filter} 
                        />      
                    </Flex>
                )
            }
        </Flex>
    ): cellContent;

    return React.createElement(element, {className: cellClass, colSpan: colSpan, rowSpan: rowSpan, ref: isHeader ? headerCell : undefined, style: {minWidth: 0}}, cell);
}