import React from "react";
import { IUser } from "../../types/ApiTypes";
import UserRoleIndicator from "../user/RoleBanner";

export default function TeamMemberList({members, maxMembersDisplayed = 4}: {members: IUser[], maxMembersDisplayed?: number}) {
    
    if (!members || !members.length) return <em>Keine Mitglieder</em>

    const memberCount = members.length;
    const actualMembers = [...members];

    if (memberCount > maxMembersDisplayed) actualMembers.splice(maxMembersDisplayed);

    return (
        <div className="d-flex flex-row align-items-center gap-2 flex-wrap w-100">
            {
                actualMembers.map(u => (
                    <UserRoleIndicator displayNameAsValue user={u} key={u._id} />
                ))
            }
            {
                memberCount > maxMembersDisplayed && <strong>+{memberCount - maxMembersDisplayed}</strong>
            }
        </div>
    )

}