import React from "react";
import { useGlobalMailTemplates } from "../../state/swr/templates/useGlobalMailTemplates";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import MailTemplateTypeBanner from "./MailTemplateTypeBanner";
import TemplatePlaceholderChip from "./TemplatePlaceholderChip";
import UpdateGlobalMailTemplateButton from "./UpdateGlobalMailTemplateButton";
import { useMailTemplateElements } from "../../hooks/useMailTemplateElements";
import Typography from "../text/Typography";
import Flex from "../container/Flex";
import TestMailTemplateButton from "./TestMailTemplateButton";
import WithPermissions from "../permissions/WithPermissions";

export default function GlobalMailTemplatesList() {
    const { getStringified } = useMailTemplateElements();
    const { globalMailTemplates, loadingGlobalMailTemplates } = useGlobalMailTemplates();

    return (
        <WithPermissions permissions={["templates.mails.read"]}>
            {
                loadingGlobalMailTemplates 
                ? <LoadingSpinner />
                : (
                    <Table
                        headers={[
                            {
                                label: "Typ"
                            },
                            {
                                label: "Betreff"
                            },
                            {
                                label: "Verfügbare Platzhalter"
                            },
                            {
                                label: "Veröffentlicht"
                            },
                            { label: "" }
                        ]}
                        items={globalMailTemplates || []}
                        renderItem={m => (
                            <TableRow key={m._id}>
                                <TableCell>
                                    <MailTemplateTypeBanner template={m.templateType} />
                                </TableCell>
                                <TableCell>
                                    <Typography>{getStringified(m.subject)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Flex row wrap>
                                        {
                                            m.availablePlaceholders && m.availablePlaceholders.length
                                            ? m.availablePlaceholders.map(p => <TemplatePlaceholderChip placeholder={p} />)
                                            : <em>Keine Platzhalter</em>
                                        }
                                    </Flex>
                                </TableCell>
                                <TableCell value={m.isPublished} labelOnFalse="Nicht veröffentlicht" labelOnTrue="In Verwendung" />
                                <TableCell>
                                    <Flex row>
                                        <TestMailTemplateButton templateId={m._id} isGlobal />
                                        <UpdateGlobalMailTemplateButton template={m} />
                                    </Flex>
                                </TableCell>
                            </TableRow>
                        )}
                    />
                )
            }
        </WithPermissions>
    )
}