import React from "react";
import { IPlan, ITenant } from "../../types/ApiTypes";
import useApi from "../../hooks/useApi";
import Button from "../buttons/Button";
import ModalForm from "../modal/ModalForm";
import { tenantsAddSubscription, tenantsCancelSubscription } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { AppColor } from "../../app/AppStyles";
import ModalDialog from "../modal/ModalDialog";
import { useTenant } from "../../state/swr/tenant/useTenant";
import WithPermissions from "../permissions/WithPermissions";

export default function SubscribeToServiceButton({plan, tenant}: {plan: IPlan, tenant: ITenant}) {
    const [promoCode, setPromoCode] = React.useState<string>("");

    const { reloadTenant } = useTenant();

    const callApi = useApi();
    const dispatch = useAppDispatch();

    const isSubscribedToService = tenant && tenant.activePlans && !!tenant.activePlans.length && !!tenant.activePlans.find(p => p._id === plan._id);

    const subscribeToService = async () => {
        const res = await callApi(tenantsAddSubscription({planId: plan._id, tenantId: tenant._id, promoCode: promoCode}));
        if (res && res.success) await reloadTenant();
    }

    const unsubscribeFromService = async () => {
        const res = await callApi(tenantsCancelSubscription({planId: plan._id, tenantId: tenant._id}));
        if (res && res.success) await reloadTenant();
    }

    const getDescription = () => isSubscribedToService ? "Wollen Sie Ihr Abo wirklich zum nächstmöglichen Zeitpunkt beenden?" : "Wollen Sie die ausgewählte Option zahlungspflichtig abonnieren?";
    const getText = () => isSubscribedToService ? "Abo kündigen" : "Abonnieren";

    return (
        <WithPermissions permissions={["billing.tenant.update"]}>
            <ModalDialog 
                title={getText()} 
                button={<Button text={getText()} color={isSubscribedToService ? "error" : "primary"} />}
            >
                <div className="d-flex flex-column gap-3 text-start">
                    <div className="d-flex flex-column gap-2">
                        <span>{getDescription()}</span>
                    </div>
                    <div className="d-flex flex-row align-items-center justify-content-between w-100 gap-5">
                        {
                            !isSubscribedToService && (
                                <div className="d-flex flex-row">
                                    <label htmlFor="promocode-input" className="fw-bold">Gutscheincode (optional)</label>
                                    <input name="promocode" id="promocode-input" onChange={(e) => setPromoCode(e.target.value ? e.target.value.toString().toUpperCase() : "")} value={promoCode} className="form-control" placeholder="ABCDEF..." />
                                </div>
                            )
                        }
                        <Button text={getText()} onClick={isSubscribedToService ? unsubscribeFromService : subscribeToService} color={isSubscribedToService ? "error" : "success"} />
                    </div>
                </div>
            </ModalDialog>
        </WithPermissions>
    )
}