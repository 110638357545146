import React from "react";
import { ticketsAssign } from "../../../api/Api";
import { AppColor, UserRoleColor } from "../../../app/AppStyles";
import { useAppSelector } from "../../../hooks/reduxHooks";
import useApi from "../../../hooks/useApi";
import { generateClassName } from "../../../hooks/useAttributes";
import useRoleUtil from "../../../hooks/useRoleUtil";
import useTicketUtil from "../../../hooks/useTicketUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import { ITicket, IUser, TicketState, UserRole } from "../../../types/ApiTypes";
import Button, { ButtonSize } from "../../buttons/Button";
import UserRoleIndicator from "../../user/RoleBanner";
import "./AssignTicketToTeamMember.css";
import Expandable from "../../comboBox/Expandable";
import AssignmentUserItem from "./AssignmentUserItem";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../../state/swr/user/useUser";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import WithPermissions from "../../permissions/WithPermissions";

interface IAssignTicketToTeamMemberProps {
    afterAssigningTicket?: () => void,
    ticket: ITicket
}

export default function AssignTicketToTeamMember({ticket, afterAssigningTicket}: IAssignTicketToTeamMemberProps) {

    const permissions = useTicketPermission("update", ticket);
    const { loadingTicketAssignee, ticketAssignee } = useTicketAssignee(ticket);

    const { role } = useCurrentTenantRole();
    const { user } = useUser();

    const {
        getUsersForRole,
        getCurrentTenantAuthority
    } = useUserUtil();

    const {
        convertToUserRole
    } = useRoleUtil();

    const {
        isClosed
    } = useTicketUtil();

    const ticketIsClosed = isClosed(ticket);

    const callApi = useApi();

    const authority = getCurrentTenantAuthority(user);

    const assignToOtherMember = async (user: IUser) => {
        const res = await callApi(ticketsAssign({ticketId: ticket._id, userId: user._id}));
        if (!res) return;
        if (afterAssigningTicket) afterAssigningTicket();
    }

    const getAvailableRoles = (): UserRole[] => {

        if ((authority && authority.userAuthority && authority.userAuthority.isTenantAdmin)
            || (user && user.isSuperAdmin)) return [
            UserRole.Clerk,
            UserRole.Client,
            UserRole.Partner,
            UserRole.Secretary,
            UserRole.TeamLead
        ];
              
        const assignedUserRole = role ? convertToUserRole(role) : UserRole.Clerk;

        switch(assignedUserRole) {
            case UserRole.TeamLead: return [UserRole.Clerk, UserRole.TeamLead];
            case UserRole.Partner: return [UserRole.Clerk, UserRole.TeamLead, UserRole.Partner];
            default: return [UserRole.Clerk];
        }
    }

    const availableRoles = getAvailableRoles();
    
    const users = getUsersForRole(ticket.team && ticket.team.members, availableRoles, ticketAssignee && ticketAssignee.user ? [ticketAssignee.user._id] : []);    
    const hasUsers = users && !!users.length;

    const buttonText = ticketIsClosed ? "Ticket ist geschlossen" : (hasUsers ? "An Teammitglied übergeben" : "Keine Teammitglieder verfügbar");

    return (
        <WithPermissions permissions={permissions}>
            <Expandable
                disabled={!hasUsers}
                expander={
                    <Button variant="outline" icon="chevron-right" iconPosition="end">{ buttonText }</Button>
                }
            >
                {
                    hasUsers && users.map(u => <AssignmentUserItem key={u._id} onClick={async () => await assignToOtherMember(u)} user={u} />)
                }
            </Expandable>
        </WithPermissions>
    )
}