import { getValue } from "@testing-library/user-event/dist/utils";
import { get } from "http";

export enum RouteParam {
    Addressee = ":addressee",
    Employee = ":employee",
    Role = ":role",
    Task = ":task",
    ImportValidationError = ":importValidationError",
    TicketAssignment = ":ticketAssignment",
    Signature = ":signature",
    GlobalMailTemplate = ":globalMailTemplate",
    MailTemplate = ":mailTemplate",
    TicketEscalationWorkflow = ":ticketEscalationWorkflow",
    PointChampVacationId = ":pointChampVacation",
    FolderId = ":mailfolder",
    MailId = ":mail",
    AttachmentId = ":attachment",
    CloudGatewayId = ":cloudGateway",
    ImportLogId = ":importLog",
    TenantId = ":tenant",
    ClientId = ":client",
    DomainId = ":domain",
    OfficeAccountId = ":officeAccount",
    TicketId = ":ticket",
    DocumentClearanceReminder = ":documentClearanceReminder",
    DocumentClearanceSetting = ":documentClearanceSetting",
    TicketMessageId = ":ticketMessage",
    TeamId = ":team",
    UserId = ":user",
    CouponId = ":coupon",
    ImapAccountId = ":imapAccount",
    PromotionCodeId = ":promotionCode",
    AbsenceId = ":absence",
    FeatureId = ":feature",
    PlanId = ":plan",
    ServiceId = ":service",
    Alias = ":alias"
}

export interface RouteParamReplaceAction {
    param: RouteParam,
    value: string
}

export interface IDynamicRoute {
    Route: string,
    getValue: (...values: RouteParamReplaceAction[]) => string | null
}

const getRoute = (...route: string[]): IDynamicRoute => {
    if (!route || !route.length) return {
        Route: "/",
        getValue: () => ""
    };

    const routeValue = route.join("/");

    return {
        Route: `/${routeValue}`,
        getValue: (...values: RouteParamReplaceAction[]) => {
            if (!values || !values.length) return null;
            for (const v of values) if (!v.value) return null;
            return values.reduce((prev, curr) => prev.replace(curr.param, curr.value), routeValue);
        }
    }
};

export const ApiRoutes = {
    ContractForDataPrivacy: {
        Current: "/cdp",
        Status: "/cdp/status",
        Download: "/cdp/latest"
    },
    RoundMail: "/roundmail",
    Signatures: {
        All: "/signatures"
    },
    MailTemplates: {
        Global: {
            All: "/templates/mails/global",
            ById: getRoute("templates", "mails", "global", RouteParam.GlobalMailTemplate),
        },
        Tenant: {
            All: "/templates/mails/tenant",
            ById: getRoute("templates", "mails", "tenant", RouteParam.MailTemplate),
        },
        TestSending: "/templates/mails/test"
    },
    Integrations: {
        PointChamp: {
            GetPointChampApiKey: "pointchamp/integration/key",
            CreateNewPointChampApiKey: "pointchamp/integration/key/create"
        },
    },
    Feedback: {
        Post: "/feedback"
    },
    OfficeAccounts: {
        Accounts: {
            ForCurrentUser: "office/accounts/users/current",
            ForTenant: "office/accounts/tenant",
            ForTeams: "office/accounts/teams"
        },
        CheckMailRepliedToStatus: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mails", RouteParam.MailId, "replied"),
        GetOfficeAccountAvatar: getRoute("office", "accounts", RouteParam.OfficeAccountId, "avatar"),
        MoveMailToTicketFolderManually: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mails", RouteParam.MailId, "move", "ticketfolder"),
        DownloadAllAttachmentsForMail: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mails", RouteParam.MailId, "attachments", RouteParam.AttachmentId, "all"),
        TransformMailToTicket: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mails", RouteParam.MailId, "transform", "ticket"),
        TransformMailToTicketMessage: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mails", RouteParam.MailId, "transform", "ticketmessage"),
        GetFolderUnreadMessagesCount: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mailfolders", RouteParam.FolderId, "mails", "unread", "count"),
        GetAttachmentPreview: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mails", RouteParam.MailId, "attachments", "preview"),
        GetAttachmentContent: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mails", RouteParam.MailId, "attachments", RouteParam.AttachmentId),
        DeleteMessage: getRoute("office", "accounts", RouteParam.OfficeAccountId, "inbox", "mails", "delete"),
        UpdateMail: getRoute("office", "accounts", RouteParam.OfficeAccountId, "inbox", "mails", "update"),
        GetOfficeAccountMailFolders: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mailfolders"),
        GetMailFolderChildFolders: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mailfolders", RouteParam.FolderId, "mailfolders"),
        GetMailsForFolder: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mailfolders", RouteParam.FolderId, "mails"),
        GetNextMails: getRoute("office", "accounts", RouteParam.OfficeAccountId, "mails", "next"),
        StartListenerForAccount: getRoute("office", "accounts", RouteParam.OfficeAccountId, "subscriptions", "mails", "start"),
        CheckListenerStatusForAccount: getRoute("office", "accounts", RouteParam.OfficeAccountId, "subscriptions", "mails", "status"),
        StopListenerForAccount: getRoute("office", "accounts", RouteParam.OfficeAccountId, "subscriptions", "mails", "stop"),
        SendMailForAccount: getRoute("office", "accounts", RouteParam.OfficeAccountId, "inbox", "mails", "send"),
        ById: getRoute("office", "accounts", RouteParam.OfficeAccountId),
        GetAll: "office/accounts"
    },
    Azure: {
        SignIn: "azure/sso/login",
    },
    MailLog: {
        GetAll: "/mails/log"
    },
    CloudGateway: {
        GetCurrentTenantSettings: "cloudgateway",
        CreateOrUpdateCurrentTenantSettings: "cloudgateway/create",
        UpdateImportSettings: getRoute("cloudgateway", RouteParam.CloudGatewayId, "update"),
        RunImport: getRoute("cloudgateway", RouteParam.CloudGatewayId, "import"),
        ById: getRoute("cloudgateway", RouteParam.CloudGatewayId),
    },
    ImportValidationError: {
        CheckError: getRoute("import", "validation", "errors", RouteParam.ImportValidationError, "check"),
        ForAddressee: getRoute("import", "validation", "errors", "addressee", RouteParam.Addressee),
        ForEmployee: getRoute("import", "validation", "errors", "employee", RouteParam.Employee),
        ForClient: getRoute("import", "validation", "errors", "client", RouteParam.ClientId)
    },
    ImapAccounts: {
        GetAll: "imap/accounts",
        Create: "/imap/accounts/create",
        ServiceStatus: getRoute("imap", "accounts", RouteParam.ImapAccountId, "service", "status"),
        ServiceStopSingle: getRoute("imap", "accounts", RouteParam.ImapAccountId, "service", "stop"),
        ServiceSendTestMail: getRoute("imap", "accounts", RouteParam.ImapAccountId, "service", "sending", "test"),
        ServiceStartSingle: getRoute("imap", "accounts", RouteParam.ImapAccountId, "service", "start"),
        ServicesStartAll: "/imap/accounts/services/start",
        ServicesStopAll: "/imap/accounts/services/stop",
        ById: getRoute("imap", "accounts", RouteParam.ImapAccountId),
        Messages: getRoute("imap", "accounts", RouteParam.ImapAccountId, "messages")
    },
    Status: {
        Version: "/version"
    },
    Subscriptions: {
        ServicesGetAll: "services",
        ServicesCreate: "services/create",
        ServicesUpdate: getRoute("services", RouteParam.ServiceId, "update"),
        ServicesDelete: getRoute("services", RouteParam.ServiceId),
        PlansGetForService: getRoute("services", RouteParam.ServiceId, "plans"),
        PlansCreate: getRoute("services", RouteParam.ServiceId, "plans", "create"),
        PlansUpdate: getRoute("services", RouteParam.ServiceId, "plans", RouteParam.PlanId, "update"),
        PlansDelete: getRoute("services", RouteParam.ServiceId, "plans", RouteParam.PlanId),
        FeaturesGetAll: "features",
        FeaturesCreate: "features/create",
        FeaturesUpdate: getRoute("features", RouteParam.FeatureId, "update"),
        CouponsGetAll: "/coupons",
        CouponsCreate: "/coupons/create",
        CouponsDelete: getRoute("coupons", RouteParam.CouponId),
        PromotionCodesGetAll: getRoute("coupons", RouteParam.CouponId, "codes"),
        PromotionCodesCreate: getRoute("coupons", RouteParam.CouponId, "codes", "create"),
    },
    Datev: {
        DesktopClientDownload: "/datev/client/download"
    },
    Employees: {
        All: "/employees",
        AreasOfResponsibility: "/employees/responsibilities",
    },
    Addressees: {
        All: "/clients/addressees"
    },
    ClientsImportLog: {
        Recent: "/log/import/clients/latest",
        GetById: getRoute("log","import","clients", RouteParam.ImportLogId),
        All: "/log/import/clients"
    },
    Clients: {
        Alias: getRoute("clients", RouteParam.ClientId, "alias"),
        Responsibilities: {
            All: "/clients/responsibilities",
            ById: getRoute("clients", RouteParam.ClientId, "responsibilities"),
        },
        ClientContacts: getRoute("clients", RouteParam.ClientId, "contacts"),
        GetEmployeeResponsibilities: "/clients/responsibilities",
        DocumentClearance: getRoute("clients", RouteParam.ClientId, "clearance"),
        DocumentClearanceLog: getRoute("clients", RouteParam.ClientId, "clearance", "log"),
        DocumentClearanceRemindManually: getRoute("clients", RouteParam.ClientId, "clearance", "remind"),
        All: "/clients",
        Create: "/clients/new",
        ById: getRoute("clients", RouteParam.ClientId),
        UpdateClientManager: getRoute("clients", RouteParam.ClientId, "responsibilities", "general"),
        ImportFromDatevJson: "/clients/import/datev/json",
    },
    DocumentClearance: {
        All: "/clearance/all",
        RemindAllManually: "/clearance/all/remind",
        ById: getRoute("clearance", RouteParam.DocumentClearanceReminder),
    },
    Domains: {
        CheckStatus: getRoute("domains", RouteParam.DomainId, "refresh"),
        ById: getRoute("domains", RouteParam.DomainId),
        Active: "/domain",
        All: "/domains"
    },
    User: {
        Statistics: {
            AnyUser: getRoute("statistics", "users", RouteParam.UserId),
            AllUsers: "/statistics/users",
            CurrentTenant: "/statistics/tenant",
            CurrentTenantTeams: "/statistics/teams",
            AnyUserDefaultTeam: getRoute("statistics", RouteParam.UserId, "teams", "default"),
            AnyTeam: getRoute("statistics", "teams", RouteParam.TeamId)
        },
        Alias: getRoute("users", RouteParam.UserId, "alias"),
        Absence: {
            All: "/absence",
            ById: getRoute("absence", RouteParam.AbsenceId),
            ForUser: getRoute("users", RouteParam.UserId, "absence"),
        },
        Authority: {
            CurrentUserClients: "/user/clients",
            AnyUserClients: getRoute("users", RouteParam.UserId, "clients"),
        },
        Notifications: {
            All: "/notifications",
        },
        Avatar: getRoute("users", RouteParam.UserId, "avatar"),
        GetCurrentUserLinkedOfficeAccount: "/user/accounts/office",
        CurrentTenantAuthority: "/user/authority",
        RequestAccessToPhoneNumber: "/user/phone/access/request",
        VerifyAccessToPhoneNumber: "/user/phone/access/verify",
        UpdatePhoneNumber: "/user/phone/update/request",
        VerifyPhoneNumberUpdate: "/user/phone/update/verify",
        DeleteAllDatevEmployees: "/users/employees/datev",
        DeleteAllDatevClients: "/users/clients/datev",
        UpdateCurrentSettings: "/user/settings",
        ImportEmployees: "/users/import/employees",
        LogOut: "/logout",
        All: "/users",
        ClientContacts: "/users/clientcontacts",
        Employees: "/users/employees",
        Update: getRoute("users", RouteParam.UserId, "update"),
        CreateNewUser: "/users/new",
        RequestPasswordResetByMail: "/users/password/reset/mail",
        RequestPasswordReset: "/users/password/reset",
        SetNewPassword: "/users/password/new",
        VerifyUser: "/users/verify",
        RequestLogIn: "/users/login",
        VerifyLogIn: "/session/verify",
        ResendSessionOtp: "/session/reauth",
        Active: "/user",
        ValidateResetToken: "/users/password/token/verify",
        Teams: {
            All: getRoute("users", RouteParam.UserId, "teams"),
            Default: getRoute("users", RouteParam.UserId, "teams", "default")
        },
        Delete: getRoute("users", RouteParam.UserId),
    },
    OpeningHours: {
        All: "/openinghours",
    },
    Tenant: {
        IsTenantOpenRightNow: "/tenant/open",
        All: "/tenants",
        Onboarding: "/tenants/onboarding",
        Active: "/tenant",
        Create: "/tenants/create",
        UpdateTenantMailSettings: getRoute("tenants", RouteParam.TenantId, "settings", "mails"),
        SubscriptionsGetBase: getRoute("tenants", "onboarding", RouteParam.TenantId, "services", "selection"),
        OnboardingSubscribeToBase: getRoute("tenants", "onboarding", RouteParam.TenantId, "services", "subscribe"),
        OnboardingGetRoles: getRoute("tenants", "onboarding", RouteParam.TenantId, "roles"),

        RequestDeletionToken: getRoute("tenants", RouteParam.TenantId, "delete", "request"),
        AbortDelete: getRoute("tenants", RouteParam.TenantId, "delete", "abort"),
        Delete: getRoute("tenants", RouteParam.TenantId, "delete"),

        UpdateSettings: getRoute("tenants", RouteParam.TenantId, "settings"),
        CurrentTenantDomains: "/tenant/domains",
        UpdateTenantBillingData: getRoute("tenants", RouteParam.TenantId, "billing", "data"),
        
        SetTenantHasPaymentDetailsFromOnboarding: getRoute("tenants", "onboarding", RouteParam.TenantId, "billing", "payment", "details", "success"),
        SetTenantHasPaymentDetails: getRoute("tenants", RouteParam.TenantId, "billing", "methods", "create", "success"),
        GetPaymentMethods: getRoute("tenants", RouteParam.TenantId, "billing", "methods"),
        AddPaymentMethod: getRoute("tenants", RouteParam.TenantId, "billing", "methods", "create"),
        SetPaymentMethodAsDefault: getRoute("tenants", RouteParam.TenantId, "billing", "methods", "default", "set"),
        DeletePaymentMethod: getRoute("tenants", RouteParam.TenantId, "billing", "methods", "delete"),

        AddSubscription: getRoute("tenants", RouteParam.TenantId, "subscriptions", RouteParam.PlanId, "add"),
        CancelSubscription: getRoute("tenants", RouteParam.TenantId, "subscriptions", RouteParam.PlanId, "cancel"),
        CurrentTenantDomainById: getRoute("tenant", "domains", RouteParam.DomainId),
        UpdateTenantAdminFromRegistration: getRoute("tenants", "onboarding", RouteParam.TenantId, "user"),
        UpdateDuringRegistration: getRoute("tenants", "onboarding", RouteParam.TenantId),
        AddDomainDuringRegistration: getRoute("tenants", "onboarding", RouteParam.TenantId, "domains"),
        ValidateSubdomainDuringRegistration: getRoute("tenants", "onboarding", RouteParam.TenantId, "domains", "validate"),
        CreateNewManagerPasswordDuringOnboarding: getRoute("tenants", "onboarding", RouteParam.TenantId, "user", "password"),
        GetDomainStatusDuringRegistration: getRoute("tenants", "onboarding", RouteParam.TenantId, "domains", RouteParam.DomainId),
        UpdateTenantBillingDataFromOnboarding: getRoute("tenants", "onboarding", RouteParam.TenantId, "billing", "data"),

        GetTenantInvoices: getRoute("tenants", RouteParam.TenantId, "invoices")
    },
    Roles: {
        All: "/roles",
        ById: getRoute("roles", RouteParam.Role),
        Current: "/role",
    },
    Session: {
        CreateImposterSession: "/session/imposter",
        EndImposterSession: "/session/imposter/end",
        GetAvailableVerificationMethods: "/session/verification/available",
        SetSelectedVerificationMethod: "/session/verification",
        Active: "/session"
    },
    Settings: "/settings",
    LegalForms: "/legalforms",
    Alias: {
        ById: getRoute("alias", RouteParam.Alias),
    },
    Logs: "/logs",
    Tasks: {
        ById: getRoute("tasks", RouteParam.Task),
        MarkAsCompleted: getRoute("tasks", RouteParam.Task, "complete"),
        All: "/tasks"
    },
    Tickets: {
        ById: getRoute("tickets", RouteParam.TicketId),
        Assignments: {
            ForCurrentUser: getRoute("tickets", RouteParam.TicketId, "assignment", "current"), 
            AddAssignment: getRoute("tickets", RouteParam.TicketId, "assignments", "add"),
            UpdateAssignment: getRoute("tickets", RouteParam.TicketId, "assignments", RouteParam.TicketAssignment),
            ById: getRoute("tickets", RouteParam.TicketId, "assignments", RouteParam.TicketAssignment),
            GetAllAssignments: getRoute("tickets", RouteParam.TicketId, "assignments"),
            GetMainAssignment: getRoute("tickets", RouteParam.TicketId, "assignments", "main"),
            GetClientContact: getRoute("tickets", RouteParam.TicketId, "assignments", "client")
        },
        Access: {
            ById: getRoute("tickets", RouteParam.TicketId, "access"),
        },
        EscalationWorkflow: {
            All: "/flows/escalation",
            ById: getRoute("flows", "escalation", RouteParam.TicketEscalationWorkflow),
            Log: getRoute("tickets", RouteParam.TicketId, "escalation", "log"),
            StartManually: getRoute("tickets", RouteParam.TicketId, "escalate", RouteParam.TicketEscalationWorkflow),
        },
        Statistics: {
            ForUser: getRoute("tickets", "statistics", "user", RouteParam.UserId),
            ForTeam: getRoute("tickets", "statistics", "team", RouteParam.TeamId),
            ForTenant: "/tickets/statistics/tenant"
        },
        AssignAlias: getRoute("tickets", RouteParam.TicketId, "alias", "create"),
        MailsForTicket: getRoute("tickets", RouteParam.TicketId, "mails"),
        TicketActivity: getRoute("tickets", RouteParam.TicketId, "activity"),
        MergeTickets: "tickets/merge",
        Messages: getRoute("tickets", RouteParam.TicketId, "messages"),
        All: "/tickets",
        Filtered: "/tickets/filtered",
        Closed: "/tickets/closed",
        AddNote: getRoute("tickets", RouteParam.TicketId, "notes"),
        AddMessage: getRoute("tickets", RouteParam.TicketId, "messages", "new"),
        AllCategories: "/tickets/categories",
        Relevant: "/tickets/relevant",
        DeleteTickets: "tickets/delete",
        Tasks: getRoute("tickets", RouteParam.TicketId, "tasks"),
        UpdateTicketStatus: "tickets/update/status",
        AssignTicket: getRoute("tickets", RouteParam.TicketId, "assign"),
        AssignToTeam: getRoute("tickets", RouteParam.TicketId, "team"),
        EscalateTickets: getRoute("tickets", RouteParam.TicketId, "escalate"),
        SetInternalSubject: getRoute("tickets", RouteParam.TicketId, "subject", "internal"),
        AllPriorities: "/tickets/priorities",
        Create: "/tickets/create"
    },
    TicketMessages: {
        ById: getRoute("ticketmessages", RouteParam.TicketMessageId),
        DownloadAttachments: getRoute("ticketmessages", RouteParam.TicketMessageId, "attachments", "download"),
        Mails: getRoute("ticketmessages", RouteParam.TicketMessageId, "mails"),
    },
    Teams: {
        All: "/teams",
        CreateTeamsForDatevAreasOfResponsibility: "/teams/new/datev",
        UpdateTeamMembersFromAreaOfResponsibility: getRoute("teams", RouteParam.TeamId, "members", "update", "datev"),
        Update: getRoute("teams", RouteParam.TeamId),
        Create: "/teams/new",
        Delete: getRoute("teams", RouteParam.TeamId),
    }
}

