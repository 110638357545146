import React from "react";
import { IOpeningHours } from "../../types/ApiTypes";
import Flex from "../container/Flex";
import LabelButton from "../buttons/LabelButton";
import Icon from "../icons/Icon";
import { useFormikContext } from "formik";
import Card from "../card/Card";
import { AppColor } from "../../app/AppStyles";

export interface IDayOpeningHoursFieldProps {
    fieldName: string,
    value: IOpeningHours[],
    label: string,
    applyToAll: (value: IOpeningHours[], workdaysOnly: boolean) => void
}

export default function DayOpeningHoursField({fieldName, value, applyToAll, label}: IDayOpeningHoursFieldProps) {

    const {
        setFieldValue
    } = useFormikContext();

    const removeOpeningHour = (index: number) => {
        if (!value || !value.length) return;
        const newValue = [...value];
        newValue.splice(index, 1);
        setFieldValue(fieldName, newValue);
    }

    const addOpeningHour = () => {
        const newValue = [...value];
        newValue.push({
            fromHour: 8,
            fromMinute: 30,
            toHour: 17,
            toMinute: 0
        });
        setFieldValue(fieldName, newValue);
    }

    const updateOpeningHour = (index: number, hourKey: keyof IOpeningHours, minuteKey: keyof IOpeningHours, newValue: number) => {
        
        if (!value || !value.length) return;

        const totalMinutes = (!isNaN(newValue) ? newValue : 0) / 1000 / 60;

        const minutes = totalMinutes % 60;
        const hours = (totalMinutes - minutes) / 60;

        console.log("HOURS", hours);
        console.log("MINUTES", minutes);

        const newValueArray = [...value];

        newValueArray[index][hourKey] = hours;
        newValueArray[index][minuteKey] = minutes;

        setFieldValue(fieldName, newValueArray);
    }

    return (
        <Card background="var(--backgroundLighter)">
            <Flex className="w-100">
                <Flex row justify="between" className="w-100">
                    <strong>{label}</strong>
                    <LabelButton icon="plus" onClick={async () => addOpeningHour()} text="Neue Öffnungszeit" />
                </Flex>
                {
                    value && value.length
                    ? (
                        <Flex className="w-100">
                            {
                                value.map((openingHour, index) => (
                                    <Flex row gap={5} key={`${index}`}>
                                        <Card padding={2} >
                                            <input 
                                                className="text-center" 
                                                style={{border: "none", outline: "none", background: "transparent"}} 
                                                onChange={e => updateOpeningHour(index, "fromHour", "fromMinute", e.target.valueAsNumber)} 
                                                type="time"
                                                min="0" 
                                                max="24" 
                                                value={`${openingHour.fromHour.toString().padStart(2, '0')}:${openingHour.fromMinute.toString().padStart(2, '0')}`} 
                                            />
                                        </Card>
                                        <strong>bis</strong>
                                        <Card padding={2} >
                                            <input 
                                                className="text-center" 
                                                style={{border: "none", outline: "none", background: "transparent"}} 
                                                onChange={e => updateOpeningHour(index, "toHour", "toMinute", e.target.valueAsNumber)} 
                                                type="time"
                                                min="0" 
                                                max="24" 
                                                value={`${openingHour.toHour.toString().padStart(2, '0')}:${openingHour.toMinute.toString().padStart(2, '0')}`} 
                                            />
                                        </Card>
                                        <Icon icon="x" color="error" onClick={() => removeOpeningHour(index)} />
                                    </Flex>
                                ))
                            }
                            <Flex className="w-100" justify="between" row gap={1}>
                                <LabelButton color="error" icon="trash" onClick={async () => setFieldValue(fieldName, [])} text="Als geschlossen markieren" />
                                <Flex row gap={3}>
                                    <span>Auf alle anwenden:</span>
                                    <LabelButton className="justify-self-end" icon="clipboard2-minus" onClick={async () => applyToAll(value, true)} text="Werktage" />
                                    <LabelButton className="justify-self-end" icon="clipboard" onClick={async () => applyToAll(value, false)} text="Alle Tage" />
                                </Flex>
                            </Flex>
                        </Flex>
                    )
                    : <strong style={{color: "red"}}>Geschlossen</strong>
                }
            </Flex>
        </Card>
    )

}