import React from "react";
import { MailLogType, MailSource } from "../../types/ApiTypes";
import Pill from "../pills/Pill";

export default function MailLogSourceBanner({source}: {source: MailSource}) {

    const getSourceName = () => {
        switch (source) {
            case MailSource.IMAP: return "IMAP";
            case MailSource.SendGrid: return "Service";
            case MailSource.MsOffice: return "Office365";
        }
    }

    const getSourceColor = () => {
        switch (source) {
            case MailSource.IMAP: return "#0033AA";
            case MailSource.SendGrid: return "#33AA00";
            case MailSource.MsOffice: return "#08A508";
        }
    }

    return <Pill color={getSourceColor()} text={getSourceName()} />
}