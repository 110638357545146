import React from "react";
import { AppColor } from "../../app/AppStyles";
import useClientUtil from "../../hooks/useClientUtil";
import { DatevClientType, IAddressee, IClient } from "../../types/ApiTypes";
import Icon from "../icons/Icon";
import Pill from "../pills/Pill";
import { generateClassName } from "../../hooks/useAttributes";
import Banner from "../pills/Banner";
import Typography from "../text/Typography";

export interface IClientTypeBannerProps {
    client?: IClient,
    showClientName?: boolean,
    textOnEmpty?: string,
    className?: string,
    onClick?: () => void
}

export default function ClientTypeBanner({client, showClientName, onClick, textOnEmpty, className}: IClientTypeBannerProps) {
    const {
        getTypeNameOfClient,
        getClientTypeColor,
        getClientName
    } = useClientUtil();

    if (!client) {
        if (textOnEmpty) return <Banner text={textOnEmpty} color="bright" />
        if (!showClientName) return null;
        return <span>-</span>
    }
    
    const name = showClientName ? getClientName(client) : getTypeNameOfClient(client);

    if (!name) return showClientName ? null : <span>-</span>;

    const pillClass = generateClassName(className, "gap-2");

    return (
        <Pill className={pillClass} color={getClientTypeColor(client)} onClick={onClick}>
            <ClientTypeIcon client={client} />
            <span className="text-nowrap text-truncate w-100"><strong>{client.number}</strong> {name}</span>
        </Pill>
    )
}

export function ClientTypeIcon({client}: {client: IClient}) {

    if (!client) return null;
    if (!client.type) return null;

    const getIcon = () => {
        switch (client.type) {
            case DatevClientType.IndividualEnterprise: return "house-heart";
            case DatevClientType.LegalPerson: return "building";
            case DatevClientType.NaturalPerson: return "person-fill";
        }
    }

    return <Icon icon={getIcon()} />
}