import useSWR from "swr";
import { ITenantAuthority } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useCurrentTenantAuthority = () => {
    const { data, isLoading, mutate } = useSWR<ITenantAuthority>(ApiRoutes.User.CurrentTenantAuthority);

    return {
        authority: data,
        loadingAuthority: isLoading,
        reloadAuthority: mutate
    }
}