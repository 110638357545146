import React from "react";
import Flex from "../../container/Flex";
import { ITicket } from "../../../types/ApiTypes";
import { useTicketMails } from "../../../state/swr/tickets/useTicketMails";
import LoadingSpinner from "../../loader/LoadingSpinner";
import MailLogCard from "../../mail/MailLogCard";
import SidebarHeader from "../../sidebar/SidebarHeader";
import { useTicketTasks } from "../../../state/swr/tickets/useTicketTasks";
import TaskItem from "../../tasks/TaskItem";
import TaskForm from "../../tasks/TaskForm";

export default function TicketTasks({ticket}: {ticket: ITicket}) {

    const {
        loadingTicketTasks,
        reloadTicketTasks,
        ticketTasks
    } = useTicketTasks(ticket._id);

    if (loadingTicketTasks) return <LoadingSpinner />

    return (
        <Flex>
            <Flex row wrap justify="between">
                <SidebarHeader text="Aufgaben und Termine" />
                <TaskForm ticket={ticket._id} />
            </Flex>
            {
                ticketTasks && ticketTasks.length
                ? ticketTasks.map(d => <TaskItem key={d._id} task={d} />)
                : <span>Keine Aufgaben</span>
            }
        </Flex>
    )
}