import React, { PropsWithChildren } from "react";
import Icon from "../icons/Icon";
import "./Dialog.css";
import Flex from "../container/Flex";

interface IDialogProps {
    setVisible: (val: boolean) => void,
    title: string,
    sidebar?: React.ReactNode | ((closeForm: () => void) => React.ReactNode),
    children: React.ReactNode | ((closeForm: () => void) => React.ReactNode)
}

export default function Dialog({title, children, sidebar, setVisible}: IDialogProps) {

    const [sidebarHeight, setSidebarHeight] = React.useState<number>(0);
    const [height, setHeight] = React.useState<number>(0);
    const [resized, setResized] = React.useState<number>(0);
    const [zoom, setZoom] = React.useState<number>(1);

    const sizeTimer = React.useRef<any>();
    const dialogSizeTimer = React.useRef<any>();
    const windowSizeTimer = React.useRef<any>();
    const dialogRef = React.useRef<HTMLDivElement>(null);
    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        clearTimeout(windowSizeTimer.current);

        const onResize = () => {
            windowSizeTimer.current = setTimeout(() => {
                const zoom = window.devicePixelRatio;
                if (zoom > 0 && zoom <= 2) setZoom(zoom);
                setResized(resized + 1);
            }, 100);    
        }

        window.addEventListener("resize", onResize);

        return () => window.removeEventListener("resize", onResize);
    }, [])

    const updateHeight = (ref: React.RefObject<HTMLDivElement>, maxValue: number = 200) => {
        if (!ref || !ref.current) return;
        const h = ref.current.clientHeight;
        const result = h < maxValue ? maxValue : h;

        const screenHeight = window.innerHeight;

        const maxSidebarHeight = result > screenHeight ? screenHeight - 120 : result * zoom - 120;

        setHeight(result * zoom);
        setSidebarHeight(maxSidebarHeight);
    }

    React.useEffect(() => {
        clearTimeout(sizeTimer.current);
        if (!modalRef || !modalRef.current) return;
        sizeTimer.current = setTimeout(() => updateHeight(modalRef), 100);
    }, [children, modalRef, resized]);

    React.useEffect(() => {
        clearTimeout(dialogSizeTimer.current);
        if (!dialogRef || !dialogRef.current) return;
        dialogSizeTimer.current = setTimeout(() => updateHeight(dialogRef, 200), 80);
    }, [resized]);

    const closeButton = (
        <div className="h-100 mb-auto d-flex flex-row align-items-center justify-content-end dialog-header">
            <Icon icon="x" onClick={() => setVisible(false)} size={45} />
        </div>
    );

    const childrenContent = (
        typeof children === "function"
        ? children(() => setVisible(false))
        : children
    )

    const sidebarContent = (
        typeof sidebar === "function"
        ? sidebar(() => setVisible(false))
        : sidebar
    );

    return (
        <div className="position-fixed top-0 start-0 vh-100 vw-100 modal-form-container overflow-hidden">
            <div className="position-relative w-100 h-100 d-flex align-items-center justify-content-center overflow-hidden">
                <div className="modal-form-background position-absolute top-0 start-0 w-100 h-100" onClick={() => setVisible(false)} />
                <div className="modal-form w-75 w-md-50" style={{height: `${height + 120}px`}} ref={dialogRef}>
                    <div className="d-flex flex-sm-row flex-column w-100 h-100 overflow-hidden">
                        <div className="p-4 w-100 overflow-auto" >
                            <div className="dialog-header d-flex flex-row align-items-center justify-content-between">
                                <h1 className="text-wrap fw-bold m-0 p-0 text-uppercase" style={{color: "#002E7F", fontSize: "34px"}}>{title}</h1>
                                <div className="d-md-none d-flex flex-row align-items-start justify-content-end">
                                    {closeButton}
                                </div>
                            </div>
                            <div className="modal-form-content w-100 d-flex flex-column position-relative text-start" ref={modalRef}>
                                { childrenContent }
                                <div className="d-flex d-md-none w-100">
                                    {sidebarContent}
                                </div>
                            </div>
                        </div>
                        <div className="p-4 w-50 d-md-flex flex-column d-none justify-content-start" style={{minWidth: "25%", backgroundColor: "#F2F5F9", minHeight: `${sidebarHeight - 20}px`}}>
                            <div className="d-md-flex d-none flex-row align-items-center justify-content-end">
                                {closeButton}
                            </div>
                            <div className="mt-auto h-100 w-100 d-flex flex-column align-items-end justify-content-end" style={{backgroundColor: "#F2F5F9", minHeight: `${sidebarHeight - 150}px`, overflowY: "auto"}}>
                                {sidebarContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}