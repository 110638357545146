import { MailFolder } from "@microsoft/microsoft-graph-types";
import React from "react";
import { IOfficeAccount } from "../../../types/ApiTypes";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Flex from "../../container/Flex";
import { getTextColorFromBackground } from "../../../util/util";
import { formatNumber } from "../../../util/formatter";
import { useFolderUnreadMessagesCount } from "../../../state/swr/office/useFolderUnreadMessagesCount";

export default function OfficeFolderUnreadCount({account, folder}: {folder: MailFolder, account: IOfficeAccount}) {
    const { folderUnreadMessagesCount, loadingFolderUnreadMessagesCount } = useFolderUnreadMessagesCount(account._id, folder.id!);

    if (loadingFolderUnreadMessagesCount) return <LoadingSpinner size={12} />;

    if (!folderUnreadMessagesCount || !folderUnreadMessagesCount.unreadItemCount) return null;

    return (
        <Flex align="center" justify="center" style={{backgroundColor: "var(--primary)", height: "20px", minWidth: "20px", borderRadius: "50px"}}>
            <strong className="pe-1 ps-1" style={{fontSize: "10px", color: getTextColorFromBackground("primary")}}>{formatNumber(folderUnreadMessagesCount.unreadItemCount)}</strong>
        </Flex>
    )
}