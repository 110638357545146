import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import SideNavLink from "./SideNavLink";
import "./SideNav.css";
import { AppRoutes } from "../../config/AppRoutes";
import { generateClassName } from "../../hooks/useAttributes";
import useUserUtil from "../../hooks/useUserUtil";
import useTenantSettings from "../../hooks/useTenantSettings";
import SideNavGroup from "./SideNavGroup";
import SideNavGroupContainer from "./SideNavGroupContainer";
import Icon from "../icons/Icon";
import { setSidebarExpanded } from "../../state/slices/sidebarSlice";
import Flex from "../container/Flex";
import SideNavLogo from "./SideNavLogo";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useTenant } from "../../state/swr/tenant/useTenant";
import { useSettings } from "../../state/swr/settings/useSettings";

export enum NavGroup {
    None,
    PersonalSpace,
    TheOffice,
    MyPortal,
    Administration,
    API
}

export enum NavTab {
    Dashboard,
    Inbox,
    Tickets,
    Clients,
    Addressees,
    DocumentClearanceOverview,
    ClientContacts,
    Employees,
    MailTemplates,
    Teams,
    ClientResponsibility,
    TenantDomains,
    Billing,
    AllUsers,
    MailManagement,
    AllDomains,
    Tenants,
    Services,
    Log,
    MailLog,
    Help,
    Settings,
    RoleManagement
}

export default function SideNav() {

    const [isExpandedDesktop, setIsExpandedDesktop] = React.useState<boolean>(true);

    const dispatch = useAppDispatch();

    const {
        isExpanded
    } = useAppSelector(state => state.sidebar);

    const { user } = useUser();
    const { role } = useCurrentTenantRole();

    const {
        links, 
        titles
    } = useTenantSettings(true);

    const className = generateClassName("sidenav-container pt-4 pb-4 d-flex flex-row align-items-start justify-content-between me-4 h-100", {
        base: "sidenav-",
        standard: "collapsed",
        onTrue: "expanded",
        value: isExpanded
    }, {
        base: "sidenav-desktop-",
        standard: "collapsed",
        onTrue: "expanded",
        value: isExpandedDesktop
    });

    if (!user) return null;

    const isSuperAdmin = user && user.isSuperAdmin;
    const isCustomer = !isSuperAdmin && (!role || role.isClient);

    return (
        <div className={className}>
            <Flex className="w-100 h-100 overflow-hidden position-relative" gap={5} align="start" >
                <div className="d-flex flex-row align-items-start mt-5 w-100 sidenav-header position-relative">
                    <SideNavLogo />
                </div>
                <div className="sidenav-content-container h-100 w-100">
                    <div className="sidenav-content d-flex flex-column gap-3" >
                        <SideNavGroupContainer>
                            <SideNavLink to={AppRoutes.Home} tab={NavTab.Dashboard} displayCondition={!isCustomer} />
                            <SideNavLink to={AppRoutes.Tickets} tab={NavTab.Tickets} otherValidPaths={[AppRoutes.Ticket]}/>
                            <SideNavLink to={AppRoutes.Inbox} tab={NavTab.Inbox} displayCondition={!isCustomer}/>
                            <SideNavGroup 
                                label={isCustomer ? "Mein Portal" : "Die Kanzlei"}
                                groupId={NavGroup.TheOffice} 
                            >
                                <SideNavLink to={AppRoutes.DocumentClearanceOverview} text={titles?.documentClearanceWord} tab={NavTab.DocumentClearanceOverview} />
                                <SideNavLink to={AppRoutes.ClientCompanyManagement} tab={NavTab.Clients} permissions={["clients.all.read"]} />
                                <SideNavLink to={AppRoutes.ClientResponsibilityManagement} tab={NavTab.ClientResponsibility} displayCondition={isSuperAdmin} />
                                <SideNavLink to={AppRoutes.AddresseeManagement} tab={NavTab.Addressees} permissions={["addressees.all.read"]} />
                                <SideNavLink to={AppRoutes.ClientManagement} tab={NavTab.ClientContacts} permissions={["users.clientContacts.read"]} />
                                <SideNavLink to={AppRoutes.EmployeeManagement} tab={NavTab.Employees} permissions={["users.employees.read"]} />
                                <SideNavLink to={AppRoutes.TeamsManagement} tab={NavTab.Teams} permissions={["teams.all.read"]} />
                            </SideNavGroup>
                            <SideNavGroup 
                                label={"Administration"} 
                                displayCondition={isSuperAdmin} 
                                groupId={NavGroup.Administration}
                            >
                                <SideNavLink to={AppRoutes.UserManagement} displayCondition={isSuperAdmin} tab={NavTab.AllUsers} />
                                <SideNavLink to={AppRoutes.DomainManagement} tab={NavTab.AllDomains} displayCondition={isSuperAdmin} />
                                <SideNavLink to={AppRoutes.TenantManagement} displayCondition={isSuperAdmin} tab={NavTab.Tenants} />
                                <SideNavLink to={AppRoutes.ServiceManagement} displayCondition={isSuperAdmin} tab={NavTab.Services} />
                            </SideNavGroup>
                            <SideNavGroup 
                                label="System" 
                                groupId={NavGroup.MyPortal}
                                displayPermissions={[ 
                                    "billing.tenant.read", 
                                    "domains.tenant.read", 
                                    "templates.mails.read", 
                                    "inboxes.all.read",
                                    "roles.all.read" 
                                ]}
                            >
                                <SideNavLink to={AppRoutes.MailTemplates} tab={NavTab.MailTemplates} permissions={[ "templates.mails.read" ]}/>
                                <SideNavLink to={AppRoutes.TenantDomainManagement} tab={NavTab.TenantDomains} permissions={[ "domains.tenant.read" ]} />
                                <SideNavLink to={AppRoutes.Billing} tab={NavTab.Billing} permissions={[ "billing.tenant.read" ]} />
                                <SideNavLink to={AppRoutes.MailManagement} tab={NavTab.MailManagement} permissions={[ "inboxes.all.read" ]} />
                                <SideNavLink to={AppRoutes.RoleManagement} tab={NavTab.RoleManagement} permissions={[ "roles.all.read" ]} />
                            </SideNavGroup>
                            <SideNavGroup 
                                label="Erweitert" 
                                displayPermissions={[
                                    "logs.email.read",
                                    "logs.api.read"
                                ]}
                                groupId={NavGroup.API}
                            >
                                <SideNavLink to={AppRoutes.Logs} permissions={[ "logs.api.read" ]} tab={NavTab.Log} />
                                <SideNavLink to={AppRoutes.MailLogs} permissions={[ "logs.email.read" ]} tab={NavTab.MailLog} />
                            </SideNavGroup>
                        </SideNavGroupContainer>
                    </div>
                </div>
                {

                    (isExpanded || isExpandedDesktop) && (
                        <div className="d-flex flex-column w-100 justify-self-end">
                            {
                                links && links.privacyPolicy && links.imprint && (
                                    <div className="d-flex flex-row align-items-center justify-content-between p-2" style={{fontSize: "0.8em"}}>
                                        <div className="d-flex flex-row align-items-center gap-1">
                                            {
                                                links.privacyPolicy 
                                                && <a target="_blank" className="hyperlink" href={links.privacyPolicy}>Datenschutzerklärung</a>
                                            }
                                            {
                                                links.imprint && <a target="_blank" className="hyperlink" href={links.imprint}>Impressum</a> 
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </Flex>
            <Flex justify="end" align="start" className={generateClassName("h-100 sidebar-toggle-desktop position-relative", { value: isExpandedDesktop, onTrue: "sidebar-close-button", standard: "sidebar-toggle-desktop-collapsed"})} row>
                <Icon hexColor="#FFF" icon={isExpandedDesktop ? "arrow-left" : "list"} size={35} className="sidebar-expander-icon-desktop" onClick={() => setIsExpandedDesktop(!isExpandedDesktop)} />
            </Flex>
            <Flex justify="end" align="start" className={generateClassName("h-100 sidebar-toggle-mobile position-relative", { value: isExpanded, onTrue: "sidebar-close-button", standard: "sidebar-toggle-desktop-collapsed"})} row>
                <Icon hexColor="#FFF" icon="x" size={50} className="sidebar-expander-icon-mobile" onClick={() => dispatch(setSidebarExpanded(false))} />
            </Flex>
        </div>
    )
}