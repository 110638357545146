import useSWR from "swr";
import { IPointChampApiKey, IRole, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useRoles = () => {
    const { data, isLoading, mutate } = useSWR<Array<IRole>>(ApiRoutes.Roles.All);

    return {
        roles: data,
        loadingRoles: isLoading,
        reloadRoles: mutate
    }
}