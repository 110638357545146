import React from "react";
import IElementProps from "../../types/ElementProps";
import "./Accordion.css";
import Icon from "../icons/Icon";
import { generateClassName } from "../../hooks/useAttributes";

export interface IAccordionProps extends IElementProps {
    isEmpty?: boolean,
    expanderClass?: string,
    text: string | number,
    iconExpanded: string,
    bold?: boolean,
    icon: string,
    isExpandedInitially?: boolean,
    iconFirst?: boolean
}

export default function Accordion({expanderClass, isExpandedInitially, icon, bold, iconExpanded, text, iconFirst, children, className, isEmpty}: IAccordionProps) {
    const [expanded, setExpanded] = React.useState<boolean>(isExpandedInitially || false);

    React.useEffect(() => isExpandedInitially !== undefined ? setExpanded(isExpandedInitially) : undefined, [isExpandedInitially]);

    const iconComponent = <Icon icon={expanded ? iconExpanded : icon} />

    const expanderContainerClass = generateClassName("accordion-expander-container gap-2 d-flex flex-row align-items-center", expanderClass);
    const accordionContainerClass = generateClassName("d-flex flex-column gap-1", className)
    const accordionContentClass = generateClassName("accordion-content-container", {
        value: expanded,
        base: "accordion-content-container-",
        onTrue: "expanded",
        standard: "collapsed"
    });

    return (
        <div className={accordionContainerClass}>
            <div className={expanderContainerClass} onClick={() => setExpanded(!expanded)}>
                {iconFirst && iconComponent}
                {
                    bold 
                    ? <strong>{text}</strong>
                    : <span>{text}</span>
                }
                {!iconFirst && iconComponent}
            </div>
            <div className={accordionContentClass}>
                {
                    children
                }
            </div>
        </div>
    )
}