import useSWR from "swr";
import { IClient, IUser, IUserClients } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useUsers = () => {
    const { data, isLoading, mutate } = useSWR<Array<IUser>>(ApiRoutes.User.All);
    
    return {
        users: data,
        loadingUsers: isLoading,
        reloadUsers: mutate
    }
}