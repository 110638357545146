import React, { useEffect } from "react";
import { Status } from "../../config/Status";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Error from "../../pages/Error";
import { ITenant } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import TenantListItem from "./TenantListItem";
import { useTenants } from "../../state/swr/tenant/useTenants";
import { useUser } from "../../state/swr/user/useUser";

export default function TenantList() {

    const {
        user
    } = useUser();

    const { loadingTenants, tenants, reloadTenants } = useTenants();

    if (!user || !user.isSuperAdmin) return null;

    if (loadingTenants) return <LoadingSpinner centered={false} />
    if (!tenants || !tenants.length) return <span>Keine Tenants gefunden.</span>
    
    return ( 
        <Table 
            border 
            headers={[{label: "Name"}, {label: "Aktiv seit"}, {label: "Zuletzt aktualisiert"}, {label: "Status"}, {label: "Status-Grund"}, {label: ""}]}
            items={tenants}
            renderItem={(t: ITenant) => <TenantListItem key={t._id} tenant={t} />}
        />
    )
}