import React, { useEffect } from "react";
import { Status } from "../../config/Status";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Error from "../../pages/Error";
import { IAddressee, IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import AddresseeListItem from "./AddresseeListItem";
import useUserUtil from "../../hooks/useUserUtil";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { useRoles } from "../../state/swr/roles/useRoles";
import usePermissions from "../../hooks/usePermissions";
import WithPermissions from "../permissions/WithPermissions";

export default function AddresseeList() {

    const { addressees, loadingAddressees } = useAddressees();
    const { loadingRoles, roles } = useRoles();

    const {
        getName
    } = useUserUtil();

    return (
        <WithPermissions permissions={["addressees.all.read"]} >
            {
                loadingRoles || loadingAddressees
                ? <LoadingSpinner centered={false} />
                :  (
                    !addressees || !addressees.length
                    ? <span>Keine Addressaten.</span>
                    :  (
                        <Table 
                            border 
                            className="w-100" 
                            headers={[
                                { 
                                    label: "Name",
                                    filterItem: (a, filter) => {
                                        if (a.actualName && a.actualName.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (!a.addresses || !a.addresses.length) return false;
                                        
                                        const address = a.addresses[0];
                
                                        if (!address) return false;
                                    
                                        if (address.post_office_box && address.post_office_box.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (address.street && address.street.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (address.postal_code && address.postal_code.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (address.city && address.city.toLowerCase().includes(filter.toLowerCase())) return true;
                
                                        return false;
                                    }
                                }, 
                                {label: ""}, 
                                {
                                    label: "Typ",
                                    valueKey: "originalMail"
                                }, 
                                {
                                    label: "Beziehungen",
                                    permissions: [ "addresseeRelationships.all.read" ],
                                    filterItem: (a, filter) => {
                                        if (!a.relationships || !a.relationships.length) return false;
                
                                        const relationshipValues = a.relationships.map(r => {
                                            if (r.has_addressee_id !== a.id && r.is_addressee_id !== a.id) return null;
                                            const isHasRelationship = r.has_addressee_id === a.id;
                                            return isHasRelationship ? r.is_addressee_display_name : r.has_addressee_display_name;
                                        });
                
                                        return relationshipValues.some(r => r && r.toLowerCase().includes(filter.toLowerCase()));
                                    }
                                }, 
                                {label: "Mandanten"},  
                                { 
                                    label: "Verknüpfter Nutzer",
                                    filterItem: (a, filter) => {
                                        if (!a.user) return false;
                                        const name = getName(a.user);
                
                                        if (name && name.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (a.user.mailAddress && a.user.mailAddress.toLowerCase().includes(filter.toLowerCase())) return true;
                
                                        return false;
                                    }
                                }
                            ]}
                            items={addressees}
                            renderItem={(a: IAddressee) => <AddresseeListItem key={a._id} addressee={a} />}
                        />
                    )
                )
            }
        </WithPermissions>
    )

    
}