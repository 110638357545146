import useSWR from "swr";
import { IClient, IRole } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useCurrentTenantRole = () => {
    const { data, isLoading, mutate } = useSWR<IRole>(ApiRoutes.Roles.Current);

    return {
        role: data,
        loadingRole: isLoading,
        reloadRole: mutate
    }
} 