import React from "react";
import { IGlobalMailTemplate, IGlobalMailTemplateDocument, IMailTemplate, IMailTemplateContent, IMailTemplateDocument, TemplatePlaceholder } from "../../types/ApiTypes";
import ModalForm from "../modal/ModalForm";
import useApi from "../../hooks/useApi";
import { globalMailTemplatesUpdate, mailTemplatesCreate, mailTemplatesUpdate } from "../../api/Api";
import { useMailTemplates } from "../../state/swr/templates/useMailTemplates";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import FormikField from "../formik/FormikField";
import { useFormikContext } from "formik";
import TemplatePlaceholderChip from "./TemplatePlaceholderChip";
import Typography from "../text/Typography";
import DeleteMailTemplateButton from "./DeleteMailTemplateButton";
import { useGlobalMailTemplates } from "../../state/swr/templates/useGlobalMailTemplates";
import { IGlobalMailTemplateUpdateRequest } from "../../api/ApiRequests";
import Card from "../card/Card";
import CheckBox from "../formik/CheckBox";
import MailTemplateElementEdit from "./MailTemplateElementEdit";
import TemplatePreview from "./TemplatePreview";
import { useUser } from "../../state/swr/user/useUser";

export interface IUpdateGlobalMailTemplateButtonProps {
    template: IGlobalMailTemplate
}

export default function UpdateGlobalMailTemplateButton({template}: IUpdateGlobalMailTemplateButtonProps) {
    const {
        user
    } = useUser();

    const { reloadGlobalMailTemplates } = useGlobalMailTemplates();

    const callApi = useApi();

    if (!template) return null;

    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <ModalForm
            initialValues={{
                availablePlaceholders: template.availablePlaceholders || [],
                body: template.body || "",
                canBeCustomized: template.canBeCustomized || false,
                isPublished: template.isPublished || false,
                subject: template.subject || "",
                templateType: template.templateType || "",
                id: template._id
            } as IGlobalMailTemplateUpdateRequest}
            enableReinitialize
            button={
                <Button text="Bearbeiten" variant="solid" icon="pen" />
            }
            title="Globale Vorlage bearbeiten"
            onSubmit={async (values) => {
                const res = await callApi(globalMailTemplatesUpdate(values));

                if (!res) return false;

                await reloadGlobalMailTemplates();
                return true;
            }}
            sidebar={
                (formik, close) => (
                    <Flex fullHeight fullWidth justify="between">
                        <TemplatePreview content={formik.values} />
                        <Flex fullWidth >
                            {
                                formik.values.availablePlaceholders && !!formik.values.availablePlaceholders.length && (
                                    <Flex>
                                        <Typography>Verfügbare Platzhalter</Typography>
                                        <Flex row wrap>
                                            {
                                                formik.values.availablePlaceholders.map(p => <TemplatePlaceholderChip placeholder={p} />)
                                            }
                                        </Flex>
                                    </Flex>
                                )
                            }
                            <Flex>
                                <Typography>Optionen</Typography>
                                <CheckBox label="Kann vom Tenant angepasst werden" name="canBeCustomized" />
                                <CheckBox label="Ist zur Verwendung veröffentlicht" name="isPublished" />
                            </Flex>
                            <Button type="submit" text="Speichern" icon="save" />
                        </Flex>
                    </Flex>
                )
            }
        >
            {
                formik => {

                    const addToAvailablePlaceholders = (placeholder: TemplatePlaceholder) => {
                        if (!formik.values.availablePlaceholders || !formik.values.availablePlaceholders.length) {
                            formik.setFieldValue("availablePlaceholders", [placeholder]);
                            return;
                        }

                        if (formik.values.availablePlaceholders.includes(placeholder)) return;

                        formik.setFieldValue("availablePlaceholders", [...formik.values.availablePlaceholders, placeholder]);
                    }

                    const removeFromAvailablePlaceholders = (placeholder?: TemplatePlaceholder) => {
                        if (!placeholder) return;
                        if (!formik.values.availablePlaceholders || !formik.values.availablePlaceholders.length) return;
                        formik.setFieldValue("availablePlaceholders", formik.values.availablePlaceholders.filter(p => p !== placeholder));
                    }

                    return (
                        <Flex className="w-100" gap={4} >
                            <Flex className="w-100">
                                <MailTemplateElementEdit name="subject" label="Betreff" onPlaceholderSelect={addToAvailablePlaceholders} onPlaceholderRemove={removeFromAvailablePlaceholders} />
                            </Flex>
                            <Flex className="w-100">
                                <MailTemplateElementEdit name="body" label="Inhalt" onPlaceholderSelect={addToAvailablePlaceholders} onPlaceholderRemove={removeFromAvailablePlaceholders} />
                            </Flex>
                        </Flex>
                    )
                }
            }
        </ModalForm>
    )
}