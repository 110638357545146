import React from "react";
import Button from "../buttons/Button";
import ModalForm from "../modal/ModalForm";
import { Locale } from "../../locale/Locale";
import { Form, Formik } from "formik";
import { AppColor } from "../../app/AppStyles";
import { IServiceCreateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import FieldWithLabel from "../formik/FormikField";
import CheckBox from "../formik/CheckBox";
import useApi from "../../hooks/useApi";
import { servicesCreate } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import Flex from "../container/Flex";
import { useServices } from "../../state/swr/subscriptions/useServices";
import { useUser } from "../../state/swr/user/useUser";

export default function CreateServiceButton() {

    const {
        user
    } = useUser();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    const { reloadServices } = useServices();

    if (!user || !user.isSuperAdmin) return null;

    return (
        <ModalForm 
            title={Locale.pages.services.addService} 
            button={<Button text={Locale.pages.services.addService} icon="plus" color="success" />}
            initialValues={{
                name: "",
                description: "",
                isGlobalBaseSubscription: false
            } as IServiceCreateRequest}
            onSubmit={async (values) => {
                const res = await callApi(servicesCreate(values));
                if (!res) return false;
                await reloadServices();
                return true;
            }}
            sidebar={formik => <Button type="submit" text="Service erstellen" icon="plus" loading={formik.isSubmitting} />}
        >
            {
                (formik) => (
                    <Flex className="w-100">
                        <FieldWithLabel name="name" label="Name der Plangruppe *" placeholder="z.B. tiggi.io" />
                        <FieldWithLabel name="description" label="Beschreibung" placeholder="Beschreibung des Abos, z.B. Zugriff auf das tiggi.io Ticketsystem" />
                        <CheckBox name="isGlobalBaseSubscription" label="Ist globales Grundlagen-Abo für den Zugriff auf das System" />
                        
                    </Flex>
                )
            }
        </ModalForm>
    )
}