import React from "react";
import { ITicket, ITicketMessage } from "../../../types/ApiTypes";
import TicketMessageText from "../ticketChat/TicketMessageText";
import "./TicketAttachments.css";
import Card from "../../card/Card";
import { formatDate } from "../../../util/formatter";
import TicketChatMessageAttachment from "../ticketChat/TicketChatMessageAttachment";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { generateStyle } from "../../../hooks/useAttributes";
import { getTextColorFromBackground } from "../../../util/util";
import useUserUtil from "../../../hooks/useUserUtil";
import TicketChatMessageAuthor from "../ticketChat/TicketChatMessageAuthor";
import Flex from "../../container/Flex";
import SidebarHeader from "../../sidebar/SidebarHeader";
import { useUser } from "../../../state/swr/user/useUser";

export default function TicketAttachments({ticket}: {ticket: ITicket}) {
    const [messagesWithAttachments, setMessagesWithAttachments] = React.useState<Array<ITicketMessage>>([]);
    const [filter, setFilter] = React.useState<string>("");
    const [matchingMessages, setMatchingMessages] = React.useState<Array<ITicketMessage>>([]);

    const {
        getName
    } = useUserUtil();

    const { user } = useUser();

    React.useEffect(() => {
        if (!ticket || !ticket.messages || !ticket.messages.length) {
            setMessagesWithAttachments([]);
            return;
        }

        const result = ticket.messages.filter(t => t.attachments && !!t.attachments.length);
        setMessagesWithAttachments(result);

    }, [ticket]);
    
    React.useEffect(() => {
        if (!filter || !filter.length) {
            setMatchingMessages(messagesWithAttachments);
            return;
        }

        const upperFilter = filter.toUpperCase();

        const matchingItems = messagesWithAttachments.filter(m => {
            if (m.content && !!m.content.length && !!m.content.find(c => c.text && c.text.toUpperCase().includes(upperFilter))) return true;
            if (m.attachments && !!m.attachments.length && !!m.attachments.find(a => a.name && a.name.toUpperCase().includes(upperFilter))) return true;
            return false;
        })

        setMatchingMessages(matchingItems);
    }, [messagesWithAttachments, filter]);

    return (
        <div className="d-flex flex-column gap-2 w-100 h-100">
            <SidebarHeader text="Anhänge" />
            { 
                messagesWithAttachments && !!messagesWithAttachments.length && <input className="form-control" placeholder="Dateien oder Nachrichten suchen..." onChange={(e) => setFilter(e.target.value)}></input>
            }
            {
                matchingMessages && !!matchingMessages.length
                ? matchingMessages.map(m => {
                    const isOwn = (typeof m.sentBy === "string" ? m.sentBy === user?._id : (m.sentBy?._id === user?._id || m.sentBy?.mailAddress === user?.mailAddress));

                    console.log(formatDate(m.createdAt));

                    const cardStyle = generateStyle({
                        name: "backgroundColor",
                        value: isOwn ? "var(--backgroundDarker)" : "var(--primaryAccent)",
                    }, {
                        name: "color",
                        value: isOwn ? getTextColorFromBackground("primaryAccent") : "#000000"
                    });

                    return (
                        <Card className="w-100" style={cardStyle} background="var(--backgroundLighter)"  >
                            <div key={m._id} className="d-flex flex-column w-100 gap-1">
                                <Flex row align="start" justify="between">
                                    <TicketChatMessageAuthor message={m} isOwn={isOwn} colorMap={{}} />
                                    <strong>{formatDate(m.createdAt, true, true)}</strong>
                                </Flex>
                                <div className="d-flex flex-column gap-2 w-100">
                                    {
                                        m.attachments && !!m.attachments.length && m.attachments.map(a => <TicketChatMessageAttachment size="small" fullWidth attachment={a} message={m} key={a.googleCloudFileId} />)
                                    }
                                </div>
                            </div>
                        </Card>
                    )
                })
                : <span>{!!filter ? "Keine Ergebnisse" : "Keine Nachrichten mit Anhang"}</span>
            }
        </div>
    )
}