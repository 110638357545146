import useSWR from "swr";
import { IPointChampApiKey, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useOpeningHours = () => {
    const { data, isLoading, mutate } = useSWR<ITenantOpeningHours>(ApiRoutes.OpeningHours.All);

    return {
        openingHours: data,
        loadingOpeningHours: isLoading,
        reloadOpeningHours: mutate
    }
}