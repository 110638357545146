import React from "react";
import useFilteredNotifications from "../../hooks/useFilteredNotifications";
import useFilteredTickets, { TicketSort } from "../../hooks/useFilteredTickets";
import SortDirectionToggle, { SortDirection } from "../buttons/SortDirectionToggle";
import Flex from "../container/Flex";
import Select, { ISelectItem } from "../comboBox/Select";

export default function SortNotificationsButton() {
    const {
        direction,
        sort,
        updateFilter,
    } = useFilteredNotifications();

    const {
        getSortOptions
    } = useFilteredTickets();

    return (
        <Flex gap={2} row >
            <Select 
                size={14}
                onChange={sort => updateFilter({notificationsSort: sort as TicketSort})}
                value={sort}
                values={getSortOptions()}
            />
            <SortDirectionToggle direction={direction} saveDirection={(direction: SortDirection) => updateFilter({notificationsDirection: direction})} />
        </Flex>
    )
}