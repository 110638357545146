import useSWR from "swr";
import { IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useOfficeMailRepliedToStatus = (accountId: string, mailId: string) => {
    const { data, isLoading, mutate } = useSWR<{hasBeenRepliedTo: boolean}>(ApiRoutes.OfficeAccounts.CheckMailRepliedToStatus.getValue({
        param: RouteParam.OfficeAccountId,
        value: accountId,
    }, {
        param: RouteParam.MailId,
        value: mailId
    }), {
        revalidateOnFocus: false,
        revalidateIfStale: false,
        revalidateOnReconnect: true
    });

    return {
        officeMailRepliedToStatus: data,
        loadingOfficeMailRepliedToStatus: isLoading,
        reloadOfficeMailRepliedToStatus: mutate
    }
}