import { IPlanRequestFeature } from "../api/requests/subscriptions/subscriptionRequests";
import { IFeature, IPlanFeature, TiggiFeature } from "../types/ApiTypes";
import { getEnumAsArray } from "../util/util";
import { useAppSelector } from "./reduxHooks";

export function useSortedFeatures() {

    const getUsableTiggiFeatures = () => getEnumAsArray<number>(TiggiFeature);
    
    const convertNumberToTiggiFeature = (n: number): string | null => {
        const tiggiFeatures = getUsableTiggiFeatures();

        if (!tiggiFeatures || !tiggiFeatures.length) return null;

        const result = tiggiFeatures.find(a => a.value === n);

        if (!result) return null;

        return result.key;
    }

    const convertPlanFeaturesToPlanRequestFeatures = (features: Array<IPlanFeature>): Array<IPlanRequestFeature> => {
        if (!features || !features.length) return [];
        return features.map(f => f && f.feature && ({feature: f.feature._id, limit: f.limit, remarks: f.remarks})).filter(a => !!a);
    }

    return {
        usableTiggiFeatures: getUsableTiggiFeatures(),
        convertNumberToTiggiFeature,
        convertPlanFeaturesToPlanRequestFeatures
    }
}