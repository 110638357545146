import useSWR from "swr";
import { ITicket, ITicketActivity } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useTicketActivity = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useSWR<Array<ITicketActivity>>(ApiRoutes.Tickets.TicketActivity.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketActivity: data,
        loadingTicketActivity: isLoading,
        reloadTicketActivity: mutate
    }
}