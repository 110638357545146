import React from "react";
import { OfficeAccountType } from "../../types/ApiTypes";
import Pill from "../pills/Pill";

export default function OfficeAccountTypeBanner({type, className}: {type: OfficeAccountType, className?: string}) {
    
    const getColor = () => {
        switch (type) {
            case OfficeAccountType.Personal: return "#F0F0F0";
            case OfficeAccountType.Team: return "#F0F0F0";
            case OfficeAccountType.Tenant: return "#F0F0F0";
        }
    }

    const getText = () => {
        switch (type) {
            case OfficeAccountType.Personal: return "Persönlich";
            case OfficeAccountType.Team: return "Team";
            case OfficeAccountType.Tenant: return "Tenant";
        }
    }

    return (
        <Pill text={getText()} color={getColor()} className={className} />
    )
}