import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { clientsAddOrUpdateDocumentClearanceSettings, clientsCreateNew, clientsDeleteDocumentClearanceSettings, clientsGetDocumentClearanceLog, clientsGetDocumentClearanceSettings, clientsSendSingleDocumentClearanceReminder, clientsUpdate, documentClearanceRemindAll, IApiResponse, isErrorApiResponse, usersCreateNew, usersUpdate } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import useClientUtil from "../../hooks/useClientUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { DocumentClearanceReminderInterval, IClient, IDocumentClearanceLog, IRole, ITenant, ITenantAuthority, IUser } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import FieldWithLabel from "../formik/FormikField";
import ModalForm from "../modal/ModalForm";
import { Status } from "../../config/Status";
import LoadingSpinner from "../loader/LoadingSpinner";
import { ICreateDocumentClearanceSettingsBody } from "../../api/ApiRequests";
import PageLoader from "../loader/PageLoader";
import useTenantSettings from "../../hooks/useTenantSettings";
import LabelButton from "../buttons/LabelButton";
import Table from "../tables/Table";
import ClientDocumentClearanceLogItem from "./ClientDocumentClearanceLogItem";
import Flex from "../container/Flex";
import { useClientUsers } from "../../state/swr/clients/useClientUsers";
import Typography from "../text/Typography";
import { useClients } from "../../state/swr/clients/useClients";
import { useClientDocumentClearanceSettings } from "../../state/swr/documentClearance/useClientDocumentClearanceSettings";
import { useClientDocumentClearanceLog } from "../../state/swr/documentClearance/useClientDocumentClearanceLog";
import Select from "../comboBox/Select";
import WithPermissions from "../permissions/WithPermissions";

interface IClientUpdateForm {
    client: IClient,
}

export default function ClientDocumentClearanceReminderForm({client}: IClientUpdateForm) {

    const {
        titles
    } = useTenantSettings(true);

    const { documentClearanceLogs, loadingDocumentClearanceLogs, reloadDocumentClearanceLogs } = useClientDocumentClearanceLog(client._id);
    const { documentClearanceSettings, loadingDocumentClearanceSettings, reloadDocumentClearanceSettings } = useClientDocumentClearanceSettings(client._id);

    const [loadingLog, setLoadingLog] = React.useState<boolean>(false);

    const { reloadClients } = useClients();
    const { clientUsers, loadingClientUsers, reloadClientUsers } = useClientUsers(client?._id);

    const callApi = useApi();

    const {
        getName
    } = useUserUtil();

    const sendAutomaticReminders = async () => {
        await documentClearanceRemindAll();
        await reloadDocumentClearanceLogs();
    }

    const sendManualReminder = async () => {
        if (!client) return;
        await clientsSendSingleDocumentClearanceReminder(client._id);
        await reloadDocumentClearanceLogs();
    } 

    const deleteExistingSettings = async () => {
        if (!documentClearanceSettings || !client) return;
        await callApi(clientsDeleteDocumentClearanceSettings(client._id));
        await reloadDocumentClearanceSettings();
    }

    return (
        <WithPermissions permissions={["clientResponsibilities.all.update"]}>
            <ModalForm 
                title={titles.documentClearanceWord || "Belegabgabe"}
                loading={loadingDocumentClearanceSettings}
                onOpen={documentClearanceSettings ? undefined : async () => await reloadDocumentClearanceSettings()}
                button={<Button icon="clock" loading={loadingClientUsers} color={documentClearanceSettings ? "success" : "primary"} disabled={!clientUsers || !clientUsers.length} disabledText="Keine Kontakte" text={titles.documentClearanceWord || "Belegabgabe"} />}
                initialValues={{
                    amountOfRemindersToBeSent: documentClearanceSettings?.reminderMailCount || 1,
                    clearingUser: documentClearanceSettings?.clearingUser || "",
                    daysBetweenReminders: documentClearanceSettings?.reminderMailDaysInterval || 7,
                    interval: documentClearanceSettings?.interval || DocumentClearanceReminderInterval.Monthly,
                    remindOnDayOfMonth: documentClearanceSettings?.remindOnDayOfMonth || 1
                } as ICreateDocumentClearanceSettingsBody}
                enableReinitialize
                onSubmit={async (values) => {
                    let result = await callApi<any>(clientsAddOrUpdateDocumentClearanceSettings(client._id, values), true);

                    if (!result) return false;

                    await reloadClientUsers();
                    await reloadDocumentClearanceSettings();
                    return true;
                }}
                sidebar={formik => <Button loading={formik.isSubmitting} icon="save" loadingText="Bitte warten..." type="submit" text="Speichern" className="float-end" color="success" />}
            >
                {
                    (formik) => {
                        return (
                            <Flex className="w-100">
                                <div className="d-flex flex-column gap-2 text-start">
                                    <SearchableComboBox 
                                        values={clientUsers!}
                                        value={formik.values.clearingUser}
                                        itemToId={c => c._id}
                                        label={formik.values.clearingUser ? "Zuständiger Mandantenkontakt" : "Bitte wählen Sie einen Mandantenkontakt aus"}
                                        itemToString={c => getName(c)}
                                        renderItem={c => (
                                            <Flex gap={0}>
                                                <Typography bold>{getName(c)}</Typography>
                                                <Typography>{c.mailAddress}</Typography>
                                            </Flex>
                                        )}
                                        onItemClick={c => formik.setFieldValue("clearingUser", c._id)}
                                    />
                                    {
                                        formik.values.clearingUser && (
                                            <Flex gap={3}>
                                                <Flex row gap={4}>
                                                    <FieldWithLabel label="Monatstag der Erinnarung" type="number" className="w-100" name="remindOnDayOfMonth" min="1" max="28" step="1" />
                                                    <FieldWithLabel label="Anzahl der Erinnerungsmails" type="number" className="w-100" name="amountOfRemindersToBeSent" min="0" max="5" step="1" />
                                                    {
                                                        formik.values.amountOfRemindersToBeSent > 1 && (
                                                            <FieldWithLabel label="Tage zwischen den Erinnerungen" type="number" className="w-100" name="daysBetweenReminders" min="1" max="14" step="1" />
                                                        )
                                                    }
                                                </Flex>
                                                <Select
                                                    label="Interval"
                                                    values={[
                                                        { data: DocumentClearanceReminderInterval.Monthly, label: "Monatlich" },
                                                        { data: DocumentClearanceReminderInterval.Quarterly, label: "Quartalsweise" },
                                                        { data: DocumentClearanceReminderInterval.Yearly, label: "Jährlich" }
                                                    ]}
                                                    value={formik.values.interval} 
                                                    onChange={(o) => formik.setFieldValue("interval", o)}
                                                />
                                            </Flex>
                                        )
                                    }
                                    <div className="d-flex align-items-center justify-content-between gap-2">
                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                            {
                                                documentClearanceSettings && <Button text="Erinnerung manuell senden" icon="send" onClick={sendManualReminder}  />
                                            }
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between gap-2">
                                            {
                                                documentClearanceSettings && <Button icon="trash" loadingText="Bitte warten..." onClick={deleteExistingSettings} text="Reminder entfernen" className="float-end" color="error" />
                                            }
                                            
                                        </div>                                                        
                                    </div>
                                    {
                                        documentClearanceSettings && (
                                            <div className="d-flex flex-column gap-2 align-items-start w-100">
                                                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                                    <strong>Verlauf</strong>
                                                    <LabelButton text="Fällige Erinnerungen überprüfen" loadingText="Fällige Erinnerungen werden überprüft..." onClick={sendAutomaticReminders} />
                                                </div>
                                                {
                                                    loadingLog 
                                                    ? <LoadingSpinner text="Log wird geladen..." />
                                                    : (
                                                        <Table 
                                                            className="w-100" headers={[{label: "Datum"}, {label: "Status"}, {label: "Text"}, {label:"Gesendete Erinnerungen"}, {label: "Nächste Erinnerung"}]}
                                                            items={documentClearanceLogs || []}
                                                            renderItem={c => <ClientDocumentClearanceLogItem key={c._id} log={c} />}
                                                        />
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </Flex>
                        )
                    }
                }
            </ModalForm>    
        </WithPermissions>
    )
}