import React from "react";
import { ICoupon } from "../../types/ApiTypes";
import Card from "../card/Card";
import CreatePromotionCodeButton from "./CreatePromotionCodeButton";
import PromotionCodeCard from "./PromotionCodeCard";
import DeleteCouponButton from "./DeleteCouponButton";
import CouponAmount from "./CouponAmount";
import CouponDuration from "./CouponDuration";
import { usePromotionCodes } from "../../state/swr/subscriptions/usePromotionCodes";
import LoadingSpinner from "../loader/LoadingSpinner";
import { useUser } from "../../state/swr/user/useUser";

export default function CouponGroup({coupon}: {coupon: ICoupon}) {

    const { loadingPromotionCodes, promotionCodes , reloadPromotionCodes } = usePromotionCodes(coupon._id);
    
    const {
        user
    } = useUser();

    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <Card className="d-flex flex-column gap-2 w-100">
            <div className="d-flex flex-row align-items-center justify-content-between">
                <h6 className="fw-bold m-0 p-0">{coupon.name}</h6>
                <DeleteCouponButton coupon={coupon} />
            </div>
            <div className="d-flex flex-row align-items-center gap-2">
                <CouponAmount coupon={coupon} />
                <CouponDuration coupon={coupon} />
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between">
                <strong>Gutscheincodes</strong>
                <CreatePromotionCodeButton coupon={coupon} />
            </div>
            <div className="d-flex flex-row flex-wrap gap-2 align-items-start w-100">
                {
                    loadingPromotionCodes
                    ? <LoadingSpinner />
                    : (
                        promotionCodes && promotionCodes.length
                        ? promotionCodes.map(p => <PromotionCodeCard key={p._id} code={p} />)
                        : <span>Keine Gutscheincodes. Hinterlegen Sie einen Gutscheincode, um das Verwenden dieser Rabattgruppe zu ermöglichen.</span>
                    )
                }
            </div>
        </Card>
    )
}