import React from "react";
import { UserRoleColor } from "../../../app/AppStyles";
import useRoleUtil from "../../../hooks/useRoleUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import { ITicket, TicketState, UserRole } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import "./TicketStateBanner.css";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";

export default function TicketAssigneeBanner({ticket}: {ticket: ITicket}) {
    
    const { ticketAssignee, loadingTicketAssignee } = useTicketAssignee(ticket);

    const {
        getCurrentTenantRole
    } = useUserUtil();
    
    const {
        convertToUserRole
    } = useRoleUtil();
    
    if (!ticket || !ticketAssignee || !ticketAssignee.user) return null;

    const role = getCurrentTenantRole(ticketAssignee.user);
    const userRole = convertToUserRole(role);

    const getTicketColor = (): string => {
        switch (userRole) {
            case UserRole.TeamLead: return UserRoleColor.TeamLead;
            case UserRole.Partner: return UserRoleColor.Partner;
            default: return UserRoleColor.Clerk;
        }
    }

    return <Pill color={getTicketColor()} text={`Bei ${userRole}`} />
}