import React from "react";
import { AppColor } from "../app/AppStyles";
import { ITenant, ITenantSettings } from "../types/ApiTypes";
import { useAppSelector } from "./reduxHooks";
import { ITenantUpdateBillingDataRequest } from "../api/ApiRequests";

export default function useTenantUtil() {
    const hasActiveSubscription = (tenant: ITenant | null | undefined): boolean => {
        if (!tenant) return false;
        if (!tenant.subscriptions || !tenant.subscriptions.length) return false;

        return !!tenant.subscriptions.find(s => s.status === "active");
    }   

    const normalizeTenantBillingData = (tenant: ITenant): ITenantUpdateBillingDataRequest => ({
        _id: tenant._id,
        address: { 
            city: tenant.address?.city || "",
            country: tenant.address?.country || "",
            countryCode: tenant.address?.countryCode || "",
            firstLine: tenant.address?.firstLine || "",
            postalCode: tenant.address?.postalCode || "",
            secondLine: tenant.address?.secondLine || "",
            state: tenant.address?.state || ""
        },
        company: tenant.company || "",
        mailAddress: tenant.mailAddress || ""
    })

    return {
        hasActiveSubscription,
        normalizeTenantBillingData
    }
}