import React from "react";
import useClientResponsibility from "../../hooks/useClientResponsibility";
import Page from "../../layout/Page";
import ClientResponsibilityList from "../../components/clientResponsibility/ClientResponsibilityList";
import { Locale } from "../../locale/Locale";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import RawClientResponsibilityList from "../../components/clientResponsibility/RawClientResponsibilityList";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import { ModalType } from "../../config/ModalTypes";
import { AppRoutes } from "../../config/AppRoutes";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../state/swr/user/useUser";

export enum ClientResponsibilityOverviewTab {
    MappedToClient = "pretty",
    RawData = "raw"
}

export default function ClientResponsibilityOverview() {

    const [activeTab, setActiveTab] = React.useState<string>(ClientResponsibilityOverviewTab.MappedToClient);

    const { role } = useCurrentTenantRole();
    const { user } = useUser();

    const navigate = useNavigate();
    const showModal = useModal();

    const {
        canManageTenant
    } = useUserUtil();

    React.useEffect(() => {
        const isTenantAdmin = canManageTenant(user);

        if (isTenantAdmin) return;

        if (!role || role.isClient) {
            showModal({text: "Zugriff verweigert", type: ModalType.Error})
            navigate(AppRoutes.Home.path);
        }
    }, [user, role]);

    return (
        <Page header={Locale.titles.clientResponsibilityManagement}>
            <TabSwitcher tabQueryParamKey="format" tabs={[
                {
                    data: ClientResponsibilityOverviewTab.MappedToClient,
                    label: "Mit Mandatszuweisung"
                },
                {
                    data: ClientResponsibilityOverviewTab.RawData,
                    label: "Rohdaten aus DATEV"
                }
            ]} 
            saveActiveTab={t => setActiveTab(t)}
            />
            {
                activeTab === ClientResponsibilityOverviewTab.MappedToClient
                ? <ClientResponsibilityList />
                : <RawClientResponsibilityList />
            }
        </Page>
    )
}