import React from "react";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { getTextColorFromBackground } from "../../util/util";
import { ITabData, ITabHighlightData, TabSwitcherSize, TabSwitcherVariant } from "./TabSwitcher";
import "./Tab.css";
import Icon from "../icons/Icon";
import useAuthorized from "../../hooks/useAuthorized";

interface ITabProps<T = string | boolean | number | undefined> {
    content: ITabData,
    size: TabSwitcherSize,
    variant?: TabSwitcherVariant,
    defaultIconSize?: number,
    currentData: T,
    setActiveTab: (data: ITabData) => void
}

export default function Tab({content, defaultIconSize, size, currentData, variant, setActiveTab}: ITabProps) {

    const {
        isAuthorized,
        loading
    } = useAuthorized(content.permissions);

    const [isActive, setIsActive] = React.useState<boolean>(false);

    const currentRef = React.useRef<HTMLDivElement>(null);

    const setCurrentTabAsActive = () => setActiveTab(content);

    React.useEffect(() => {
        if (content.hidden) {
            setIsActive(false);
            return;
        }
        
        const tabIsActive = currentData !== undefined ? currentData === content.data.toString() : false;

        if (!tabIsActive) {
            setIsActive(false);
            return;
        }

        if (tabIsActive === isActive) return;

        setIsActive(tabIsActive);
        setCurrentTabAsActive();

    }, [isActive, content, currentData]);

    if (!content || content.hidden || !isAuthorized) return null;

    const className = generateClassName("tab d-flex flex-row align-items-center gap-2", {
        value: isActive,
        base: `tab-${variant}-`,
        onTrue: "active",
        standard: "inactive"
    }, {
        value: isActive,
        base: `tab-${size}-`,
        onTrue: "active",
        standard: "inactive"
    }, {
        value: size,
        base: "tab-"
    }, {
        value: variant,
        base: "tab-"
    }, {
        value: variant !== "icons",
        onTrue: "pb-2 pt-2 ps-1 pe-1"
    })

    const labelClass = generateClassName("text-nowrap", {
        value: size === "regular",
        onTrue: "text-uppercase"
    })

    if (variant === "icons" && !content.icon) return null;
    else if (variant !== "icons" && !content.label) return null;

    const iconSize = content.iconSize || defaultIconSize || 22;

    return (
        <div ref={currentRef} onClick={setCurrentTabAsActive} className={className}>
            {
                variant === "icons"
                ? <Icon icon={content.icon} tooltip={content.iconTooltip} color={isActive ? "primary" : "muted"} size={iconSize} />
                : <span className={labelClass}>{content.label}</span>
            }
        </div>
    )
}