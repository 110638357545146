import React, { useEffect } from "react";
import { DatevAddresseeAddressType, IEmployee, IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import EmployeeListItem from "./EmployeeListItem";
import { useDatevEmployees } from "../../state/swr/employees/useDatevEmployees";
import { useEmployees } from "../../state/swr/employees/useEmployees";
import { useRoles } from "../../state/swr/roles/useRoles";
import WithPermissions from "../permissions/WithPermissions";

interface ILinkedEmployee {
    id: string,
    datevEmployee?: IEmployee,
    employee: IUser
}

export default function EmployeeList() {
    const [sortedEmployees, setSortedEmployees] = React.useState<ILinkedEmployee[]>([]);
    
    const { datevEmployees, loadingDatevEmployees } = useDatevEmployees();
    const { employees, loadingEmployees } = useEmployees();
    const { loadingRoles, reloadRoles, roles } = useRoles();

    console.log(datevEmployees);

    useEffect(() => {
        if (loadingEmployees) return;
        if (loadingDatevEmployees) return;

        const result: Array<ILinkedEmployee> = [];

        if (datevEmployees && !!datevEmployees.length) {
            datevEmployees.forEach(d => result.push({id: d._id, employee: d.user, datevEmployee: d}));
        }

        if (employees && !!employees.length) {
            employees.forEach(e => {
                if (!!result.find(r => r.employee && r.employee._id && e && e._id && r.employee._id === e._id)) return;
                result.push({id: e._id, employee: e, datevEmployee: undefined});
            })
        }

        setSortedEmployees(result);
    }, [datevEmployees, employees, loadingEmployees, loadingDatevEmployees]);
    
    
    if (loadingDatevEmployees || loadingEmployees || loadingRoles) return <LoadingSpinner centered={false} />
    
    if (!sortedEmployees || !sortedEmployees.length) return <span>Keine Mitarbeiter</span>;

    return (
        <WithPermissions permissions={["users.employees.read"]}>
            <div className="h-100">
                <Table 
                    border 
                    className="w-100" 
                    headers={[{label: "Name"}, {label: "Mail"}, {label: "Status"}, {label: "Mit DATEV verknüpft"}, {label: "Tenant-Admin"}, {label: "Aktiv seit"}, {label: ""}]}
                    renderItem={e => <EmployeeListItem key={e.id} employee={e.employee} datevEmployee={e.datevEmployee} />}
                    items={sortedEmployees}
                />
            </div>
        </WithPermissions>
    )
}