import useSWR from "swr";
import { IClient, ICloudGatewayData, ICoupon, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useCloudGateway = () => {
    const { data, isLoading, mutate } = useSWR<ICloudGatewayData>(ApiRoutes.CloudGateway.GetCurrentTenantSettings);

    return {
        cloudGatewaySettings: data,
        loadingCloudGatewaySettings: isLoading,
        reloadCloudGatewaySettings: mutate
    }
}