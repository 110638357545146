import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import PageLoader from "../loader/PageLoader";
import { Status } from "../../config/Status";
import ServiceCard from "./ServiceCard";
import { useServices } from "../../state/swr/subscriptions/useServices";
import { useUser } from "../../state/swr/user/useUser";

export default function ServiceOverview() {

    const {
        user
    } = useUser(); 
    
    const { services, loadingServices } = useServices();

    if (!user || !user.isSuperAdmin) return null;
    if (loadingServices) return <PageLoader />
    if (!services || !services.length) return <span>Aktuell sind keine Services hinterlegt</span>

    return (
        <div className="d-flex flex-column gap-3" >
            {
                services.map(s => <ServiceCard key={s._id} service={s} />)
            }
        </div>
    )
}