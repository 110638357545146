import React from "react";
import { ITicket, ITicketAssignment } from "../../../types/ApiTypes";
import { useTicketAssignments } from "../../../state/swr/tickets/useTicketAssignments";
import useApi from "../../../hooks/useApi";
import { ticketAssignmentsRemove } from "../../../api/Api";
import Button from "../../buttons/Button";

export interface IDeleteTicketAssignmentButtonProps {
    assignment: ITicketAssignment,
    canDelete: boolean,
    ticket: ITicket
}

export default function DeleteTicketAssignmentButton({ assignment, canDelete, ticket }: IDeleteTicketAssignmentButtonProps) {
    
    const {
        reloadTicketAssignments
    } = useTicketAssignments(ticket)

    const callApi = useApi();

    const deleteAssignment = async () => {
        const res = await callApi(ticketAssignmentsRemove({
            id: assignment._id,
            ticketId: ticket._id
        }));

        if (!res || !res.success) return;
        
        await reloadTicketAssignments();
    }

    if (!canDelete && (assignment.isMainAssignee || assignment.isMainClientContact)) return null;
    
    return (
        <Button
            icon="trash"
            text="Löschen"
            color="error"
            variant="text"
            disabled={!canDelete}
            onClick={deleteAssignment}
        />
    )
}