import React from "react";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import { DatevAddresseeType, IAddress, IAddressee } from "../../types/ApiTypes";
import Icon from "../icons/Icon";
import AddresseeLegalForm from "./AddresseeLegalForm";

export default function AddresseeTypeIcon({addressee, showText = false}: {addressee: IAddressee, showText?: boolean}) {

    return (
        <div className="d-flex flex-row align-items-center gap-2">
            <Icon icon={addressee.type === DatevAddresseeType.LegalPerson ? "building" : "person"} />
            {
                showText && <AddresseeLegalForm addressee={addressee} />
            }
        </div>
    )
}