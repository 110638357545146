import React from "react";
import { useUsers } from "../state/swr/user/useUsers";
import { IUser } from "../types/ApiTypes";
import useUserUtil from "./useUserUtil";
import { useTeams } from "../state/swr/teams/useTeams";

export interface IWithFilteredUsers {
    displayed?: DisplayedUsers,
    showWithoutRole?: boolean
}
export type DisplayedUsers = "all" | "clients" | "teamMembers" | "employees";

export default function useFilteredUsers({displayed = "all", showWithoutRole = false}: IWithFilteredUsers) {
    
    const [filteredUsers, setFilteredUsers] = React.useState<Array<IUser>>([]);

    const {
        users,
        loadingUsers
    } = useUsers();

    const {
        teams
    } = useTeams();

    const {
        getRole
    } = useUserUtil();

    React.useEffect(() => {
        if (loadingUsers) {
            setFilteredUsers([]);
            return;
        }

        if (!users || !users.length) {
            setFilteredUsers([]);
            return;
        }

        if (displayed === "all") {
            setFilteredUsers(users);
            return;
        }

        const employees = users.filter(u => {
            const role = getRole(u);
            if (!role) return showWithoutRole;
            return role.isClient === (displayed === "clients");
        });

        if (displayed === "employees") {
            setFilteredUsers(employees);
            return;
        }

        if (!teams || !teams.length) {
            setFilteredUsers([]);
            return;
        }

        const teamMembers = employees.filter(u => teams.some(t => t.members.some(m => m._id === u._id)));
        setFilteredUsers(teamMembers);
    }, [users, displayed, loadingUsers, showWithoutRole]);
    
    return {
        loading: loadingUsers,
        users: filteredUsers
    }
}