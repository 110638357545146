import React from "react";
import { IClient, IUser, Permission } from "../../types/ApiTypes";
import { useAnyUserClients } from "../../state/swr/user/useAnyUserClients";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import LoadingSpinner from "../loader/LoadingSpinner";
import ClientTypeBanner from "./ClientTypeBanner";
import { useClients } from "../../state/swr/clients/useClients";
import WithPermissions from "../permissions/WithPermissions";

export interface IClientSelectProps {
    saveClient: (client: IClient) => void,
    clientId?: string,
    showAllClients?: boolean,
    clientContactId?: string,
    bold?: boolean,
    hideIfEmpty?: boolean
}

export default function ClientSelect({saveClient, bold, hideIfEmpty = true, showAllClients = false, clientId, clientContactId}: IClientSelectProps) {

    const { clients, loadingClients } = useClients();
    const { userClients, loadingUserClients } = useAnyUserClients(clientContactId || "");

    const requiredPermissions: Array<Permission> = showAllClients ? ["clients.all.read"] : ["clients.own.read" ];

    const realClients = showAllClients ? clients : userClients?.clients;

    if ((!loadingClients && !loadingUserClients) && (!realClients || !realClients.length)) return null;

    return (
        <WithPermissions permissions={requiredPermissions} >
            {
                loadingUserClients || loadingClients 
                ? <LoadingSpinner text="Mandanten werden geladen" />
                : <SearchableComboBox
                    bold={bold}
                    useDefaultValue
                    renderItem={c => <ClientTypeBanner client={c} showClientName />}
                    label="Mandant"
                    className="w-100"
                    values={realClients || []}
                    value={clientId}
                    itemToId={client => client._id}
                    itemToString={client => client.name}
                    onItemClick={client => saveClient(client)}
                    placeholder="Mandant auswählen"
                />
            }
        </WithPermissions>
    )
}