import useSWR from "swr";
import { IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useOfficeMailListenerStatus = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery(ApiRoutes.OfficeAccounts.CheckListenerStatusForAccount.getValue({
        param: RouteParam.OfficeAccountId, 
        value: id
    }));

    return {
        officeMailListenerStatus: data,
        loadingOfficeMailListenerStatus: isLoading,
        reloadOfficeMailListenerStatus: mutate
    }
}