import React from "react";
import { setConstantValue } from "typescript";
import { ticketsDelete } from "../../../api/Api";
import { AppColor } from "../../../app/AppStyles";
import useApi from "../../../hooks/useApi";
import { ITicket } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import ModalForm from "../../modal/ModalForm";
import { Form, Formik } from "formik";
import { ITicketDeleteRequest } from "../../../api/ApiRequests";
import Flex from "../../container/Flex";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import CheckBox from "../../formik/CheckBox";
import { useAllTickets } from "../../../state/swr/tickets/useAllTickets";
import { useTicket } from "../../../state/swr/tickets/useTicket";
import { ITicketActionProps } from "./CloseTicketButton";
import useFilteredTickets from "../../../hooks/useFilteredTickets";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";

interface IDeleteTicketButtonProps extends ITicketActionProps {
    
    isSingleTicket?: boolean,
    tickets: ITicket[]
}

export default function DeleteTicketButton({tickets, isSingleTicket, variant, afterAction}: IDeleteTicketButtonProps) {

    const { role } = useCurrentTenantRole();
    const { isShowingClosedTickets } = useFilteredTickets();

    const callApi = useApi();

    const { reloadTickets } = useAllTickets(isShowingClosedTickets);

    if (role && role.isClient) return null;
    if (!tickets || !tickets.length) return null;

    const ticketDescriptor = isSingleTicket || tickets.length === 1 ? "Ticket" : "Tickets";


    const title =  `${ticketDescriptor} permanent löschen`;
    const buttonText = variant === "text" ? "" : `${ticketDescriptor} löschen`;

    return (
        <ModalForm
            button={
                <Button variant={variant} iconSize={variant === "text" ? 30 : undefined} icon="trash" color="muted">{buttonText}</Button>
            }
            title={title}
            initialValues={{
                ticketIds: tickets.map(ticket => ticket._id),
                sendMail: false
            } as ITicketDeleteRequest}
            onSubmit={async (values) => {
                const res = await callApi(ticketsDelete(values));
                if (!res) return false;
                reloadTickets(prev => (prev || []).filter(ticket => !values.ticketIds.includes(ticket._id)));
                if (afterAction) afterAction();
                return true;
            }}
            sidebar={formik => <Button color="error" icon="trash" type="submit" text="Bestätigen und löschen" loading={formik.isSubmitting} />}
        >
            {
                formik => (
                    <Flex>
                        <p>Wollen Sie {tickets.length === 1 ? "das ausgewählte Ticket" : "die ausgewählten Tickets"} wirklich permanent und unwiderruflich löschen?</p>
                        <CheckBox name="sendMail" label="E-Mail mit Statusänderung an Mandanten versenden" />
                    </Flex>
                )
            }
        </ModalForm>
    )
}