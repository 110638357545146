import React from "react";
import Flex from "../container/Flex";
import { IPermission, IRole, Permission } from "../../types/ApiTypes";
import Typography from "../text/Typography";
import { useFormikContext } from "formik";
import CheckBox from "../formik/CheckBox";
import PermissionContainer from "./PermissionContainer";

export interface IPermissionProps {
    label: string,
    description?: string,
    readOnly?: boolean,
    name: Permission,
    disabled?: boolean
}

export default function PermissionCheckboxes({label, disabled, readOnly, name, description}: IPermissionProps) {

    if (disabled) return null;

    return (
        <PermissionContainer
            create={<CheckBox name={`permissions.${name}.create`} readOnly={readOnly} />}
            read={<CheckBox name={`permissions.${name}.read`} readOnly={readOnly} />}
            update={<CheckBox name={`permissions.${name}.update`} readOnly={readOnly} />}
            del={<CheckBox name={`permissions.${name}.delete`} readOnly={readOnly} />}
            title={(
                <Flex gap={0} style={{width: "50%"}}>
                    <Typography bold>{label}</Typography>
                    {description && <Typography size={12} wrap >{description}</Typography>}
                </Flex>
            )}
        />
    )
}