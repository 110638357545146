import React from "react";
import { useNavigate } from "react-router-dom";
import ClientContactList from "../../components/clientContacts/ClientContactList";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import { useAppSelector } from "../../hooks/reduxHooks";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../state/swr/user/useUser";
import useTenantSettings from "../../hooks/useTenantSettings";
import UserUpdateForm from "../../components/user/UserUpdateForm";
import { UserRole } from "../../types/ApiTypes";

export default function ClientContactManagement() {

    const { role } = useCurrentTenantRole();
    const { user } = useUser();

    const { options } = useTenantSettings(true);

    const {
        interfaces
    } = options;

    const {
        datev
    } = interfaces;

    const navigate = useNavigate();
    const showModal = useModal();

    const {
        canManageTenant
    } = useUserUtil();

    React.useEffect(() => {
        const isTenantAdmin = canManageTenant(user);

        if (isTenantAdmin) return;

        if (!role || role.isClient) {
            showModal({text: "Zugriff verweigert", type: ModalType.Error})
            navigate(AppRoutes.Home.path);
        }
    }, [user, role]);

    return (
        <Page header={Locale.titles.clientContactManagement} actionButtons={
            datev?.canCreateClientContactsManually 
            ? <UserUpdateForm isClientContact={true} color="primary" title="Neuen Mandantenkontakt erstellen" text="Neuer Mandantenkontakt" /> 
            : undefined 
        }>
            <ClientContactList />
        </Page>
    )
}