import useSWR from "swr";
import { ICdpAgreement } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useCdp = () => {
    const { data, isLoading, mutate, error } = useSWR<ICdpAgreement>(ApiRoutes.ContractForDataPrivacy.Current);

    return {
        cdp: data,
        loadingCdp: isLoading,
        cdpError: error,
        reloadCdp: mutate
    }
}