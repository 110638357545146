import React from "react";
import useFilteredTickets from "../../../hooks/useFilteredTickets";
import Icon from "../../icons/Icon";
import "./TicketSearchBox.css";
import { AppColor } from "../../../app/AppStyles";
import SearchBox from "../../search/SearchBox";

export default function TicketSearchBox() {

    const {
        updateFilter,
        filterText
    } = useFilteredTickets();

    return (
        <SearchBox
            className="me-1"
            initialValue={filterText}
            delay={200}
            onChange={(e) => updateFilter({text: e})}
        />
    )
}