import React from "react";
import { useNavigate } from "react-router-dom";
import TenantCreateForm from "../../components/tenant/TenantCreateForm";
import TenantList from "../../components/tenant/TenantList";
import TenantUpdateForm from "../../components/tenant/settings/TenantUpdateModal";
import { AppRoutes } from "../../config/AppRoutes";
import { useAppSelector } from "../../hooks/reduxHooks";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";

export default function TenantManagement() {
    const { user } = useUser();
    const { role } = useCurrentTenantRole();

    const navigate = useNavigate();

    if (!user || !(user.isSuperAdmin)) {
        navigate(AppRoutes.Home.path);
        return null;
    }

    return (
        <Page header={Locale.titles.tenantManagement} actionButtons={<TenantCreateForm />}>
            <TenantList />
        </Page>
    )
}