import React from "react";
import { ITeam, ITenant, IUser } from "../../types/ApiTypes";
import { formatDate } from "../../util/formatter";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserRoleIndicator from "../user/RoleBanner";
import TeamMemberList from "./TeamMemberList";
import TeamUpdateForm from "./TeamUpdateForm";
import TeamUpdateMembersFromResponsibilityButton from "./TeamUpdateMembersFromResponsibilityButton";
import Pill from "../pills/Pill";
import Icon from "../icons/Icon";
import MailAccount from "../mail/MailAccount";
import TeamBanner from "./TeamBanner";
import Flex from "../container/Flex";


export default function TeamsListItem({team}: {team: ITeam}) {
    return (
        <TableRow>
            <TableCell>
                <TeamBanner team={team} />
            </TableCell>
            <TableCell>
                <TeamMemberList members={team.members} />
            </TableCell>
            <TableCell>
                {
                    team.areaOfResponsibility ? 
                    (
                        <Pill color="#FF0053">
                            <Icon icon="star" />
                            <span>{team.areaOfResponsibility.name}</span>
                        </Pill>
                    )
                    : <span>-</span>
                }
            </TableCell> 
            <TableCell content={formatDate(team.createdAt)} />
            <TableCell content={formatDate(team.updatedAt)} />
            <TableCell>
                <Flex row wrap justify="end">
                    <TeamUpdateMembersFromResponsibilityButton team={team} />
                    <TeamUpdateForm team={team} />
                </Flex>
            </TableCell>
        </TableRow>
    )
}
