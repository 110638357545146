import useSWR from "swr";
import { ITicketEscalationWorkflow } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useTicketEscalationWorkflow = () => {
    const { data, isLoading, mutate } = useSWR<Array<ITicketEscalationWorkflow>>(ApiRoutes.Tickets.EscalationWorkflow.All);

    return {
        ticketEscalationWorkflow: data,
        loadingTicketEscalationWorkflow: isLoading,
        reloadTicketEscalationWorkflow: mutate
    }
}