import { IsUnknownOrNonInferrable } from "@reduxjs/toolkit/dist/tsHelpers";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { isReturnStatement, reduceEachTrailingCommentRange } from "typescript";
import { UserRoleColor } from "../app/AppStyles";
import { IRole, ITenant, ITenantAuthority, ITicket, ITicketAccessHistoryItem, ITicketMessage, IUser, UserRole } from "../types/ApiTypes";
import { useAppSelector } from "./reduxHooks";
import { useCurrentTenantRole } from "../state/swr/user/useCurrentTenantRole";
import { useRoles } from "../state/swr/roles/useRoles";
import useAnyRoleUtil from "./useAnyRoleUtil";

export default function useRoleUtil() {
    const { role } = useCurrentTenantRole();
    const { roles } = useRoles();

    const {
        convertToUserRole,
        getRoleId,
        getFilteredRoles,
        isRole
    } = useAnyRoleUtil();

    return {
        isRole: (requiredRole?: UserRole): boolean => isRole(role, requiredRole),
        getRoleId: (r: UserRole) => getRoleId(r, roles),
        convertToUserRole,
        getRolesExcept: (rolesToExcept: Array<UserRole>) => getFilteredRoles(roles, r => !rolesToExcept.find(x => x != r.displayName))
    }
}