import React from "react";
import useSWR from "swr";
import { IMailLog, ITicket, ITicketActivity } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { useAllTickets } from "./useAllTickets";

export const useTicket = (id: string, initialData?: ITicket) => {
    const { data, isLoading, mutate, isValidating } = useSWR<ITicket>(ApiRoutes.Tickets.ById.getValue({param: RouteParam.TicketId, value: id }));
 
    const { reloadTickets } = useAllTickets();

    const reloadTicket = async () => {
        mutate().then(t => {
            try {
                if (!t) return;
    
                reloadTickets(prev => {
                    if ((t as any).length) return;
                    if (!t) return prev;
                    if (!prev || !prev.length) return [t];
                    
                    const existingIndex = prev.findIndex(i => i._id === t._id);
        
                    if (existingIndex === -1) return [...prev, t];
        
                    const updatedTickets = [...prev];
        
                    updatedTickets[existingIndex] = t;
        
                    return updatedTickets;
                }, { revalidate: false });
            }
            catch {}
        });
    }

    return {
        ticket: data,
        loadingTicket: isLoading,
        validatingTicket: isValidating,
        reloadTicket: mutate,
        reloadAndUpdateAllTickets: reloadTicket,
    }
}