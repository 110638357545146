import useSWR from "swr";
import { IAddressee, IAlias } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useAddressees = () => {
    const { data, isLoading, mutate } = useSWR<Array<IAddressee>>(ApiRoutes.Addressees.All);

    return {
        addressees: data,
        loadingAddressees: isLoading,
        reloadAddressees: mutate
    }
} 