import React from "react";
import IElementProps from "../../types/ElementProps";

export default function SideNavGroupContainer({children}: IElementProps) {
    const [expandedGroup, setExpandedGroup] = React.useState<number>(0);

    if (!children) return null;
    if (!Array.isArray(children)) return children;
    
    const changeExpandedGroup = (index: number) => {
        if (expandedGroup === index) setExpandedGroup(-1);
        else setExpandedGroup(index);
    }

    return React.Children.map(children, (child, index) => React.cloneElement(child, {groupIndex: index, expandedGroup: expandedGroup, setExpandedGroup: changeExpandedGroup}));
}