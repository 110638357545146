import useSWR from "swr";
import { IClient, ICoupon, IFeature, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useFeatures = () => {
    const { data, isLoading, mutate } = useSWR<Array<IFeature>>(ApiRoutes.Subscriptions.FeaturesGetAll);

    return {
        features: data,
        loadingFeatures: isLoading,
        reloadFeatures: mutate
    }
}