import React from "react";
import { ILogEntry, LogEntryEventType, LogEntryService, LogEntryType } from "../../types/ApiTypes";
import Pill from "../pills/Pill";

export default function LogEntryServiceBanner({log}: {log: ILogEntry}) {

    if (!log.event || log.service === LogEntryService.None) return <span>-</span>

    const getText = () => {
        switch (log.service) {
            case LogEntryService.SendGrid: return "SendGrid";
            case LogEntryService.Azure: return "Microsoft Azure";
        }
    }

    const getColor = () => {
        switch (log.service) {
            case LogEntryService.SendGrid: return "#F39F18";
            case LogEntryService.Azure: return "#0078D4";
        }

        return "";
    }

    return (
        <Pill text={getText()} color={getColor()} />
    )
}