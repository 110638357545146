import React from "react";
import { IAddressee, IClient } from "../../types/ApiTypes";
import AddresseeAddress from "../addressee/AddresseeAddress";
import AddresseeLegalForm from "../addressee/AddresseeLegalForm";
import AddresseeRelationshipItem from "../addressee/AddresseeRelationshipItem";
import AddresseeTypeIcon from "../addressee/AddresseeTypeIcon";
import Icon from "../icons/Icon";
import Pill from "../pills/Pill";
import { ClientTypeIcon } from "./ClientTypeBanner";

export default function ClientAddresseeItem({addressee, client}: {client: IClient, addressee?: IAddressee | null | undefined}) {

    if (!addressee) return null;

    return (
        <div className="d-flex flex-column w-100 gap-2">
            <Pill color="#F0F0F0" className="gap-3 w-100">
                <AddresseeTypeIcon addressee={addressee} />
                <div className="d-flex flex-column gap-1 w-100">
                    <strong className="text-nowrap">{addressee.actualName}</strong>
                    <AddresseeLegalForm addressee={addressee} />
                    <AddresseeAddress addressee={addressee} />
                </div>
            </Pill>
            <div className="d-flex flex-row align-items-center flex-wrap gap-1">
                {
                    client && addressee && client.relationships && !!client.relationships.length 
                    && client.relationships.map(r => <AddresseeRelationshipItem addressee={addressee} relationship={r} key={r._id} />)
                }
            </div>
        </div>
    )
}