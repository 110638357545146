import React from "react";
import MultiStepWizardPage from "../../../wizard/MultiStepWizardPage";
import Button from "../../../buttons/Button";
import { Form, Formik } from "formik";
import useModal from "../../../../hooks/useModal";
import { ModalType } from "../../../../config/ModalTypes";
import PageLoader from "../../../loader/PageLoader";
import { AppRoutes } from "../../../../config/AppRoutes";
import { Elements, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import useApi from "../../../../hooks/useApi";
import { tenantsOnboardingSetHasPaymentDetails } from "../../../../api/Api";

export default function OnboardingPaymentDetails({tenantId, registrationToken, clientSecret, nextPage}: {tenantId: string, registrationToken: string, clientSecret: string, nextPage: () => void}) {
    const stripe = useStripe();
    const elements = useElements();

    const callApi = useApi();
    const showModal = useModal();
    
    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                if (!stripe || !elements) return;

                elements.submit();

                const { 
                    error
                } = await stripe.confirmPayment({
                    elements: elements,
                    clientSecret: clientSecret,
                    confirmParams: {
                        return_url: window.location.href
                    }
                });

                if (error) {
                    showModal({
                        text: "Bei der Validierung Ihrer Zahlungsangaben ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.",
                        type: ModalType.Error
                    });
                    return;
                }

                await callApi(tenantsOnboardingSetHasPaymentDetails({tenantId: tenantId, registrationToken: registrationToken}));

                nextPage();
            }}
        >
            {
                (formik) => stripe
                ? (
                    <Form className="d-flex flex-column h-100 w-100 p-5 position-relative">
                        <div className="position-absolute top-25 p-5">
                            <h1 className="display-1 fw-bold" style={{color: "var(--primaryAccent)"}}>Nur noch wenige Schritte!</h1>
                            <h1>Wir freuen uns auf Sie.</h1>
                        </div>
                        <div className="w-100 h-100 d-flex flex-column justify-content-center p-5 gap-3">
                            <h6 className="fw-bold">Bitte geben Sie Ihre Zahlungsdetails ein.</h6>
                            <p>Wir stellen Ihnen eine 30-tägige Testperiode zur Verfügung. Sie können Ihr Abo jederzeit beenden und Ihr Zahlungsmittel wird erst mit Ablauf der Testperiode belastet.</p>
                            <PaymentElement id="payment-element" />
                            <Button type="submit" text="Weiter" loading={formik.isSubmitting} />
                        </div>
                        <Button className="align-self-end" externalLink to={AppRoutes.Feedback.path} text="Feedback"/>
                    </Form>
                )
                : <PageLoader />
            }
        </Formik>
    )
}