import React from "react";
import Flex from "../../container/Flex";
import { ITicket, IUser } from "../../../types/ApiTypes";
import { useTicketAssignments } from "../../../state/swr/tickets/useTicketAssignments";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Typography from "../../text/Typography";
import UserRoleBanner from "../../user/RoleBanner";
import { useUser } from "../../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useEmployees } from "../../../state/swr/employees/useEmployees";
import ModalDialog from "../../modal/ModalDialog";
import Button from "../../buttons/Button";

export interface ITicketMessageMentionsProps {
    mentions: Array<string>,
    ticket: ITicket,
    saveMentions: (mentions: Array<string>) => void,
}

export default function TicketMessageMentions({mentions, ticket, saveMentions}: ITicketMessageMentionsProps) {

    const { role } = useCurrentTenantRole();
    const { user } = useUser();
    const { employees } = useEmployees();

    const { loadingTicketAssignments, ticketAssignments } = useTicketAssignments(ticket);

    const isIncluded = (id: string) => {
        if (!mentions || !mentions.length) return false;
        return mentions.includes(id);
    }

    const addToMentions = (id: string) => {
        if (!mentions || !mentions.length) {
            saveMentions([id]);
            return;
        }

        if (isIncluded(id)) return;

        const newMentions = [...mentions, id];
        saveMentions(newMentions);
    }

    const removeFromMentions = (id: string) => {
        if (!mentions || !mentions.length) return;
        const newMentions = mentions.filter(mention => mention !== id);
        saveMentions(newMentions);
    }

    if (loadingTicketAssignments) return <LoadingSpinner />;
    
    const getAvailableUsers = (): Array<IUser> | null => {

        if ((!role || role.isClient) && (!user?.isSuperAdmin)) {
            if (!ticketAssignments || !ticketAssignments.length) return [];
            return ticketAssignments.filter(t => !!t.user).map(t => t.user!);
        }

        if (!employees || !employees.length) return [];
        return employees;
    }

    const users = getAvailableUsers();
    
    if (!users || !users.length) return null;

    const mentionCount = mentions && !!mentions.length ? mentions.length : 0;

    return (
        <ModalDialog
            button={
                <Button text={mentionCount ? `${mentionCount} Nutzer erwähnt` : "Nutzer erwählen"} variant={mentionCount ? "solid" : "text"} color={mentionCount ? "error" : "primary"} icon="at" />
            }
            title="Nutzer erwählen"
            sidebar={
                (close: any) => <Button onClick={async () => close()} text="Speichern" />
            }
        >
            <Flex fullWidth gap={1}>
                <Flex row wrap fullWidth style={{maxHeight: "100px", overflowY: "auto"}}>
                    {
                        users.map(t => {
                            const isSelected = isIncluded(t._id);
                            return <UserRoleBanner key={t._id} user={t} displayNameAsValue inactive={!isSelected} onClick={async () => isSelected ? removeFromMentions(t._id) : addToMentions(t._id)} />
                        })
                    }
                </Flex>
            </Flex>
        </ModalDialog>
    )
}