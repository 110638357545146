import { isReturnStatement } from "typescript";
import { ITicket, ITicketAccessHistoryItem, ITicketMessage, ITicketMessageText, TicketState } from "../types/ApiTypes";
import { useAppSelector } from "./reduxHooks";

export default function useTicketMessageUtil() {

    const getCurrentMessage = (msg: ITicketMessage): string | undefined => {
        if (!msg) return;
        if (!msg.content || !msg.content.length) return;

        const msgText = msg.content[msg.content.length - 1];

        return msgText.text;
    }

    return {
        getCurrentMessage
    }
}
