import React from "react";
import { useNavigate } from "react-router-dom";
import TeamsList from "../../components/teams/TeamsList";
import TeamsListItem from "../../components/teams/TeamsListItem";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import { useAppSelector } from "../../hooks/reduxHooks";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import TeamsCreateFromResponsibilityButton from "../../components/teams/TeamsCreateForDatevAreasOfResponsibility";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import TeamUpdateForm from "../../components/teams/TeamUpdateForm";

export default function TeamsManagement() {
    return (
        <Page 
            header={Locale.titles.teamsManagement} 
            actionButtons={[
                <TeamsCreateFromResponsibilityButton key="teams-create-from-responsibilities-button" />, 
                <TeamUpdateForm key="team-create-button" />
            ]}
        >
            <TeamsList />
        </Page>
    )
}