import { IFileMetadata } from "../types/ApiTypes";

export default function useAttachments() {
    const getIcon = (attachment: IFileMetadata) => {
        const typeString = attachment.type;

        switch (typeString) {
            case "application/pdf": return "filetype-pdf";
            case "text/csv": return "filetype-csv";
            case "image/jpg":
            case "image/jpeg": return "filetype-jpg";
            case "image/png": return "filetype-png";
            case "application/json": return "filetype-json";
            case "application/msword": return "filetype-doc";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": return "filetype-docx";
            case "application/vnd.ms-powerpoint": return "filetype-ppt";
            case "application/vnd.ms-excel": return "filetype-xls";
            case "application/vnd.ms-excel.sheet.macroEnabled.12":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": return "filetype-xlsx";
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation": return "filetype-pptx";

        }    

        if (typeString.includes("image")) return "file-earmark-image";
        if (typeString.includes("video")) return "filetype-video";

        return "file-earmark";
    }

    const getColor = (attachment: IFileMetadata, forBackground?: boolean) => {
        const typeString = attachment.type;

        let result;

        try {
            switch (typeString) {
                case "application/pdf": result = "#FF3333"; break;
                case "image/jpeg":
                case "image/jpg":
                case "image/png": result = "#55AAFF"; break;
                case "application/msword": 
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": result = "#1B5EBE"; break;
                case "application/vnd.ms-powerpoint": 
                case "application/vnd.openxmlformats-officedocument.presentationml.presentation": result = "#C13B1B"; break;
                case "application/vnd.ms-excel": 
                case "application/vnd.ms-excel.sheet.macroEnabled.12":
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": result = "#10793F"; break;
    
            }    
        }
        catch {}

        if (!result) return forBackground ? "#F0F0F055" : "#F0F0F0";

        return forBackground ? `${result}22` : result;
    }

    return {
        getIcon,
        getColor
    }
}