import { IUploadableEmployee } from "../components/employees/ImportEmployeeForm"
import { IOfficeMailFormValues } from "../components/inbox/OfficeMailForm";
import { 
    AbsenceType,
    AliasType,
    CdpAgreement,
    DocumentClearanceReminderInterval,
    ExternalAccount, 
    IAliasContent, 
    IAttachment, 
    IClient, 
    ICloudGatewayLogIn, 
    ICloudGatewayQueryOptions, 
    IFileMetadata, 
    IGlobalMailTemplateDocument, 
    IMailData, 
    IMailTemplateDocument, 
    IOfficeAccountDocument, 
    IRoleDocument, 
    ISettings, 
    ISignatureDocument, 
    ITaskDocument, 
    ITeamBase, 
    ITenant,
    ITenantAddress, 
    ITenantAuthority, 
    ITenantSettings, 
    ITicketNoteItem, 
    IUploadableFile, 
    IUser, 
    IUserClients, 
    IUserClientsDocument, 
    IUserDefaultTeamsDocument, 
    IUserSettings, 
    OfficeAccountType, 
    SessionVerificationMethod, 
    TicketSource, 
    TicketType 
} from "../types/ApiTypes";
import { Message } from "@microsoft/microsoft-graph-types";

export interface ISettingsUpdateRequest extends ISettings { }


export interface IRequestWithId {
    id: string
}

export interface IClientUpdateManagerRequest extends IRequestWithId {
    clientManagerId: string
}

export interface ISendTestMailOverImapRequest extends IRequestWithId {
    to: string,
    text: string
}

export interface ITenantUpdateMailAccountRequest extends IRequestWithId {
    usersReceiveUnsafeAttachmentsPerMailDefault: boolean,
    dontSendTicketErrorMailsWithPersonalAccount: boolean,
    sendMailsPreferrablyWithPersonalAccounts: boolean,
    dontSendTicketErrorMails: boolean
}

export interface ICreateDocumentClearanceSettingsBody {
    amountOfRemindersToBeSent: number,
    daysBetweenReminders: number,
    remindOnDayOfMonth: number,
    clearingUser: string,
    interval: DocumentClearanceReminderInterval 
}

export interface IUserPhoneNumberVerifyRequest {
    otp: string
}

export interface IUserPhoneNumberUpdateRequest {
    phoneNumber: string
}

export interface ITenantOnboardingRequest {
    registrationToken: string,
    tenantId: string
}

export interface IClientImportLogRequest {
    importId: string
}

export interface ITenantOnboardingCreateInitialUserRequest extends ITenant, ITenantOnboardingRequest {
    firstName: string,
    lastName: string,
    roleId: string
}

export interface ITenantOnboardingUpdateBillingDataRequest extends ITenantOnboardingRequest, ITenantUpdateBillingDataRequest { }

export interface ITenantByIdRequest {
    _id: string
}

export interface ITenantDefaultPaymentMethodRequest {
    id: string,
    paymentMethodId: string
}

export interface ITenantOnboardingSetHasTenantDetailsRequest extends ITenantOnboardingRequest {
}

export interface ITenantUpdateBillingDataRequest {
    _id: string,
    address: ITenantAddress,
    company: string,
    mailAddress: string
}

export interface ITenantSubscriptionRequest {
    tenantId: string,
    planId: string,
}

export interface ITenantOnboardingAddSubscriptionRequest extends ITenantSubscriptionAddRequest, ITenantOnboardingRequest { }

export interface ITenantSubscriptionAddRequest extends ITenantSubscriptionRequest {
    promoCode?: string
}

export interface ITenantOnboardingValidateSubdomain extends ITenant, ITenantOnboardingRequest {
    subdomain: string
}

export interface ITenantOnboardingUpdateRequest extends ITenant, ITenantOnboardingRequest {
}

export interface ITenantOnboardingGetInitialPasswordRequest extends ITenantOnboardingRequest {
}

export interface ITenantOnboardingDomainAddRequest extends ITenant, ITenantOnboardingRequest {    
    subdomain: string
}

export interface ITenantOnboardingDomainGetStatusRequest extends ITenantOnboardingRequest {
    tenantId: string,
    domainId: string
}

export interface ITenantUpdateSettingsRequest extends ITenantSettings {
    tenantId: string
}

export interface ITenantVerifyRequest {
    token: string
}

export interface ITenantCreateRequest {
    mailAddress: string,
    onboardingIsManaged: boolean
}

export interface IDomainDeleteRequest {
    domainId: string
}

export interface IDomainRefreshRequest {
    domainId: string
}

export interface ITenantDeleteRequest {
    tenantId: string,
    deleteTenantToken: string
}

export interface ITenantRequestDeletionTokenRequest {
    tenantId: string
}

export interface IDomainCreateRequest {
    domain: string
}

export interface IUserUpdateAbsenceRequest extends IRequestWithId {
    to: string,
    deputyId: string,
}

export interface IUserCreateAbsenceRequest extends IUserUpdateAbsenceRequest {
    from: string,
    to: string,
    userId: string,
    deputyId: string,
    type: AbsenceType
}

export interface IUserLogInRequest {
    mailAddress: string,
    password: string
}

export interface IUserNewPasswordRequest {
    token: string,
    oldPassword: string,
    newPassword: string
}

export interface IUserUpdateRequest {
    _id: string,
    mailAddress: string,
    firstName: string,
    lastName: string,
    mailNamePrefix: string,
    title: string,
    isTestingUser: boolean,
    authority: ITenantAuthority[],
    isSuperAdmin: boolean,
    isDeveloper: boolean
}

export interface IUserUpdateSettingsRequest extends IUserSettings {
    
}

export interface IUserVerificationRequest {
    token: string,
    password: string
}

export interface IUserVerifyResetTokenRequest {
    token: string
}

export interface IUserBlockRequest {
    mailAddress: string
}

export interface IUserResetPasswordWithMailRequest {
    mailAddress: string
}

export interface IUserDeleteRequest {
    userId: string
}

export interface IUserRegistratationRequest extends IUser {
}

export interface ISessionUpdateVerificationMethodRequest {
    method: SessionVerificationMethod
}


export interface ISessionVerificationRequest {
    otp: string,
    stayLoggedIn: boolean
}

export interface ITicketCreationRequest {
    subject: string,
    sendMail: boolean,
    firstMessage?: string,
    isDeveloperTicket?: boolean,
    source: TicketSource,
    createFromMailId?: string,
    attachments: Array<IUploadableFile>,
    createFromMailAccountId?: string,
    firstMessageIsFromClient: boolean,
    firstNote?: string,
    assignee?: string,
    type: TicketType,
    clientMail: string,
    clientContactId?: string,
    clientId?: string
}

export interface ITicketSetInternalSubjectRequest {
    internalSubject: string,
    ticketId: string
}

export interface ITicketsUpdateMultipleRequest {
    ticketIds: string[]
}

export interface ITicketsUpdateStatusRequest extends ITicketsUpdateMultipleRequest {
    solved: boolean,
    sendMail: boolean,
    message?: string,
    closed: boolean,
}


export interface ITicketAddNoteRequest {
    text: string,
    ticketId: string
}

export interface ITicketEscalateRequest {
    ticketId: string,
    escalateTo: string
}

export interface ITicketAssignToTeamRequest {
    ticketId: string,
    assignee: string,
    teamId: string
}

export interface ITicketUpdateLastAccessRequest {
    ticketId: string
}

export interface ITicketAssignRequest {
    ticketId: string,
    clientContactId?: string,
    clientId?: string,
    userId: string
}

export interface ITicketMergeRequest {
    ticketIds: Array<string>,
    mergeIntoTicket: string,
    mergeMessages: boolean
}

export interface ITicketDeleteRequest extends ITicketsUpdateMultipleRequest {
    sendMail: boolean
}

export interface ITeamDeleteRequest {
    teamId: string
}

export interface ICreateOrUpdateCloudGatewayLogIn extends ICloudGatewayLogIn { }

export interface IUpdateCloudGatewayQuerySettings extends ICloudGatewayQueryOptions, IRequestWithId { }

export interface ITeamUpdateRequest extends IRequestWithId {
    color: string,
    name: string,
    members?: string[],
    mailAccountId: string,
    areaOfResponsibilityId: string
}

export interface ITeamCreateRequest extends ITeamBase {
    members?: string[]
}

export interface ICreateTaskRequest extends Omit<Omit<ITaskDocument, "assignedTo">, "ticket"> { 
    ticket: string,
    assignedTo: Array<string>,
}

export interface IUpdateTaskRequest extends ITaskDocument, IRequestWithId { }

export interface IClientCreateRequest {
    name: string,
    legalForm?: string,
    importNumber?: string,
    clientManager?: string,
}

export interface IClientUpdateRequest extends IClient {
}

export interface ITicketMessageAddAttachment extends IFileMetadata, IUploadableFile {
}

export interface ITicketMessageDownloadAttachmentRequest extends IRequestWithId {
    attachments: Array<IAttachment>
}

export interface ITicketMessageAddRequest extends IRequestWithId {
    attachments: Array<ITicketMessageAddAttachment>,
    mentions: Array<string>,
    text: string
}

export interface ITicketMessageUpdateRequest extends IRequestWithId {
    text: string
}

export interface IClientDeleteRequest extends IRequestWithId {

}

export interface IEmployeesImportRequest {
    employees: IUploadableEmployee[]
};

export interface IClientsImportFromDatevRequest {
    clients: any[],
    alsoImportInactiveData: boolean
}

export interface IAzureGetSignInUrlRequest {
    type: OfficeAccountType,
    reAuthOfficeAccountId?: string,
    onBehalfOfUser?: string
}

export interface IAzureWithUrlResponse {
    url: string
}

export interface IOfficeAccountInboxRequest extends IRequestWithId {
    nextUrl?: string
}

export interface IOfficeGraphResponse<T> {
    "@odata.nextLink"?: string,
    value: Array<T>
}

export interface IOfficeInboxContentResponse extends IOfficeGraphResponse<Message> {}

export interface IOfficeSendMailForAccountRequest extends IRequestWithId {
    mail: IOfficeMailFormValues,
    originalSubject?: string,
    inResponseToMailId?: string
}

export interface IOfficeAccountMailByIdRequest extends IRequestWithId {
    messageId: string
}

export interface IOfficeAccountUpdateMailRequest extends IRequestWithId, IOfficeAccountMailByIdRequest {
    isRead?: boolean
}

export interface IOfficeAccountAttachmentPreviewRequest extends IRequestWithId {
    mailId: string
}

export interface IOfficeAccountAttachmentContentRequest extends IRequestWithId {
    mailId: string,
    attachmentId: string
}

export interface IOfficeAccountAddMailAsMessageToTicketRequest extends IRequestWithId {
    mailId: string,
    ticketId: string
}

export interface IOfficeAccountUpdateRequest extends Omit<IOfficeAccountDocument, "assignments"> {
    assignments: {
        personalUser?: string,
        team?: string
    }
}

export interface IUpdateUserClientsRequest extends IUserClientsDocument, IRequestWithId {

}

export interface ICreateAliasRequest extends IAliasContent, IRequestWithId { }

export interface IUserUpdateDefaultTeamRequest extends IRequestWithId, IUserDefaultTeamsDocument { }

export interface ITicketEscalateManuallyRequest {
    ticketId: string,
    escalationWorkflowId: string
}

export interface IGlobalMailTemplateUpdateRequest extends IGlobalMailTemplateDocument, IRequestWithId {}

export interface IMailTemplateCreateRequest extends IMailTemplateDocument { }

export interface IMailTemplateUpdateRequest extends IMailTemplateDocument, IRequestWithId { }

export interface ISessionCreateImposterRequest {
    user: string,
    minutes: number
}

export interface ITestMailTemplateRequest {
    templateId: string,
    isGlobal: boolean,
    ticketId: string,
    ticketMessageId: string,
}

export interface IUpdateSignatureRequest extends ISignatureDocument {

}

export enum RoundMailTargetType {
    Tickets = "tickets",
    Clients = "clients"
}

export interface ISendRoundMailRequest {
    targetType: RoundMailTargetType,
    targets: Array<string>,
    subject: string,
    content: string
}

export interface ICdpGrantConsentRequest {
    version: CdpAgreement
}

export interface ITicketAssignmentRequest {
    ticketId: string,
}

export interface ITicketAssignmentAddRequest extends ITicketAssignmentRequest {
    userId?: string,
    mailAddress?: string,
    clientId?: string,
    isActive?: boolean,
    isMainAssignee?: boolean,
    isMainClientContact?: boolean,
    isCc?: boolean
}

export interface ITicketAssignmentUpdateRequest extends ITicketAssignmentRequest, ITicketAssignmentAddRequest, IRequestWithId  { }

export interface ITicketAssignmentDeleteRequest extends ITicketAssignmentRequest, IRequestWithId { }

export interface IAssignAliasFromTicketRequest extends IRequestWithId {
    clientId: string,
    userId: string,
    type: AliasType
}

export interface IRoleCreateRequest extends IRoleDocument {}

export interface IRoleUpdateRequest extends IRoleDocument, IRequestWithId {}