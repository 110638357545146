import { IPermission, ITicket, Permission, TicketType } from "../types/ApiTypes";

export default function useTicketPermission(key: keyof IPermission, ticket: ITicket): Array<Permission>  {

    const result: Array<Permission> = [ `tickets.all.${key}` ];

    if (ticket.type === TicketType.ExternalTicket) result.push(`tickets.external.${key}`);
    else if (ticket.type === TicketType.InternalTicket) result.push(`tickets.internal.${key}`);
    else result.push(`tickets.own.${key}`);

    return result;
}