import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import useTicketUtil from "../../hooks/useTicketUtil";
import Ticket from "../../pages/tickets/Ticket";
import { TicketType } from "../../types/ApiTypes";
import "./TicketTypeRadioGroup.css";
import WithPermissions from "../permissions/WithPermissions";

export default function TicketTypeRadioGroup({value, saveValue}: {saveValue: (t: TicketType) => void, value: TicketType}) {
    return (
        <div className="d-flex flex-row flex-wrap align-items-center gap-4 ">
            <TicketTypeRadio value={TicketType.TicketByClient} currentValue={value} saveValue={saveValue} />
            <TicketTypeRadio value={TicketType.TicketToClient} currentValue={value} saveValue={saveValue} />
            <WithPermissions permissions={["tickets.internal.create"]}>
                <TicketTypeRadio value={TicketType.InternalTicket} currentValue={value} saveValue={saveValue} />
            </WithPermissions>
            <WithPermissions permissions={["tickets.external.create"]}>
                <TicketTypeRadio value={TicketType.ExternalTicket} currentValue={value} saveValue={saveValue} />
            </WithPermissions>
        </div>
    )
}

function TicketTypeRadio({currentValue, value, saveValue}: {currentValue: TicketType, value: TicketType, saveValue: (t: TicketType) => void}) {
    const isActive = currentValue === value;

    const {
        getTicketTypeName,
        getTicketTypeColor
    } = useTicketUtil();
    
    const containerClass = generateClassName("position-relative radio-indicator-container", {
        value: isActive,
        onTrue: "radio-indicator-container-active"
    })

    const labelClass = generateClassName("radio-label", {
        value: isActive,
        onTrue: "radio-label-active"
    })

    const indicatorStyle = generateStyle({
        name: "backgroundColor",
        applyCondition: isActive,
        value: getTicketTypeColor(value)
    })

    const textStyle = generateStyle({
        name: "color",
        applyCondition: isActive,
        value: getTicketTypeColor(value)
    })

    const indicatorOutlineStyle = generateStyle({
        name: "borderColor",
        value: getTicketTypeColor(value)
    });

    return (
        <div className="d-flex radio flex-row align-items-center gap-2" onClick={() => saveValue(value)}>
            <div className={containerClass} style={indicatorOutlineStyle} >
                <div className="ticket-type-radio-container-background" style={indicatorStyle} />
                {
                    isActive && <div className="radio-active-indicator" style={indicatorStyle} />
                }
            </div>
            <span className={labelClass} style={textStyle}>{getTicketTypeName(value)}</span>
        </div>
    )
}