import React from "react";
import { IPromotionCode } from "../../types/ApiTypes";
import Card from "../card/Card";
import CopyableDnsEntry from "../domain/CopyableDnsEntry";
import CopyToClipboardButton from "../buttons/CopyToClipboardButton";
import Pill from "../pills/Pill";

export default function PromotionCodeCard({code}: {code: IPromotionCode}) {
    return (
        <Card className="d-flex flex-column gap-2" style={{border: "1px solid var(--backgroundDarker)"}}>
            <div className="d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 p-0 fw-bold">{code.code}</h6>
                <CopyToClipboardButton value={code.code} />
            </div>
            <div className="w-100">
                {
                    code.limitToTenant 
                    ? <Pill rounded color="#F0F0F0">{code.limitToTenant.name}</Pill>
                    : <Pill rounded color="#D0D0F0" className="w-100">Alle Tenants</Pill>
                }
            </div>
        </Card>
    )
}