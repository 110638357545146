import useSWR from "swr";
import { IDocumentClearanceReminder, ILogEntry, IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useDocumentClearanceReminders = () => {
    const { data, isLoading, mutate } = useSWR<Array<IDocumentClearanceReminder>>(ApiRoutes.DocumentClearance.All);

    return {
        documentClearanceReminders: data,
        loadingDocumentClearanceReminders: isLoading,
        reloadDocumentClearanceReminders: mutate
    }
}
