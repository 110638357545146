import useSWR from "swr";
import { IAddressee, IAlias, IDatevAreaOfResponsibility, IDomain } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useDomains = () => {
    const { data, isLoading, mutate } = useSWR<Array<IDomain>>(ApiRoutes.Domains.All);

    return {
        domains: data,
        loadingDomains: isLoading,
        reloadDomains: mutate
    }
}