import React from "react";
import { generateClassName } from "../../hooks/useAttributes"
import IElementProps from "../../types/ElementProps";
import "./Flex.css";

export enum FlexGap {
    InnerContent = 2,
    Even = 3,
    BetweenItems = 4
}

export interface IFlexProps extends IElementProps {
    row?: boolean,
    align?: "start" | "center" | "baseline" | "top" | "end" | "bottom",
    justify?: "start" | "center" | "end" | "between" | "around",
    overflowX?: "auto" | "hidden" | "visible" | "scroll" | "initial" | "inherit",
    overflowY?: "auto" | "hidden" | "visible" | "scroll" | "initial" | "inherit",
    wrap?: boolean,
    reverse?: boolean,
    fullWidth?: boolean,
    fullHeight?: boolean,
    gap?: 0 | 1 | 2 | 3 | 4 | 5
}

export default function Flex({wrap, fullHeight, fullWidth, row = false, overflowX, overflowY, gap, onClick, reverse, children, style, className, align, justify = "start"}: IFlexProps) {
    const realGap = gap === undefined ? FlexGap.InnerContent : gap;

    const defaultAlign = row ? "center" : "start";
    
    const flexClass = generateClassName(className, "flexbox d-flex", {
        base: "flex-",
        value: row, 
        onTrue: `row${reverse ? "-reverse" : ""}`,
        standard: `column${reverse ? "-reverse" : ""}`
    }, {
        base: "gap-",
        value: realGap
    }, {
        base: "align-items-",
        value: align || defaultAlign
    }, {
        base: "justify-content-",
        value: justify
    }, {
        value: wrap,
        onTrue: "flex-wrap"
    }, {
        value: fullWidth,
        onTrue: "w-100"
    }, {
        value: fullHeight,
        onTrue: "h-100"
    }, {
        value: !!onClick,
        onTrue: "flex-clickable"
    }, {
        base: "overflow-x-",
        value: overflowX
    }, {
        base: "overflow-y-",
        value: overflowY
    
    });

    return (
        <div className={flexClass} style={style} onClick={onClick} >
            {children}
        </div>
    )
}