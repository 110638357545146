import React from "react"; 
import { tenantsDeleteFromCurrentDomains } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { IDomainDeleteRequest } from "../../api/ApiRequests";
import Button from "../buttons/Button";
import { useUser } from "../../state/swr/user/useUser";
import { useTenantDomains } from "../../state/swr/domains/useTenantDomains";
import { useDomains } from "../../state/swr/domains/useDomains";
import WithPermissions from "../permissions/WithPermissions";

export default function DeleteDomainButton({domainId}: IDomainDeleteRequest) {
    const { user } = useUser();
    const { reloadTenantDomains } = useTenantDomains();
    const { reloadDomains } = useDomains();

    const callApi = useApi();
    const dispatch = useAppDispatch();

    const deleteDomain = async () => {
        const res = await callApi(tenantsDeleteFromCurrentDomains({domainId: domainId}), true);
        if (!res) return;

        await reloadTenantDomains();

        if (user?.isSuperAdmin) await reloadDomains();
    }

    return (
        <WithPermissions permissions={[ "domains.tenant.delete" ]}>
            <Button 
                type="button" 
                className="float-end" 
                icon="trash" 
                color="error" 
                loadingText="Wird gelöscht..." 
                text="Domain löschen" 
                onClick={deleteDomain} 
            /> 
        </WithPermissions>
    )

}