import React from "react";
import Button from "../buttons/Button";
import ModalForm from "../modal/ModalForm";
import { AppColor } from "../../app/AppStyles";
import ImportClientsForm from "../clients/ImportClientsForm";
import CloudGatewaySetUp from "./CloudGatewaySetUp";
import DownloadDatevExporter from "./DownloadDatevExporter";
import ModalDialog from "../modal/ModalDialog";
import WithPermissions from "../permissions/WithPermissions";

export default function DatevImportDialog() {
    return (
        <WithPermissions permissions={[ "interfaces.datev.update" ]} >
            <ModalDialog 
                title="Mandanten importieren" 
                button={<Button color="secondary" icon="upload">Mandanten importieren</Button>}
            >
                {
                    (closeForm) => (
                        <div className="d-flex flex-column gap-4 position-relative">
                            <div className="d-flex flex-column gap-1">
                                <strong>Option 1: Über CloudGateway-Schnittstelle importieren</strong>
                                <CloudGatewaySetUp afterComplete={closeForm} />
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <strong>Option 2: Über Desktop-Client importieren</strong>
                                <DownloadDatevExporter afterComplete={closeForm} />
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <strong>Option 3: DATEV-Export importieren</strong>
                                <ImportClientsForm afterComplete={closeForm} />
                            </div>
                        </div>
                    )
                }
            </ModalDialog>
        </WithPermissions>
    )
}