import { Field, Form, Formik } from "formik";
import React from "react";
import { ticketSetInternalSubject } from "../../../api/Api";
import { ITicketSetInternalSubjectRequest } from "../../../api/ApiRequests";
import { AppColor } from "../../../app/AppStyles";
import { useAppSelector } from "../../../hooks/reduxHooks";
import useApi from "../../../hooks/useApi";
import { generateClassName } from "../../../hooks/useAttributes";
import { ITicket } from "../../../types/ApiTypes";
import Icon from "../../icons/Icon";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Typography from "../../text/Typography";
import Flex from "../../container/Flex";
import WithLabel from "../../text/WithLabel";
import { useUser } from "../../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";

export default function TicketChatInternalSubjectField({ticket}:{ticket: ITicket}) {
    const [hovering, setHovering] = React.useState<boolean>(false);
    const [editing, setEditing] = React.useState<boolean>(false);

    const callApi = useApi();

    const { user } = useUser();
    const { role } = useCurrentTenantRole();

    if (!(user && user.isSuperAdmin) && !(role && !role.isClient)) return null;

    const fieldClass = generateClassName("w-100 ticket-chat-internal-subject-field", {
        base: "ticket-chat-internal-subject-field-",
        value: editing,
        onTrue: "editing form-control",
        standard: "read-only"
    })

    const containerClass = generateClassName("w-100 d-flex ticket-internal-subject-form flex-row gap-2 justify-content-start", {
        value: editing,
        base: "align-items-",
        onTrue: "center",
        standard: "start"
    });

    return (
        <Formik
            initialValues={{
                ticketId: ticket._id,
                internalSubject: ticket.internalSubject || ticket.subject || ""
            } as ITicketSetInternalSubjectRequest}
            onSubmit={async (values) => {
                await callApi(ticketSetInternalSubject(values))
                setEditing(false);
            }}
            >
            {
                formik => (
                    <Form className={containerClass} onMouseEnter={() => setHovering(true)} onMouseOver={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
                        <WithLabel label="Interner Betreff" gap={0}>
                            <Flex row>
                                {
                                    editing && (
                                        formik.isSubmitting 
                                        ? <LoadingSpinner size={18} />
                                        : <Icon icon="check" color="success" onClick={() => formik.submitForm()} />
                                    )
                                }
                                {
                                    (hovering || editing) && <Icon icon={editing ? "x" : "pen"} color={editing ? "error" : "primary"} onClick={() => setEditing(!editing)} />
                                }
                                {
                                    editing 
                                    ? <Field disabled={formik.isSubmitting} readOnly={!editing} name="internalSubject" className={fieldClass} />
                                    : <span className="text-wrap text-break">{formik.values.internalSubject}</span>
                                }
                            </Flex>
                        </WithLabel>
                    </Form>
                )
            }

        </Formik>
        
    )
}