import { Formik } from "formik";
import React from "react";
import { IUser } from "../../../types/ApiTypes";
import LabelButton from "../../buttons/LabelButton";
import Card from "../../card/Card";
import AddPhoneNumberButton from "./AddPhoneNumberButton";

export default function VerifiedPhoneNumber({user}: {user: IUser}) {
    return (
        <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                <span className="fw-bold">Verknüpfte Telefonnummer</span>
                <AddPhoneNumberButton user={user} />
            </div>
            <span>{user.phoneNumber || "Keine Telefonnummer verknüpft"}</span>    
        </div>
    )
}