import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Status } from "../../config/Status";
import PageLoader from "../loader/PageLoader";
import Table from "../tables/Table";
import MailLogOverviewItem from "./MailLogOverviewItem";
import Pill from "../pills/Pill";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import { IMailLog } from "../../types/ApiTypes";
import { useMailLog } from "../../state/swr/log/useMailLogs";
import WithPermissions from "../permissions/WithPermissions";

export default function MailLogOverview() {
    const [sortedEntries, setSortedEntries] = React.useState<Array<IMailLog>>([]);

    const { loadingMailLogs, mailLogs, reloadMailLogs } = useMailLog();
    
    React.useEffect(() => {
        if (loadingMailLogs) return;

        if (!mailLogs || !mailLogs.length) {
            setSortedEntries([]);
            return;
        }

        const sortThis = [...mailLogs];
        
        const result = sortThis.sort((a, b) => {
            const aDate = new Date(a.sentAt || a.createdAt);
            const bDate = new Date(b.sentAt || b.createdAt);

            return bDate.getTime() - aDate.getTime();
        });

        setSortedEntries(result);

    }, [mailLogs, loadingMailLogs])

    const dispatch = useAppDispatch();
    
    if (loadingMailLogs) return <PageLoader /> 

    if (!sortedEntries || !sortedEntries.length) return <span>Keine Einträge</span>

    return (
        <WithPermissions permissions={ [ "logs.email.read" ]}>
            <div className="d-flex flex-column w-100 h-100 gap-3">
                <Table 
                    headers={[{label: ""}, {label: "Datum"}, {label: "Absender"}, {label: "Empfänger"}, {label: "Betreff"}, {label: "Nachricht"}, { label: "Ticket" }, {label: "Quelle"}, {label: "Verarbeitet"}, {label: "Ablehnungsgrund"}, {label: ""}]}
                    items={sortedEntries}
                    renderItem={e => <MailLogOverviewItem key={e._id} logEntry={e} />}
                />
            </div>
        </WithPermissions>        
    )
}