import React from "react";
import { IPlan } from "../../types/ApiTypes";
import SubscriptionPlanFeature from "./SubscriptionPlanFeature";

export default function PlanFeaturesContainer({plan}: {plan: IPlan}) {
    if (!plan || !plan.features || !plan.features.length) return null;

    return (
        <div className="p-2" style={{backgroundColor: "var(--backgroundDarker)", borderRadius: "var(--borderRadius)"}}>
            <strong>Features</strong>
            {
                plan.features && !!plan.features.length
                ? plan.features.map(f => <SubscriptionPlanFeature feature={f} key={f.feature._id} />)
                : <span>Keine weiteren Features</span>                   
            }
        </div>
    )
}