import React from "react";
import { ITicketMessage } from "../../../types/ApiTypes";
import { useAppSelector } from "../../../hooks/reduxHooks";
import "./TicketMessageHistory.css";
import { generateClassName } from "../../../hooks/useAttributes";
import { useTicketSidebarHistory } from "../../../hooks/useTicketSidebarHistory";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";

export default function TicketMessageHistory({message}: {message: ITicketMessage}) {

    const { role } = useCurrentTenantRole();

    const {
        showTicketMessageHistory
    } = useTicketSidebarHistory();

    if (!message || !message.content || !message.content.length || message.content.length <= 1) return null;
    
    if (role && role.isClient) return null;
    
    return (
        <div className="ticket-message-history d-flex flex-row align-items-center gap-2">
            <span className="fw-bold ticket-message-history-toggle" onClick={() => showTicketMessageHistory(message._id)}>Bearbeitet</span>
        </div>
    )
}