import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Card from "../card/Card";
import { Formik } from "formik";
import TenantBillingDetailsForm from "../tenant/TenantBillingDetailsForm";
import useApi from "../../hooks/useApi";
import { tenantsUpdateBillingData } from "../../api/Api";
import useTenantUtil from "../../hooks/useTenantUtil";
import { useTenant } from "../../state/swr/tenant/useTenant";
import WithPermissions from "../permissions/WithPermissions";

export default function BillingDetails() {
    const { loadingTenant, reloadTenant, tenant } = useTenant();

    const {
        normalizeTenantBillingData
    } = useTenantUtil();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    return (
        <WithPermissions permissions={[ "billing.tenant.update" ]}>
            <div className="d-flex flex-column gap-2">
                <Formik
                    initialValues={normalizeTenantBillingData(tenant!)}
                    onSubmit={async (values) => {
                        const res = await callApi(tenantsUpdateBillingData(values));

                        if (!res) return;

                        await reloadTenant();
                    }}
                >
                    {
                        (formik) => (
                            <Card>
                                <h6 className="fw-bold">Rechnungsanschrift</h6>
                                <TenantBillingDetailsForm formik={formik} />
                            </Card>
                        )
                    }
                </Formik>
            </div>
        </WithPermissions>
    )
}