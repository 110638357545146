import { Form, Formik } from "formik";
import React from "react";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import { AppRouteParams, AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import useModal from "../../hooks/useModal";
import { IUserVerificationRequest } from "../../api/ApiRequests";
import Button from "../buttons/Button";
import FieldWithLabel from "../formik/FormikField";
import FormWithTitle from "../formik/FormWithTitle";
import * as yup from "yup";
import { min, required } from "../../hooks/useLocale";
import useApi from "../../hooks/useApi";
import { usersVerify, usersVerifyResetToken } from "../../api/Api";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useSession } from "../../state/swr/session/useSession";

interface IUserVerificationValues extends IUserVerificationRequest {
    passwordRepeat: string
}

export default function VerifyUserForm() {

    const verificationToken = useParams()[AppRouteParams.VerificationToken.keyName];

    const callApi = useApi();
    const showModal = useModal();
    const navigate = useNavigate();
    
    const { session } = useSession();

    React.useEffect(() => {
        if (session) {
            showModal({
                text: "Bitte melden Sie sich ab, bevor Sie einen weiteren Nutzer verifizieren.",
                type: ModalType.Error
            });
    
            navigate(AppRoutes.Home.path);
            return;
        }

        if (!verificationToken) {
            showModal({
                text: "Bitte geben Sie einen gültigen Verifizierungscode an.",
                type: ModalType.Error
            });
    
            navigate(AppRoutes.Home.path);
            return;
        }

        const abortController = new AbortController();

        const verifyToken = async () => {
            const res = await usersVerifyResetToken({
                token: verificationToken
            }, abortController);

            if (res && (res.success || res.canceled)) return;

            showModal({
                text: res.message || "Der angegebene Code ist ungültig.",
                type: ModalType.Error
            });
        
            navigate(AppRoutes.Home.path);
        }

        verifyToken();

        return () => abortController.abort();
    }, [verificationToken]);

    if (!verificationToken) return null;

    return (
        <Formik
            initialValues={{
                password: "",
                passwordRepeat: "",
                token: verificationToken
            } as IUserVerificationValues}
            validationSchema={yup.object().shape({
                password: yup.string().required(required("Passwort")).min(8, min(8)),
                passwordRepeat: yup.string().required("Bitte wiederholen Sie das Passwort.").min(8, min(8)),
            })}
            onSubmit={(values, actions) => {
                if (values.password !== values.passwordRepeat) {
                    showModal({
                        text: "Die Passwörter stimmen nicht überein. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.",
                        type: ModalType.Error
                    })
                    actions.setSubmitting(false);
                    return;
                }

                const verifyUser = async () => {
                    const res = await callApi(usersVerify(values), true);
                    actions.setSubmitting(false);

                    if (res) {
                        showModal({
                            text: "Ihr Konto wurde erfolgreich verifiziert. Bitte loggen Sie sich nun ein.",
                            type: ModalType.Success
                        });
                        navigate(AppRoutes.Home.path);
                        return;
                    }
                }

                verifyUser();
            }}
            >
            {
                (formik) => {
                    return (
                        <FormWithTitle title="Registrierung abschließen" description="Bitte geben Sie ein neues Passwort für Ihr Konto ein, um den Registrierungsprozess abzuschließen.">
                            <FieldWithLabel type="password" name="password" label="Passwort" />
                            <FieldWithLabel type="password" name="passwordRepeat" label="Passwort wiederholen"  />
                            <Button type="submit" text="Bestätigen" loading={formik.isSubmitting} />
                        </FormWithTitle>
                    )
                }
            }

        </Formik>
    )
}