import React from "react";
import { AppColor } from "../../app/AppStyles";
import Icon from "../icons/Icon";
import "./CopyToClipboardButton.css";
import { generateStyle } from "../../hooks/useAttributes";

interface ICopyToClipboardButtonProps {
    value: string
}

export default function CopyToClipboardButton({value}: ICopyToClipboardButtonProps) {
    
    const [copied, setCopied] = React.useState<boolean>(false);
    const [cooldownTimer, setCooldownTimer] = React.useState<any>();

    const copyToClipboard = () => {
        clearTimeout(cooldownTimer);
        navigator.clipboard.writeText(value);
        setCopied(true);
        const x = setTimeout(() => setCopied(false), 1000);
        setCooldownTimer(x);
    }

    const textStyle = generateStyle({
        name: "color",
        value: copied ? "success" : "primary",
        isVar: true
    })

    return (
        <button disabled={copied} onClick={copyToClipboard} className="ms-3 gap-1 d-flex flex-row align-items-center copy-to-clipboard-button position-relative">
            <div className="copy-to-clipboard-button-background w-100 h-100 position-absolute top-0 start-0" />
            <Icon size={12} className="m-0 p-0" icon={copied ? "check" : "layers"} color={copied ? "success" : "primary" } />
            <span style={textStyle} >
                {
                    copied 
                    ? "Kopiert"
                    : "Kopieren"
                }
            </span>
        </button>
    )
}