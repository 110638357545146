import React, { MouseEventHandler } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppColor } from "../../app/AppStyles";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import IElementProps from "../../types/ElementProps";
import { getTextColorFromBackground, parseVarColorToHex } from "../../util/util";
import Icon from "../icons/Icon";
import "./Button.css";
import { hexWithOpacity } from "../../util/color";

export type ButtonSize = "small" | "regular";

export type ButtonVariant = "solid" | "outline" | "text" | "hyperlink" | "icon";
export type ButtonType = "button" | "submit" | "reset";

export interface IButtonProps extends IElementProps {
    loading?: boolean,
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<any>,    
    align?: "start" | "end" | "center",
    icon?: string, 
    iconPosition?: "start" | "end",
    iconSize?: number,
    preventFloatEndOnSubmit?: boolean,
    disabled?: boolean,
    variant?: ButtonVariant,
    text?: string, 
    type?: ButtonType,
    color?: AppColor,
    externalLink?: boolean,
    disabledText?: string,
    size?: ButtonSize,
    openInNewTab?: boolean,
    to?: string,
    disabledIcon?: string,
    loadingText?: string
}

export default function Button({className, externalLink = false, to = "", align = "center", iconPosition = "start", preventFloatEndOnSubmit = false, iconSize, variant = "solid", disabledIcon, disabledText, onClick, disabled, openInNewTab, loading, text, type, loadingText, children, icon, color = "primary", size = "regular"}: IButtonProps) {

    const [hover, setHover] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(loading || false)
    
    React.useEffect(() => {
        if (loading === undefined) return;
        setIsLoading(loading);
    }, [loading]);

    const navigate = useNavigate();

    const clickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        try {
            setIsLoading(true);

            if (!to) {
                if (!onClick) return;
                await onClick(e);
                return;
            }
    
            if (!externalLink) {
                navigate(to);
                e.preventDefault();
                return;
            }
    
            if (openInNewTab) {
                window.open(to, "_blank");
                e.preventDefault();
                return;
            }
            
            window.location.href = to;
            e.preventDefault();
        }
        catch { }
        finally {
            setIsLoading(false);
        }
    }

    const textColor = variant === "solid" ? (
        color 
        ? color === "bright" ? `var(--primary)` : getTextColorFromBackground(color, "#FFFFFF") 
        : "#000000"
    ) : `var(--${color || "primary"})`;

    const backgroundStyle = generateStyle({
        value: color,
        isVar: true,
        name: "backgroundColor"
    })

    const buttonStyle = generateStyle({
        value: textColor,
        name: "color"
    }, {
        unit: "em",
        standard: "1",
        value: size === "small" ? "0.8" : undefined,
        name: "fontSize"
    }, {
        name: "border",
        applyCondition: (variant === "outline" || variant === "solid"),
        value: `2px solid var(--${color})`
    }, {
        name: "border",
        applyCondition: hover && variant !== "outline" && variant !== "solid",
        value: `2px solid ${hexWithOpacity(color, 0.3, true)})`
    })

    const isSubmit = type === "submit";
    const canFloatEnd = isSubmit && !preventFloatEndOnSubmit;

    const buttonClass = generateClassName(className, "button position-relative", {
        value: canFloatEnd,
        onTrue: "align-self-end"
    }, {
        value: variant,
        base: "button-"
    });

    const contentClass = generateClassName("button-content position-relative d-flex flex-row align-items-center gap-1", {
        value: align,
        base: "justify-content-"
    })

    const textClass = generateClassName("button-text text-nowrap m-0 p-0 ", {
        value: variant === "icon",
        onTrue: "button-text-icon",
        standard: "text-uppercase fw-bold"
    })

    const textStyle = generateStyle({
        name: "color",
        value: hover ? getTextColorFromBackground(hexWithOpacity(color, 0.3, true), "#FFFFFF") : "black",
        important: true,
        applyCondition: variant === "icon"
    });


    const buttonText = (
        disabled
        ? (disabledText || text)
        : (
            isLoading
            ? (loadingText || "Bitte warten...")
            : (typeof children === "string" ? children : text)
        )
    ) || "";

    const hasText = !!buttonText;

    const iconComponent = (
        <Icon 
            icon={disabled ? (disabledIcon || icon) : icon} 
            loading={isLoading} 
            size={iconSize || (size === "small" ? 14 : 20) || 20} 
            tooltip={variant === "icon" ? buttonText : ""} />
    );

    return (
        <button 
            type={type || "button"} 
            onClick={clickHandler} 
            className={buttonClass} 
            style={buttonStyle} 
            disabled={isLoading || disabled} 
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onMouseOver={() => setHover(true)}
        >
            { 
                hover && !(disabled || isLoading) &&  (
                    <div 
                        className="button-nonsolid-hover-effect position-absolute w-100 h-100 top-0 start-0" 
                        style={generateStyle({
                            name: "backgroundColor", 
                            important: false, 
                            value: hexWithOpacity(color, 0.2, true) 
                        })} 
                    />
                )
            }
            {
                variant === "solid" && <div className="button-background w-100 h-100 position-absolute top-0 start-0" style={backgroundStyle} />
            }
            <div className={contentClass}>
                {
                    iconPosition === "start" && iconComponent
                }
                {
                    (hasText || children) && <div style={textStyle} className={textClass}>{children || buttonText}</div>
                }
                {
                    iconPosition === "end" && iconComponent
                }
            </div>
        </button>
    )
}