import React from "react";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import useTicketMessageUtil from "../../../hooks/useTicketMessageUtil";
import useTiggySocket from "../../../hooks/useTiggySocket";
import useUserUtil from "../../../hooks/useUserUtil";
import { ITicket, ITicketMessage } from "../../../types/ApiTypes";
import { formatDate, formatDateGmailStyle, formatDateTiggiStyle, formatTime } from "../../../util/formatter";
import { getTextColorFromBackground } from "../../../util/util";
import "./TicketChatMessage.css";
import TicketMessageReadIndicator from "./TicketMessageReadIndicator";
import TicketMessageText from "./TicketMessageText";
import TicketMessageHistory from "./TicketMessageHistory";
import TicketChatMessageAttachment from "./TicketChatMessageAttachment";
import Flex from "../../container/Flex";
import TicketChatMessageAuthor from "./TicketChatMessageAuthor";
import ModalForm from "../../modal/ModalForm";
import { useTicketMessageMails } from "../../../state/swr/tickets/useTicketMessageMails";
import MailLogCard from "../../mail/MailLogCard";
import ModalDialog from "../../modal/ModalDialog";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import Typography from "../../text/Typography";
import TicketChatMessageAttachments from "./TicketChatMessageAttachments";
import { useTenant } from "../../../state/swr/tenant/useTenant";
import { useUser } from "../../../state/swr/user/useUser";
import { useUsers } from "../../../state/swr/user/useUsers";
import UserRoleBanner from "../../user/RoleBanner";
import { AppColor } from "../../../app/AppStyles";
import Divider from "../../sidebar/Divider";
import { useTicketClientContact } from "../../../state/swr/tickets/useTicketClientContact";
import TicketMessageInfoDialog from "./TicketMessageInfoDialog";

interface ITicketChatMessageProps {
    message: ITicketMessage,
    ticket: ITicket,
    colorMap: {[userId: string]: string}
}

export default function TicketChatMessage({ticket, message, colorMap}: ITicketChatMessageProps) {

    const { loadingTicketClientContact, reloadTicketClientContact, ticketClientContact } = useTicketClientContact(ticket);

    const { tenant } = useTenant();

    const {
        getName,
        getCurrentTenantRole
    } = useUserUtil();

    const {
        senderMailAddress,
    } = message;

    const {
        sendRead
    } = useTiggySocket();
    
    const { user } = useUser();

    const isMentioned = () => {
        if (!message.mentions || !message.mentions.length) return false;
        if (!user) return false;
        return message.mentions.includes(user._id);
    }

    const isOwn = (typeof message.sentBy === "string" ? message.sentBy === user?._id : (message.sentBy?._id === user?._id || message.sentBy?.mailAddress === user?.mailAddress));
    
    React.useEffect(() => {
        if (isOwn) return;
        if (message && message.isRead) return;
        sendRead(ticket._id, message._id);
        if (message.isRead) return;
    }, [message, ticket]);

    const role = getCurrentTenantRole(typeof message.sentBy !== "string" ? message.sentBy : undefined);

    const className = generateClassName("ticket-chat-message w-100 d-flex flex-column position-relative", {
        value: isOwn,
        onTrue: "ticket-chat-message-own align-self-end",
        standard: "ticket-chat-message-other align-self-start"
    });


    const style = generateStyle({
        name: "color",
        value: isOwn ? getTextColorFromBackground("primary") : undefined
    });

    if (!message || !message.content) return null;

    const cardColor = isMentioned() ? "error" : (isOwn ? "primary" : undefined);

    return (
        <Card 
            noPadding
            color={cardColor}
            wrapperClass={className}
            header={
                <Flex className="w-100">
                    <Flex row justify={isOwn ? "end" : "between"} align="start" className="w-100" wrap>
                        {
                            !isOwn && (
                                <Flex gap={0}>
                                    {
                                            <Flex row>
                                                {
                                                    message.sentBy && typeof message.sentBy !== "string" && <Typography bold color="primary" size={14}>{getName(message.sentBy)}</Typography> 
                                                }
                                                {
                                                    !!(role && role.isClient) && <Typography color="primary">| {ticketClientContact && ticketClientContact.client ? ticketClientContact.client.name : "Kein Mandant"}</Typography> 
                                                }
                                            </Flex>
                                    }
                                    <Typography size={12} basedOnThisBackground={isOwn ? "primary" : undefined}>
                                        {
                                            (role && !role.isClient && tenant && tenant.name) 
                                            ? `@${tenant.name}`
                                            : senderMailAddress
                                        }
                                    </Typography>
                                </Flex>
                            )
                        }
                        <Flex row wrap className="gap-ms-3 gap-1">
                            <TicketMessageInfoDialog ticketMessage={message} ticket={ticket} />
                            {
                                isOwn && <TicketMessageReadIndicator message={message} />
                            }
                            <TicketMessageHistory message={message} />
                            <Typography className="ms-auto" basedOnThisBackground={isOwn ? "primary" : undefined} size={14}>{formatDateTiggiStyle(message.createdAt)}</Typography>
                        </Flex>
                    </Flex>
                </Flex>
            }
        >
            <Flex className="ps-3 pb-2 pt-3 pe-3 w-100" style={style}>
                <TicketMessageText message={message} canEdit={isOwn} />
                <TicketChatMessageAttachments message={message} />
                <MentionedUsers message={message} cardColor={cardColor} />
            </Flex>
        </Card>
    )
}

function MentionedUsers({message, cardColor}: {message: ITicketMessage, cardColor?: AppColor}) {
    const { users, loadingUsers } = useUsers();

    if (loadingUsers) return null;
    if (!users || !users.length) return null;
    if (!message.mentions || !message.mentions.length) return null;

    const mentionedUsers = users.filter(u => message.mentions.includes(u._id));

    if (!mentionedUsers.length) return null;

    return (
        <Flex fullWidth>
            <Divider height={1} opacity={0.5}/>
            <Typography basedOnThisBackground={cardColor} >Erwähnt:</Typography>
            <Flex row fullWidth>
                {mentionedUsers.map(m => <UserRoleBanner key={m._id} user={m} displayNameAsValue />)}
            </Flex>
        </Flex>
    )
}