import { FieldArray, useFormikContext } from "formik";
import React from "react";
import TicketChatAttachmentIcon from "./TicketChatAttachmentIcon";
import { IUploadableFile } from "../../../types/ApiTypes";
import useAttachments from "../../../hooks/useAttachments";
import Pill from "../../pills/Pill";
import Icon from "../../icons/Icon";
import { formatFileSize } from "../../../util/formatter";

export interface ITicketChatAttachmentFieldProps {
    name: string
}

export default function TicketChatAttachmentsField({name}: ITicketChatAttachmentFieldProps) {
    const {
        values
    } = useFormikContext<any>();

    
    const {
        getColor
    } = useAttachments();

    return (
        <FieldArray name={name}>
            {
                (arrayHelpers) => (
                    <div>
                        {
                            values[name] && !!values[name].length && (
                                <div className="d-flex flex-column gap-1">
                                    <strong>Anhänge</strong>
                                    <div className="d-flex flex-row gap-2 align-items-center justify-content-start flex-wrap">
                                        {
                                            values[name].map((a: IUploadableFile, index: number)=> (
                                                <Pill key={`${a.name}-${a.size}`} color={getColor(a, true)} className="p-0 pe-1 overflow-hidden d-flex h-100 flex-row align-items-center gap-2">
                                                    <div style={{backgroundColor: getColor(a)}} className="p-2 h-100">
                                                        <TicketChatAttachmentIcon attachment={a} />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <div className="d-flex flex-row align-items-center gap-2">
                                                            <strong>{a.name}</strong>
                                                            <Icon icon="x" onClick={() => arrayHelpers.remove(index)} />
                                                        </div>
                                                        <span style={{fontSize: "0.9em"}}>{formatFileSize(a.size, true)}</span>
                                                    </div>
                                                </Pill>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </div>
                ) 
            }
        </FieldArray>
    )
}