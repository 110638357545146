import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import { IUserStatisticsForTeam } from "../../../../types/ApiTypes";

export const useAllTeamsStatistics = () => {
    const { data, isLoading, mutate } = useSWR<Array<IUserStatisticsForTeam>>(ApiRoutes.User.Statistics.CurrentTenantTeams, { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        allTeamsStatistics: data,
        loadingAllTeamsStatistics: isLoading,
        reloadAllTeamsStatistics: mutate
    }
}