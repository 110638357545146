import React from "react"
import { sessionSetVerificationMethod } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { SessionVerificationMethod } from "../../types/ApiTypes";
import LabelButton from "../buttons/LabelButton";
import Button from "../buttons/Button";
import { useSession } from "../../state/swr/session/useSession";

export default function ResetSelectedVerificationMethodButton() {

    const { reloadSession } = useSession();

    const callApi = useApi();
    const dispatch = useAppDispatch();

    const resetMethod = async () => {
        await callApi(sessionSetVerificationMethod({method: SessionVerificationMethod.None}));
        await reloadSession(undefined, { revalidate: false });
    }

    return <Button variant="text" color="secondary" text="Andere Verifizierungsmethode wählen" loadingText="Lädt..." onClick={resetMethod} />
}