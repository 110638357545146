import React from "react";
import Pill from "../pills/Pill";
import { ITeam, ITeamBase } from "../../types/ApiTypes";
import Banner from "../pills/Banner";
import { getTextColorFromBackground, parseVarColorToHex } from "../../util/util";

export interface ITeamBanner {
    team: ITeamBase,
    onClick?: () => void,
    isActive?: boolean,
    textOnEmpty?: string
}

export default function TeamBanner({team, textOnEmpty, isActive = true, onClick}: ITeamBanner) {
    if (!team) {
        if (textOnEmpty) return <Banner text={textOnEmpty} color="bright" />
        return null;
    }
    
    const inactiveColor = "#A0A0A0";
    const color = (isActive && team.color) || inactiveColor;

    return (
        <Pill text={team.name} icon="people" color={color} onClick={onClick} />
    )
}