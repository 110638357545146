import React from "react";
import { IOfficeAccount, IOfficeAccountDocument, ITeam, IUser } from "../../types/ApiTypes";
import { Form, Formik } from "formik";
import ModalForm from "../modal/ModalForm";
import useApi from "../../hooks/useApi";
import Flex from "../container/Flex";
import CheckBox from "../formik/CheckBox";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import { useAppSelector } from "../../hooks/reduxHooks";
import { officeAccountsUpdate } from "../../api/Api";
import { useOfficeAccounts } from "../../state/swr/office/useOfficeAccounts";
import { Status } from "../../config/Status";
import LoadingSpinner from "../loader/LoadingSpinner";
import useUserUtil from "../../hooks/useUserUtil";
import UserRoleIndicator from "../user/RoleBanner";
import TeamBanner from "../teams/TeamBanner";
import Button from "../buttons/Button";
import UserSearchableComboBox from "../comboBox/UserSearchableComboBox";
import { useUsers } from "../../state/swr/user/useUsers";
import { useTeams } from "../../state/swr/teams/useTeams";
import useInboxPermissions from "../../hooks/useInboxPermissions";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import Card from "../card/Card";

export interface IUpdateOfficeAccountFormProps {
    account: IOfficeAccount
}

export enum UpdateOfficeAccountFormTab {
    Personal = "personal",
    Teams = "teams",
}

export default function UpdateOfficeAccountForm({account}: IUpdateOfficeAccountFormProps) {
    
    const {
        getAccountPermissions
    } = useInboxPermissions();

    const [tab, setTab] = React.useState<UpdateOfficeAccountFormTab>(UpdateOfficeAccountFormTab.Personal);

    const { loadingTeams, teams } = useTeams();
    const { loadingUsers, reloadUsers, users } = useUsers();
    const { reloadOfficeAccounts } = useOfficeAccounts();

    const {
        getName
    } = useUserUtil();

    const callApi = useApi();

    const permissions = getAccountPermissions("update", account);

    return (
        <WithPermissions permissions={permissions}>
            <ModalForm 
                title="Konto ändern" 
                button={
                    <Button icon="pen">Ändern</Button>
                }
                enableReinitialize
                initialValues={{
                    assignments: {
                        team: account.assignments?.team,
                        personalUser: account.assignments?.personalUser
                    },
                    isMainTenantAccount: account.isMainTenantAccount !== undefined ? account.isMainTenantAccount : false,
                } as IOfficeAccountDocument}
                onSubmit={async (values) => {
                    const res = await callApi(officeAccountsUpdate({
                        assignments: {
                            team: values.assignments.team?._id,
                            personalUser: values.assignments.personalUser?._id
                        },
                        isMainTenantAccount: values.isMainTenantAccount
                    }, account._id));

                    if (!res) return false;

                    await reloadOfficeAccounts();
                    return true;
                }}
                sidebar={formik => <Button type="submit" disabled={formik.isSubmitting} icon="save" text="Speichern" loading={formik.isSubmitting}  />}
            >
                {
                    formik => {
                        
                        const setAssignments = (user?: IUser, team?: ITeam) => {
                            formik.setFieldValue("assignments.team", team);
                            formik.setFieldValue("assignments.personalUser", user);
                        }

                        const toggleAssignmentsBasedOnMainAccount = () => {
                            if (formik.values.isMainTenantAccount) return;
                            setAssignments();
                        }

                        return (
                            <Flex gap={3} className={"w-100 " + (!formik.values.isMainTenantAccount ? "mb-5 pb-5" : "")}>
                                <Flex>
                                    <Typography bold color="primary">Kanzleikonto / Hauptkonto</Typography>
                                    <CheckBox name="isMainTenantAccount" onChange={toggleAssignmentsBasedOnMainAccount} label="Zum Versenden von Kanzlei-Mails und Systemmails für Ihre Kanzlei verwenden" />
                                </Flex>
                                {
                                    !formik.values.isMainTenantAccount && (
                                        <Flex className="w-100 mb-5 pb-5" >
                                            <Typography bold color="primary">Zuweisungen</Typography>
                                            <Card
                                                header={
                                                    <TabSwitcher 
                                                        removeOnUnmount
                                                        saveActiveTab={t => setTab(t as any)}
                                                        size="tiny" 
                                                        tabs={[
                                                            {
                                                                data: UpdateOfficeAccountFormTab.Personal,
                                                                label: "Persönlich",
                                                                first: !!formik.values.assignments.personalUser ? true : undefined
                                                            },
                                                            {
                                                                data: UpdateOfficeAccountFormTab.Teams,
                                                                label: "Teams",
                                                                first: !!formik.values.assignments.team ? true : undefined
                                                            }
                                                        ]}
                                                        tabQueryParamKey="assign"
                                                    />
                                                }
                                            >

                                                {
                                                    (
                                                        () => {
                                                            switch (tab) {
                                                                case UpdateOfficeAccountFormTab.Personal: 
                                                                    if (loadingUsers) return <LoadingSpinner />
                                                                    if (!users) return <div>Keine Benutzer gefunden</div>

                                                                    return (
                                                                        <UserSearchableComboBox
                                                                            className="w-100"
                                                                            users={users}
                                                                            onlyCurrentTenant
                                                                            value={formik.values.assignments.personalUser}
                                                                            onItemClick={u => setAssignments(u)}
                                                                        />
                                                                    );
                                                                case UpdateOfficeAccountFormTab.Teams: 
                                                                        
                                                                    if (loadingTeams) return <LoadingSpinner />
                                                                    if (!teams) return <div>Keine Teams gefunden</div>
                                                                        
                                                                    return (
                                                                        <SearchableComboBox
                                                                            className="w-100"
                                                                            values={teams}
                                                                            value={formik.values.assignments.team}
                                                                            itemToId={t => t._id}
                                                                            itemToString={t => t.name}
                                                                            onItemClick={t => setAssignments(undefined, t)}
                                                                            renderItem={t => <TeamBanner team={t}/>}
                                                                        />
                                                                    );
                                                            }
                                                        }
                                                    )()
                                                }
                                            </Card>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        )
                    }
                }
            </ModalForm>
        </WithPermissions>
    )   
}