import React from "react";
import { IImportValidationError, ImportValidationErrorType } from "../../types/ApiTypes";
import ModalForm from "../modal/ModalForm";
import Pill from "../pills/Pill";
import PopUpToast from "../toast/PopUpToast";
import Typography from "../text/Typography";
import { IRequestWithId } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { importValidationErrorCheck } from "../../api/Api";
import Flex from "../container/Flex";
import useImportValidationUtil from "../../hooks/useImportValidationUtil";
import Icon from "../icons/Icon";
import WithPermissions from "../permissions/WithPermissions";
import Card from "../card/Card";
import RoleBanner from "../user/RoleBanner";
import { formatDateTiggiStyle } from "../../util/formatter";
import { useImportValidationError } from "../../state/swr/importValidation/useImportValidationError";

export interface IImportValidationChipProps {
    importValidationError: IImportValidationError
}

export default function ImportValidationChip({importValidationError}: IImportValidationChipProps) {
    
    const {
        reloadImportValidationErrors
    } = useImportValidationError({ 
        addressee: importValidationError.addressee, 
        client: importValidationError.client, 
        employee: importValidationError.employee
    });

    const {
        color,
        description,
        text
    } = useImportValidationUtil(importValidationError);

    const callApi = useApi();

    if (!importValidationError) return null

    return (
        <WithPermissions permissions={[ "interfaces.datev.read" ]}>
            <ModalForm
                button={<ImportValidationErrorPill importValidationError={importValidationError} />}
                initialValues={{
                    id: importValidationError._id
                } as IRequestWithId}    
                onSubmit={async (values) => {
                    const res = await callApi(importValidationErrorCheck(values));
                    await reloadImportValidationErrors();
                    return !!res && !!res.success;
                }}
                title="Import-Fehler"
                submitText="Als Geprüft markieren"
                submitIcon="check"
                submitDisabled={importValidationError.checked}
            >
                {
                    formik => (
                        <Flex fullWidth gap={3}>
                            <Flex>
                                <Typography bold color="primary">Fehler</Typography>
                                <Typography wrap className="w-100">{description}</Typography>
                            </Flex>
                            {
                                importValidationError.checked && (
                                    <Card 
                                    color="success"
                                    header={
                                        <Flex row justify="between" fullWidth> 
                                            <Typography bold>Geprüft</Typography>
                                            <Typography bold>{formatDateTiggiStyle(importValidationError.checkedAt)}</Typography>
                                        </Flex>
                                    }
                                    >
                                        <Flex>
                                            <Typography>Der Fehler wurde geprüft von:</Typography>
                                            <RoleBanner user={importValidationError.checkedBy} displayNameAsValue showMail />
                                        </Flex>
                                    </Card>
                                )
                            }
                        </Flex>
                    )
                }
            </ModalForm>
        </WithPermissions>
    )
}

function ImportValidationErrorPill({importValidationError}: IImportValidationChipProps) {
    
    const {
        color,
        description,
        text
    } = useImportValidationUtil(importValidationError);

    return (
        <Pill color={color} className="gap-1" style={{fontSize: "0.9em"}}>
            {

                importValidationError.checked 
                ? <Icon icon="check" color="bright" />
                : <PopUpToast message={description} title="Was bedeutet das?" />
            }
            <Typography basedOnThisBackground={color} bold>{text}</Typography>
        </Pill>
    )
}