import { isReturnStatement } from "typescript";
import { ITicket, ITicketAccessHistoryItem, ITicketMessage, TicketSource, TicketState, TicketType } from "../types/ApiTypes";
import { useAppSelector } from "./reduxHooks";
import { useUser } from "../state/swr/user/useUser";

export default function useTicketUtil() {

    const { user } = useUser();
 
    const getTicketTypeName = (type: TicketType): string => {
        switch (type) {
            case TicketType.InternalTicket: return "Intern";
            case TicketType.TicketByClient: return "Von Mandant";
            case TicketType.TicketToClient: return "An Mandant";
            case TicketType.ExternalTicket: return "Extern";
        }
    }

    const getTicketTypeColor = (type: TicketType): string => {
        switch (type) {
            case TicketType.InternalTicket: return "#F5C5C5";
            case TicketType.TicketByClient: return "#595BD0";
            case TicketType.TicketToClient: return "#BADFF8";
            case TicketType.ExternalTicket: return "#FFA500";
        }
    }

    const isClosed = (ticket: ITicket) => ticket.state === TicketState.Archived || ticket.state === TicketState.Closed || ticket.state === TicketState.Solved;

    const getUnreadMessages = (ticket: ITicket): Array<ITicketMessage> => {
        const unreadMessages = ticket && ticket.messages && !!ticket.messages.length && ticket.messages.filter(m => {
            const isOwn = typeof m.sentBy === "string" ? m.sentBy === user?._id : (m.sentBy?._id === user?._id || m.sentBy?.mailAddress === user?.mailAddress);
            if (isOwn) return false;
            return m && !m.isRead;
        });

        if (!unreadMessages) return [];

        return unreadMessages;
    }

    const getTicketSourceName = (ticket: ITicket) => {
        if (!ticket) return "";
        if (!ticket.source) return "";

        switch (ticket.source) {
            case TicketSource.Android: return "Android-App";
            case TicketSource.ManualFromMail: return "Manuell aus E-Mail";
            case TicketSource.Mail: return "E-Mail";
            case TicketSource.Web: return "Website";
            case TicketSource.iOS: return "iOS-App";
        }
    }

    const getDisplayId = (ticket: ITicket) => {
        if (!ticket.friendlyId) return "";
        return `# ${ticket.friendlyId.replace("[", "").replace("]", "").replace("3T-", "")}`;
    }

    return {
        getDisplayId,
        getTicketSourceName,
        isClosed,
        getTicketTypeColor,
        getTicketTypeName,
        getUnreadMessages
    }
}
