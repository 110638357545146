import React from "react";
import { ITenant, TenantStatus } from "../../types/ApiTypes";
import { formatDate } from "../../util/formatter";
import Pill from "../pills/Pill";

export default function TenantStatusBanner({tenant}: {tenant: ITenant}) {

    if (!tenant) return <span>-</span>

    const getColorOfStatus = () => {
        switch (tenant.status) {
            case TenantStatus.AboutToBeDeleted: 
            case TenantStatus.Blocked: 
                
                return "#DF0000";

            case TenantStatus.Onboarding: return "#BEEBB7";
            case TenantStatus.Active: return "#19D17F";
            case TenantStatus.Unverified: return "#F0F0F0";
        }
    }

    const getTextForStatus = () => {
        switch (tenant.status) {
            case TenantStatus.AboutToBeDeleted: return `Wird gelöscht am ${formatDate(tenant.willBeDeletedOn)}`;
            case TenantStatus.Onboarding: return "Onboarding";
            case TenantStatus.Unverified: return "Unverifiziert";
            case TenantStatus.Blocked: return "Blockiert";
            default: return "Aktiv";
        }
    }

    return (
        <Pill color={getColorOfStatus()}>
            {
                getTextForStatus()
            }
        </Pill>
    )
}