import React from "react";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import useFilteredOfficeMails, { OfficeMailFilterQueryParam, OfficeMailShow } from "../../../hooks/useFilteredOfficeMails";

export default function OfficeInboxUnreadSwitch() {
    return (
        <TabSwitcher
            className="office-inbox-mail-list-tab-switcher"                    
            tabs={[
                {
                    data: OfficeMailShow.All,
                    label: "Alle"
                }, {
                    data: OfficeMailShow.Unread,
                    label: "Ungelesen"
                }
            ]}
            tabQueryParamKey={OfficeMailFilterQueryParam.Unread}
            size="tiny"
        />
    )
}