import useSWR from "swr";
import { ITicket, ITicketAccessHistory, ITicketActivity, ITicketAssignment, ITicketEscalationLog } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTicketClientContact = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useTiggiQuery<ITicketAssignment>(ApiRoutes.Tickets.Assignments.GetClientContact.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketClientContact: data,
        loadingTicketClientContact: isLoading,
        reloadTicketClientContact: mutate
    }
} 