import useSWR from "swr";
import { IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useMainTenantOfficeAccount = () => {
    const { data, isLoading, mutate } = useTiggiQuery<IOfficeAccount>(ApiRoutes.OfficeAccounts.Accounts.ForTenant);

    return {
        tenantOfficeAccount: data,
        loadingTenantOfficeAccount: isLoading,
        reloadTenantOfficeAccount: mutate
    }
}