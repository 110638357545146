import React from "react";
import { IsUnknownOrNonInferrable } from "@reduxjs/toolkit/dist/tsHelpers";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { isReturnStatement, reduceEachTrailingCommentRange } from "typescript";
import { UserRoleColor } from "../app/AppStyles";
import { IClient, IRole, ITenant, ITenantAuthority, ITicket, ITicketAccessHistoryItem, ITicketMessage, IUser, UserRole } from "../types/ApiTypes";
import { useAppSelector } from "./reduxHooks";
import { useUser } from "../state/swr/user/useUser";
import { useTenant } from "../state/swr/tenant/useTenant";

export interface ISortedUser {
    user: IUser,
    originalCollectionIndex: number
}

export interface ISortedUsers {
    roleName: string,
    members: ISortedUser[]
}

export default function useUserUtil() {

    const { user: currentUser } = useUser();

    const { tenant } = useTenant();

    const normalizeBoolean = (val: any, standard: boolean = false) => {
        if (val === undefined) return standard;
        if (val === null) return standard;
        if (typeof val !== "boolean") return standard;
        return val;
    }

    const normalizeUser = (user?: Partial<IUser> | null, withCurrentTenantAuthority: boolean = false): IUser => ({
        _id: user?._id || "",
        firstName: user?.firstName || "",
        phoneNumber: user?.phoneNumber || "",
        isTestingUser: user?.isTestingUser || false,
        isPhoneNumberVerified: user?.isPhoneNumberVerified || false,
        lastSeen: user?.lastSeen || new Date(),
        updatedBy: user?.updatedBy || {} as IUser,
        lastName: user?.lastName || "",
        mailNamePrefix: user?.mailNamePrefix || "",
        title: user?.title || "",
        mailAddress: user?.mailAddress || "",
        isSuperAdmin: user?.isSuperAdmin || false,
        isDeveloper: user?.isDeveloper || false,
        isBlocked: user?.isBlocked || false,
        isVerified: user?.isVerified || false,
        authority: user?.authority || [withCurrentTenantAuthority ? { tenant: tenant, isTenantAdmin: false, vacations: []}: {} as ITenantAuthority],
        settings: {
            mails: {
                receivesAnyMail: normalizeBoolean(user?.settings?.mails?.receivesAnyMail, true),
                receiveSystemNotifications: normalizeBoolean(user?.settings?.mails?.receiveSystemNotifications, true)
            },
            absence: {
                absenceNotificationMail: user?.settings?.absence?.absenceNotificationMail || "",
                assignTicketsToDeputy: normalizeBoolean(user?.settings?.absence?.assignTicketsToDeputy, true),
                sendAbsenceNotifications: normalizeBoolean(user?.settings?.absence?.sendAbsenceNotifications, false),
            }
        }
    });

    const getName = (user?: IUser | null, withMail: boolean = false) => {
        if (!user) return "-";
        
        if (!user.firstName && !user.lastName) return user.mailAddress || "-";

        if (!withMail) return [user.firstName, user.lastName].join(" ").trim();

        return [user.firstName, user.lastName, `(${user.mailAddress})`].join(" ").trim();
    }

    const getAuthority = (user?: IUser | null, matchThisTenant?: ITenant | null): {userAuthority: ITenantAuthority, userAuthorityIndex: number} | undefined => { 
        if (!user || !user.authority || !user.authority.length) return;
        if (!matchThisTenant) matchThisTenant = tenant;
        
        const authorityIndex = user.authority.findIndex(a => {
            if (!a || !a.tenant) return false;
            if (!matchThisTenant) return false;
            if (typeof a.tenant === "string") return a.tenant === matchThisTenant._id;
            else return a.tenant._id === matchThisTenant._id
        });

        if (authorityIndex < 0) return undefined;

        return {
            userAuthority: user.authority[authorityIndex],
            userAuthorityIndex: authorityIndex
        }
    }

    const getCurrentTenantAuthority = (user: IUser | null | undefined): {userAuthority: ITenantAuthority, userAuthorityIndex: number} | undefined => getAuthority(user, tenant);

    const getRole = (user?: IUser | null, matchThisTenant?: ITenant | null): IRole | undefined => { 
        const authority = getAuthority(user, matchThisTenant);
        if (!authority || !authority.userAuthority) return undefined;
        return authority.userAuthority.role;
    }

    const getCurrentTenantRole = (user?: IUser): IRole | undefined => getRole(user, tenant);
    
    const getSortedUsersElementTemplate = (role: UserRole): ISortedUsers => ({
        roleName: role,
        members: [] as ISortedUser[]
    })

    const getSortedUser = (user: IUser, index: number): ISortedUser => ({
        originalCollectionIndex: index,
        user: user
    })

    const getTenantManagers = (users: IUser[] | null, excludedUserIds?: string[], tenant?: ITenant ) => {
        if (!users || !users.length) return [];

        const filteredUsers = users.filter(u => {
            if (excludedUserIds && !!excludedUserIds.length && excludedUserIds.includes(u._id)) return false;

            const r = tenant ? getAuthority(u, tenant) : getCurrentTenantAuthority(u);

            if (!r || !r.userAuthority || !r.userAuthority.role) return false;
            return r.userAuthority.isTenantAdmin;
        })

        return filteredUsers;
    }

    const getUsersForRole = (users: IUser[] | null, roles: UserRole[], excludedUserIds?: string[], tenant?: ITenant): IUser[] => {
        if (!users || !users.length) return [];

        const filteredUsers = users.filter(u => {
            if (!u || !u._id) return false;

            if (excludedUserIds && !!excludedUserIds.length && excludedUserIds.includes(u._id)) return false;

            const r = tenant ? getAuthority(u, tenant) : getCurrentTenantAuthority(u);

            if (!r || !r.userAuthority) return false;

            if (!r.userAuthority.role) return false;

            const result = roles.find(role => {
                if (!r.userAuthority.role) return false;
                return role === r.userAuthority.role.displayName
            });

            return result && !!result.length;
        })

        return filteredUsers;
    }

    const getSortedUsers = (users: IUser[] | undefined): ISortedUsers[] => {
        const template: ISortedUsers[] = [
            getSortedUsersElementTemplate(UserRole.Partner),
            getSortedUsersElementTemplate(UserRole.TeamLead),
            getSortedUsersElementTemplate(UserRole.Clerk),
            getSortedUsersElementTemplate(UserRole.Secretary),
            getSortedUsersElementTemplate(UserRole.Client),
            {
                roleName: "Keine Rollenzuweisung",
                members: [] as ISortedUser[]
            }
        ]
        
        if (!users || !users.length) return template;

        const result = users.reduce((prev: ISortedUsers[], curr: IUser, currentIndex: number) => {
            const authority = getCurrentTenantAuthority(curr);

            if (!authority || !authority.userAuthority.role || !authority.userAuthority.role.displayName) {
                prev[prev.length - 1].members.push(getSortedUser(curr, currentIndex));
                return prev;
            }

            const index = prev.findIndex(v => v.roleName === authority.userAuthority.role?.displayName);

            if (index >= 0) prev[index].members.push(getSortedUser(curr, currentIndex));
            else prev.push({
                roleName: authority.userAuthority.role.displayName,
                members: [ getSortedUser(curr, currentIndex) ]
            });

            return prev;

        }, template);

        return result;
    }

    const canManageTenant = (user: IUser | null | undefined = currentUser): boolean => {
        if (!user) return false;
        if (user.isSuperAdmin) return true;

        const authority = getCurrentTenantAuthority(user);

        if (!authority || !authority.userAuthority) return false;

        return authority.userAuthority.isTenantAdmin;
    }

    return {
        getCurrentTenantAuthority,
        getAuthority,
        getCurrentTenantRole,
        getRole,
        normalizeUser,
        getSortedUsers,
        getUsersForRole,
        canManageTenant,
        getTenantManagers,
        getName
    }
}