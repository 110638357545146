import useSWR from "swr";
import { IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useClientContacts = () => {
    const { data, isLoading, mutate } = useSWR<Array<IUser>>(ApiRoutes.User.ClientContacts);

    return {
        clientContacts: data,
        loadingClientContacts: isLoading,
        reloadClientContacts: mutate
    }
}