import React from "react";
import { IOfficeAccount, ITicket } from "../../../types/ApiTypes";
import useApi from "../../../hooks/useApi";
import Button from "../../buttons/Button";
import { officeAccountsTransformMailToTicketMessage } from "../../../api/Api";
import { Form, Formik } from "formik";
import { IOfficeAccountAddMailAsMessageToTicketRequest } from "../../../api/ApiRequests";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import Flex from "../../container/Flex";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { Status } from "../../../config/Status";
import Card from "../../card/Card";
import TicketTypeBanner from "../../ticketing/banner/TicketTypeBanner";
import TeamBanner from "../../teams/TeamBanner";
import TicketAssigneeBanner from "../../ticketing/banner/TicketAssigneeBanner";
import Icon from "../../icons/Icon";
import LoadingSpinner from "../../loader/LoadingSpinner";
import TicketChatMessagePreview from "../../ticketing/ticketChat/TicketChatMessagePreview";
import UserRoleIndicator from "../../user/RoleBanner";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import useModal from "../../../hooks/useModal";
import { ModalType } from "../../../config/ModalTypes";
import { useAllTickets } from "../../../state/swr/tickets/useAllTickets";
import ModalForm from "../../modal/ModalForm";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import { useTicketClientContact } from "../../../state/swr/tickets/useTicketClientContact";

export interface IAddMailAsMessageToTicketButtonProps {
    account: IOfficeAccount,
    mailId?: string
}

export interface IAddMailAsMessageToTicketFormValues extends IOfficeAccountAddMailAsMessageToTicketRequest {
    ticket: ITicket | undefined
}

export default function AddMailAsMessageToTicketButton({account, mailId}: IAddMailAsMessageToTicketButtonProps) {

    const [matchingTickets, setMatchingTickets] = React.useState<ITicket[]>([]);
    const [filter, setFilter] = React.useState<string>("");

    const callApi = useApi();
    const showModal = useModal();

    const { tickets, loadingTickets } = useAllTickets();

    React.useEffect(() => {
        if (!tickets || !tickets.length) return setMatchingTickets([]);
        if (!filter) return setMatchingTickets(tickets);

        const filteredTickets = tickets.filter(t => {
            if (t.subject.toLowerCase().includes(filter.toLowerCase())) return true;
            if (t.internalSubject.toLowerCase().includes(filter.toLowerCase())) return true;
            return false;
        });

        setMatchingTickets(filteredTickets);

    }, [tickets, filter]);

    if (!account || !account._id) return null;
    if (!mailId) return null;
    if (!tickets || !tickets.length) return null;
    
    return (
        <ModalForm 
            button={<Button text="Als Nachricht an Ticket anhängen" iconPosition="end" icon="chat" variant="outline" loadingText="Lädt Tickets..." loading={loadingTickets} />}
            title="Mail als Nachricht an Ticket anhängen"
            initialValues={{
                ticketId: "",
                id: account._id,
                mailId: mailId,
                ticket: undefined
            } as IAddMailAsMessageToTicketFormValues }
            onSubmit={async (values) => {

                if (!values.ticketId) {
                    showModal({
                        text: "Bitte wählen Sie Ticket aus",
                        type: ModalType.Error
                    });
                    return false;
                }
                
                const res = await callApi(officeAccountsTransformMailToTicketMessage({
                    id: values.id,
                    mailId: values.mailId,
                    ticketId: values.ticketId
                }));

                if (!res || !res.success) return false;

                return true;
            }}
            sidebar={formik => <Button type="submit" text="Als Nachricht hinzufügen" icon="plus" loading={formik.isSubmitting} />}
        >
            {
                formik => (
                    <Form className="w-100">
                        <Flex className="w-100" gap={4}> 
                            <Flex className="w-100">
                                <strong>Suche</strong>
                                <input placeholder="Betreff oder internen Betreff durchsuchen..." onChange={(e) => setFilter(e.target.value)} value={filter} className="form-control" />
                            </Flex>
                            <Flex className="w-100">
                                <strong>Tickets</strong>
                                <Flex className="w-100" style={{maxHeight: "350px", overflowY: "auto"}}>
                                    {
                                        loadingTickets
                                        ? <LoadingSpinner /> 
                                        : (
                                            matchingTickets && matchingTickets.length
                                            ? (
                                                matchingTickets.map(t => {
                                                    const isSelected = !!formik.values.ticketId && formik.values.ticketId === t._id;

                                                    return (
                                                        <Flex key={t._id} row align="start" className="w-100">
                                                            {
                                                                <div className="d-flex flex-column check-box align-items-start p-2" >
                                                                    <div className="d-flex flex-row align-items-center" >
                                                                        <input 
                                                                            type="checkbox" 
                                                                            style={{height: "16px", width: "16px"}} 
                                                                            checked={isSelected} 
                                                                            onChange={() => {
                                                                                formik.setFieldValue("ticket", t);
                                                                                formik.setFieldValue("ticketId", t._id)
                                                                            }} 
                                                                            className="form-check-input m-0 p-0" 
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            <TicketCard isSelected={isSelected} ticket={t} />
                                                        </Flex>
                                                    )
                                                })
                                            )
                                            : <span>Keine Tickets</span>
                                        )
                                    }
                                </Flex>
                            </Flex>
                            {
                                formik.values.ticket && (
                                    <Flex className="w-100">
                                        <strong>Auswahl</strong>
                                        <TicketCard isSelected ticket={formik.values.ticket} />
                                    </Flex>
                                )
                            }
                            
                        </Flex>
                    </Form>
                )
            }
        </ModalForm>
    );
}

function TicketCard({ticket, isSelected}: {ticket: ITicket, isSelected?: boolean}) {

    const { loadingTicketAssignee, ticketAssignee } = useTicketAssignee(ticket);
    const { loadingTicketClientContact, ticketClientContact } = useTicketClientContact(ticket);

    return (
        <Card className="p-2 w-100" noPadding background={ isSelected ? "rgba(212, 175, 55, 0.2)" : "var(--backgroundLighter)"} style={{ border: `1px solid ${isSelected ? "gold" : "transparent"}`}}>
            <Flex row className="w-100" justify="between" align="start">
                <Flex>
                    <strong>{ticket.internalSubject}</strong>
                    <TicketChatMessagePreview ticket={ticket} />
                    <Flex row>
                        <TicketTypeBanner ticket={ticket} />
                        { ticket.team && <TeamBanner team={ticket.team} /> }
                        <TicketAssigneeBanner ticket={ticket} />
                        { 
                            loadingTicketAssignee 
                            ? <LoadingSpinner />
                            : ticketAssignee && ticketAssignee.user && <UserRoleIndicator user={ticketAssignee.user} displayNameAsValue />
                        }
                        { 
                            loadingTicketClientContact
                            ? <LoadingSpinner />
                            : ticketClientContact && ticketClientContact.user && <UserRoleIndicator user={ticketClientContact.user} displayNameAsValue />}
                        { 
                            loadingTicketClientContact
                            ? <LoadingSpinner />
                            : ticketClientContact && ticketClientContact.client && <ClientTypeBanner client={ticketClientContact.client} showClientName />
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}