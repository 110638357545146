import React from "react";
import DeleteAllDatevImportsButton from "../../clients/DeleteAllDatevImportsButton";
import { useTenant } from "../../../state/swr/tenant/useTenant";
import useTenantSettings from "../../../hooks/useTenantSettings";
import { Form, Formik } from "formik";
import useApi from "../../../hooks/useApi";
import { ITenantUpdateSettingsRequest } from "../../../api/ApiRequests";
import { tenantsUpdate } from "../../../api/Api";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import Button from "../../buttons/Button";
import CheckBox from "../../formik/CheckBox";
import ResponsibilitySelect from "../../clientResponsibility/ResponsibilitySelect";
import WithPermissions from "../../permissions/WithPermissions";

export default function DatevConnectorSettings() {

    const callApi = useApi();
    const { tenant, reloadTenant } = useTenant();
    const settings = useTenantSettings(true);

    if (!tenant) return null;

    return (
        <WithPermissions permissions={["interfaces.datev.update"]}>
            <Formik
                enableReinitialize
                initialValues={{
                    ...settings,
                    tenantId: tenant._id,
                } as ITenantUpdateSettingsRequest}
                onSubmit={async (values, actions) => {
                    const result = await callApi(tenantsUpdate(values));
                    if (!result) return;
                    await reloadTenant();
                }}  

            >
                {
                    formik => (
                        <Form className="w-100">
                            <Flex fullWidth>
                                <Flex fullWidth row justify="between" wrap>
                                    <h6 className="fw-bold">Datev-Importe</h6>
                                    <DeleteAllDatevImportsButton />
                                </Flex>
                                <Flex fullWidth>
                                    <Typography color="primary">Import-Einstellungen</Typography>
                                    <CheckBox name="options.interfaces.datev.overwriteUserDataOnImport" label="Nutzerdaten (MA / MK ) beim Import überschreiben anstatt ergänzen" />
                                    <CheckBox name="options.interfaces.datev.canCreateClientContactsManually" label="Mandantenkontakte können manuell erstellt werden" />
                                    <ResponsibilitySelect
                                        label="Standardverantwortlichkeit für Mandatsverantwortung"
                                        value={formik.values.options.interfaces.datev.defaultResponsibilityForClientManager}
                                        saveItem={i => formik.setFieldValue("options.interfaces.datev.defaultResponsibilityForClientManager", i._id)}
                                    />
                                    <em>Wählen Sie hier eine DATEV-Zuständigkeit aus. Nutzer mit dieser Verantwortung werden beim Import für die Mandatsverantwortung eines Mandanten verwendet.</em>
                                    <Button type="submit" text="Speichern" loading={formik.isSubmitting} icon="save" color="success" />
                                </Flex>
                            </Flex>
                        </Form>
                    )
                }
            </Formik>
        </WithPermissions>
    )
}