import React from "react";
import { AppColor } from "../../../app/AppStyles";
import { Locale } from "../../../locale/Locale";
import { ITenant } from "../../../types/ApiTypes";
import ModalForm from "../../modal/ModalForm";
import TenantUpdateForm from "./TenantUpdateForm";
import "./TenantUpdateForm.css";
import ModalDialog from "../../modal/ModalDialog";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";


export default function TenantUpdateModal({tenant}: {tenant: ITenant}) {

    if (!tenant) return null;

    return (
        <WithPermissions permissions={["tenant.own.update"]}>
            <ModalDialog 
                title={Locale.pages.tenantManagement.updateTenant} 
                button={<Button icon="pen" text={Locale.pages.tenantManagement.updateTenantButton} />}
            >
                {
                    closeForm => <TenantUpdateForm afterSubmit={closeForm} tenant={tenant} />
                }
            </ModalDialog>
        </WithPermissions>
    )
}