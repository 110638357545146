import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import { DatevStatus, IAddressee, IEmployee, IUser } from "../../types/ApiTypes";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserRoleIndicator from "../user/RoleBanner";
import UserUpdateForm from "../user/UserUpdateForm";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useRoles } from "../../state/swr/roles/useRoles";
import ManageEmployeeAbsenceButton from "./ManageEmployeeAbsenceButton";
import Flex from "../container/Flex";

export default function EmployeeListItem({employee, datevEmployee, isMissingMail = false}: {employee?: IUser, datevEmployee?: IEmployee, isMissingMail?: boolean}) {
    const {
        getName,
        getCurrentTenantAuthority
    } = useUserUtil();

    const { roles } = useRoles();

    const availableRoles = roles && !!roles.length && roles.filter(r => !r.isClient);

    if (!availableRoles) return null;

    availableRoles.sort((a, b) => a.accessLevel - b.accessLevel);

    const authority = getCurrentTenantAuthority(employee);

    const user = datevEmployee?.user || employee;
    const isDatevOnlyUser = datevEmployee && !datevEmployee.user;

    const getUserBaseFromDatevEmployee = (): Partial<IUser> | undefined => {
        if (!datevEmployee) return undefined;

        const {
            email,
            name,
        } = datevEmployee;

        const result: Partial<IUser> = {};

        if (!!email) result.mailAddress = email;
        
        if (!!name) {
            try {
                if (name.includes(",")) {
                    const [lastName, firstName] = name.split(",").map(n => n.trim());
                    result.firstName = firstName;
                    result.lastName = lastName;
                }
                else result.firstName = name;
            }
            catch {}
        }

        return result;
    }

    return (
        <TableRow>
            <TableCell>
                {
                    employee 
                    ? (
                        <div className="d-flex flex-column align-items-start">
                            <span>{getName(employee)}</span>
                            <UserRoleIndicator user={employee} />
                        </div>
                    )
                    : (
                        datevEmployee 
                        ? datevEmployee.name
                        : <span>-</span>
                    )
                }
            </TableCell>
            <TableCell >
                {
                    employee && !!employee.mailAddress
                    ? <span>{employee.mailAddress}</span>
                    : (
                        isMissingMail
                        ? <Pill color="#FF0000">Keine E-Mail hinterlegt</Pill>
                        : <span>-</span>
                    )
                }
            </TableCell>
            <TableCell>
                {
                    employee
                    ? <CheckmarkIcon falseLabel="Nicht verifiziert" trueLabel="Verifiziert" value={employee.isVerified} />
                    : (
                        datevEmployee 
                        ? <Pill color={datevEmployee.status === DatevStatus.Active ? "#F0F0F0" : "#F0F0F0"}>{datevEmployee.status === DatevStatus.Active ? "Aktiv in DATEV" : "Inaktiv"} </Pill>
                        : <span>-</span>
                    )
                }
            </TableCell>
            <TableCell>
                <Pill color={!!datevEmployee ? "#A3FFA3" : "#FFA3A3" }><span className="text-nowrap">{!!datevEmployee ? "Mit DATEV verknüpft" : "Nicht verknüpft"}</span></Pill>
            </TableCell>
            <TableCell value={!!authority && !!authority.userAuthority && !!authority.userAuthority.isTenantAdmin} labelOnFalse="Kein Admin-Zugriff" labelOnTrue="Tenant-Admin" />
            <TableCell date={employee ? employee.createdAt : (datevEmployee ? datevEmployee.entry_date : undefined )} />
            <TableCell fillAvailableSpace justify="end" >
                <Flex>
                    {
                        <ManageEmployeeAbsenceButton user={user} />
                    }
                    {
                        user
                        ? <UserUpdateForm user={user} />
                        : (
                            isDatevOnlyUser && datevEmployee && (
                                <UserUpdateForm
                                    text="In Nutzer umwandeln"
                                    title="Umwandlung in Nutzer"
                                    creationBase={getUserBaseFromDatevEmployee()} 
                                />
                            )
                        ) 
                    }
                </Flex>
            </TableCell>
        </TableRow>
    )
}