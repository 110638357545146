import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { IMailLog, MailRejectReason, MailSource } from "../../types/ApiTypes";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import LabelButton from "../buttons/LabelButton";
import MailLogTypeBanner from "./MailLogTypeBanner";
import Pill from "../pills/Pill";
import MailLogSourceBanner from "./MailLogSourceBanner";
import Icon from "../icons/Icon";
import { AppColor } from "../../app/AppStyles";
import MailRejectReasonBanner from "./MailRejectReasonBanner";
import { formatDate } from "../../util/formatter";
import MailLogCreatedByBanner from "./MailLogCreatedByBanner";
import Flex from "../container/Flex";
import TicketLink from "../ticketing/TicketLink";
import { useTenant } from "../../state/swr/tenant/useTenant";

export default function MailLogOverviewItem({logEntry, onlyActiveTenant}: {logEntry: IMailLog, onlyActiveTenant?: boolean}) {
    
    const [contentVisible, setContentVisible] = React.useState<boolean>(false);
    const [additionalDataVisible, setAdditionalDataVisible] = React.useState<boolean>(false);

    const { tenant } = useTenant();


    if (!tenant) return null;
    if (onlyActiveTenant && logEntry.tenant._id !== tenant._id) return null;

    return (
        <TableRow>
             <TableCell>
                <MailLogTypeBanner type={logEntry.type} />
            </TableCell>
            <TableCell date={logEntry.sentAt || logEntry.createdAt} showTime />
            <TableCell>
                <div className="d-flex flex-column gap-1">
                    {
                        logEntry.fromName && <strong>{logEntry.fromName}</strong>
                    }
                    <div style={{maxWidth: "150px", overflowX: "auto"}}>
                        <span>{logEntry.fromMail}</span>
                    </div>                    
                </div>
            </TableCell>
            <TableCell content={logEntry.toMail} />
            <TableCell content={logEntry.subject} />
            <TableCell>
                {
                    logEntry.content
                    ? (
                        <>
                            {
                                contentVisible && <p>{logEntry.content}</p>
                            }
                            <LabelButton onClick={async () => setContentVisible(!contentVisible)} text={contentVisible ? "Nachricht ausblenden" : "Inhalt anzeigen"} />
                        </>
                    )
                    : <span>-</span>
                }
            </TableCell>
            <TableCell>
                {
                    logEntry.ticket 
                    ? <TicketLink ticket={logEntry.ticket} />
                    : <span>-</span>
                }
            </TableCell>
            <TableCell>
                <MailLogSourceBanner source={logEntry.source} />
            </TableCell>
            <TableCell>
                <div>
                    {
                        !logEntry.rejected
                        ? <Icon color="success" icon="check" />
                        : <Pill color="#FF0000">Abgelehnt</Pill>
                    }
                </div>
            </TableCell>
            <TableCell>
                {
                    logEntry.rejected 
                    ? <MailRejectReasonBanner reason={logEntry.rejectReason} /> 
                    : "-"
                }
            </TableCell>
            <TableCell>
                <div className="d-flex flex-column align-items-start justify-content-start gap-2">
                    {
                        additionalDataVisible && logEntry.rejectMessage && (
                            <Flex>
                                <strong>Abgelehnt, weil</strong>
                                <span>{logEntry.rejectMessage}</span>
                            </Flex>
                        )
                    }
                    {
                        additionalDataVisible && logEntry.sourceId && (
                            <div className="d-flex flex-column">
                                <strong>Quell-ID</strong>
                                <span>{logEntry.sourceId}</span>
                            </div>
                        )
                    }
                    {
                        additionalDataVisible && logEntry.eventId && (
                            <div className="d-flex flex-column">
                                <strong>Event-ID</strong>
                                <span>{logEntry.eventId}</span>
                            </div>
                        )
                    }
                    {
                        additionalDataVisible && logEntry.rejected && logEntry.rejectReason === MailRejectReason.ExistsInLog && logEntry.alreadyProcessedItem && (
                            <div className="d-flex flex-column">
                                <strong>Verweis auf Log-Eintrag</strong>
                                <span>{logEntry.alreadyProcessedItem._id}</span>
                            </div>
                        )
                    }
                    {
                        additionalDataVisible && logEntry.additonalData && (
                            <>
                                {
                                    logEntry.additonalData.runningNotifierCount !== undefined && (
                                        <div className="d-flex flex-column">
                                            <strong>Aktive Notifier</strong>
                                            <span>{logEntry.additonalData.runningNotifierCount}</span>
                                        </div>
                                    )
                                }
                                {
                                    logEntry.additonalData.runningNotifiers && !!logEntry.additonalData.runningNotifiers.length && (
                                        <div className="d-flex flex-column">
                                            <strong>Liste der aktiven Notifier</strong>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            ID
                                                        </th>
                                                        <th>
                                                            Account-ID
                                                        </th>
                                                        <th>
                                                            Erstellt am
                                                        </th>
                                                        <th>
                                                            Erstellt von
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        logEntry.additonalData.runningNotifiers.map(l => (
                                                            <tr>
                                                                <td>{l.notifierId}</td>
                                                                <td>{l.accountId}</td>
                                                                <td>{formatDate(l.createdOn, true)}</td>
                                                                <td><MailLogCreatedByBanner creator={l.createdBy} /></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }
                                {
                                    logEntry.additonalData.queueLength !== undefined && (
                                        <div className="d-flex flex-column">
                                            <strong>Länge der Verarbeitungswarteschlange</strong>
                                            <span>{logEntry.additonalData.queueLength}</span>
                                        </div>
                                    )
                                }
                                {
                                    logEntry.additonalData.sourceCreatedOn !== undefined && (
                                        <div className="d-flex flex-column">
                                            <strong>Notifier erstellt am</strong>
                                            <span>{formatDate(logEntry.additonalData.sourceCreatedOn, true)}</span>
                                        </div>
                                    )
                                }
                                {
                                    logEntry.additonalData.sourceCreator !== undefined && (
                                        <div className="d-flex flex-column">
                                            <strong>Notifier erstellt durch</strong>
                                            <MailLogCreatedByBanner creator={logEntry.additonalData.sourceCreator} />
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                    <LabelButton onClick={async () => setAdditionalDataVisible(!additionalDataVisible)} text={additionalDataVisible ? "Ausblenden" : "Weitere Infos anzeigen"} />
                </div>
            </TableCell>
        </TableRow>
    )
}