import React from "react";
import useClientUtil from "../../hooks/useClientUtil";
import { IClient, IUser } from "../../types/ApiTypes";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserRoleIndicator from "../user/RoleBanner";
import ClientAddresseeItem from "./ClientAddresseeItem";
import ClientTypeBanner from "./ClientTypeBanner";
import ClientDocumentClearanceReminderForm from "./ClientDocumentClearanceReminderForm";
import { useAppSelector } from "../../hooks/reduxHooks";
import ClientEmployeeResponsibilityList from "./ClientEmployeeResponsibilityList";
import UpdateGeneralClientManagerForm from "./UpdateClientForm";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import { useAnyClientAlias } from "../../state/swr/clients/useAnyClientAlias";
import LoadingSpinner from "../loader/LoadingSpinner";

export default function ClientListItem({client}: {client: IClient}) {

    const { clientAlias, loadingClientAlias, reloadClientAlias } = useAnyClientAlias(client._id);

    const {
        getClientName
    } = useClientUtil();

    return (
        <TableRow>
            <TableCell>
                {
                    client.number 
                    ? <strong>{client.number}</strong>
                    : <span>-</span>
                }
            </TableCell>
            <TableCell className="d-flex flex-row gap-1">
                <div className="d-flex flex-column">
                    <span>{getClientName(client)}</span>
                </div>
            </TableCell>
            <TableCell>
                <ClientTypeBanner client={client} />
            </TableCell>
            <TableCell>
                <div className="d-flex flex-row gap-3">
                    <ClientAddresseeItem addressee={client.naturalPerson} client={client} />
                    <ClientAddresseeItem addressee={client.legalPerson} client={client} />
                </div>
            </TableCell>
            <TableCell>
                {
                    client.clientManager 
                    ? <UserRoleIndicator user={client.clientManager} displayNameAsValue />
                    : <span>-</span>
                }
            </TableCell>
            <TableCell>
                <ClientEmployeeResponsibilityList client={client} />
            </TableCell>
            <TableCell>
                <Flex gap={1}>
                    {
                        loadingClientAlias 
                        ? <LoadingSpinner />
                        : (
                            clientAlias && clientAlias.length
                            ? clientAlias.map(a => (
                                <Typography>{a.aliasMailAddress}</Typography>
                            ))
                            : <span>-</span>
                        )
                    }
                </Flex>
            </TableCell>
            <TableCell date={client.createdAt} />
            <TableCell>
                <div className="d-flex flex-row flex-wrap gap-2">
                    <ClientDocumentClearanceReminderForm client={client} />
                    <UpdateGeneralClientManagerForm client={client} />
                </div>
            </TableCell>
        </TableRow>
    )
}