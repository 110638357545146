import React from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import { useAppSelector } from "../../hooks/reduxHooks";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import AddresseeList from "../../components/addressee/AddresseeList";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../state/swr/user/useUser";

export default function AddresseeManagement() {
    const { addressees } = useAddressees();

    const { role } = useCurrentTenantRole();
    const { user } = useUser();

    const navigate = useNavigate();
    const showModal = useModal();

    const {
        canManageTenant
    } = useUserUtil();

    React.useEffect(() => {
        const isTenantAdmin = canManageTenant(user);

        if (isTenantAdmin) return;

        if (!role || role.isClient) {
            showModal({text: "Zugriff verweigert", type: ModalType.Error})
            navigate(AppRoutes.Home.path);
        }
    }, [user, role]);

    const getTitle = () => {
        if (!addressees || !addressees.length) return Locale.titles.addresseeManagement;

        if (addressees.length === 1) return `1 ${Locale.titles.addresseeManagement.substring(0, Locale.titles.addresseeManagement.length - 2)}`;

        return `${addressees.length} ${Locale.titles.addresseeManagement}`;
    }

    return (
        <Page header={getTitle()}>
            <AddresseeList />
        </Page>
    )
}