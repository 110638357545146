import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { tanStackFetch } from "../api/Api";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: tanStackFetch,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5
        }
    }
});

export default function QueryProvider({children}: {children: any}) {
    return (
        <QueryClientProvider client={queryClient}>
            {
                children
            }
        </QueryClientProvider>
    )
}
