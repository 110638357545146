import useSWR from "swr";
import { IAlias, IClient, IUserClients, IUserProfilePicture } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useAnyClientAlias = (id: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IAlias>>(ApiRoutes.Clients.Alias.getValue({ param: RouteParam.ClientId, value: id}));

    return {
        clientAlias: data,
        loadingClientAlias: isLoading,
        reloadClientAlias: mutate
    }
}