import React from "react";
import { ITicket, TicketEscalationLogStatus } from "../../../types/ApiTypes";
import { useTicketEscalationLog } from "../../../state/swr/tickets/useTicketEscalationLog";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Card from "../../card/Card";
import { formatDateGmailStyle, formatDuration, formatTime } from "../../../util/formatter";
import TicketEscalationActionChip from "../../escalation/TicketEscalationActionChip";
import { useTenantOpenState } from "../../../state/swr/tenant/useTenantOpenState";
import SidebarHeader from "../../sidebar/SidebarHeader";
import Typography from "../../text/Typography";
import EscalateTicketManuallyButton from "../../escalation/EscalateTicketManuallyButton";
import TicketEscalationLog from "../../escalation/TicketEscalationLog";

export default function TicketEscalationSidebar({ticket}: {ticket: ITicket}) {
    const { isOpen, loadingIsOpen, reloadIsOpen } = useTenantOpenState();

    return (
        <Flex gap={3} className="w-100">
            <SidebarHeader text="Eskalationen" />
            {
                loadingIsOpen
                ? <LoadingSpinner />
                : !isOpen && <Typography size={12} bold color="error">Die Kanzlei ist gerade geschlossen</Typography>
            }
            <Flex row fullWidth justify="between" wrap>
                <Flex row fullWidth justify="between" wrap gap={0}>
                    <Typography color="primary" bold>Zeit ab Eröffnung</Typography>
                    <span>{formatDuration(ticket.minutesOpen)}</span>
                </Flex>
                <Flex row fullWidth justify="between" wrap gap={0}>
                    <Typography color="primary" bold>Zeit zur ersten Nachricht (aktueller Bearbeiter)</Typography>
                    <span>{formatDuration(ticket.minutesWithoutMessageFromCurrentAssignee)}</span>
                </Flex>
            </Flex>
            <TicketEscalationLog ticket={ticket} />
        </Flex>
    )
}
