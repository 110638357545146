import useSWR from "swr";
import { ITicket, ITicketAccessHistory, ITicketActivity, ITicketEscalationLog } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useTicketEscalationLog = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useSWR<Array<ITicketEscalationLog>>(ApiRoutes.Tickets.EscalationWorkflow.Log.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketEscalationLog: data,
        loadingTicketEscalationLog: isLoading,
        reloadTicketEscalationLog: mutate
    }
} 