import useSWR from "swr";
import { IPointChampApiKey, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useTenantOpenState = () => {
    const { data, isLoading, mutate } = useSWR(ApiRoutes.Tenant.IsTenantOpenRightNow);

    return {
        isOpen: data,
        loadingIsOpen: isLoading,
        reloadIsOpen: mutate
    }
}