import useSWR from "swr";
import { IMailLog, ITicket, ITicketActivity } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ITicketFilter } from "../../../hooks/useFilteredTickets";
import useFilterQuery from "../../../hooks/useFilterQuery";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTicketsWithFilter = (filter: ITicketFilter) => {

    const {
        getUrlWithFilter
    } = useFilterQuery();

    const { data, isLoading, mutate } = useTiggiQuery<Array<ITicket>>(getUrlWithFilter(ApiRoutes.Tickets.Filtered, filter));

    return {
        tickets: data,
        loadingTickets: isLoading,
        validatingTickets: isLoading,
        reloadTickets: mutate
    }
}