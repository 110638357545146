import { Form, Formik, setIn } from "formik";
import React, { useState } from "react";
import { ticketsCreate } from "../../api/Api";
import { ITicketCreationRequest, ITicketMessageAddAttachment } from "../../api/ApiRequests";
import { AppColor } from "../../app/AppStyles";
import { ModalType } from "../../config/ModalTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import useModal from "../../hooks/useModal";
import useTicketUtil from "../../hooks/useTicketUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { IClient, IUploadableFile, IUser, TicketSource, TicketType } from "../../types/ApiTypes";
import Button, { ButtonVariant } from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import CheckBox from "../formik/CheckBox";
import FieldWithLabel from "../formik/FormikField";
import ModalForm from "../modal/ModalForm";
import UserRoleIndicator from "../user/RoleBanner";
import "./CreateTicketModal.css";
import TicketTypeRadioGroup from "./TicketTypeRadioGroup";
import UserSearchableComboBox from "../comboBox/UserSearchableComboBox";
import useClientUtil from "../../hooks/useClientUtil";
import Flex from "../container/Flex";
import DOMPurify from "dompurify";
import TicketChatHtmlMessageContent from "./ticketChat/TicketChatHtmlMessageContent";
import ClientSelect from "../clients/ClientSelect";
import ModalDialog from "../modal/ModalDialog";
import Icon from "../icons/Icon";
import Dialog from "../modal/Dialog";
import TicketChatAttachmentsField from "./ticketChat/TicketChatAttachmentsField";
import FileSelectButton from "../buttons/FileSelectButton";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useClientContacts } from "../../state/swr/clientContacts/useClientContacts";
import { useEmployees } from "../../state/swr/employees/useEmployees";
import { useAllTickets } from "../../state/swr/tickets/useAllTickets";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from "../richText/Editor";
import { useUserClients } from "../../state/swr/user/useUserClients";
import Typography from "../text/Typography";
import useFilteredTickets from "../../hooks/useFilteredTickets";
import { useTicketsWithFilter } from "../../state/swr/tickets/useTicketsWithFilter";

export interface ICreateTicketFormProps {
    title?: string,
    variant?: ButtonVariant,
    buttonText?: string,
    initialSubject?: string,
    initialMessage?: string,
    initialMailAddress?: string,
    createFromMailId?: string,
    createFromMailAccountId?: string,
    firstMessageIsFromClient?: boolean,
    afterCreate?: () => Promise<void>
}

export default function CreateTicketModal({title, afterCreate, createFromMailId, variant = "icon", buttonText = "Neues Ticket erstellen", createFromMailAccountId, initialMailAddress, firstMessageIsFromClient = false, initialMessage, initialSubject}: ICreateTicketFormProps) {

    const [renderHtml, setRenderHtml] = useState<boolean>(true);
    const [initialClientContact, setInitialClientContact] = React.useState<IUser>();
    const [initialType, setInitialType] = React.useState<TicketType>();

    const { reloadTickets } = useAllTickets();
    const { getFilterForRoute } = useFilteredTickets();
    const {
        reloadTickets: reloadFilteredTickets
    } = useTicketsWithFilter(getFilterForRoute());

    const { user } = useUser();
    const { role } = useCurrentTenantRole();
    const { clientContacts } = useClientContacts();
    const { employees } = useEmployees();

    const { currentUserClients, loadingCurrentUserClients } = useUserClients();

    const isNotClient = (role && !role.isClient) || (user?.isSuperAdmin);

    const dispatch = useAppDispatch();
    const showModal = useModal();
    const callApi = useApi();

    const updateInitialClientContact = (val: IUser | undefined) => {
        setInitialClientContact(val);
        setInitialType(val ? TicketType.TicketByClient : TicketType.ExternalTicket);
    }
    
    React.useEffect(() => {
        if (!createFromMailId) return;

        if (!initialMailAddress) return updateInitialClientContact(undefined);
        if (!clientContacts || !clientContacts.length) return updateInitialClientContact(undefined);

        const clientContact = clientContacts.find(c => c.mailAddress === initialMailAddress);

        if (!clientContact) return updateInitialClientContact(undefined);
        updateInitialClientContact(clientContact);
    }, [initialMailAddress, clientContacts]);
    
    const getTicketCreatedMessage = () => {
        if (role && role.isClient) return "Ticket erfolgreich angelegt. Wir werden uns in Kürze bei Ihnen melden!";
        return "Ticket erfolgreich angelegt.";
    }

    return (
        <ModalForm
            title={title || "Neues Ticket eröffnen"} 
            sidebar={(formik, close) => <Button loading={formik.isSubmitting} disabled={!formik.dirty} loadingText="Bitte warten..." type="submit" text="Ticket erstellen" icon="plus" color="success" />}
            enableReinitialize
            initialValues={{
                subject: initialSubject || "",
                isDeveloperTicket: false,
                firstMessage: initialMessage || "",
                clientMail: initialMailAddress || "",
                createFromMailAccountId: createFromMailId ? createFromMailAccountId : undefined,
                createFromMailId: createFromMailId || "",
                firstMessageIsFromClient: firstMessageIsFromClient,
                source: createFromMailId ? TicketSource.ManualFromMail : TicketSource.Web,
                firstNote: "",
                type: initialType !== undefined ? initialType : TicketType.TicketByClient,
                attachments: [],
                sendMail: true,
                assignee: undefined,
                clientContactId: !isNotClient ? user?._id : (initialClientContact && initialClientContact._id) || undefined,
                clientId: currentUserClients ? ( currentUserClients.defaultClient ? currentUserClients.defaultClient._id : (currentUserClients.clients && currentUserClients.clients.length ? currentUserClients.clients[0]._id : undefined )) : undefined,
            } as ITicketCreationRequest}
            onSubmit={async (values) => {
                
                if (role && !role.isClient) {

                    if (!values.clientMail && !values.clientContactId && values.type === TicketType.ExternalTicket) {
                        showModal({
                            text: "Bitte geben Sie eine Mailadresse ein.",
                            type: ModalType.Error
                        });
    
                        return false;
                    }
    
                    if (values.type !== TicketType.ExternalTicket && !values.clientContactId) {    
                        showModal({
                            text: "Bitte wählen Sie einen Mandantenkontakt aus.",
                            type: ModalType.Error
                        });
    
                        return false;
                    }
                }

                const result = await callApi(ticketsCreate(values));
                
                if (!result || !result.success) return false;

                reloadTickets(prev => [...(prev || []), result.data]);
                reloadFilteredTickets(prev => [...(prev || []), result.data]);

                if (afterCreate) await afterCreate();

                showModal({
                    text: getTicketCreatedMessage(),
                    type: ModalType.Success
                });

                return true;
            }}
            button={
                <Button variant={variant} iconPosition="end" icon="plus-circle-fill" iconSize={variant === "icon" ? 30 : undefined}>{variant === "icon" ? "" : buttonText}</Button>
            }
        >
            {
                (formik) => {
                    const switchClientContact = (c: IUser | undefined) => {
                        if (!c) return;
                        formik.setFieldValue("clientContactId", c._id);
                        formik.setFieldValue("clientMail", c ? c.mailAddress : "");
                    }

                    const isInternalTicket = formik.values.type === TicketType.InternalTicket;
                    const isExternalTicket = formik.values.type === TicketType.ExternalTicket;

                    const usableUsers = isInternalTicket ? employees : clientContacts;

                    const canAssignDataToTicket = (user && ((user.isSuperAdmin && !role) || (role && !role.isClient)));

                    const handleNewAttachments = async (files: FileList) => {
                        try {
                            const fileReads: Array<Promise<ITicketMessageAddAttachment>> = [];

                            for (const file of files) {
                                if (!file) continue;
                                if (!file.type) continue;

                                fileReads.push(new Promise((resolve, reject) => {
                                    
                                    const reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    reader.onloadend = () => {
                                        const result = reader.result;
                                        if (!result) return reject();

                                        const stringValue = typeof result === "string" ? result : result.toString();
                                        const rawContent = stringValue.split(',')[1];

                                        resolve({
                                            type: file.type,
                                            content: rawContent,
                                            name: file.name,
                                            size: file.size
                                        });
                                    };
                                }));
                            }

                            const newAttachments = await Promise.all(fileReads);

                            if (!newAttachments || !newAttachments.length) return;

                            const totalAttachments = [...formik.values.attachments, ...newAttachments];
                            const totalSize = totalAttachments.reduce((prev, curr) => prev += curr.size, 0);

                            if (totalSize > 1000 * 1000 * 50) {
                                showModal({
                                    text: "Sie können nur maximal 50 MB an diese Nachricht anhängen.",
                                    type: ModalType.Error
                                });

                                return;
                            }

                            formik.setFieldValue("attachments", totalAttachments);

                        }
                        catch (err) {
                            console.log(err);
                        }
                    }

                    return (
                        <Flex className="w-100" gap={3}>

                            {
                                isNotClient && (
                                    <div className="d-flex flex-column justify-content-start gap-2">
                                        <span>Typ</span>
                                        <TicketTypeRadioGroup value={formik.values.type} saveValue={t => formik.setFieldValue("type", t)} />
                                    </div>
                                )
                            }
                            <FieldWithLabel noMargin label="Betreff" className="w-100" name="subject" placeholder="Einkommensteuer, Abschluss, Sonderprüfung..."/>
                            {
                                (isExternalTicket && isNotClient)
                                ? <FieldWithLabel name="clientMail" label="Mailadresse des externen Empfängers" type="email" className="w-100" placeholder="Geben Sie hier die Mail des Ticketempfängers ein..."/>
                                : canAssignDataToTicket && (
                                    <UserSearchableComboBox 
                                        users={usableUsers}
                                        onItemClick={switchClientContact}
                                        label={isInternalTicket ? "Mitarbeiter zuweisen" : "Mandantenkontakt"}
                                        value={formik.values.clientContactId}
                                    />
                                )
                            }
                            {
                                !isInternalTicket && !isExternalTicket && (
                                    <ClientSelect
                                        saveClient={c => formik.setFieldValue("clientId", c)}
                                        clientId={formik.values.clientId}
                                        clientContactId={formik.values.clientContactId}
                                    />
                                )
                            }
                            {
                                (isNotClient && !isInternalTicket && canAssignDataToTicket) && (
                                    <UserSearchableComboBox 
                                        users={employees}
                                        onItemClick={(u) => formik.setFieldValue("assignee", u)}
                                        label="Optional: Mitarbeiter (Bearbeiter)"
                                        placeholder="Mitarbeiter auswählen..."
                                        value={formik.values.assignee}
                                    />
                                )
                            }
                            <Flex className="w-100">
                                <Typography>Optional: Erste Nachricht</Typography>
                                {
                                    (!renderHtml || !initialMessage) || !formik.values.firstMessage
                                    ? (
                                        <Editor
                                            useFieldStyle
                                            className="w-100"
                                            onChange={val => formik.setFieldValue("firstMessage", val)}
                                            content={formik.values.firstMessage}
                                            onBlur={() => setRenderHtml(true)}
                                            onFocus={() => setRenderHtml(false)}
                                            disabled={formik.isSubmitting}
                                        />
                                    )
                                    : <TicketChatHtmlMessageContent isEditForm={false} isPreview message={formik.values.firstMessage} onClick={() => setRenderHtml(false)} className="create-ticket-form-first-message-field"/>
                                }
                                {
                                    !createFromMailId && <TicketChatAttachmentsField name="attachments" />
                                }
                                <Flex align="end" justify="end" className="w-100" >
                                    <FileSelectButton disabled={!!createFromMailId} accept="*" text={createFromMailId ? "Anhänge werden aus Mail übernommen" : "Anhang hinzufügen"} handleSelectedFiles={handleNewAttachments} icon="paperclip" />
                                </Flex>
                                {
                                    isNotClient && <CheckBox name="firstMessageIsFromClient" label="Erste Nachricht ist vom Mandanten" />
                                }
                            </Flex>
                            {
                                isNotClient && <FieldWithLabel noMargin as="textarea" label="Optional: Interne Notiz" className="w-100" inputClass="create-ticket-form-first-notes-field" name="firstNote" placeholder="Geben Sie die erste Nachricht ein" />
                            }
                            {
                                isNotClient && (
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <CheckBox tooltip="Sendet eine Mail über das eröffnete Ticket an den Mandanten" name="sendMail" label={isInternalTicket ? "Mail an Mitarbeiter senden" : "Mail an Mandant senden" } />
                                    </div>
                                )
                            }
                            {
                                user && user.isSuperAdmin && <CheckBox name="isDeveloperTicket" label="Entwickler-Ticket" />
                            }
                        </Flex>    
                    )
                }
            }
        </ModalForm>
    )
}