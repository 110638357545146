import React from "react";
import RequestPasswordResetByMailForm from "../../components/user/RequestPasswordResetByMailForm";
import Page from "../../layout/Page";
import Logo from "../../components/logo/Logo";
import Flex from "../../components/container/Flex";
import Typography from "../../components/text/Typography";

export default function RequestPasswordReset() {
    return (
        <Page fullscreen centered colored>
            <Flex gap={3}>
                <Logo />
                <Typography basedOnThisBackground="primary" size={24} bold >Passwort-Reset anfrangen</Typography>
                <RequestPasswordResetByMailForm />
            </Flex>
        </Page>
    )
}