import React from "react";
import Flex from "../container/Flex";
import "./SideNavElement.css"
import Icon from "../icons/Icon";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";

export interface ISideNavElementProps {
    icon?: string,
    label?: string,
    isInsideGroup?: boolean
}

export default function SideNavElement({icon, label, isInsideGroup}: ISideNavElementProps) {

    const elementClass = generateClassName("p-2 sidenav-element", {
        value: isInsideGroup,
        onTrue: "sidenav-element-inside-group"
    });

    const containerClass = generateClassName("sidenav-element-icon-container", {
        value: isInsideGroup,
        onTrue: "sidenav-element-icon-container-inside-group"
    })

    return (
        <Flex row className={elementClass}>
            <Flex className={containerClass} align="center" >
                { 
                    icon && <Icon icon={icon} size={isInsideGroup ? 22 : 27} className="sidenav-element-icon" />       
                }
            </Flex>
            {
                label && (
                    <span 
                        className="sidenav-element-label" 
                        style={generateStyle({
                            name: "fontSize",
                            value: isInsideGroup ? 14 : 18,
                            unit: "px"
                        })}
                    >
                        {label}
                    </span>
                )
            }
        </Flex>
    )

}