import React from "react";
import { MailFolder, MailSearchFolder, Message } from "@microsoft/microsoft-graph-types";
import Flex from "../container/Flex";
import Card from "../card/Card";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { formatDate, formatDateGmailStyle, formatOfficeMailAddress, formatOfficeMailAddressByRecipient } from "../../util/formatter";
import "./OfficeInboxItem.css";
import useApi from "../../hooks/useApi";
import { IOfficeAccount } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Icon from "../icons/Icon";
import { officeAccountsUpdateMail } from "../../api/Api";
import MarkMailAsReadButton from "./mailItem/MarkMailAsReadButton";
import DeleteMailButton from "./mailItem/DeleteMailButton";
import { useOfficeMailRepliedToStatus } from "../../state/swr/office/useOfficeMailRepliedToStatus";
import { hexWithOpacity } from "../../util/color";
import useInboxPermissions from "../../hooks/useInboxPermissions";
import WithPermissions from "../permissions/WithPermissions";

export interface IOfficeInboxItemProps {
    item: Message,
    isActive: boolean,
    folderId?: string,
    account: IOfficeAccount,
    onClick: (i: Message) => void,
    afterMarkUnread: () => void,
    afterDelete: (i: Message) => void
}

export default function OfficeInboxItem({item, afterDelete, folderId, afterMarkUnread, isActive, account, onClick}: IOfficeInboxItemProps) {

    const tooltipTimer = React.useRef<any>();
    const mailItemRef = React.useRef<HTMLDivElement>(null);

    const [tooltipOrigin, setTooltipOrigin] = React.useState<DOMRect>();
    const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);
    const [hover, setHover] = React.useState<boolean>(false);
    const [isRead, setIsRead] = React.useState<boolean>(!!item.isRead);
    const [isChangingReadStatus, setIsChangingReadStatus] = React.useState<boolean>(false);
    
    const { officeMailRepliedToStatus } = useOfficeMailRepliedToStatus(account._id, item.id?.toString() || "");

    const readTimeout = React.useRef<any>();

    React.useEffect(() => {
        if (!readTimeout.current) return;
        if (isRead) return;
        if (!isActive) {
            setIsChangingReadStatus(false);
            clearTimeout(readTimeout.current);
        }
    }, [isActive]);

    const clickHandler = async () => {
        onClick(item);
        if (isRead) return;
        setIsChangingReadStatus(true);
        readTimeout.current = setTimeout(async () => {
            if (!item.id) return;
            const res = await officeAccountsUpdateMail({id: account._id, messageId: item.id.toString(), isRead: true});
            setIsChangingReadStatus(false);
            if (!res || !res.success) return;
            setIsRead(true);
        }, 500);
    }

    const hoverHandler = (show: boolean = false) => {
        setHover(show);
        clearTimeout(tooltipTimer.current);

        if (!show) {
            setTooltipVisible(false);
            return;
        }

        tooltipTimer.current = setTimeout(() => {
            setTooltipVisible(true);
        }, 500);
    }

    const backgroundClass = generateClassName("mail-item-background", {
        value: isActive,
        onTrue: "mail-item-background-active"
    }, {
        value: isRead,
        onTrue: "mail-item-background-read"
    });

    const senderClass = generateClassName("text-truncate w-100", {
        value: !isRead,
        onTrue: "fw-bold"
    })

    const itemClass = generateClassName("mail-item w-100 position-relative", {
        value: isRead,
        onTrue: "mail-item-read",
        standard: "mail-item-unread"
    }, {
        value: isActive,
        onTrue: "mail-item-active"
    });

    return (
        <div 
            ref={mailItemRef}
            className="mail-item-container position-relative" 
            style={generateStyle({name: "backgroundColor", value: hexWithOpacity("muted", isActive ? 0.8 : 0.3, true), applyCondition: !isRead || isActive})} 
            onMouseEnter={() => hoverHandler(true)} 
            onMouseOver={() => hoverHandler(true)}
            onMouseLeave={() => hoverHandler(false)} 
        >
            <Flex onClick={clickHandler} className={itemClass}>
                <div className={backgroundClass} />
                <Flex gap={0} className="ps-1 pe-1 w-100 pt-1 pb-1 mail-item-content position-relative">
                    <Flex row justify="between" className="w-100">
                        <Flex row gap={1} className="w-75">
                            {
                                !isRead && (
                                    isChangingReadStatus
                                    ? <LoadingSpinner size={8} />
                                    : <div style={{height: "5px", width: "5px", borderRadius: "50px", backgroundColor: "var(--primary)"}} />
                                )
                            }
                            <strong className={senderClass}>{formatOfficeMailAddressByRecipient(item.from)}</strong>
                        </Flex>
                        {
                            hover 
                            ? (
                                <Flex row align="bottom" >
                                    <MarkMailAsReadButton mail={item} account={account} isRead={isRead} folderId={folderId} saveIsRead={(val: boolean) => { setIsRead(val); if (!val) afterMarkUnread(); }} />
                                    <DeleteMailButton mail={item} account={account} afterDelete={() => afterDelete(item)} />
                                </Flex>
                            )
                            : (
                                <Flex row gap={1}>
                                    {
                                        officeMailRepliedToStatus && officeMailRepliedToStatus.hasBeenRepliedTo && <Icon icon="arrow-left" color="primary" />
                                    }
                                    {
                                        item.hasAttachments && <Icon icon="paperclip" size={18} />
                                    }
                                    <span className="text-nowrap w-25 text-end" style={{fontSize: "12px"}}>{formatDateGmailStyle(item.sentDateTime)}</span>
                                </Flex>
                            )
                        }
                    </Flex>
                    <span className="text-truncate w-100">{item.subject || "<Kein Betreff>"}</span>
                    {
                        item.bodyPreview
                        ? <span className={generateClassName("w-100 mail-item-preview", { value: tooltipVisible, standard: "text-truncate" })} style={{fontSize: "12px"}}>{item.bodyPreview}</span>
                        : <em className="text-truncate w-100 mail-item-preview">Kein Inhalt</em>
                    }
                    
                </Flex>
            </Flex>
        </div>
    )
}