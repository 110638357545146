import useSWR from "swr";
import { ITicket, ITicketAccessHistory, ITicketActivity, ITicketAssignment, ITicketEscalationLog } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import useTiggiQuery from "../../../hooks/useTiggiQuery";


export const useTicketAssignee = (ticket: ITicket) => {

    const { data, isLoading, mutate } = useTiggiQuery<ITicketAssignment>(ApiRoutes.Tickets.Assignments.GetMainAssignment.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketAssignee: data,
        loadingTicketAssignee: isLoading,
        reloadTicketAssignee: mutate
    }
} 

// export const useTicketAssignee = (ticket: ITicket) => {
//     const { data, isLoading, mutate } = useSWR<ITicketAssignment>(ApiRoutes.Tickets.Assignments.GetMainAssignment.getValue({
//         param: RouteParam.TicketId,
//         value: ticket._id
//     }), { revalidateOnFocus: false, revalidateIfStale: false });

//     return {
//         ticketAssignee: data,
//         loadingTicketAssignee: isLoading,
//         reloadTicketAssignee: mutate
//     }
// } 