import React from "react";
import Flex from "../container/Flex";
import { useNotifications } from "../../state/swr/user/useNotifications";
import LoadingSpinner from "../loader/LoadingSpinner";
import TicketActivityItem from "../ticketing/TicketActivityItem";
import InfiniteScroll from "../infiniteScroll/InfiniteScroll";
import CreateTicketModal from "../ticketing/CreateTicketModal";
import Icon from "../icons/Icon";
import SearchBox from "../search/SearchBox";
import useFilteredNotifications from "../../hooks/useFilteredNotifications";
import Typography from "../text/Typography";
import SortTicketsButton from "../ticketing/filter/SortTicketsButton";
import SortNotificationsButton from "./SortNotificationsButton";
import MyTasks from "../tasks/MyTasks";

export default function NotificationList() {

    const { loading, matchingNotifications, direction, sort, filterText, updateFilter } = useFilteredNotifications();

    if (loading) return <LoadingSpinner />

    return (
        <>
            <Flex className="w-100 mb-3">
                <Flex className="w-100" row justify="between">
                    <Typography bold color="primary" size={24}>Tickets</Typography>
                    <SortNotificationsButton />
                </Flex>
                <Flex row justify="between" className="w-100">
                    <CreateTicketModal />
                    <SearchBox 
                        className="me-1"
                        onChange={(e) => updateFilter({notificationsFilterText: e})}
                        initialValue={filterText}
                    />
                </Flex>
            </Flex>
            <div className="w-100 h-100"  style={{overflowY: "auto"}}>
                <div className="d-flex flex-column gap-2 w-100">
                    <InfiniteScroll>
                        {
                            matchingNotifications && !!matchingNotifications.length
                            ? matchingNotifications.map(d => <TicketActivityItem key={d._id} activity={d} showTicketLink />)
                            : <span>Noch keine Benachrichtigungen</span>
                        }
                    </InfiniteScroll>
                </div>
            </div>
            <MyTasks />
        </>
    )
}