import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { getColorFromType, getIconFromType } from "../../config/ModalTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { generateStyle } from "../../hooks/useAttributes";
import useModal from "../../hooks/useModal";
import { setModal } from "../../state/slices/modalSlice";
import Icon from "../icons/Icon";
import "./ModalBanner.css";

export default function ModalBanner() {

    const [showTimer, setShowTimer] = useState<any>();

    const {
        visible,
        text,
        id,
        type
    } = useAppSelector(state => state.modal);

    const showModal = useModal();

    useEffect(() => {
        if (!visible) return;
    
        clearTimeout(showTimer);
        const timer = setTimeout(() => showModal(false), 4000);
        setShowTimer(timer);

    }, [visible, id]);
    
    if (!visible) return null;

    const style = generateStyle({
        name: "backgroundColor",
        isVar: true,
        value: getColorFromType(type)
    }, {
        name: "color",
        value: "#FFFFFF"
    });

    return (
        <div className="modal-banner d-flex align-items-center justify-content-between" style={style}>
            <div className="d-flex align-items-center">
                <Icon icon={getIconFromType(type)} />
                <span className="ms-3 fw-bold">{text}</span>
            </div>
            <Icon icon="x" onClick={() => showModal(false)}/>
        </div>
    )
}