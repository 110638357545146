import React from "react";
import { ILogEntry, LogEntryType, TiggiMode } from "../../types/ApiTypes";
import Pill from "../pills/Pill";

export default function LogEntrySystemBanner({log}: {log: ILogEntry}) {

    const getText = () => {
        switch (log.system) {
            case TiggiMode.Development: return "Development";
            case TiggiMode.Production: return "Production";
            case TiggiMode.Staging: return "Staging";
            case TiggiMode.Testing: return "Testing";
        }
    }

    const getColor = () => {
        switch (log.system) {
            case TiggiMode.Development: return "#78858B";
            case TiggiMode.Production: return "#252850";
            case TiggiMode.Staging: return "#282828";
            case TiggiMode.Testing: return "#7D7F7D";
        }
    }

    return (
        <Pill text={getText()} color={getColor()} />
    )
}