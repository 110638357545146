import React from "react";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { logsDelete } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useLogs } from "../../state/swr/log/useLogs";

export default function DeleteLogsManuallyButton() {
    const callApi = useApi();
    const { reloadLogs } = useLogs();

    const clickHandler = async () => {
        await callApi(logsDelete());
        await reloadLogs(undefined, { revalidate: true });
    }

    return <Button onClick={clickHandler} color="error" text="Alle Logeinträge löschen" icon="trash" />
}