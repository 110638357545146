import React from "react";
import { IUser } from "../../../types/ApiTypes";
import { generateStyle } from "../../../hooks/useAttributes";
import { getTextColorFromBackground } from "../../../util/util";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { useOfficeAccountAvatar } from "../../../state/swr/office/useOfficeAccountAvatar";
import { URL } from "url";
import { Buffer } from "buffer";
import useDownloads from "../../../hooks/useDownloads";
import { useAnyUserAvatar } from "../../../state/swr/user/useAnyUserAvatar";

export interface IUserAvatarProps {
    user?: IUser | null,
    mailAddress: string,
    size?: number
}

export default function UserAvatar({mailAddress, user, size = 48}: IUserAvatarProps) {

    const { loadingUserAvatar, reloadUserAvatar, userAvatar } = useAnyUserAvatar(user ? user._id : "");

    const getInitials = (): {firstInitial: string, lastInitial: string} => {
        if (user) return {
            firstInitial: user.firstName[0].toUpperCase(),
            lastInitial: user.lastName[0].toUpperCase()
        };

        return {
            firstInitial: mailAddress.split("@")[0][0].toUpperCase(),
            lastInitial: mailAddress.split("@")[1][0].toUpperCase()
        }
    }

    const initials = getInitials();

    const getColorsFromName = (): {firstColor: string, lastColor: string} => {

        const firstLetter = initials.firstInitial.toLowerCase();
        const lastLetter = initials.lastInitial.toLowerCase();

        const firstLetterColor = LetterColors[firstLetter];
        const lastLetterColor = LetterColors[lastLetter];

        return {
            firstColor: firstLetterColor,
            lastColor: lastLetterColor
        }
    }

    const colorForUser = getColorsFromName();

    const generateGradientForAvatar = () => {
        const startOfGradient = (initials.firstInitial.charCodeAt(0) + 0) / 26;
        return `linear-gradient(80deg, ${colorForUser.firstColor} ${startOfGradient}%, ${colorForUser.lastColor} 100%)`
    }

    const gradient = generateGradientForAvatar();

    if (!gradient) return null;
    
    const style = generateStyle({
        name: "width", 
        value: size,
        unit: "px"
    }, {
        name: "height", 
        value: size,
        unit: "px"
    }, {
        name: "background",
        value: gradient
    }, {
        name: "borderRadius",
        value: size * 10,
        unit: "px"
    }, {
        name: "color",
        value: getTextColorFromBackground(colorForUser.firstColor, "black")
    });

    const letterStyle = generateStyle({
        name: "fontSize",
        value: size / 2,
        unit: "px"
    });

    return (
        <div className="d-flex flex-row align-items-center justify-content-center overflow-hidden" style={style}>
            {
                loadingUserAvatar
                ? <LoadingSpinner size={size} />
                : userAvatar && <img className="w-100 h-100" src={userAvatar.small} />
            }
            {
                (loadingUserAvatar || !userAvatar) && <strong className="position-absolute" style={letterStyle}>{initials.firstInitial}{initials.lastInitial}</strong>
            }
        </div>
    )
}

const LetterColors: {[key: string]: string} = {
    "a": "#F25CA2",
    "b": "#5AA9E6",
    "c": "#A8D54C",
    "d": "#FFB24D",
    "e": "#8E65E3",
    "f": "#F56C47",
    "g": "#59C4A3",
    "h": "#EA85D0",
    "i": "#52B36F",
    "j": "#FFA447",
    "k": "#6EB3E1",
    "l": "#ED6F7A",
    "m": "#75C86B",
    "n": "#E35F93",
    "o": "#7FAF5D",
    "p": "#D34FC4",
    "q": "#6E87D1",
    "r": "#F28457",
    "s": "#53BDAE",
    "t": "#F99273",
    "u": "#5B9CD1",
    "v": "#C37D5E",
    "w": "#58CF80",
    "x": "#F591AC",
    "y": "#54B773",
    "z": "#EC5A81"
}