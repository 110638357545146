import useSWR from "swr";
import { IAddressee, IAlias, IDatevAreaOfResponsibility } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useAreasOfResponsibility = () => {
    const { data, isLoading, mutate } = useSWR<Array<IDatevAreaOfResponsibility>>(ApiRoutes.Employees.AreasOfResponsibility);

    return {
        areasOfResponsibility: data,
        loadingAreasOfResponsibility: isLoading,
        reloadAreasOfResponsibilities: mutate
    }
}