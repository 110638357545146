import React from "react";
import SearchableComboBox from "./SearchableComboBox";
import { IUser } from "../../types/ApiTypes";
import UserRoleIndicator from "../user/RoleBanner";
import useUserUtil from "../../hooks/useUserUtil";
import { generateClassName } from "../../hooks/useAttributes";

export interface IUserSearchableComboBoxProps {
    onItemClick: (u: IUser | undefined) => void, 
    users: Array<IUser> | null | undefined, 
    label?: string, 
    employeesOnly?: boolean,
    placeholder?: string, 
    value: IUser | string | undefined,
    bold?: boolean,
    className?: string,
    onlyCurrentTenant?: boolean
}

export default function UserSearchableComboBox({className, employeesOnly, onlyCurrentTenant, users, label, placeholder, value, onItemClick, bold}: IUserSearchableComboBoxProps) {
    
    const [availableUsers, setAvailableUsers] = React.useState<Array<IUser>>([]);

    const {
        getName,
        getCurrentTenantAuthority
    } = useUserUtil();

    React.useEffect(() => {
        const getUsers = () => {
            if (!users || !users.length) return [];

            const matchingItems = users.filter(u => {
                const auth = getCurrentTenantAuthority(u)
                if (!auth) return !onlyCurrentTenant;
                if (!employeesOnly) return true;
                return !auth.userAuthority?.role?.isClient;
            });
            
            return matchingItems || [];
        }

        const available = getUsers();
        setAvailableUsers(available);
    }, [users, onlyCurrentTenant, employeesOnly])

    return (
        <SearchableComboBox 
            bold={bold}
            className={className || "w-100"}
            values={availableUsers}
            itemToId={(u) => u._id}
            renderItem={(u) => <UserRoleIndicator displayNameAsValue user={u} showMail className="w-100" />}
            itemToString={(u) => u.mailAddress}
            filterFunction={(u, f) => {
                if (!u) return false;

                const mailIncludesValue = u.mailAddress.toUpperCase().includes(f);

                if (mailIncludesValue) return true;

                const name = getName(u);

                if (!name) return false;
                
                return name.toUpperCase().includes(f);
            }}
            renderValue={(u) => <UserRoleIndicator displayNameAsValue user={u} showMail className="w-100" />}
            clearValue={() => onItemClick(undefined)}
            onItemClick={onItemClick}
            label={label}
            placeholder={placeholder || "Nutzer auswählen..."}
            value={value}
        />
    )
}