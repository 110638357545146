import React from "react";
import { ITicket, ITicketActivity, TicketActivityType, TicketSource } from "../../types/ApiTypes";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import UserRoleIndicator from "../user/RoleBanner";
import UserAvatar from "../user/profile/UserAvatar";
import useUserUtil from "../../hooks/useUserUtil";
import { formatDateGmailStyle } from "../../util/formatter";
import Card from "../card/Card";
import TicketLink from "./TicketLink";
import ClientTypeBanner from "../clients/ClientTypeBanner";
import TeamBanner from "../teams/TeamBanner";
import TicketCardHeader from "./lists/TicketCardHeader";
import { useNavigate } from "react-router-dom";

export interface ITicketActivityItemProps {
    activity: ITicketActivity,
    showTicketLink?: boolean,
    ticket?: ITicket
}

export default function TicketActivityItem({activity, ticket, showTicketLink = false}: ITicketActivityItemProps) {

    const {
        getName
    } = useUserUtil();

    const getIcon = () => {
        switch (activity.activityType) {
            case TicketActivityType.Archived: return "archive";
            case TicketActivityType.Assigned: return "person";
            case TicketActivityType.Unassigned: return "person-dash";
            case TicketActivityType.AssignedToTeam: return "people";
            case TicketActivityType.Closed: return "x";
            case TicketActivityType.Created: return "plus";
            case TicketActivityType.Escalated: return "chevron-up";
            case TicketActivityType.InternalSubjectChanged: return "pen";
            case TicketActivityType.Merged: return "layers";
            case TicketActivityType.MessageAdded: return "envelope";
            case TicketActivityType.MessageUpdated: return "pen";
            case TicketActivityType.NoteAdded: return "sticky";
            case TicketActivityType.Reopened: return "check";
            case TicketActivityType.Solved: return "check";
            case TicketActivityType.ClientChanged: return "house";
        }
    }

    const getContent = () => {
        switch (activity.activityType) {
            case TicketActivityType.Archived: return "hat das Ticket archiviert.";
            case TicketActivityType.Unassigned: return "hat einen Nutzer aus der Zuweisung entfernt.";

            case TicketActivityType.Assigned: return "hat das Ticket einem Nutzer zugewiesen.";
            case TicketActivityType.AssignedToTeam: return "hat das Ticket einem Team zugewiesen.";
            case TicketActivityType.Closed: return "hat das Ticket geschlossen.";
            case TicketActivityType.Created: 

                if (!ticket) return "hat das Ticket eröffnet.";
                
                switch (ticket.source) {
                    case TicketSource.Android: return "hat das Ticket über die Android-App eröffnet.";
                    case TicketSource.iOS: return "hat das Ticket über die iOS-App eröffnet.";
                    case TicketSource.Mail: return "hat das Ticket per E-Mail eröffnet.";
                    case TicketSource.ManualFromMail: return "hat das Ticket aus einer E-Mail eröffnet.";
                    case TicketSource.Web: return "hat das Ticket über die Webseite eröffnet.";
                }
            
            case TicketActivityType.Escalated: return "hat das Ticket eskaliert.";
            case TicketActivityType.ClientChanged: return "hat das Ticket einem Mandanten zugewiesen.";
            case TicketActivityType.InternalSubjectChanged: return "hat den internen Betreff geändert.";
            case TicketActivityType.Merged: return "hat das Ticket zusammengeführt.";

            case TicketActivityType.MessageAdded: return "hat eine Nachricht hinzugefügt.";
            case TicketActivityType.MessageUpdated: return "hat eine Nachricht bearbeitet.";
            case TicketActivityType.NoteAdded: return "hat eine Notiz hinzugefügt.";
            case TicketActivityType.Reopened: return "hat das Ticket wiedereröffnet.";
            case TicketActivityType.Solved: return "hat das Ticket gelöst.";
        }
    }

    const navigate = useNavigate();

    return (
        <Card  
            color="bright"
            onClick={showTicketLink ? () => navigate(`/tickets/${activity.ticket._id}`) : undefined}
            className="p-2 w-100" 
            header={showTicketLink ? <TicketCardHeader ticket={activity.ticket} /> : undefined}
        >
            <Flex row justify="between" align="start" className="w-100" gap={2}>
                <div className="position-relative">
                    <UserAvatar user={activity.activityBy} mailAddress={activity.isSystemActivity ? "tiggi@tiggi.com" : (activity.activityByMail || "x@y")} size={40} />
                    <div style={{width: "20px", height: "20px", backgroundColor: "var(--backgroundLighter)", overflow: "hidden", border: "1px solid var(--backgroundDarkest)", top: "-5px", right: "-5px", borderRadius: "50px"}} className="position-absolute d-flex justify-content-center align-items-center">
                        <Icon hexColor="var(--backgroundDarkest)" size={12} icon={getIcon()} />
                    </div>
                </div>
                <Flex gap={0} className="w-100 text-truncate text-break w-100" align="start" justify="start">
                    <strong className="text-truncate text-break w-100" style={{maxWidth: "250px"}}>
                        {
                            activity.isSystemActivity 
                            ? "SYSTEM"
                            : (
                                activity.activityBy 
                                ? getName(activity.activityBy)
                                : (activity.activityByMail || "Keine Mailadresse hinterlegt")
                            )
                        }
                    </strong>
                    <span className="text-wrap text-break">{getContent()}</span>
                    <Flex row wrap fullWidth>
                        {
                            activity.activityAffectsUser && <UserRoleIndicator user={activity.activityAffectsUser} displayNameAsValue />
                        }
                        {
                            activity.activityAffectsClient && <ClientTypeBanner client={activity.activityAffectsClient} showClientName />
                        }
                        {
                            activity.mergedIntoTicket && <TicketLink ticket={activity.mergedIntoTicket} />
                        }
                        {
                            activity.activityAffectsTeam && <TeamBanner team={activity.activityAffectsTeam} />
                        }
                    </Flex>
                </Flex>
                <Flex align="end">
                    <span className="text-nowrap">{formatDateGmailStyle(activity.createdAt)}</span>
                </Flex>
            </Flex>
        </Card>
    )
}