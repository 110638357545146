import React, { PropsWithChildren } from "react";
import Error from "../pages/Error";

interface IErrorBoundaryState {
    hasError: boolean
}

export default class ErrorBoundary extends React.Component<PropsWithChildren, IErrorBoundaryState> {
    constructor(props: PropsWithChildren) {
        super(props);
        this.state = { 
            hasError: false 
        };
    }
  
    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
  
    logErrorToMyService(error: any, errorInfo: any) {
        console.log("Error at " + Date.now().toLocaleString() + ":")
        console.log(error);
        console.log(errorInfo);
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.logErrorToMyService(error, errorInfo);
    }
    
    render() {
        if (this.state.hasError) return <Error hideBackButton />
        return this.props.children; 
    }
}