import React from "react";
import TenantOnboardingWizard from "../../components/tenant/onboarding/TenantOnboardingWizard";
import Page from "../../layout/Page";

export default function TenantOnboarding() {
    return (
        <Page fullscreen >
            <TenantOnboardingWizard />
        </Page>
    )
}