import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppRouteParams, AppRoutes } from "../../config/AppRoutes";
import Page from "../../layout/Page";
import PageLoader from "../../components/loader/PageLoader";
import GiveDocumentClearanceButton from "../../components/documentClearance/GiveDocumentClearanceButton";
import { useDocumentClearanceReminder } from "../../state/swr/documentClearance/useDocumentClearanceReminder";
import { getMonthName } from "../../util/formatter";

export default function GiveDocumentClearance() {

    const clearanceReminderId = AppRouteParams.DocumentClearanceReminderId.getValue(useParams());

    const { documentClearanceReminder, loadingDocumentClearanceReminder, reloadDocumentClearanceReminder } = useDocumentClearanceReminder(clearanceReminderId || "");

    if (loadingDocumentClearanceReminder) return <PageLoader />

    return (
        <Page fullscreen className="p-5 d-flex flex-column align-items-start">
            <h1>Abgabe aller Belege bestätigen</h1>
            {
                clearanceReminderId 
                ? (
                    <>
                        <h5>Hiermit bestätigen Sie, dass Sie alle Belege für {documentClearanceReminder && documentClearanceReminder.month !== undefined ? `den Monat ${getMonthName(documentClearanceReminder.month)}` : "den aktuellen Monat"} an uns übermittelt haben.</h5>
                        <GiveDocumentClearanceButton isDone={documentClearanceReminder && documentClearanceReminder.isDone} reminderId={clearanceReminderId} />
                    </>
                )
                : (
                    <>
                        <h5>Ihr Link ist fehlerhaft. Bitte überprüfen Sie diesen.</h5>
                    </>
                )
            }
        </Page>
    )
}