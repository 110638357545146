import React from "react";
import { AppColor } from "../../app/AppStyles";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { getTextColorFromBackground } from "../../util/util";
import IElementProps from "../../types/ElementProps";

export interface ITypographyProps extends IElementProps {
    element?: string,
    size?: TypographySize,
    lineHeight?: number,
    truncate?: boolean,
    wrap?: boolean,
    breakWords?: boolean,
    color?: string | AppColor,
    basedOnThisBackground?: AppColor | string,
    bold?: boolean,
    upper?: boolean,
    italic?: boolean,
    maxWidth?: number
}

export type TypographySize = 9 | 12 | 14 | 20 | 24 | 28 | 34 | 50;

export default function Typography({element = "span", maxWidth, italic, lineHeight, className, upper, breakWords = false, truncate = true, wrap = false, basedOnThisBackground, bold, size = 14, color, style, children}: ITypographyProps) {
    const isHex = !color || color.includes("#");

    const realStyle = generateStyle({
        name: "color",
        isVar: !isHex,
        value: basedOnThisBackground ? getTextColorFromBackground(basedOnThisBackground, "#000000") : (color || "#000")
    }, {
        name: "fontSize",
        value: size,
        unit: "px"
    }, {
        name: "fontWeight",
        applyCondition: !!bold,
        value: "bold"
    }, {
        name: "lineHeight",
        value: lineHeight,
        unit: "px",
        applyCondition: lineHeight !== undefined
    }, {
        name: "maxWidth",
        value: maxWidth,
        unit: "px",
        applyCondition: maxWidth !== undefined
    }, {
        name: "fontStyle",
        value: "italic",
        applyCondition: !!italic
    });

    const realClassName = generateClassName("m-0 p-0", className, {
        value: wrap,
        base: "text-",
        onTrue: "wrap",
        standard: "nowrap"
    }, {
        value: !!italic,
        onTrue: "font-italic"
    }, {
        value: breakWords,
        onTrue: "text-break"
    }, {
        value: truncate,
        onTrue: "text-truncate"
    }, {
        value: upper,
        onTrue: "text-uppercase"
    })

    return React.createElement(element, {style: style ? {...style, realStyle} : realStyle, className: realClassName}, children);
}