import { Formik, Form, Field, FormikConsumer, FormikProps } from "formik";
import React from "react";
import { IApiResponse, clientsCreateAlias, clientsImportFromDatev, clientsUpdateManager } from "../../api/Api";
import { IClientsImportFromDatevRequest, IClientUpdateManagerRequest, ICreateAliasRequest } from "../../api/ApiRequests";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { IClient, IClientImportLogEntry, IUser } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import LoadingSpinner from "../loader/LoadingSpinner";
import ModalForm from "../modal/ModalForm";
import "./ImportClientsForm.css";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import useUserUtil from "../../hooks/useUserUtil";
import Flex from "../container/Flex";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import { useAnyClientAlias } from "../../state/swr/clients/useAnyClientAlias";
import AliasForm from "../alias/AliasForm";
import { useClients } from "../../state/swr/clients/useClients";
import { useEmployees } from "../../state/swr/employees/useEmployees";
import WithPermissions from "../permissions/WithPermissions";

interface IUpdateGeneralClientManagerFormValues extends IClientUpdateManagerRequest {
    clientManager: IUser | null | undefined
}

export enum UpdateClientTab {
    General = "general",
    Alias = "alias"
}

export default function UpdateClientForm({client}: {client: IClient}) {

    const { reloadClients } = useClients();
    const { employees, reloadEmployees, loadingEmployees } = useEmployees();

    const { clientAlias, loadingClientAlias, reloadClientAlias } = useAnyClientAlias(client._id);

    const [tab, setTab] = React.useState<UpdateClientTab>(UpdateClientTab.General);

    const callApi = useApi();

    const {
        getName
    } = useUserUtil();

    const dispatch = useAppDispatch();

    const getInitialValues = () => {
        switch (tab) {
            case UpdateClientTab.General: return {
                clientManagerId: "",
                clientManager: client.clientManager,
                id: client._id
            } as IUpdateGeneralClientManagerFormValues;

            case UpdateClientTab.Alias: return {
                aliasMailAddress: "",
                id: client._id
            } as ICreateAliasRequest 
        }
    }

    const getSubmitText = () => {
        switch (tab) {
            case UpdateClientTab.General: return "Verantwortlichen Mitarbeiter anpassen";
            case UpdateClientTab.Alias: return "Alias hinzufügen";
        }
    }

    return (
        <WithPermissions permissions={[ "clients.all.update" ]} >
            {
                loadingEmployees 
                ? <LoadingSpinner text="Mitarbeiter werden geladen..." centered={false} />
                : (
                    !employees || !employees.length
                    ? <Button disabled text="Keine Mitarbeiter" />
                    : (
                        <ModalForm 
                            button={<Button text="Mandat anpassen" icon="pen" />}
                            title="Mandant anpassen"
                            initialValues={getInitialValues()}
                            enableReinitialize
                            closeAfterSubmit={tab !== UpdateClientTab.Alias}
                            onSubmit={async (values) => {

                                let res: IApiResponse | null = null;

                                if (tab === UpdateClientTab.General) {
                                    if (!values || !(values as any).clientManager) return false;

                                    res = await callApi(clientsUpdateManager({
                                        clientManagerId: (values as any).clientManager._id,
                                        id: values.id
                                    }));
                                }
                                else if (tab === UpdateClientTab.Alias) res = await callApi(clientsCreateAlias(values as ICreateAliasRequest));

                                if (!res || !res.success) return false;
                                
                                await reloadClients();
                                await reloadClientAlias();
                                await reloadEmployees();

                                return true;
                            }}
                            sidebar={formik => <Button type="submit" loading={formik.isSubmitting} text={getSubmitText()} color="success" />}
                        >
                            {
                                (formik: FormikProps<any>) => {
                                    const getContent = () => {
                                        switch (tab) {
                                            case UpdateClientTab.General:

                                                return (
                                                    <SearchableComboBox
                                                        values={employees}
                                                        itemToId={e => e ? e._id : "client-manager"} 
                                                        itemToString={e => getName(e)}
                                                        value={formik.values.clientManager}
                                                        onItemClick={e => formik.setFieldValue("clientManager", e)}
                                                        label="Verantwortlicher Mitarbeiter"
                                                        
                                                    />
                                                );

                                            case UpdateClientTab.Alias: return <AliasForm data={clientAlias} isLoading={loadingClientAlias} mutate={reloadClientAlias} />
                                        }
                                    }

                                    return (
                                        <Flex className="w-100">
                                            <TabSwitcher
                                                tabQueryParamKey="view"
                                                tabs={[
                                                    {data: UpdateClientTab.General, label: "Allgemein"},
                                                    {data: UpdateClientTab.Alias, label: "Alias"}
                                                ]}
                                                saveActiveTab={t => setTab(t as UpdateClientTab)}
                                            />
                                            { getContent() }
                                        </Flex>
                                    )
                                }
                            }
                        </ModalForm>
                    )
                )
            }
        </WithPermissions>
    )
}