import useSWR from "swr";
import { ITask } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useTicketTasks = (ticketId: string) => {
    const { data, isLoading, mutate } = useSWR<Array<ITask>>(ApiRoutes.Tickets.Tasks.getValue({ param: RouteParam.TicketId, value: ticketId }));

    return {
        ticketTasks: data,
        loadingTicketTasks: isLoading,
        reloadTicketTasks: mutate
    }
} 