import React from "react";
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import Card from "../card/Card";
import Typography from "../text/Typography";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import LoadingBar from "../loader/LoadingBar";
import LoadingSpinner from "../loader/LoadingSpinner";
import { AppColor } from "../../app/AppStyles";

export interface IChartData {
    value: number,
    name: string,
    fill?: string
}

export interface ITicketTeamStatisticProps {
    title: string,
    loading?: boolean,
    statistics: Array<IChartData>,
    cardColor?: AppColor
}

export default function TicketStatisticPie({title, loading, cardColor, statistics}: ITicketTeamStatisticProps) {

    const total = statistics && statistics.length ? statistics.reduce((acc, curr) => acc + curr.value, 0) : 0;

    return (
        <Card wrapperClass="w-100" color={cardColor} header={<Typography size={14} bold basedOnThisBackground={cardColor}>{title}</Typography>}>
            <ResponsiveContainer className="d-flex flex-column" height={250} >
            {
                
                loading
                ? <LoadingSpinner size={50} />
                : (
                    total > 0 
                    ? (
                        <PieChart width={730} height={250}>
                            <Pie isAnimationActive={false} data={statistics} dataKey="value" legendType="line" nameKey="name" cx="50%" cy="50%" innerRadius={40} outerRadius={80} fill="#8884d8" />
                            <Tooltip />
                        </PieChart>
                    )
                    : (
                        <Flex className="w-100 h-100" align="center" justify="center">
                            <Icon size={64} color="muted" icon="bar-chart" invertThisColor={cardColor} />
                            <Typography basedOnThisBackground={cardColor} >Keine Werte</Typography>
                        </Flex>
                    )
                )
            }
            </ResponsiveContainer>
        </Card>
    )
}