import useSWR from "swr";
import { IClient, ICoupon, IFeature, IService, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useServices = () => {
    const { data, isLoading, mutate } = useSWR<Array<IService>>(ApiRoutes.Subscriptions.ServicesGetAll);

    return {
        services: data,
        loadingServices: isLoading,
        reloadServices: mutate
    }
}