import React, { ReactNode, ReactElement } from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import IElementProps from "../../types/ElementProps";
import "./Card.css";
import Flex from "../container/Flex";
import { hexWithOpacity } from "../../util/color";
import { AppColor } from "../../app/AppStyles";
import { getTextColorFromBackground } from "../../util/util";

export interface ICardProps extends IElementProps {
    noPadding?: boolean,
    header?: ReactElement | ReactNode | undefined,
    padding?: number | string,
    wrapperClass?: string,
    color?: AppColor,
    strongColor?: boolean,
    background?: string,
    expanded?: boolean
}

export default function Card({wrapperClass, strongColor = false, color, expanded = true, children, onClick, noPadding, header}: ICardProps) {
    const actualClassName = generateClassName(wrapperClass, "card-container", wrapperClass && wrapperClass.includes("w-") ? undefined : "w-100", {
        value: !!onClick,
        onTrue: "card-clickable"
    });

    const contentClass = generateClassName("card-content w-100 overflow-hidden", {
        value: noPadding,
        onTrue: "p-0",
        standard: "p-3"
    });

    return (
        <div 
            className={actualClassName} 
            onClick={onClick ? () => onClick() : undefined} 
            style={generateStyle({
                name: "backgroundColor",
                applyCondition: !!color,
                value: hexWithOpacity(color || "", strongColor || color === "bright" ? 0.7 : 0.4, true)
            })}
        >
            {
                header && (
                    <div 
                        className="card-header pe-3 ps-3 pt-1 pb-1" 
                        style={generateStyle({
                            name: "backgroundColor",
                            value: hexWithOpacity(color || "muted", color ? (color === "bright" ? 0.1 : 0.8) : 0.3, true)
                        }, {
                            name: "color",
                            value: getTextColorFromBackground(color || "muted"),
                            important: true
                        })} 
                    >
                        {header}
                    </div>
                )
            }
            {
                expanded && children && (
                    <Flex 
                        className={contentClass} 
                        
                    >
                        {children}
                    </Flex>
                )
            }
        </div>
    )
}
