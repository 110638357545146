import React from "react";
import { useNavigate } from "react-router-dom";
import ClientList from "../../components/clients/ClientList";
import DeleteClientsButton from "../../components/clients/DeleteClientsButton";
import ImportClientsForm from "../../components/clients/ImportClientsForm";
import UserUpdateForm from "../../components/user/UserUpdateForm";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import { useAppSelector } from "../../hooks/reduxHooks";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import DatevImportDialog from "../../components/datev/DatevImportDialog";
import { useClients } from "../../state/swr/clients/useClients";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../state/swr/user/useUser";

export default function ClientManagement() {

    const { clients } = useClients();

    const { role } = useCurrentTenantRole();
    const { user } = useUser();

    const navigate = useNavigate();
    const showModal = useModal();

    const {
        canManageTenant
    } = useUserUtil();

    React.useEffect(() => {
        const isTenantAdmin = canManageTenant(user);

        if (isTenantAdmin) return;

        if (!role || role.isClient) {
            showModal({text: "Zugriff verweigert", type: ModalType.Error})
            navigate(AppRoutes.Home.path);
        }
    }, [user, role]);

    const getTitle = () => {
        if (!clients || !clients.length) return Locale.titles.clientManagement;

        if (clients.length === 1) return `1 ${Locale.titles.clientManagement.substring(0, Locale.titles.clientManagement.length - 1)}`;

        return `${clients.length} ${Locale.titles.clientManagement}`;
    }

    return (
        <Page limitMaxHeight header={getTitle()} actionButtons={[<DeleteClientsButton key="delete-clients-button" />, <DatevImportDialog key="datev-import-dialog" />]}>
            <ClientList />
        </Page>
    )
}