import React from "react";
import Drawer from "../modal/Drawer";
import Icon from "../icons/Icon";
import NotificationList from "./NotificationList";
import Flex from "../container/Flex";
import MyTasks from "../tasks/MyTasks";

export default function NotificationDrawer() {
    return (
        <Drawer
            title="Benachrichtigungen"
            button={<Icon size={25} icon="bell-fill" hexColor="#B2C0D9" tooltip="Benachrichtigungen ansehen" />}
        >
            <NotificationList />
        </Drawer>
    )
}