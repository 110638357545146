import useSWR from "swr";
import { IClient, ICoupon, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useCoupons = () => {
    const { data, isLoading, mutate } = useSWR<Array<ICoupon>>(ApiRoutes.Subscriptions.CouponsGetAll);

    return {
        coupons: data,
        loadingCoupons: isLoading,
        reloadCoupons: mutate
    }
}