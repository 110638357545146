import useSWR from "swr";
import { IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useOfficeAccounts = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IOfficeAccount>>(ApiRoutes.OfficeAccounts.GetAll);

    return {
        officeAccounts: data,
        loadingOfficeAccounts: isLoading,
        reloadOfficeAccounts: mutate
    }
}