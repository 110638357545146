import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Status } from "../../config/Status";
import PageLoader from "../loader/PageLoader";
import Table from "../tables/Table";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import UpdateFeatureButton from "./UpdateFeatureButton";
import { useFeatures } from "../../state/swr/subscriptions/useFeatures";
import { useSortedFeatures } from "../../hooks/useSortedFeatures";
import { useUser } from "../../state/swr/user/useUser";

export default function FeatureOverview() {

    const {
        user
    } = useUser();

    const { features, loadingFeatures, reloadFeatures } = useFeatures();

    const dispatch = useAppDispatch();

    const {
        convertNumberToTiggiFeature
    } = useSortedFeatures();

    if (!user || !user.isSuperAdmin) return null;
    if (loadingFeatures) return <PageLoader />

    if (!features || !features.length) return <span>Noch keine Features angelegt. Legen Sie ein Feature an, um Ihren Plänen ein Verkaufsargument hinzuzufügen.</span>

    return (
        <Table 
            headers={[{label: "Name"}, {label: "Feature"}, {label: ""}]} 
            border 
            items={features}
            renderItem={f => (
                <TableRow key={f._id}>
                    <TableCell>{f.name}</TableCell>
                    <TableCell>{convertNumberToTiggiFeature(f.feature)}</TableCell>
                    <TableCell justify="end" align="middle"><UpdateFeatureButton feature={f} /></TableCell>
                </TableRow>
            )}
        />
    )
}