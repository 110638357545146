import React from "react";
import { ITicket } from "../../../types/ApiTypes";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/reduxHooks";
import useTicketUtil from "../../../hooks/useTicketUtil";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import TicketTypeBanner from "../banner/TicketTypeBanner";
import TicketLastReplyStateIcon from "../banner/TicketLastReplyStateIcon";
import { formatDateTiggiStyle } from "../../../util/formatter";
import TicketFriendlyIdBanner from "../banner/TicketFriendlyIdBanner";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { useTicketCurrentUserAssignment } from "../../../state/swr/tickets/useTicketCurrentUserAssignment";
import Pill from "../../pills/Pill";

export interface ITicketCardHeaderProps {
    ticket: ITicket,
    loading?: boolean
}

export default function TicketCardHeader({ticket, loading}: ITicketCardHeaderProps) {

    const { ticketCurrentUserAssignment } = useTicketCurrentUserAssignment(ticket);
    const { role } = useCurrentTenantRole();

    const isClient = !!role && role.isClient;

    return (
        <Flex row justify="between" wrap className="w-100">
            <Flex row>
                <Flex row gap={1}>
                    { loading && <LoadingSpinner color="secondary" />}
                    {
                        ticketCurrentUserAssignment && ticketCurrentUserAssignment.isCc && (
                            <>
                                <Pill color="#A31200" className="pt-0 pb-0 ps-1 pe-1"><Typography color="bright" size={12}>CC</Typography></Pill>
                                <Typography bold>|</Typography>
                            </>
                        )
                    }
                    <TicketFriendlyIdBanner ticket={ticket} bold size={12} />
                    {
                        !isClient && (
                            <>
                                <Typography bold>|</Typography>
                                <TicketTypeBanner ticket={ticket} />
                            </>
                        )
                    }
                </Flex>
                <TicketLastReplyStateIcon ticket={ticket} />
            </Flex>
            <Flex className="ms-auto" >
                <Typography size={12}>{formatDateTiggiStyle(ticket.lastUpdate)}</Typography>
            </Flex>
        </Flex>
    )
}
