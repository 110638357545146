import useSWR from "swr";
import { IUserStatistics } from "../../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";

export const useAllUserStatistics = () => {
    const { data, isLoading, mutate } = useSWR<Array<IUserStatistics>>(ApiRoutes.User.Statistics.AllUsers, { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        allUserStatistics: data,
        loadingAllUserStatistics: isLoading,
        reloadAllUserStatistics: mutate
    }
}