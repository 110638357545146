import { Form, Formik } from "formik";
import React from "react";
import Flex from "../../container/Flex";
import useApi from "../../../hooks/useApi";
import { pointChampCreateNewApiKey } from "../../../api/Api";
import Dialog from "../../modal/Dialog";
import CopyToClipboardButton from "../../buttons/CopyToClipboardButton";
import { usePointChampApiKey } from "../../../state/swr/integrations/usePointChampApiKey";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Button from "../../buttons/Button";
import Icon from "../../icons/Icon";
import WithPermissions from "../../permissions/WithPermissions";

export default function PointChampConnectorSettings() {
    return (
        <WithPermissions permissions={["interfaces.pointchamp.update"]}>
            <PointChampConnectorForm />
        </WithPermissions>
    )
}

function PointChampConnectorForm() {
    const [apiKey, setApiKey] = React.useState<string>("Test1234");
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    const { loadingPointChampApiKey, pointChampApiKey, reloadPointChampApiKey } = usePointChampApiKey();

    const callApi = useApi();

    React.useEffect(() => {
        if (!modalOpen) setApiKey("");
    }, [modalOpen]);

    if (modalOpen) return (
        <Dialog setVisible={setModalOpen} title="Ihr PointChamp-API-Key">
            <p>Ihr API-Key ist</p>
            <Flex row>
                <h6 className="m-0 p-0">{apiKey}</h6>
                <CopyToClipboardButton value={apiKey} />
            </Flex>
            <br />
            <strong>Bitte bewahren Sie diesen Code gut auf. Sobald Sie dieses Fenster schließen, können Sie den Code nicht erneut ansehen.</strong>
            <p>Kopieren Sie diesen Schlüssel in Ihrem PointChamp-Dashboard, um die Integration freizuschalten.</p>
        </Dialog>
    );

    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                const res = await callApi(pointChampCreateNewApiKey());

                if (!res || !res.success) return;

                await reloadPointChampApiKey();
                setApiKey(res.data.apiKey)
                setModalOpen(true);
            }}
        >

            {
                formik => (
                    <Form>
                        <Flex>
                            <h6 className="fw-bold m-0 p-0">PointChamp-API-Key</h6>  
                            {
                                loadingPointChampApiKey 
                                ? <LoadingSpinner />
                                : (
                                    pointChampApiKey 
                                    && (
                                        <Flex>
                                            <p className="m-0 p-0">Ihr API-Key ist</p>
                                            <Flex row>
                                                <h6 className="m-0 p-0">**** **** **** **** **** **** **** {pointChampApiKey.lastFour}</h6>
                                            </Flex>
                                        </Flex>
                                    )
                                )
                            }
                            <Button type="submit" text="Neuen API-Schlüssel generieren" icon={pointChampApiKey ? "arrow-clockwise" : "plus"} />
                        </Flex>
                    </Form>
                )
            }
        </Formik>
    )
}