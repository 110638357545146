import React, { ReactElement, ReactNode } from "react";
import "./Drawer.css";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import Typography from "../text/Typography";
import { useLocation } from "react-router-dom";

export interface IDrawerProps {
    button: ReactElement,
    title: string,
    children: ReactNode
}

export default function Drawer({button, title, children}: IDrawerProps) {

    const [visible, setVisible] = React.useState<boolean>(false);

    return (
        <>
            {
                React.cloneElement(button, {
                    onClick: async () => setVisible(true)
                })
            }
            {
                visible && (
                    <div className="position-fixed vw-100 vh-100" style={{zIndex: "9999999"}}>
                        <div className="modal-form-background position-fixed top-0 start-0 w-100 h-100" onClick={() => setVisible(false)} />     
                        <div className="drawer h-100 d-flex flex-column gap-3 pt-4 ps-4 pe-4">
                            <Flex row justify="between">
                                <Typography color="primary" size={20} bold >{title}</Typography>
                                <Icon onClick={() => setVisible(false)} icon="x" size={34} color="primary" />
                            </Flex>
                            <div className="w-100 h-100" style={{overflowY: "auto"}}>
                                <div className="d-flex flex-column gap-2 mb-3">
                                    {
                                        children
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}