import React from "react";
import useApi from "../../hooks/useApi";
import { teamsCreateForAreasOfResponsibility, teamsUpdateMembersFromAreaOfResponsibility } from "../../api/Api";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Button from "../buttons/Button";
import { Status } from "../../config/Status";
import { ITeam } from "../../types/ApiTypes";
import { AppColor } from "../../app/AppStyles";
import { useAreasOfResponsibility } from "../../state/swr/areasOfResponsibilities/useAreasOfResponsibility";
import { useTeams } from "../../state/swr/teams/useTeams";
import WithPermissions from "../permissions/WithPermissions";

export default function TeamUpdateMembersFromResponsibilityButton({team}: {team: ITeam}) {
    const callApi = useApi();
    const dispatch = useAppDispatch();

    const { areasOfResponsibility, loadingAreasOfResponsibility } = useAreasOfResponsibility();
    const { reloadTeams } = useTeams();

    const clickHandler = async () => {
        const res = await callApi(teamsUpdateMembersFromAreaOfResponsibility({id: team._id}));
        if (!res || !res.success) return;        
        await reloadTeams();
    }

    return (
        <WithPermissions permissions={["interfaces.datev.update"]}>
            <Button 
                text="Team-Mitglieder aus DATEV abrufen" 
                loading={loadingAreasOfResponsibility} 
                loadingText="Zuständigkeiten werden abgerufen" 
                disabled={!areasOfResponsibility || !areasOfResponsibility.length || !team.areaOfResponsibility}
                disabledText={!areasOfResponsibility || !areasOfResponsibility.length ? "Keine Zuständigkeiten importiert" : "Keine Zuständigkeit zugewiesen"}
                onClick={clickHandler}
                disabledIcon="x"
                icon="person-plus-fill"
                color="success"
            />
        </WithPermissions>
    )
}