import React from "react";
import TicketStatsCardGrid from "./TicketStatsCardGrid";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantAuthority } from "../../state/swr/user/useCurrentTenantAuthority";
import Flex from "../container/Flex";
import UserSearchableComboBox from "../comboBox/UserSearchableComboBox";
import { useAnyUserStatistics } from "../../state/swr/user/statistics/useAnyUserStatistics";
import Button from "../buttons/Button";
import { useUsers } from "../../state/swr/user/useUsers";
import { useEmployees } from "../../state/swr/employees/useEmployees";
import WithPermissions from "../permissions/WithPermissions";

export interface IUserProductivityOverviewProps {
    userId?: string,
    setUserId: (userId?: string) => void
}

export default function UserProductivityOverview({ userId, setUserId } : IUserProductivityOverviewProps) {
    const { user } = useUser();
    const { employees } = useEmployees();
    const { authority } = useCurrentTenantAuthority();

    const { loadingUserStatistics, userStatistics } = useAnyUserStatistics(userId || user?._id || "");
    
    return (
        <WithPermissions permissions={[ "statistics.own.read"]}>
            <TicketStatsCardGrid 
                isPersonal
                title="Deine Tickets in Zahlen" 
                isLoading={loadingUserStatistics} 
                statistics={userStatistics} 
                cardColor="secondary"
                filterComponent={(
                    <WithPermissions permissions={[ "statistics.tenant.read" ]}>
                        <Flex row>
                            <UserSearchableComboBox 
                                label=""
                                onlyCurrentTenant
                                placeholder="Mitarbeiter suchen..."
                                className="text-right"
                                onItemClick={(user) => setUserId(user!._id)}
                                users={employees}
                                value={userId}
                            />
                            {userId && <Button onClick={async () => setUserId(undefined)} variant="icon" icon="x" />}
                        </Flex>
                    </WithPermissions>
                )}
            />
        </WithPermissions>
    )
}