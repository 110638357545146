import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import Typography from "../text/Typography";
import "./Tooltip.css";

export interface IWithTooltip {
    tooltip?: string,
}

export interface ITooltipProps extends IWithTooltip {
    children: React.ReactNode,
    className?: string
}

export interface IIconTooltipOrigin {
    top: number,
    left: number,
}

export default function Tooltip({children, tooltip, className}: ITooltipProps) {

    const [hover, setHover] = React.useState<boolean>(false);
    const [showTooltipOnBottom, setShowTooltipOnBottom] = React.useState<boolean>(false);
    const [tooltipOrigin, setTooltipOrigin] = React.useState<IIconTooltipOrigin>();

    const tooltipRef = React.useRef<HTMLDivElement>(null);
    const hoverTimeout = React.useRef<any>();

    const getTooltipOrigin = () => {
        if (!tooltipRef.current) return;

        const rect = tooltipRef.current.getBoundingClientRect();

        setTooltipOrigin({
            top: (rect.top + (rect.height / 2)) + 14,
            left: rect.left - (rect.width / 2)
        });
    }

    const toggleHover = () => {
        if (!tooltip) return;

        getTooltipOrigin();
        clearTimeout(hoverTimeout.current);

        hoverTimeout.current = setTimeout(() => {
            setHover(false);
        }, 1000);

        setHover(true);

        if (!tooltipRef.current) return;

        const rect = tooltipRef.current.getBoundingClientRect();

        setShowTooltipOnBottom((rect.top < (window.innerHeight / 2)));
    }

    const tooltipClass = generateClassName("tooltip-container d-flex flex-row align-items-center justify-content-center p-1 ps-2 pe-2", {
        base: "tooltip-",
        value: showTooltipOnBottom,
        onTrue: "bottom",
        standard: "top"
    });

    const wrapperClass = generateClassName(className, "position-relative");
    
    return (
        <div 
            ref={tooltipRef}
            className={wrapperClass}
            onMouseEnter={toggleHover}
            onMouseLeave={() => setHover(false)}
        >
            {children}
            {
                hover && tooltip && tooltipOrigin && (
                    <div 
                        className={tooltipClass}
                        style={generateStyle({
                            name: "left",
                            value: tooltipOrigin.left - 30,
                            unit: "px"
                        }, {
                            name: "top",
                            value: tooltipOrigin.top + 5,
                            unit: "px"
                        })}
                    >
                        <Typography size={12}>{tooltip}</Typography>
                    </div>
                )
            }
        </div>
    )
}