import React from "react";
import Flex from "../container/Flex";
import TicketStatsCardGrid from "./TicketStatsCardGrid";
import { useTenantUserStatistics } from "../../state/swr/user/statistics/useTenantUserStatistics";
import { useAllUserStatistics } from "../../state/swr/user/statistics/useAllUserStatistics";
import TicketStatsPieGrid from "./TicketStatsPieGrid";
import Typography from "../text/Typography";
import WithPermissions from "../permissions/WithPermissions";

export default function TenantProductivityOverview() {

    const { loadingTenantUserStatistics, reloadTenantUserStatistics, tenantUserStatistics  } = useTenantUserStatistics();
    const { allUserStatistics, loadingAllUserStatistics } = useAllUserStatistics();
    
    return (
        <WithPermissions permissions={[ "statistics.tenant.read" ]}>
            <Flex className="w-100">
                <TicketStatsCardGrid 
                    title="Performance deines Tenants" 
                    cardColor="secondary" 
                    isLoading={loadingTenantUserStatistics} 
                    statistics={tenantUserStatistics} 
                />
                <TicketStatsPieGrid title="Übersicht nach Bearbeiter" cardColor="secondary" isLoading={loadingAllUserStatistics} statistics={allUserStatistics || []} />
            </Flex>
        </WithPermissions>
    )
}