import React from "react";
import { IUser } from "../../../types/ApiTypes";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import useUserUtil from "../../../hooks/useUserUtil";
import UserRoleBanner from "../../user/RoleBanner";
import Button from "../../buttons/Button";

export interface IAssignmentUserItemProps {
    user: IUser,
    onClick: () => Promise<any>
}

export default function AssignmentUserItem({onClick, user}: IAssignmentUserItemProps) {
    const {
        getName
    } = useUserUtil();

    return (
        <Button variant="text" className="w-100" onClick={onClick} align="start">
            <span className="text-nowrap text-start p-0 m-0 w-100">{getName(user)}</span>
            <UserRoleBanner user={user} />
        </Button>
    )
}