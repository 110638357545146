import { Form, Formik } from "formik";
import React, { useState } from "react";
import { tenantsAddToCurrentDomains } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { IDomainCreateRequest } from "../../api/ApiRequests";
import Button from "../buttons/Button";
import FieldWithLabel from "../formik/FormikField";
import ModalForm from "../modal/ModalForm";
import Flex from "../container/Flex";
import { useTenantDomains } from "../../state/swr/domains/useTenantDomains";
import WithPermissions from "../permissions/WithPermissions";

export default function AddDomainForm() {

    const { reloadTenantDomains } = useTenantDomains();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    return (
        <WithPermissions permissions={[ "domains.tenant.create" ]}>
            <ModalForm 
                title="Neue Domain" 
                button={<Button icon="cloud-plus-fill" color="success">Domain hinzufügen</Button>}
                initialValues={{
                    domain: ""
                } as IDomainCreateRequest}
                onSubmit={async (values, actions) => {
                    const result = await callApi(tenantsAddToCurrentDomains(values), true);
                    if (!result || !result.success) return false;
                    await reloadTenantDomains();
                    return true;
                }}
                sidebar={formik => <Button loading={formik.isSubmitting} type="submit" text="Speichern" color="success" />}
            >
                {
                    (formik) => <FieldWithLabel label="Ihre Domain" placeholder="https://www.webseite.de" name="domain" />
                }
            </ModalForm>    
        </WithPermissions>
    )
}