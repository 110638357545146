import React from "react";
import { string } from "yup";
import { useAppSelector } from "../../hooks/reduxHooks";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import useClientUtil from "../../hooks/useClientUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { DatevAddresseeType, DatevDuplicateReason, IAddressee, IUser } from "../../types/ApiTypes";
import { ApiUrl } from "../../util/envVars";
import AddresseeAddress from "./AddresseeAddress";
import ClientTypeBanner, { ClientTypeIcon } from "../clients/ClientTypeBanner";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Icon from "../icons/Icon";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import { AppColor } from "../../app/AppStyles";
import AddresseeDuplicateStatus from "./AddresseeDuplicateStatus";
import AddresseeTypeIcon from "./AddresseeTypeIcon";
import AddresseeRelationshipItem from "./AddresseeRelationshipItem";
import { useAnyUserClients } from "../../state/swr/user/useAnyUserClients";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import WithPermissions from "../permissions/WithPermissions";
import { useImportValidationError } from "../../state/swr/importValidation/useImportValidationError";
import ImportValidationChip from "../importValidation/ImportValidationChip";

export default function AddresseeListItem({addressee}: {addressee: IAddressee}) {
    const { loadingUserClients, userClients } = useAnyUserClients(addressee.user?._id || "");
    const { addressees } = useAddressees();

    const {
        getCurrentTenantAuthority,
        getName
    } = useUserUtil();

    const {
        getAddresseeDuplicates,
        getAddresseeTypeColor,
    } = useAddresseeUtil();

    const addresseeUserExists = (user: IUser): number => {
        if (!user || !user._id) return 0;
        const exists = addressees && addressees.filter(a => a.user && a.user._id === user._id);

        return exists && exists.length ? exists.length - 1 : 0;
    }

    const isAddresseeUserAssignedToOthers = addresseeUserExists(addressee.user);

    const duplicates = getAddresseeDuplicates(addressee);

    return (
        <TableRow>
            <TableCell>
                <div className="d-flex flex-column">
                    <strong>{addressee.actualName}</strong>
                    <AddresseeAddress addressee={addressee} />
                </div>
            </TableCell>
            <TableCell>
                <Flex>
                    <AddresseeDuplicateStatus addressee={addressee} />
                    <Flex>
                        {
                            duplicates && duplicates.map(d => (
                                <Pill key={d._id} color={getAddresseeTypeColor(d)} className="gap-2">
                                    <AddresseeTypeIcon addressee={d} />
                                    {
                                        <span>{d.actualName}</span>
                                    }
                                </Pill>
                            ))                
                        }
                    </Flex>
                </Flex>
            </TableCell>
            <TableCell>
                <div className="d-flex flex-column gap-1">
                    <Pill color={getAddresseeTypeColor(addressee)} className="gap-2">
                        <AddresseeTypeIcon addressee={addressee} showText />
                    </Pill>
                    {
                        addressee.isMissingMail
                        ? <Pill color="#FEDD00">Keine Email</Pill>
                        : addressee.originalMail && (
                            <Pill color="#FFF5FF" className="gap-2">
                                <Icon icon="at" color="error" size={18} />
                                <span>{addressee.originalMail}</span>
                            </Pill>
                        )
                    }
                </div>
            </TableCell>
            <WithPermissions permissions={[ "addresseeRelationships.all.read" ]}>
                <TableCell>
                    {
                        addressee && addressee.relationships && addressee.relationships.length
                        ? (
                            <div className="d-flex flex-row align-items-center flex-wrap gap-1">
                                {
                                    addressee.relationships.map(r => <AddresseeRelationshipItem addressee={addressee} relationship={r} key={r._id} />)
                                }
                            </div>
                        ) 
                        : <span>-</span>
                    }
                </TableCell>
            </WithPermissions> 
            <TableCell>
                <Flex row className="w-100">
                    {
                        loadingUserClients 
                        ? <LoadingSpinner />
                        : (
                            userClients && userClients.clients && userClients.clients.length
                            ? userClients.clients.map(c => (
                                <Pill color="#F0F0FF" className="gap-2" key={c._id}>
                                    <ClientTypeIcon client={c}></ClientTypeIcon>
                                    <span>{c.name}</span>
                                </Pill>
                            ))
                            : <span>-</span>
                        )
                    }
                </Flex>
            </TableCell>
            <TableCell>
                {
                    addressee.user 
                    ? (
                        <Pill className="gap-3 justify-content-start" color={isAddresseeUserAssignedToOthers ? "#FFF0F0" : "#F0F0F0"}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <Icon icon="person-fill" style={{marginBottom: "-5px"}} size={14} />
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <Icon icon="link" color={isAddresseeUserAssignedToOthers ? "error" : "success"} size={18}/>
                                    {
                                        !!isAddresseeUserAssignedToOthers && <em style={{fontSize: "0.8em"}}>+{isAddresseeUserAssignedToOthers} weitere</em>
                                    }
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <strong>{getName(addressee.user)}</strong>
                                <span style={{fontSize: "0.9em"}}>{addressee.user.mailAddress}</span>
                            </div>
                        </Pill>
                    )
                    : <span>-</span>
                }
            </TableCell>
        </TableRow>
    )
}