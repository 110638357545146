import React from "react";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import LoadingSpinner from "../loader/LoadingSpinner";
import useModal from "../../hooks/useModal";
import { Permission } from "../../types/ApiTypes";
import { ModalType } from "../../config/ModalTypes";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../hooks/usePermissions";
import useAuthorized from "../../hooks/useAuthorized";

export interface IWithPermissionProps {
    children: any,
    permissions: Array<Permission> | undefined,
    redirect?: boolean
}

export default function WithPermissions({permissions, redirect = false, children}: IWithPermissionProps) {
    const {
        isAuthorized,
        loading
    } = useAuthorized(permissions);

    const navigate = useNavigate();
    const showModal = useModal();

    React.useEffect(() => {
        if (loading) return;
        if (isAuthorized) return;
        if (!redirect) return;

        showModal({ text: 'Sie haben keine Berechtigung für diese Seite.', type: ModalType.Error });
        navigate(-1);
    }, [isAuthorized, children]);

    if (loading) return <LoadingSpinner />
    if (!isAuthorized) return null;

    return children;
}