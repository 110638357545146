import React, { useEffect } from "react";
import { Status } from "../../config/Status";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import Error from "../../pages/Error";
import { IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserListItem from "./UserListItem";
import { useUsers } from "../../state/swr/user/useUsers";
import { useRoles } from "../../state/swr/roles/useRoles";

export default function UserList() {
    const { users, loadingUsers } = useUsers();
    const { roles, loadingRoles } = useRoles();

    if (loadingRoles || loadingUsers) return <LoadingSpinner centered={false} />

    if (!users || !users.length) return <span>Keine Nutzer gefunden.</span>

    return (
        <Table 
            border 
            className="w-100" 
            headers={[{label: "Nutzer"}, {label: "Status"}, {label: "Globaler Administrator"}, {label: "Entwickler-Zugriff"}, {label: "Aktiv seit"}, {label: "Zuletzt aktualisiert"}, {label: ""}]}
            items={users}
            renderItem={(u: IUser) => <UserListItem key={u._id} user={u} />}
        />
    )
}