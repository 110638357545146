import React from "react";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import { IAddress, IAddressee } from "../../types/ApiTypes";

export default function AddresseeLegalForm({addressee}: {addressee: IAddressee}) {
    
    const {
        getAddresseeLegalForm
    } = useAddresseeUtil();


    const legalForm = getAddresseeLegalForm(addressee);

    return <span>{legalForm}</span>
}