import React from "react";
import useApi from "../../hooks/useApi";
import Button from "../buttons/Button";
import { ITask } from "../../types/ApiTypes";
import { tasksComplete } from "../../api/Api";
import { useTasks } from "../../state/swr/tasks/useTasks";
import { useTicketTasks } from "../../state/swr/tickets/useTicketTasks";

export interface ICompleteTaskButtonProps {
    task: ITask
}

export default function CompleteTaskButton({task}: ICompleteTaskButtonProps) {

    const {
        reloadTasks
    } = useTasks();

    const {
        reloadTicketTasks
    } = useTicketTasks(task?.ticket?._id || "");

    const callApi = useApi();

    if (!task || task.completed) return null;

    const clickHandler = async () => {
        await callApi(tasksComplete({id: task._id }));
        if (task && task.ticket) await reloadTicketTasks();
        await reloadTasks();
    }

    return (
        <Button onClick={clickHandler} text="Als Erledigt markieren" color="success" variant="text" icon="check" />
    )
}