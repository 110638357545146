import useSWR from "swr";
import { IPointChampApiKey, ITenantOpeningHours, ITicketStatistics } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useAnyUserTicketStatistics = (id: string) => {
    const { data, isLoading, mutate } = useSWR<ITicketStatistics>(ApiRoutes.Tickets.Statistics.ForUser.getValue({
        param: RouteParam.UserId,
        value: id
    }), { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        userTicketStatistics: data,
        loadingUserTicketStatistics: isLoading,
        reloadUserTicketStatistics: mutate
    }
}