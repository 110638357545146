import React from "react";
import { useEffect } from "react";
import { addresseesDeleteAll, clientsDeleteAll, usersDeleteAllDatevClients, usersDeleteAllDatevEmployees } from "../../api/Api";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import Button from "../buttons/Button";
import { useUser } from "../../state/swr/user/useUser";
import { useUsers } from "../../state/swr/user/useUsers";
import { useClients } from "../../state/swr/clients/useClients";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { useEmployees } from "../../state/swr/employees/useEmployees";
import { useDatevEmployees } from "../../state/swr/employees/useDatevEmployees";
import { useClientContacts } from "../../state/swr/clientContacts/useClientContacts";
import useDatevImport from "../../hooks/useDatevImport";

export default function DeleteAllDatevImportsButton() {

    const { reloadDatevImport } = useDatevImport();

    const [text, setText] = React.useState<string>();

    const callApi = useApi();

    const { user } = useUser();

    if (!user) return null;
    if (!user.isSuperAdmin) return null;

    const deleteClients = async () => {
        setText("Mandanten werden gelöscht...");
        await callApi(clientsDeleteAll());
        setText("Addressaten werden gelöscht...");
        await callApi(addresseesDeleteAll());
        setText("Mandantenkontakte werden gelöscht...");
        await callApi(usersDeleteAllDatevClients());
        setText("Mitarbeiter werden gelöscht...");
        await callApi(usersDeleteAllDatevEmployees(), true);

        await reloadDatevImport();
    }

    return (
        <Button icon="trash" color="error" text="Alle aus DATEV importierten Daten löschen" loadingText={text} onClick={deleteClients} />
    )
}