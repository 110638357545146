import React from "react";
import { useTenantSignature } from "../../state/swr/signatures/useTenantSignature";
import { Form, Formik } from "formik";
import { IUpdateSignatureRequest } from "../../api/ApiRequests";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import CheckBox from "../formik/CheckBox";
import Editor from "../richText/Editor";
import Button from "../buttons/Button";
import LoadingSpinner from "../loader/LoadingSpinner";
import useApi from "../../hooks/useApi";
import { tenantSignaturesUpdate } from "../../api/Api";
import WithPermissions from "../permissions/WithPermissions";

export default function SignatureUpdateForm() {

    const callApi = useApi();

    const { 
        loadingTenantSignature, 
        reloadTenantSignature, 
        tenantSignature 
    } = useTenantSignature();

    return (
        <WithPermissions permissions={["tenant.own.update"]}>
            <Formik
                initialValues={{
                    contentOnNewMail: tenantSignature?.contentOnNewMail || "",
                    contentOnReply: tenantSignature?.contentOnReply || "",
                    isActive: tenantSignature?.isActive || false,
                    useSameSignatureOnReply: tenantSignature?.useSameSignatureOnReply || true,
                    useSignatureOnForward: tenantSignature?.useSignatureOnForward || false
                } as IUpdateSignatureRequest}
                enableReinitialize
                onSubmit={async (values) => {
                    const res = await callApi(tenantSignaturesUpdate(values));

                    if (!res || !res.success) return;

                    await reloadTenantSignature();
                }}
            >
                {
                    formik => (
                        loadingTenantSignature 
                        ? <LoadingSpinner /> 
                        : (
                            <Form className="w-100">
                                <Flex fullWidth>
                                    <Flex row justify="between" fullWidth>
                                        <Typography size={20} bold color="primary">Signatur</Typography>
                                        <CheckBox name="isActive" label="Signatur ist aktiv" />
                                    </Flex>
                                    {
                                        formik.values.isActive && (
                                            <Flex fullWidth>
                                                <Typography bold>Für neue Mails</Typography>
                                                <Editor 
                                                    content={formik.values.contentOnNewMail}
                                                    onChange={val => formik.setFieldValue("contentOnNewMail", val)}
                                                    disabled={formik.isSubmitting}
                                                    useFieldStyle
                                                />
                                                <CheckBox 
                                                    label="Bei Weiterleitung Signatur verwenden"
                                                    name="useSignatureOnForward"
                                                />
                                                <CheckBox 
                                                    label="Selbe Signatur für Antworten / Weiterleitungen verwenden"
                                                    name="useSameSignatureOnReply"
                                                />
                                                {
                                                    !formik.values.useSameSignatureOnReply && (
                                                        <>
                                                            <Typography bold>Für neue Mails</Typography>
                                                            <Editor 
                                                                content={formik.values.contentOnReply}
                                                                onChange={val => formik.setFieldValue("contentOnReply", val)}
                                                                disabled={formik.isSubmitting}
                                                                useFieldStyle
                                                            />
                                                        
                                                        </>
                                                    )
                                                }
                                            </Flex>
                                        )
                                    }
                                    <Button type="submit" text="Speichern" icon="save" loading={formik.isSubmitting} />
                                </Flex>
                            </Form>
                        )
                    )
                }
            </Formik>
        </WithPermissions>
    )
}