import React from "react";
import Flex from "../../container/Flex";
import Expandable from "../../comboBox/Expandable";
import { useClients } from "../../../state/swr/clients/useClients";
import { IClient } from "../../../types/ApiTypes";
import useFilteredTickets from "../../../hooks/useFilteredTickets";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import Icon from "../../icons/Icon";
import Typography from "../../text/Typography";
import SearchBox from "../../search/SearchBox";

export default function TicketClientFilter() {

    const [selectedClient, setSelectedClient] = React.useState<IClient>();
    const [filter, setFilter] = React.useState<string>("");

    const {
        client,
        updateFilter
    } = useFilteredTickets();

    const {
        clients,
        loadingClients
    } = useClients();

    React.useEffect(() => {
        if (!client) {
            setSelectedClient(undefined);
            return;
        }

        if (!clients || !clients.length) {
            setSelectedClient(undefined);
            return;
        }

        const foundClient = clients.find(c => c._id === client);

        if (!foundClient) {
            setSelectedClient(undefined);
            return;
        }

        setSelectedClient(foundClient);
    }, [client]);
    
    const updateFilterToClient = (client?: IClient) => {
        if (!client) {
            updateFilter({
                client: ""
            });
            setSelectedClient(undefined);
            return;
        }

        updateFilter({
            client: client._id
        });

        setSelectedClient(client);
    }

    if (loadingClients) return null;
    if (!clients || !clients.length) return null;

    const filteredClients = clients.filter(c => c.name.toLowerCase().includes(filter.toLowerCase()));

    return (
        <Flex row>
            {
                selectedClient && <Icon icon="x" onClick={() => updateFilterToClient(undefined)} />
            }
            <Expandable 
                expander={
                    selectedClient
                    ? <ClientTypeBanner client={selectedClient} showClientName />
                    : <Typography bold upper color="muted">Nach Mandant filtern</Typography>
                } 
            >
                {
                    close => (
                        <Flex align="end" className="p-2" style={{minWidth: "300px"}}> 
                            <div style={{ position: "sticky", top: "10px", zIndex: 1}} className="w-100">
                                <div className="w-100 position-relative">
                                    <input
                                        className="form-control w-100"
                                        
                                        placeholder="Mandant suchen..."
                                        value={filter}
                                        onChange={e => setFilter(e.target.value)}
                                    />
                                    <Icon 
                                        icon={filter ? "x" : "search"}
                                        color="primary"
                                        wrapperClass="position-absolute top-50 end-0 translate-middle-y" 
                                        style={{paddingRight: "15px"}}
                                        onClick={() => setFilter("")}
                                    /> 
                                </div>
                            </div>
                            {
                                filteredClients && !!filteredClients.length
                                ? filteredClients.map(c => (
                                    <ClientTypeBanner 
                                        className="w-100" 
                                        onClick={() => {
                                            updateFilterToClient(c)
                                            close()
                                        }} 
                                        key={c._id} 
                                        client={c} 
                                        showClientName 
                                    />
                                ))
                                : (
                                    filter 
                                    ? "Keine Ergebnisse"
                                    : "Keine Mandanten"
                                )
                            }
                        </Flex>
                    )
                }
            </Expandable>
        </Flex>
    )
}