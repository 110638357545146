import useSWR from "swr";
import { IAddressee, IAlias, IDatevAreaOfResponsibility, IDomain } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useTenantDomains = () => {
    const { data, isLoading, mutate } = useSWR<Array<IDomain>>(ApiRoutes.Tenant.CurrentTenantDomains);

    return {
        tenantDomains: data,
        loadingTenantDomains: isLoading,
        reloadTenantDomains: mutate
    }
}