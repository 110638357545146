import useSWR from "swr";
import { IUserStatistics } from "../../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";

export const useTenantUserStatistics = () => {
    const { data, isLoading, mutate } = useSWR<IUserStatistics>(ApiRoutes.User.Statistics.CurrentTenant, { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        tenantUserStatistics: data,
        loadingTenantUserStatistics: isLoading,
        reloadTenantUserStatistics: mutate
    }
}