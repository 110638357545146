import { IApiResponse, isErrorApiResponse } from "../api/Api"
import { ModalType } from "../config/ModalTypes";
import { Locale } from "../locale/Locale";
import useModal from "./useModal";

export default function useApi() {

    const showModal = useModal();

    return async<T = any> (apiCall: Promise<IApiResponse<T>>, showMessageOnSuccess: boolean = false, message: string = "Erfolg"): Promise<IApiResponse<T> | null> => {
        const result = await apiCall;

        if (result && result.canceled) return null;

        if (!result) {
            showModal({
                text: Locale.errors.generic,
                type: ModalType.Error 
            });

            return null;
        }

        if (isErrorApiResponse(result)) {
            showModal({
                text: result.message || Locale.errors.generic,
                type: ModalType.Error
            });

            return null;
        }
        
        if (showMessageOnSuccess) showModal({
            text: message,
            type: ModalType.Success
        });

        return result;
    }
}