import React from "react";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { azureGetSignInUrl } from "../../api/Api";
import { OfficeAccountType, Permission } from "../../types/ApiTypes";
import { IAzureGetSignInUrlRequest } from "../../api/ApiRequests";
import WithPermissions from "../permissions/WithPermissions";
import { useUser } from "../../state/swr/user/useUser";
import useInboxPermissions from "../../hooks/useInboxPermissions";

export default function LinkOfficeAccountButton(props: IAzureGetSignInUrlRequest) {

    const callApi = useApi();

    const {
        getInboxPermissions
    } = useInboxPermissions();

    const isPersonalWithoutUser = props.type === OfficeAccountType.Personal && !props.onBehalfOfUser;
    const isReAuth = !!props.reAuthOfficeAccountId;

    const text = isReAuth 
    ? "Authentifizierung erforderlich" 
    : (
        isPersonalWithoutUser 
        ? "Bitte loggen Sie sich ein" 
        : "Office365-Account verknüpfen"
    );

    const color = isReAuth ? "error" : "primary";
    const icon = isReAuth ? "exclamation" : "plus";

    const permissions = getInboxPermissions("create", props.type, props.onBehalfOfUser);

    return (
        <WithPermissions permissions={permissions} >
            <Button 
                disabled={!isReAuth && isPersonalWithoutUser}
                text={text}
                color={color}
                onClick={async () => {
                    const res = await callApi(azureGetSignInUrl(props));
    
                    if (!res || !res.success) return;
                    
                    window.location.href = res.data.url;
                }}
                icon={icon}
            />
        </WithPermissions>
    )
}