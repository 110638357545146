import React from "react";
import { IAddressee } from "../../types/ApiTypes";

export default function AddresseeAddress({addressee}: {addressee: IAddressee}) {
    if (!addressee) return null;
    if (!addressee.addresses || !addressee.addresses.length) return null;

    const a = addressee.addresses[0];

    if (!a) return null;

    return (
        <div className="d-flex flex-column">
            {a.post_office_box && <span>{a.post_office_box}</span>}
            <span>{a.street}</span>
            <span>{a.postal_code} {a.city}</span>
        </div>
    )
}