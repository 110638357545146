import React, { PropsWithChildren, ReactNode } from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import useWindowSize from "../../hooks/useWindowSize";
import { IExpandOrigin } from "../comboBox/Expandable";
import "./Select.css";
import Typography, { TypographySize } from "../text/Typography";
import Button from "../buttons/Button";

export interface ISelectItem<T> {
    label: string,
    data: T
}

export interface ISelectProps<T> {
    values: Array<ISelectItem<T>>,
    value?: T,
    bold?: boolean,
    size?: TypographySize,
    onChange: (value: T) => void,
    label?: string
}

export default function Select<T = string | number>({values, bold, label, onChange, size, value}: ISelectProps<T>) {

    const [currentValue, setCurrentValue] = React.useState<ISelectItem<T> | undefined>();
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [expandOrigin, setExpandOrigin] = React.useState<IExpandOrigin>();
    const [expandToTop, setExpandToTop] = React.useState<boolean>(false);
    const [expandFromRight, setExpandFromRight] = React.useState<boolean>(false);

    const windowSize = useWindowSize();

    const expanderRef = React.useRef<HTMLDivElement>(null);
    const closeTimer = React.useRef<any>();
    
    React.useEffect(() => {
        if (!value) return setCurrentValue(undefined);
        if (!values || !values.length) return setCurrentValue(undefined);

        const matchingValue = values.find(v => v.data === value);

        if (!matchingValue) return setCurrentValue(undefined);

        setCurrentValue(matchingValue)

    }, [value, values]);

    React.useEffect(() => setExpanded(false), [windowSize]);

    const toggleExpanded = () => {
        if (expanded) return setExpanded(false);
        
        setExpanded(true);

        if (!expanderRef || !expanderRef.current) return;
    
        const location = expanderRef.current.getBoundingClientRect();

        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        setExpandToTop((location.top > (windowHeight / 2)));
        setExpandFromRight((location.left > (windowWidth / 2)));

        setExpandOrigin({
            top: location.top,
            left: location.left,
            bottom: location.bottom,
            right: location.right,
            height: location.height,
            width: location.width
        })
    }

    const sortByContentClass = generateClassName("select-button-content d-flex flex-column gap-0", {
        base: "select-button-content-expand-to-",
        value: expandToTop,
        onTrue: "top",
        standard: "bottom"
    }, {
        base: "select-button-content-expand-from-",
        value: expandFromRight,
        onTrue: "right",
        standard: "left",
    });

    const closeExpander = () => {
        clearTimeout(closeTimer.current)
        closeTimer.current = setTimeout(() => setExpanded(false), 150);
    }

    const clickHandler = (o: ISelectItem<T>) => {
        closeExpander();
        onChange(o.data);
    }

    return (
        <div 
            className="d-flex flex-column position-relative gap-2 select-button" 
            onMouseLeave={closeExpander} 
        >
            {
                label && <Typography bold={bold} color="primary">{label}</Typography>
            }
            <div 
                className="select-button-expander" 
                onClick={toggleExpanded}
                ref={expanderRef}
            >
                <Typography size={size} bold color={currentValue ? "primary" : "muted"} upper>{currentValue ? currentValue.label : "Auswählen..."}</Typography>
            </div>
            {
                expanded && expandOrigin && (
                    <div 
                        className={sortByContentClass} 
                        style={generateStyle({
                            name: "top",
                            value: expandOrigin.top - 12,
                            applyCondition: !expandToTop
                        }, {
                            name: "left",
                            value: expandOrigin.left,
                            applyCondition: !expandFromRight
                        }, {
                            name: "right",
                            value: window.innerWidth - expandOrigin.right,
                            applyCondition: expandFromRight
                        }, {
                            name: "bottom",
                            value: window.innerHeight - expandOrigin.bottom - 12,
                            applyCondition: expandToTop
                        }, {
                            name: "transformOrigin",
                            value: `${expandFromRight ? "right" : "left"} ${expandToTop ? "top" : "bottom"}`,
                        })}
                    >
                        <div className="d-flex flex-column pb-2 pt-2 gap-0 w-100 h-100 text-center">
                            {
                                values && values.map((o: ISelectItem<T>) => (
                                    <div className="p-1 ps-2 pe-2 select-button-item" onClick={() => clickHandler(o)}>
                                        <Typography size={size} bold color={value && value === o.data ? "primary" : "muted"} upper>{o.label}</Typography>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

