import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tenantsOnboardingAddToCurrentDomains, tenantsOnboardingGetRolesForTenant, tenantsOnboardingCreateManagingUser, tenantsOnboardingGetCurrentDomainStatus, tenantsOnboardingGetCurrentState, tenantsOnboardingGetGlobalService, tenantsOnboardingGetInitialPassword, tenantsOnboardingSelectBaseSubscription, tenantsOnboardingSetBillingData, tenantsOnboardingUpdate, tenantsOnboardingValidateTenantSubdomain } from "../../../api/Api";
import { ITenantOnboardingAddSubscriptionRequest, ITenantOnboardingCreateInitialUserRequest, ITenantOnboardingDomainAddRequest, ITenantOnboardingUpdateBillingDataRequest, ITenantOnboardingUpdateRequest } from "../../../api/ApiRequests";
import { AppRouteParams, AppRoutes } from "../../../config/AppRoutes";
import { ModalType } from "../../../config/ModalTypes";
import useApi from "../../../hooks/useApi";
import { generateClassName } from "../../../hooks/useAttributes";
import useModal from "../../../hooks/useModal";
import useRoleUtil from "../../../hooks/useRoleUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import { IDomain, IPlan, IRole, ITenant, UserRole } from "../../../types/ApiTypes";
import { RootUrl, StripePK } from "../../../util/envVars";
import { buildUrlFromDomain } from "../../../util/util";
import Button from "../../buttons/Button";
import CopyToClipboardButton from "../../buttons/CopyToClipboardButton";
import Card from "../../card/Card";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import FieldWithLabel from "../../formik/FormikField";
import Icon from "../../icons/Icon";
import LoadingSpinner from "../../loader/LoadingSpinner";
import PageLoader from "../../loader/PageLoader";
import RoleBanner from "../../user/RoleBanner";
import MultiStepWizard from "../../wizard/MultiStepWizard";
import MultiStepWizardPage from "../../wizard/MultiStepWizardPage";
import TenantBillingDetailsForm from "../TenantBillingDetailsForm";
import OnboardingPaymentDetails from "./elements/OnboardingPaymentDetails";
import "./TenantOnboardingWizard.css";
import useAnyRoleUtil from "../../../hooks/useAnyRoleUtil";

interface ITenantOnboardingCreateInitialUserFormValues extends ITenantOnboardingCreateInitialUserRequest {
    role?: IRole
}

enum TenantOnboardingPage {
    GetTenantAdminInformation = 0,
    GetCompanyBillingDetails = 1,
    SelectSubscription = 2,
    GetCompanyPaymentDetails = 3,
    GetTenantNiceName = 4,
    CreateTenantDomain = 5,
    SuccessPage = 6
}

const stripePromise = loadStripe(StripePK);

export default function TenantOnboardingWizard() {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [roles, setRoles] = React.useState<IRole[]>([]);
    const [domain, setDomain] = React.useState<IDomain>({} as IDomain);
    const [domainLoading, setDomainLoading] = React.useState<boolean>(false);
    const [tenant, setTenant] = React.useState<ITenant | null>(null);
    const [initialIndex, setInitialIndex] = React.useState<TenantOnboardingPage>(TenantOnboardingPage.GetTenantAdminInformation);
    const [checkDomainStatusTimer, setCheckDomainStatusTimer] = React.useState<any>();
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [passwordLoading, setPasswordLoading] = React.useState<boolean>(false);
    const [stripeClientSecret, setStripeClientSecret] = React.useState<string>("");
    const [globalSubscriptions, setGlobalSubscriptions] = React.useState<Array<IPlan>>([]);
    
    const {
        getFilteredRoles,
    } = useAnyRoleUtil();
    
    const {
        getName,
        getAuthority
    } = useUserUtil();

    const verificationToken = useParams()[AppRouteParams.VerificationToken.keyName];

    const callApi = useApi();
    const showModal = useModal();
    const navigate = useNavigate();
    
    const getNewInitialPassword = async (abortController?: AbortController ) => {
        if (!verificationToken) return;
        if (!tenant || !tenant._id) return;

        setPasswordLoading(true);
        const pw = await callApi(tenantsOnboardingGetInitialPassword({registrationToken: verificationToken, tenantId: tenant._id}, abortController));

        setPasswordLoading(false);

        if (!pw) return;
        if (!pw.data.password) return;

        setNewPassword(pw.data.password);
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        clearInterval(checkDomainStatusTimer);
        
        if (!verificationToken) return;

        if (!tenant) {
            setInitialIndex(TenantOnboardingPage.GetTenantAdminInformation);
            return;
        }
        
        const authority = getAuthority(tenant.onboardedBy, tenant);

        if (!tenant.onboardedBy || !tenant.onboardedBy.firstName || !tenant.onboardedBy.lastName || !authority || !authority.userAuthority || !authority.userAuthority.role || !authority.userAuthority.isTenantAdmin) {
            setInitialIndex(TenantOnboardingPage.GetTenantAdminInformation);
            return;
        }

        if (!tenant.address || !tenant.company) {
            setInitialIndex(TenantOnboardingPage.GetCompanyBillingDetails);
            return;
        } 

        if (!tenant.subscriptions || !tenant.subscriptions.length) {
            setInitialIndex(TenantOnboardingPage.SelectSubscription);
            setLoading(true);

            const fetchGlobalSubscription = async () => {
                const globalSubs = await callApi(tenantsOnboardingGetGlobalService({registrationToken: verificationToken, tenantId: tenant._id}, abortController));

                if (!globalSubs || !globalSubs.success) {
                    setLoading(false);
                    return;
                }

                setGlobalSubscriptions(globalSubs.data);
                setLoading(false);
            }

            fetchGlobalSubscription();
            
            return () => abortController.abort();
        }

        if (!tenant.hasPaymentDetails && stripeClientSecret) {
            setInitialIndex(TenantOnboardingPage.GetCompanyPaymentDetails);
            return;
        }

        if (!tenant.name) {
            setInitialIndex(TenantOnboardingPage.GetTenantNiceName);
            return;
        }

        if (!tenant.domains || !tenant.domains.length) {
            setInitialIndex(TenantOnboardingPage.CreateTenantDomain);
            return;
        }

        const domain = typeof tenant.domains[0] !== "string" ? tenant.domains[0] : null;
        
        if (!domain) {
            setInitialIndex(TenantOnboardingPage.CreateTenantDomain);
            return;
        }

        setDomain(domain);
        
        if (!domain.isVerified) {
            const interval = setInterval(async () => {
                console.log(`Refreshing domain status for domain '${domain.domain}...'`);
                setDomainLoading(true);
    
                const newDomain = await callApi<IDomain>(tenantsOnboardingGetCurrentDomainStatus({domainId: domain._id, tenantId: tenant._id, registrationToken: verificationToken}, abortController));
    
                if (!newDomain) {
                    setDomainLoading(false);
                    return;
                } 
                
                if (newDomain.data.isVerified) {
                    window.location.reload();
                    return;
                }
    
                setDomainLoading(false);
                setDomain(newDomain.data);
            }, 30000);
            
            setCheckDomainStatusTimer(interval);
            setInitialIndex(TenantOnboardingPage.CreateTenantDomain);
            return;
        }

        getNewInitialPassword(abortController);
        setInitialIndex(TenantOnboardingPage.SuccessPage);

        return () => abortController.abort();
    }, [tenant]);
    
    React.useEffect(() => {
        if (!verificationToken) {
            showModal({
                text: "Bitte geben Sie einen gültigen Verifizierungscode an.",
                type: ModalType.Error
            });
    
            navigate(AppRoutes.Home.path);
            return;
        }

        const abortController = new AbortController();
        setLoading(true);

        const verifyToken = async () => {
            const res = await tenantsOnboardingGetCurrentState({
                token: verificationToken
            }, abortController);

            if (res.canceled) return;

            if (!res || !res.success || !res.data) {
                showModal({
                    text: res.message || "Der angegebene Code ist ungültig.",
                    type: ModalType.Error
                });
            
                navigate(AppRoutes.Home.path);
                return;
            }

            if (res.data.domains?.length && typeof res.data.domains[0] !== "string" && res.data.domains[0].isVerified) {
                // window.location.href = buildUrlFromDomain(res.data.domains[0]);
            }

            setTenant(res.data);
            setLoading(false);
        }

        verifyToken();

        return () => abortController.abort();
    }, [verificationToken]);

    React.useEffect(() => {
        if (!verificationToken) return;
        if (!tenant) return;

        const abortController = new AbortController();
        setLoading(true);

        const fetchRoles = async () => {
            try {
                const res = await tenantsOnboardingGetRolesForTenant({
                    registrationToken: verificationToken,
                    tenantId: tenant._id
                }, abortController);

                if (!res || !res.success || res.canceled) return;

                setRoles(res.data ?? []);
            }
            finally {
                setLoading(false);
            }
        }

        fetchRoles();

        return () => abortController.abort();
    }, [tenant, verificationToken]);

    if (loading) return <PageLoader />
    if (!tenant) return null;
    if (!verificationToken) return null;

    const actualRoles = getFilteredRoles(roles, r => !r.isClient);

    return (
        <MultiStepWizard initialIndex={initialIndex}>
            {
                (nextPage) => {
                    
                    const refreshDomain = async () => {
                        setDomainLoading(true);

                        const newDomain = await callApi<IDomain>(tenantsOnboardingGetCurrentDomainStatus({domainId: domain._id, tenantId: tenant._id, registrationToken: verificationToken}));

                        if (!newDomain) {
                            setDomainLoading(false);
                            return;
                        } 
                        
                        if (newDomain.data.isVerified) {
                            setDomainLoading(false);
                            nextPage();
                            await getNewInitialPassword();
                            return;
                        }

                        setDomainLoading(false);
                        setDomain(newDomain.data);
                    }

                    return [
                        <MultiStepWizardPage>
                            <Formik
                                initialValues={{
                                    firstName: tenant.onboardedBy && tenant.onboardedBy.firstName || "",
                                    lastName: tenant.onboardedBy && tenant.onboardedBy.lastName || "",
                                    roleId: "",
                                    _id: tenant._id,
                                    name: tenant.name || "",
                                    registrationToken: verificationToken,
                                    role: undefined
                                } as ITenantOnboardingCreateInitialUserFormValues}
                                onSubmit={async (values) => {
                                    if (!values) return;

                                    if (!values.role) {
                                        showModal({
                                            text: "Bitte wählen Sie eine Rolle aus.",
                                            type: ModalType.Error
                                        });
                                        return;
                                    }

                                    values.roleId = values.role._id;

                                    const res = await callApi(tenantsOnboardingCreateManagingUser(values));

                                    if (!res) return;

                                    nextPage();
                                    setTenant(res.data);
                                }}
                            >
                                {
                                    (formik) => (
                                        <Form className="d-flex flex-column h-100 w-100 p-5 position-relative">
                                            <div className="position-absolute top-25 p-5">
                                                <h1 className="display-1 fw-bold" style={{color: "var(--primaryAccent)"}}>Willkommen!</h1>
                                                <h1>Ihr neues Ticketsystem wartet auf Sie!</h1>
                                            </div>
                                            <div className="w-100 h-100 d-flex flex-column justify-content-center p-5 gap-2">
                                                <h6 className="fw-bold">Bitte stellen Sie sich vor.</h6>
                                                <div className="d-flex flex-row align-items-center w-100 gap-3">
                                                    <FieldWithLabel bold label="Ihr Vorname" name="firstName" className="w-100"/>
                                                    <FieldWithLabel bold label="Ihr Nachname" name="lastName" className="w-100"/>
                                                </div>
                                                <SearchableComboBox 
                                                    label="Ihre Rolle im Unternehmen"
                                                    renderItem={r => <RoleBanner role={r} />}
                                                    renderValue={r => <RoleBanner role={r} />}
                                                    placeholder="Ihre Rolle im Unternehmen..."
                                                    bold
                                                    className="w-100 mb-3"
                                                    values={actualRoles || roles || []} 
                                                    onItemClick={(r) => formik.setFieldValue("role", r)}
                                                    value={formik.values.role} 
                                                    itemToId={(role: IRole) => role._id} 
                                                    itemToString={(role: IRole) => role.displayName} 
                                                />
                                                <Button type="submit" text="Weiter" loading={formik.isSubmitting} />
                                            </div>
                                            <Button className="align-self-end" externalLink to={AppRoutes.Feedback.path} text="Feedback"/>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </MultiStepWizardPage>,
                        <MultiStepWizardPage>
                            <div className="d-flex flex-column h-100 w-100 p-5 position-relative">
                                <div className="position-absolute top-25">
                                    <h1 className="display-1 fw-bold" style={{color: "var(--primaryAccent)"}}>Willkommen!</h1>
                                    <h1>Ihr neues Ticketsystem wartet auf Sie!</h1>
                                </div>
                                <Formik
                                    initialValues={{
                                        address: {
                                            firstLine: "",
                                            secondLine: "",
                                            postalCode: "",
                                            city: "",
                                            state: "",
                                            country: "",
                                            countryCode: ""
                                        },
                                        company: "",
                                        registrationToken: verificationToken,
                                        _id: tenant._id
                                    } as ITenantOnboardingUpdateBillingDataRequest}
                                    onSubmit={async (values) => {
                                        const res = await callApi(tenantsOnboardingSetBillingData(values));

                                        if (!res) return;

                                        setTenant(res.data.tenant);
                                        nextPage();
                                    }}
                                >
                                    {
                                        (formik) => (
                                            <div className="d-flex flex-column h-100 align-items-center justify-content-center w-100">
                                                <div className="d-flex flex-column w-100">
                                                    <h6 className="fw-bold">Bitte teilen Sie uns etwas mehr über Ihre Kanzlei mit.</h6>
                                                    <TenantBillingDetailsForm formik={formik} />
                                                </div>
                                            </div>
                                        )

                                    }
                                </Formik>
                                <Button className="align-self-end" externalLink to={AppRoutes.Feedback.path} text="Feedback"/>
                            </div>
                        </MultiStepWizardPage>,
                        <MultiStepWizardPage>
                            <div className="d-flex flex-column h-100 w-100 p-5 position-relative">
                                <div className="position-absolute top-25">
                                    <h1 className="display-1 fw-bold" style={{color: "var(--primaryAccent)"}}>Willkommen!</h1>
                                    <h1>Ihr neues Ticketsystem wartet auf Sie!</h1>
                                </div>
                                {
                                    globalSubscriptions && !!globalSubscriptions.length 
                                    ? (
                                        <Formik
                                            initialValues={{
                                                planId: globalSubscriptions[0]._id,
                                                tenantId: tenant._id,
                                                promoCode: "",
                                                registrationToken: verificationToken
                                            } as ITenantOnboardingAddSubscriptionRequest}
                                            onSubmit={async (values) => {
                                                const res = await callApi(tenantsOnboardingSelectBaseSubscription(values));

                                                if (!res) return;

                                                const {
                                                    tenant,
                                                    secret
                                                } = res.data;

                                                setTenant(tenant);
                                                setStripeClientSecret(secret);

                                                nextPage();
                                            }}
                                        >
                                            {
                                                formik => (
                                                    <Form className="d-flex flex-column h-100 w-100 position-relative">
                                                        <div className="d-flex flex-column h-100 align-items-center justify-content-center w-100">
                                                            <div className="d-flex flex-column w-100">
                                                                <Card>
                                                                    <h6 className="fw-bold">Gutschein-Code</h6>
                                                                    <p>Sichern Sie sich exklusive Rabatte und Vergünstigungen mit einem Gutscheincode.</p>
                                                                    <FieldWithLabel bold label="Gutscheincode" name="promoCode" placeholder="ABCDEF..." className="w-100"/>
                                                                    <Button type="submit" text="Weiter" loading={formik.isSubmitting} />
                                                                </Card>

                                                            </div>
                                                        </div>
                                                        <Button className="align-self-end" externalLink to={AppRoutes.Feedback.path} text="Feedback"/>
                                                    </Form>
                                                )
                                            }
                                        </Formik>
                                    )
                                    : <span>Aktuell ist kein Abonnement verfügbar. Bitte kontaktieren Sie uns.</span>
                                }
                            </div>
                        </MultiStepWizardPage>,
                        <MultiStepWizardPage>
                            {
                                stripeClientSecret 
                                ? (
                                    <Elements stripe={stripePromise} options={{clientSecret: stripeClientSecret, loader: "auto", appearance: { theme: "stripe" } }} >
                                        <OnboardingPaymentDetails clientSecret={stripeClientSecret} nextPage={nextPage} tenantId={tenant._id} registrationToken={verificationToken} />
                                    </Elements>
                                )
                                : <span>Es ist ein Fehler aufgetreten</span>
                            }
                        </MultiStepWizardPage>,
                        <MultiStepWizardPage>
                            <Formik
                                initialValues={{
                                    _id: tenant._id,
                                    name: tenant.name || tenant.company || "",
                                    registrationToken: verificationToken
                                } as ITenantOnboardingUpdateRequest}
                                onSubmit={async (values, actions) => {
                                    if (!values) return;

                                    const res = await callApi(tenantsOnboardingUpdate(values));

                                    if (!res) return;

                                    nextPage();
                                }}
                            >
                                {
                                    (formik) => (
                                        <Form className="d-flex flex-column h-100 w-100 p-5 position-relative">
                                            <div className="position-absolute top-25 p-5">
                                                <h1 className="display-1 fw-bold" style={{color: "var(--primaryAccent)"}}>Hallo, {getName(tenant.onboardedBy)}!</h1>
                                                <h1>Ihr neues Ticketsystem wartet auf Sie!</h1>
                                            </div>
                                            <div className="w-100 h-100 d-flex flex-column justify-content-center p-5">
                                                <h6 className="fw-bold">Bitte geben Sie den Anzeigenamen für Ihr Portal ein.</h6>
                                                <p>Dieser Name wird an mehreren Stellen im Portal verwendet, etwa als Absender für E-Mails an Ihre Mandanten oder als Vorschlag für Ihre Domain auf unserem System. Es handelt sich hier lediglich um den Anzeigenamen und er muss daher nicht die Rechtsform Ihrer Kanzlei oder ähnliche Bestandteile enthalten.</p>
                                                <FieldWithLabel bold label="Name der Kanzlei" name="name" className="w-100"/>
                                                <Button type="submit" text="Weiter" loading={formik.isSubmitting} />
                                            </div>
                                            <Button className="align-self-end" externalLink to={AppRoutes.Feedback.path} text="Feedback"/>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </MultiStepWizardPage>,
                        <MultiStepWizardPage>
                            <Formik
                                initialValues={{
                                    subdomain: (tenant.name && tenant.name.replace(" ", "-").replaceAll(".", "").replaceAll(" ", "").toLowerCase()) || "",
                                    _id: tenant._id,
                                    registrationToken: verificationToken
                                } as ITenantOnboardingDomainAddRequest}
                                onSubmit={async (values, actions) => {
                                    if (!values) return;

                                    const res = await callApi(tenantsOnboardingAddToCurrentDomains(values));

                                    if (!res) return;

                                    setDomain(res.data);
                                    nextPage();
                                }}
                            >
                                {
                                    (formik) => (
                                        <Form className="d-flex flex-column h-100 w-100 p-5 position-relative">
                                            <div className="position-absolute top-25 p-5">
                                                <h4>Ihre Kanzlei</h4>
                                                <h1 className="display-1 fw-bold" style={{color: "var(--primaryAccent)"}}>Nur noch ein Schritt...</h1>
                                                <h1>Wir personalisieren unseren Service für Sie!</h1>
                                            </div>
                                            <div className="w-100 h-100 d-flex flex-column justify-content-center p-5">
                                                <h6 className="fw-bold">Ihr Portal - Ihre Domain!</h6>
                                                <p>Um unseren Service für Ihre Mandanten zur Verfügung zu stellen, benötigen Sie eine Domain. Sie erhalten von uns eine Basis-Domain. Mithilfe unseres kinderleichten und vollautomatisierten Authentifizierungsprozess können Sie mit wenigen DNS-Einträgen auf Ihre eigene Subdomain umstellen. Anschließend steht der Service nach wenigen Minuten unter der angegebenen Adresse zur Verfügung.</p>
                                                <h6>Ihre Start-Domain</h6>
                                                {
                                                    (!domain || !domain.domain) && <TenantDomainField name="subdomain" values={formik.values} tenant={tenant} saveValue={(v) => formik.setFieldValue("domain", v)} />
                                                }
                                                <Button type="submit" text="Weiter" loading={formik.isSubmitting} />
                                            </div>
                                            <Button className="align-self-end" externalLink to={AppRoutes.Feedback.path} text="Feedback"/>
                                        </Form>
                                    )
                                }
                            </Formik>
                        </MultiStepWizardPage>,
                        <MultiStepWizardPage>
                            <div className="d-flex flex-column h-100 w-100 p-5 position-relative">
                                <div className="position-absolute top-25 p-5">
                                    <h4>Ihre Kanzlei</h4>
                                    <h1 className="display-1 fw-bold" style={{color: "var(--primaryAccent)"}}>Verifizerung läuft...</h1>
                                    <h1>Wir legen Ihre Domain an!</h1>
                                </div>
                                <div className="w-100 h-100 d-flex flex-column justify-content-center p-5">
                                    <h6 className="fw-bold">Ihre Domain</h6>
                                    <p>Sie müssen nichts mehr tun. Nachfolgend können Sie einsehen, ob Ihre Domain bereits angelegt wurde. Wir informieren Sie, sobald wir fertig sind. Dieser Vorgang dauert in der Regel bis zu fünf Minuten.</p>
                                    <Card>
                                        <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                                            <div className="d-flex flex-row align-items-center gap-3">
                                                <LoadingSpinner />
                                                <div>
                                                    <h6 className="fw-bold m-0 p-0">{domain.domain}</h6>
                                                    <span>Domain wird erstellt...</span>
                                                </div>
                                            </div>
                                            <Button disabled={domain.isVerified} onClick={refreshDomain} loading={domainLoading} icon="arrow-clockwise" text="Status Aktualisieren" loadingText="Lädt Status..." className="float-end me-3" />
                                        </div>
                                    </Card>
                                </div>
                                <Button className="align-self-end" externalLink to={AppRoutes.Feedback.path} text="Feedback"/>
                            </div>
                        </MultiStepWizardPage>,
                        <MultiStepWizardPage>
                            <div className="d-flex flex-column h-100 w-100 p-5 position-relative">
                                <div className="position-absolute top-25 p-5">
                                    <h4>Ihre Kanzlei</h4>
                                    <h1 className="display-1 fw-bold" style={{color: "var(--success)"}}>Verifizierung abgeschlossen!</h1>
                                    <h1>Glückwunsch! Sie haben sich erfolgreich registriert.</h1>
                                </div>
                                <div className="w-100 h-100 d-flex flex-column justify-content-center p-5">
                                    <h6 className="fw-bold">Vielen Dank, dass Sie sich für unseren Service entschieden haben.</h6>
                                    <p>Loggen Sie sich nun mit Ihrer E-Mail-Addresse und folgendem Einmalpasswort an.</p>
                                    <div>
                                        <h6 className="fw-bold">Einmalpasswort</h6>
                                        <div className="d-flex flex-row align-items-center gap-2">
                                            <CopyToClipboardButton value={newPassword} />
                                            <h1 className="fw-bold m-0 p-0" style={{color: "var(--success)"}}>
                                                {
                                                    passwordLoading 
                                                    ? "--------"
                                                    : newPassword
                                                }
                                            </h1>
                                            {
                                                passwordLoading
                                                ? <LoadingSpinner />
                                                : <Icon onClick={getNewInitialPassword} icon="arrow-clockwise" size={28}/>
                                            }
                                        </div>
                                    </div>
                                    <h1></h1>
                                    <Button text="Zum Log-In" externalLink openInNewTab to={buildUrlFromDomain(domain)}  color="success" className="align-self-start" />
                                </div>
                                <Button className="align-self-end" externalLink to={AppRoutes.Feedback.path} text="Feedback"/>
                            </div>
                        </MultiStepWizardPage>
                    ]
                }
            }
        </MultiStepWizard>
    )
}

function TenantDomainField({name, saveValue, values}: {name: string, values: ITenantOnboardingDomainAddRequest, saveValue: (v: string) => void, tenant: ITenant}) {

    const [checkingDomain, setCheckingDomain] = React.useState<boolean>(false);
    const [domainValid, setDomainValid] = React.useState<boolean>(false);
    const [checkTimer, setCheckTimer] = React.useState<number>();

    const callApi = useApi();

    React.useEffect(() => {

        clearTimeout(checkTimer);
        setDomainValid(false);
        setCheckingDomain(true);
        
        if (!values || !values.subdomain) {
            setCheckingDomain(false);
            setDomainValid(false);
            return;
        }
        

        const abortController = new AbortController();

        setTimeout(async () => {

            const res = await callApi(tenantsOnboardingValidateTenantSubdomain(values, abortController));

            setDomainValid(!!res);
            setCheckingDomain(false);
        }, 500);

        return () => abortController.abort();

    }, [values]);
    
    const fieldClass = generateClassName({
        value: domainValid,
        onTrue: "tenant-domain-field-valid",
        standard: "tenant-domain-field-invalid"
    });

    return (
        <div>
            <div className="d-flex flex-row align-items-center gap-1 position-relative">
                <div className="mb-2 d-flex flex-row align-items-center position-absolute end-100" style={{marginRight: "8px"}}>
                    {
                        checkingDomain && <LoadingSpinner size={18} />
                    }
                </div>
                <FieldWithLabel label=""inputClass={fieldClass} name={name} />
                <span className="mb-3 fw-bold">.{RootUrl}</span>
            </div>
        </div>
    )
}