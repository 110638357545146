import React, { PropsWithChildren, ReactNode } from "react";
import Button, { ButtonSize, IButtonProps } from "../buttons/Button";
import { AppColor } from "../../app/AppStyles";
import LabelButton from "../buttons/LabelButton";
import Dialog from "./Dialog";
import { Formik, Form, FormikBag, FormikHelpers, FormikProps, FormikValues } from "formik";
import LoadingSpinner from "../loader/LoadingSpinner";

interface IModalFormProps<T extends FormikValues> {
    className?: string,
    initialValues: T,
    closeAfterSubmit?: boolean,
    disabled?: boolean,
    submitDisabled?: boolean,
    enableReinitialize?: boolean,
    onOpen?: (abortController: AbortController) => Promise<any>,
    onSubmit: (values: T, helpers: FormikHelpers<T>) => Promise<boolean>,
    button: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
    title: string,
    submitText?: string,
    submitIcon?: string,
    loading?: boolean,
    sidebar?: (formik: FormikProps<T>, close: Function) => ReactNode,
    children: (formik: FormikProps<T>, close: Function) => ReactNode
}

export default function ModalForm<T extends FormikValues>({submitDisabled, enableReinitialize, submitText, submitIcon, loading, closeAfterSubmit = true, disabled, initialValues, title, children, className, sidebar, onOpen, button, onSubmit}: IModalFormProps<T>) {
    
    const [visible, setVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!visible) return;
        if (!onOpen) return;

        const abortController = new AbortController();

        onOpen(abortController);

        return () => abortController.abort();
    }, [visible]);
    
    return (
        <>
            {
                <div onClick={disabled ? undefined : async () => setVisible(true)} className={className}>
                    {
                        React.cloneElement(button, { disabled: disabled })
                    }
                </div>
            }
            {
                visible && (
                    <Formik 
                        initialValues={initialValues}
                        enableReinitialize={enableReinitialize}
                        onSubmit={async (values, actions) => {
                            const res = await onSubmit(values, actions)
                            if (!res) return;
                            if (!closeAfterSubmit) return;
                            setVisible(false);
                        }}
                    >
                        {
                            formik => (
                                <Form className="text-start position-fixed bottom-0 end-0" style={{zIndex: "999999999"}}>
                                    <Dialog sidebar={sidebar ? close => sidebar(formik, close) : <Button type="submit" disabled={submitDisabled} text={submitText || "Speichern"} color="success" loading={formik.isSubmitting} icon={submitIcon || "save"} />} setVisible={setVisible} title={title} >
                                        {
                                            close => loading ? <LoadingSpinner /> : children(formik, close)
                                        }       
                                    </Dialog>
                                </Form>
                            )
                        }
                    </Formik>
                )
            }
        </>
    )
}