import React from "react";
import { TemplatePlaceholder } from "../../types/ApiTypes";
import Banner from "../pills/Banner";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import Pill from "../pills/Pill";
import Button from "../buttons/Button";
import useTenantSettings from "../../hooks/useTenantSettings";

export interface ITemplatePlaceholderActions {
    onAdd?: (placeholder: TemplatePlaceholder) => void,
    onRemove?: (placeholder: TemplatePlaceholder) => void
}

export interface ITemplatePlaceholderChipProps extends ITemplatePlaceholderActions {
    placeholder: TemplatePlaceholder,
    currentText?: string,
    saveText?: (newText: string) => void,
}
    
export default function TemplatePlaceholderChip({currentText, onAdd, onRemove, placeholder, saveText}: ITemplatePlaceholderChipProps) {

    const { titles } = useTenantSettings(true);

    const isActive = currentText && currentText.includes(placeholder);

    const deleteFromText = () => {
        if (onRemove) onRemove(placeholder);
        if (!saveText) return;
        saveText("");
    }

    const addToText = () => {
        if (onAdd) onAdd(placeholder);
        if (!saveText) return;
        return saveText(placeholder);
    }

    const getPlaceholderDisplayName = () => {
        switch (placeholder) {
            case TemplatePlaceholder.ClientName: return "Firma des Mandanten";
            case TemplatePlaceholder.TicketId: return "Ticket-ID";
            case TemplatePlaceholder.TicketMessage: return "Neueste Ticketnachricht";
            case TemplatePlaceholder.TicketSubject: return "Ticket-Betreff";
            case TemplatePlaceholder.UserFirstName: return "Vorname des Empfängers";
            case TemplatePlaceholder.UserLastName: return "Nachname des Empfängers";
            case TemplatePlaceholder.TenantName: return `Name ${titles?.tenantWord || "Tenant"}`;
            case TemplatePlaceholder.DocumentClearanceLink: return `Link für ${titles?.documentClearanceWord || "Belegfreigabe"}`;
            case TemplatePlaceholder.SenderFirstName: return "Vorname des Absenders";
            case TemplatePlaceholder.SenderLastName: return "Nachname des Absenders";
            case TemplatePlaceholder.TaskSubject: return "Aufgabenbetreff";
            case TemplatePlaceholder.TicketLink: return "Link zum Ticket";
            case TemplatePlaceholder.UserAdditionalPrefix: return "Zusätzliche Anrede des Empfängers";
            case TemplatePlaceholder.UserTitle: return "Titel des Empfängers";
        }
    }

    return (
        <Flex row gap={0}>
            <Pill color={isActive ? "success" : "bright"} textColor={isActive ? "bright" : "primary"} text={getPlaceholderDisplayName()} onClick={onAdd || saveText ? addToText : undefined} />
            {
                isActive && <Button onClick={async () => deleteFromText()} icon="x" variant="icon" size="small" iconSize={12} color="error" />
            }
        </Flex>
    )
}