import React from "react";
import { ITicketMessage, IUser } from "../../../types/ApiTypes";
import { generateStyle } from "../../../hooks/useAttributes";
import useUserUtil from "../../../hooks/useUserUtil";
import { useAppSelector } from "../../../hooks/reduxHooks";
import Flex from "../../container/Flex";
import { useTenant } from "../../../state/swr/tenant/useTenant";

export default function TicketChatMessageAuthor({message, isOwn, colorMap}: {message: ITicketMessage, colorMap: {[userId: string]: string}, isOwn: boolean}) {
    const { tenant } = useTenant();

    const {
        getName,
        getCurrentTenantRole
    } = useUserUtil();

    if (isOwn) return null;

    const {
        sentBy,
        senderMailAddress,
        senderName
    } = message;

    if (!sentBy && !senderName && !senderMailAddress) return null;

    const author = sentBy;

    const hasUserAsSender = author && typeof author !== "string";

    const color = hasUserAsSender ? colorMap[`${author._id}`] : "#000000";

    const style = generateStyle({
        name: "color",
        applyCondition: !isOwn,
        value: color
    });

    const role = hasUserAsSender && getCurrentTenantRole(author);

    return (
        <Flex gap={0}>
            <strong style={style}>
                {
                    hasUserAsSender 
                    ? getName(author)
                    : senderName || senderMailAddress
                }
            </strong>
            <span style={{fontSize: "0.8em", marginTop: "-5px", color: "#A0A0A0"}}>
                {
                    (role && !role.isClient && tenant && tenant.name) 
                    ? `@${tenant.name}`
                    : senderMailAddress
                }
            </span>
        </Flex>
    );
}