import useSWR from "swr";
import { IClient, ICoupon, ILegalForm, IMailLog, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useMailLog = () => {
    const { data, isLoading, mutate } = useSWR<Array<IMailLog>>(ApiRoutes.MailLog.GetAll);

    return {
        mailLogs: data,
        loadingMailLogs: isLoading,
        reloadMailLogs: mutate
    }
}