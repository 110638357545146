import useSWR from "swr";
import { IClient, ICoupon, ISettings, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useSettings = () => {
    const { data, isLoading, mutate } = useSWR<ISettings>(ApiRoutes.Settings);

    return {
        settings: data,
        loadingSettings: isLoading,
        reloadSettings: mutate
    }
}