import React from "react";
import useApi from "../../hooks/useApi";
import { teamsCreateForAreasOfResponsibility, tenantsAbortDelete } from "../../api/Api";
import Button from "../buttons/Button";
import { ITenant, TenantStatus } from "../../types/ApiTypes";
import WithPermissions from "../permissions/WithPermissions";
import { useUser } from "../../state/swr/user/useUser";

export default function TenantAbortDeleteButton({tenant, afterSubmit}: {tenant: ITenant, afterSubmit: () => void}) {
    
    const {
        user
    } = useUser();

    const callApi = useApi();

    const clickHandler = async () => {
        const res = await callApi(tenantsAbortDelete({id: tenant._id}));
        if (!res || !res.success) return;        
        if (afterSubmit) afterSubmit();
    }

    if (!user || !user.isSuperAdmin) return null;
    
    if (!tenant || !tenant.tenantCanBeDeleted || !tenant.willBeDeletedOn || tenant.status !== TenantStatus.AboutToBeDeleted) return null;

    return (
        <Button 
            color="success" 
            text="Löschen abbrechen" 
            loadingText="Löschen wird abgebrochen..." 
            onClick={clickHandler}
            icon="x"
        />
    )
}