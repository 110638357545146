import React from "react";
import Icon from "../icons/Icon";
import "./PopUpToast.css";

export default function PopUpToast({icon = "info-circle", message, title}: {icon?: string, message: string, title?: string}) {
    const [toastVisible, setToastVisible] = React.useState<boolean>(false);

    return (
        <div className="position-relative p-0 m-0">
            <div className="pop-up-toast-handle p-0 m-0" onMouseEnter={() => setToastVisible(true)} onMouseLeave={() => setToastVisible(false)} onMouseOver={() => setToastVisible(true)}>
                <Icon icon={icon} size={12} />
            </div>
            {
                toastVisible && (
                    <div className="position-absolute pop-up-toast-content m-0"  onMouseEnter={() => setToastVisible(true)} onMouseOver={() => setToastVisible(true)} onMouseLeave={() => setToastVisible(false)}>
                        {
                            title && <><strong className="text-nowrap">{title}</strong><br/></>
                        }
                        <span>{message}</span>
                    </div>
                )
            }
        </div>
    )
}