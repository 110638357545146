import React from "react";
import { useNavigate } from "react-router-dom";
import AddDomainForm from "../../components/domain/AddDomainForm";
import DomainList from "../../components/domain/DomainList";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import { Status } from "../../config/Status";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import { useTenantDomains } from "../../state/swr/domains/useTenantDomains";

export default function TenantDomainManagement() {
    const { tenantDomains, loadingTenantDomains } = useTenantDomains();

    return (
        <Page header={Locale.titles.tenantDomainManagement} actionButtons={<AddDomainForm />}>
            {
                loadingTenantDomains
                ? <LoadingSpinner />
                : <DomainList domains={tenantDomains || []} />
            }
        </Page>
    )
}