import React from "react";
import { useNavigate } from "react-router-dom";
import EmployeeList from "../../components/employees/EmployeeList";
import ImportEmployeeForm from "../../components/employees/ImportEmployeeForm";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import { useAppSelector } from "../../hooks/reduxHooks";
import useModal from "../../hooks/useModal";
import useUserUtil from "../../hooks/useUserUtil";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import { useUser } from "../../state/swr/user/useUser";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";
import UserUpdateForm from "../../components/user/UserUpdateForm";

export default function EmployeeManagement() {
    return (
        <Page header={Locale.titles.employeeManagement} actionButtons={[<ImportEmployeeForm key="import-employee-form" />, <UserUpdateForm key="create-employee-form" />]}>
            <EmployeeList />
        </Page>
    )
}