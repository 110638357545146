import React from "react";
import PermissionContainer from "./PermissionContainer";
import Typography from "../text/Typography";
import Icon from "../icons/Icon";
import Flex from "../container/Flex";
import Card from "../card/Card";

export interface IPermissionGroupProps {
    label: string,
    disabled?: boolean,
    children: any
}

export default function PermissionGroup({label, disabled, children}: IPermissionGroupProps) {

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    if (disabled) return (
        <Typography color="error">Die Berechtigung "{label}" ist für diese Rolle nicht verfügbar.</Typography>
    )

    const iconSize = isExpanded ? 18 : 0;

    return (
        <Card 
            expanded={isExpanded}
            header={
                <Flex fullWidth onClick={() => setIsExpanded(!isExpanded)}>
                    <PermissionContainer 
                        isHeader
                        title={
                            <Flex row> 
                                <Icon color="primary" icon={isExpanded ? "chevron-up" : "chevron-down"} />
                                <Typography color="primary" bold>{label}</Typography>
                            </Flex>
                        }
                        create={<Icon icon="plus-circle" size={iconSize} color="primary" tooltip="Berechtigt zum Erstellen von Ressourcen" />}
                        read={<Icon icon="eye" size={iconSize}  color="primary" tooltip="Berechtigt zum Lesen von Ressourcen" />}
                        update={<Icon icon="pen" size={iconSize} color="primary" tooltip="Berechtigt zum Bearbeiten von Ressourcen" />}
                        del={<Icon icon="trash-fill" size={iconSize} color="error" tooltip="Berechtigt zum Löschen von Ressourcen" />}
                    />
                </Flex>
            }
        >
            <Flex fullWidth gap={3}>
                {
                    isExpanded && children
                }
            </Flex>
        </Card>
    )
}