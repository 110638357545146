import { useCurrentTenantRole } from "../state/swr/user/useCurrentTenantRole";
import { useUser } from "../state/swr/user/useUser";
import { IPermissions, Permission } from "../types/ApiTypes";

export default function usePermissions() {
    
    const checkPermission = (p: {[key: string]: any}, key: string): boolean => {
        if(typeof p === 'undefined') return false;
        var nextKey = key.indexOf('.')

        if(nextKey > -1) {
            return checkPermission(p[key.substring(0, nextKey)], key.substring(nextKey + 1));
        }
        return p[key];
    }

    const hasPermissions = (available: IPermissions, required: Array<Permission>) => {
        if (!available) return false;
        if (!required || !required.length) return true;

        for (const r of required) if (!!checkPermission(available, r)) return true;

        return false;
    };

    return { 
        hasPermissions
    }
}