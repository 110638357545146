import React from "react";
import { IOfficeAccount } from "../../types/ApiTypes";
import { useOfficeMailListenerStatus } from "../../state/swr/office/useOfficeMailListenerStatus";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { azureGetSignInUrl, officeAccountStartMailService, officeAccountStopMailService } from "../../api/Api";
import LoadingSpinner from "../loader/LoadingSpinner";
import { AppColor } from "../../app/AppStyles";
import useInboxPermissions from "../../hooks/useInboxPermissions";
import WithPermissions from "../permissions/WithPermissions";

export default function OfficeAccountMailListenerStatusButton({officeAccount}: {officeAccount: IOfficeAccount}) {
    const { loadingOfficeMailListenerStatus, officeMailListenerStatus, reloadOfficeMailListenerStatus } = useOfficeMailListenerStatus(officeAccount._id);

    const {
        getAccountPermissions
    } = useInboxPermissions();

    const callApi = useApi();

    const clickHandler = async () => {
        if (!officeMailListenerStatus) await callApi(officeAccountStartMailService({id: officeAccount._id}));
        else callApi(officeAccountStopMailService({id: officeAccount._id}));
        await reloadOfficeMailListenerStatus();
    }

    const permissions = getAccountPermissions("read", officeAccount);

    if (officeMailListenerStatus) return null;

    return (
        <WithPermissions permissions={permissions} >
            <Button 
                loading={loadingOfficeMailListenerStatus}
                loadingText="Status wird überprüft..."
                text={!officeMailListenerStatus ? "Service starten"  : "Service stoppen" }
                color={!officeMailListenerStatus ? "success" : "error"}
                onClick={clickHandler}
                icon={!officeMailListenerStatus ? "check" : "x"}
            />
        </WithPermissions>
    )
}