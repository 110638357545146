import { useUser } from "../state/swr/user/useUser";
import { IOfficeAccount, IPermission, OfficeAccountType, Permission } from "../types/ApiTypes";

export default function useInboxPermissions() {
    const {
        user
    } = useUser();

    const getInboxPermissions = (key: keyof IPermission, type: OfficeAccountType, matchThisUser?: string) => {
        const result: Array<Permission> = [
            `inboxes.all.${key}`
        ];

        if (matchThisUser && user?._id === matchThisUser) result.push(`inboxes.own.${key}`);
        else if (type === OfficeAccountType.Tenant) result.push(`inboxes.tenant.${key}`);
        else if (type === OfficeAccountType.Team) result.push(`inboxes.teams.${key}`);

        return result;
    }

    const getAccountPermissions = (key: keyof IPermission, account: IOfficeAccount) => getInboxPermissions(key, account.type, account.assignments?.personalUser?._id);

    return {
        getAccountPermissions,
        getInboxPermissions
    }
}