import useSWR from "swr";
import { ILogEntry, IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useLogs = () => {
    const { data, isLoading, mutate } = useSWR<Array<ILogEntry>>(ApiRoutes.Logs);

    return {
        logs: data,
        loadingLogs: isLoading,
        reloadLogs: mutate
    }
}