import React from "react";
import { AliasType, ITicket } from "../../types/ApiTypes";
import { useTicketClientContact } from "../../state/swr/tickets/useTicketClientContact";
import ModalForm from "../modal/ModalForm";
import Flex from "../container/Flex";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { ticketsCreateAlias } from "../../api/Api";
import { IAssignAliasFromTicketRequest } from "../../api/ApiRequests";
import ClientSelect from "../clients/ClientSelect";
import { useClients } from "../../state/swr/clients/useClients";
import WithPermissions from "../permissions/WithPermissions";
import TypeSelect from "../tabswitcher/TypeSelect";
import UserSearchableComboBox from "../comboBox/UserSearchableComboBox";
import UserSelect from "../user/UserSelect";

export interface ICreateAliasFromTicketButtonProps {
    ticket: ITicket
}    

export default function CreateAliasFromTicketButton({ticket}: ICreateAliasFromTicketButtonProps) {

    const { clients } = useClients();
    const callApi = useApi();

    const {
        loadingTicketClientContact,
        ticketClientContact,
        reloadTicketClientContact
    } = useTicketClientContact(ticket);

    if (loadingTicketClientContact) return null;
    if (ticketClientContact && (ticketClientContact.user || ticketClientContact.client)) return null;
    if (!clients || !clients.length) return null;

    return (
        <WithPermissions permissions={["alias.all.create"]}>
            <ModalForm
                title="Alias erstellen"
                initialValues={{
                    type: AliasType.Client,
                    userId: "",
                    clientId: "",
                    id: ticket._id
                } as IAssignAliasFromTicketRequest}
                button={<Button text="Alias zuweisen" variant="text" icon="plus" size="small" />}
                onSubmit={async (values) => {
                    const res = await callApi(ticketsCreateAlias(values));
                    if (!res || !res.success) return false;
                    await reloadTicketClientContact();
                    return true;
                }}
            >
                {
                    formik => (
                        <Flex>
                            <TypeSelect 
                                label="Typ"
                                options={[
                                    { text: "Mandantenkontakt", data: AliasType.User },
                                    { text: "Mandant", data: AliasType.Client }
                                ]}
                                saveValue={v => formik.setFieldValue("type", v)}
                                value={formik.values.type}
                            />
                            {
                                (
                                    () => {
                                        switch (formik.values.type) {
                                            case AliasType.Client: return (
                                                <ClientSelect 
                                                    saveClient={c => formik.setFieldValue("clientId", c._id)}
                                                    showAllClients
                                                    clientId={formik.values.clientId}
                                                />
                                            );
                                            case AliasType.User: return (
                                                <UserSelect
                                                    label="Mandantenkontakt"
                                                    onChange={u => formik.setFieldValue("userId", u?._id)} 
                                                    value={formik.values.userId}
                                                    displayed="clients"
                                                />
                                            )
                                        }
                                    }
                                )()
                            }
                        </Flex>
                    )
                }
            </ModalForm>
        </WithPermissions>
    )

}