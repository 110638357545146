import useSWR from "swr";
import { IOfficeAccount } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { MailFolder } from "@microsoft/microsoft-graph-types";
import { IOfficeGraphResponse } from "../../../api/ApiRequests";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useOfficeAcountFolderChildFolders = (accountId: string, folderId: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<MailFolder>>(ApiRoutes.OfficeAccounts.GetMailFolderChildFolders.getValue({    
        param: RouteParam.OfficeAccountId, 
        value: accountId 
    }, { 
        param: RouteParam.FolderId, 
        value: folderId
    }));

    return {
        childFolders: data,
        loadingChildFolders: isLoading,
        reloadChildFolders: mutate
    }
}