import React from "react";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import Icon from "../icons/Icon";
import { useTasks } from "../../state/swr/tasks/useTasks";
import TaskForm from "./TaskForm";
import TaskItem from "./TaskItem";

export default function MyTasks() {
    
    const { tasks, reloadTasks, loadingTasks } = useTasks();

    return (
        <Flex fullWidth>
            <Flex row fullWidth justify="between">
                <Typography bold color="primary" size={14}>Meine Aufgaben</Typography>
                <TaskForm />
            </Flex>
            <Flex>
                {
                    tasks && tasks.length
                    ? tasks.map(t => <TaskItem task={t} key={t._id} />)
                    : <Typography>Keine Aufgaben</Typography>
                }
            </Flex>
        </Flex>
    )
}