import IElementProps from "../../types/ElementProps";
import React from "react";
import "./TableRow.css";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { AppColor } from "../../app/AppStyles";
import { hexWithOpacity } from "../../util/color";

interface ITableRowProps extends IElementProps {
    color?: AppColor
}

export default function TableRow({children, color, onClick}: ITableRowProps) {

    const className = generateClassName("table-row", {
        value: onClick,
        onTrue: "table-row-clickable"
    });

    const style = generateStyle({
        name: "backgroundColor",
        value: hexWithOpacity(color || "", 0.3, true),
        applyCondition: !!color
    });

    return (
        <tr className={className} onClick={onClick ? onClick() : undefined} style={style}>
            {children}
        </tr>
    )
}