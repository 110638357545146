import { Form, Formik, FormikBag, FormikProps } from "formik";
import React from "react";
import { ITenantOnboardingUpdateBillingDataRequest, ITenantUpdateBillingDataRequest } from "../../api/ApiRequests";
import { ITenant } from "../../types/ApiTypes";
import { tenantsOnboardingSetBillingData, tenantsUpdateBillingData } from "../../api/Api";
import useApi from "../../hooks/useApi";
import useCountryCodes, { IIsoCountryCode } from "../../hooks/useCountryCodes";
import FieldWithLabel from "../formik/FormikField";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Pill from "../pills/Pill";
import Button from "../buttons/Button";
import { AppRoutes } from "../../config/AppRoutes";
import WithPermissions from "../permissions/WithPermissions";

export default function TenantBillingDetailsForm({formik}: {formik: FormikProps<ITenantUpdateBillingDataRequest> | FormikProps<ITenantOnboardingUpdateBillingDataRequest>}) {
    const countryCodes = useCountryCodes();

    const saveCountry = (i: IIsoCountryCode) => {
        formik.setFieldValue("address.country", i.country);
        formik.setFieldValue("address.countryCode", i.code);
    }

    const getCurrentCountryCode = (): IIsoCountryCode => ({
        code: formik.values.address.countryCode,
        codeToUpper: formik.values.address.countryCode.toUpperCase(),
        country: formik.values.address.country
    });

    return (
        <WithPermissions permissions={["billing.tenant.update"]}>
            <Form className="w-100 h-100 d-flex flex-column justify-content-center gap-3">
                <FieldWithLabel bold name="company" label="Firma" noMargin placeholder="Die Firmierung Ihrer Kanzlei inklusive Rechtsform" /> 
                <div className="d-flex flex-column align-items-start gap-3 w-100">
                    <strong>Adresse</strong>
                    <FieldWithLabel name="address.firstLine" noMargin label="Straße" className="w-100" placeholder="Straße oder Postfach" />
                    <FieldWithLabel name="address.secondLine" noMargin label="Optional: Zweite Adresszeile" placeholder="Stockwerk, Anbau, Wohnung" className="w-100" />
                    <div className="d-flex flex-row align-items-center w-100 gap-3">
                        <FieldWithLabel name="address.postalCode" noMargin label="Postleitzahl" placeholder="Postleitzahl" className="w-15" /> 
                        <FieldWithLabel name="address.city" noMargin label="Stadt" className="w-100" />
                    </div>
                    <div className="d-flex flex-row align-items-center w-100 gap-3">
                        <FieldWithLabel name="address.state" noMargin label="Bundesland" placeholder="Bundesland" className="w-50" /> 
                        <SearchableComboBox 
                            label="Land"
                            className="w-100"
                            value={getCurrentCountryCode()}
                            values={countryCodes}
                            itemToId={i => i.code}
                            itemToString={i => i.country}
                            renderValue={i => (
                                <Pill className="d-flex flex-row align-items-center w-100 selected-country gap-2">
                                    <img src={`https://flagsapi.com/${i.codeToUpper}/flat/32.png`} style={{height: "20px", width: "auto"}} />
                                    <span>{i.country} ({i.codeToUpper})</span>
                                </Pill>
                            )}
                            onItemClick={saveCountry}
                        />
                    </div>
                </div>
                <Button type="submit" text="Speichern" icon="save" loading={formik.isSubmitting} />
            </Form>
        </WithPermissions>
    );
}