import React from "react";
import useSWR, { MutatorCallback, MutatorOptions } from "swr";
import { IPointChampApiKey, IRole, ISession, ISessionState, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { setCookie, Cookies, getCookie, deleteCookie } from "../../../util/cookies";
import { useUser } from "../user/useUser";

export const useSession = () => {
    const [ token, setToken ] = React.useState<string>();
    const { data, isLoading, mutate } = useSWR<ISession>(ApiRoutes.Session.Active);

    const { reloadUser } = useUser();

    const setSessionToken = (token: string | null | undefined) => {
        if (!token) {
            deleteCookie(Cookies.Session);
            setToken("");
            return;
        }

        setCookie(Cookies.Session, token);
        setToken(token);
    }

    const reloadSession = async (data?: ISession | Promise<ISession | undefined> | MutatorCallback<ISession> | undefined, opts?: boolean | MutatorOptions<ISession> | undefined) => {
        await mutate(data, opts);
        await reloadUser();
    }

    return {
        session: data,
        setSessionToken: setSessionToken,
        sessionToken: token,
        loading: isLoading,
        reloadSession: reloadSession
    }
} 