import React from "react";
import { IUserAbsence, AbsenceSource, IUser } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import useApi from "../../../hooks/useApi";
import { absenceRemove } from "../../../api/Api";
import { useAnyUserAbsence } from "../../../state/swr/user/useAnyUserAbsence";
import WithPermissions from "../../permissions/WithPermissions";
import { useUser } from "../../../state/swr/user/useUser";

export interface IUserAbsenceDeleteButtonProps {
    absence: IUserAbsence,
    user: IUser
}

export default function UserAbsenceDeleteButton({absence, user}: IUserAbsenceDeleteButtonProps) {

    const {
        user: currentUser
    } = useUser();

    const { reloadUserAbsence } = useAnyUserAbsence(user);
    const callApi = useApi();

    const isOwn = currentUser && currentUser._id === user._id;

    const clickHandler = async () => {
        const res = await callApi(absenceRemove({id: absence._id}))
        if (!res || !res.success) return;
        await reloadUserAbsence();
    }

    return (
        <WithPermissions permissions={isOwn ? ["absence.own.delete"] : ["absence.all.delete"]}>
            <Button 
                icon="trash" 
                disabled={!(absence.source === AbsenceSource.Web)}
                onClick={clickHandler} 
                text={absence.source === AbsenceSource.Web ? "Urlaub löschen" : "Extern verwaltet"}
                color="error" 
            /> 
        </WithPermissions>
    )
}