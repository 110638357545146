import { MailFolder, Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import Icon from "../../icons/Icon";
import { officeAccountsUpdateMail } from "../../../api/Api";
import { IOfficeAccount } from "../../../types/ApiTypes";
import { useFolderUnreadMessagesCount } from "../../../state/swr/office/useFolderUnreadMessagesCount";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import WithPermissions from "../../permissions/WithPermissions";

export interface IMarkMailAsReadButtonProps {
    mail: Message, 
    account: IOfficeAccount,
    folderId?: string,
    isRead: boolean,
    saveIsRead: (val: boolean) => void
}

export default function MarkMailAsReadButton({mail, account, saveIsRead, folderId, isRead}: IMarkMailAsReadButtonProps) {
    const {
        getAccountPermissions
    } = useInboxPermissions();

    const [loading, setLoading] = React.useState<boolean>(false);
    
    const { reloadFolderUnreadMessagesCount } = useFolderUnreadMessagesCount(account._id, folderId || "");

    const markAsRead = async () => {
        setLoading(true);
        const res = await officeAccountsUpdateMail({id: account._id, messageId: mail.id!, isRead: !isRead});
        
        if (res && res.success) {
            await reloadFolderUnreadMessagesCount();
            saveIsRead(!isRead);
        }

        setLoading(false);
    }

    const permissions = getAccountPermissions("update", account);

    if (!mail || !mail.id) return null;

    return (
        <WithPermissions permissions={permissions}>
            <Icon icon={isRead ? "envelope-open" : "envelope"} size={isRead ? 14 : undefined} onClickAsync={markAsRead} loading={loading} />
        </WithPermissions>
    )
}