import React from "react";
import IElementProps from "../../types/ElementProps";
import { IMultiStepWizardPageProps } from "./MultiStepWizardPage";
import "./MultiStepWizard.css";

interface IMultiStepWizardProps {
    initialIndex: number,
    hideProgressBar?: boolean,
    children: (nextPage: () => Promise<void>, prevPage: () => Promise<void>) => React.ReactElement<IMultiStepWizardPageProps>[];
}

export default function MultiStepWizard({children, initialIndex, hideProgressBar = false}: IMultiStepWizardProps) {
    const [index, setIndex] = React.useState<number>(initialIndex);

    React.useEffect(() => setIndex(initialIndex), [initialIndex]);

    let elementCount = 0;

    const prevPage = async () => setIndex(curr => curr == 0 ? 0 : curr - 1);
    const nextPage = async () => setIndex(curr => (curr + 1) >= elementCount ? curr : curr + 1);

    const childElements = children(nextPage, prevPage);
    
    if (!childElements || !childElements.length) return null;
    
    elementCount = childElements.length;

    const percentageComplete = (index + 1) == elementCount ? 1 : (index + 1) / elementCount;

    const content = childElements.map((c, i) => React.cloneElement(c, {key: `multi-step-wizard-page-${i}`, active: index === i}));

    return (
        <div className="w-100 position-relative h-100 pb-4 overflow-hidden" >
            <div className="multi-step-wizard d-flex flex-row align-items-center w-100 h-100">
                {
                    content[index]                
                }
            </div>
            {
                !hideProgressBar && (
                    <div className="position-absolute w-100" style={{top: "calc(100% - 8px)", height: "8px", backgroundColor: "var(--bs-gray-400"}}>
                        <div className="h-100 w-100" style={{transition: "all 0.3s ease", maxWidth: `calc(${percentageComplete} * 100%)`, backgroundColor: percentageComplete === 1 ? "var(--success)" : "var(--primaryAccent)", opacity: "0.75"}} />
                    </div>
                )
            }
        </div>
    )
}