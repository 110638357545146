import { EnumType } from "typescript";
import { AppColor } from "../app/AppStyles";
import { IDomain } from "../types/ApiTypes";
import { Mode } from "./envVars";

export const isDevelopment = (): Boolean => {
  if (!Mode) return false;
  return Mode === "DEV";
}

export const buildUrlFromDomain = (domain: IDomain) => {
    let result = domain.domain;
    
    if (domain.protocol) result = [domain.protocol, domain.domain].join("://").trim();
    if (domain.usePort) result += `:${domain.portNumber}`;

    return result;
}

const hexToRgb = (hex: string): {r: number, b: number, g: number} | null => {
    if (!hex || typeof hex !== "string") return null;
    var result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex.trim());
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

export const parseVarColorToHex = (color?: string, standard: string = "#FFFFFF"): string => {

    if (!color) return parseVarColorToHex(standard);
    if (color.includes("#") && (color.length === 7 || color.length === 4 || color.length === 9)) return color;
    
    const computedValue = getComputedStyle(document.documentElement).getPropertyValue(`--${color}`);
    
    if (!computedValue) return "#FFFFFF";

    return computedValue;
}

export const getTextColorFromBackground = (color?: string, standard: string = "#000000"): string => {

    const realColor = parseVarColorToHex(color, standard);

    if (!realColor) return standard;

    const rgb = hexToRgb(realColor);

    if (!rgb) return standard;

    const brightness = (rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114);

    if (brightness > 186) return "#000000";
    
    return "#FFFFFF";

}

export const getEnumAsArray = <T = string | number>(x: Object): Array<{key: string, value: T}> => {
    if (!x) return [];

    const enumContent = Object.values(x);

    if (!enumContent || !enumContent.length) return [];

    const startOfValues = enumContent.length / 2;

    const keys = enumContent.splice(0, startOfValues);
    const values = enumContent;

    return keys.map((k, index) => ({key: k, value: values[index]}));
}

export interface TimeSpan {
    minutes?: number,
    hours?: number,
    days?: number,
    weeks?: number
  };
  
export const getDateInXFromNow = ({minutes = 0, hours = 0, days = 0, weeks = 0}: TimeSpan) => {

    const minute = 1000 * 60;
    const hour =  minute * 60;
    
    let millies = 0;

    if (minutes !== 0) millies += minute * minutes;
    if (hours !== 0) millies += hours * hour;
    if (days !== 0) millies += (days * hour * 24);
    if (weeks !== 0) millies += (weeks * hour * 24 * 7);
    
    const result = new Date();
    result.setTime(Date.now() + millies);
    return result;
}