import { IImportValidationError, ImportValidationErrorType } from "../types/ApiTypes";

export default function useImportValidationUtil(importValidationError: IImportValidationError) {
    const getColor = () => {
        if (importValidationError.checked) return "success";

        switch (importValidationError.reason) {
            case ImportValidationErrorType.DuplicateMail: return "#F08D00";
            case ImportValidationErrorType.DuplicateName: 
            case ImportValidationErrorType.PossibleDuplicateMail: return "#FF0000";
        }
    }

    const getDescription = () => {
        switch (importValidationError.reason) {
            case ImportValidationErrorType.DuplicateMail: return "Der angegebene Datentyp kommt bei mindestens einem anderen Datensatz desselben Typs vor und beeinträchtigt so die Funktion des Systems. Bitte überprüfen Sie die angegebenen Datensätze.";
            case ImportValidationErrorType.DuplicateName: return "Dieser Name kommt bei einem anderen Datensatz vor.";
            case ImportValidationErrorType.PossibleDuplicateMail: return "Der angegebene Datentyp kommt bei einem anderen Datensatz vor, beispielsweise teilen sich eine natürliche Person und ein Betrieb eine E-Mail.";
        }
    }

    const getText = () => {
        switch (importValidationError.reason) {
            case ImportValidationErrorType.DuplicateMail: return "Duplikat (E-Mail)";
            case ImportValidationErrorType.DuplicateName: return "Duplikat (Name)";
            case ImportValidationErrorType.PossibleDuplicateMail: return "Mögliches Duplikat (E-Mail)";
        }
    }

    return {
        text: getText(),
        color: getColor(),
        description: getDescription()
    }
}