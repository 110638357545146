import React from "react";
import { setConstantValue } from "typescript";
import { AppColor } from "../../../app/AppStyles";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import useApi from "../../../hooks/useApi";
import useTicketUtil from "../../../hooks/useTicketUtil";
import { ITicket, TicketState } from "../../../types/ApiTypes";
import Button, { ButtonVariant } from "../../buttons/Button";
import ModalForm from "../../modal/ModalForm";
import { Form, Formik } from "formik";
import FormWithTitle from "../../formik/FormWithTitle";
import CheckBox from "../../formik/CheckBox";
import Flex from "../../container/Flex";
import { ITicketsUpdateStatusRequest } from "../../../api/ApiRequests";
import { ticketsUpdateStatus } from "../../../api/Api";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useAllTickets } from "../../../state/swr/tickets/useAllTickets";
import { useTicket } from "../../../state/swr/tickets/useTicket";
import useTicketPermission from "../../../hooks/useTicketPermissions";

export interface ITicketActionProps {
    variant: ButtonVariant,
    afterAction?: () => void,
}

interface ICloseTicketButtonProps extends ITicketActionProps {
    isSolved?: boolean,
    isSingleTicket?: boolean,
    isClosed?: boolean,
    tickets: ITicket[]
}

export default function CloseTicketButton({tickets, afterAction, variant, isSingleTicket, isSolved = false, isClosed = false}: ICloseTicketButtonProps) {

    const singleTicketId = isSingleTicket && tickets && tickets.length === 1 ? tickets[0]._id : "";

    const { role } = useCurrentTenantRole();
    const { reloadTickets } = useAllTickets();
    const { reloadTicket, loadingTicket } = useTicket(singleTicketId);

    const dispatch = useAppDispatch();

    const {
        isClosed: isTicketClosed
    } = useTicketUtil();

    const callApi = useApi();

    if (!tickets || !tickets.length) return null;

    const ticketDescriptor = isSingleTicket || tickets.length === 1 ? "Ticket" : "Tickets";

    const title = isClosed ? isSolved ? `${ticketDescriptor} lösen` : `${ticketDescriptor} schließen` : `${ticketDescriptor} erneut öffnen`;
    const buttonText = variant === "text" ? "" : (isClosed ? isSolved ? `${ticketDescriptor} lösen` : `${ticketDescriptor} schließen` : `${ticketDescriptor} erneut öffnen`);
    
    const icon = isClosed ? isSolved ? "check-circle-fill" : "x-circle-fill" : "envelope-open";
    const color = isSolved ? "success" : "muted";
    const disabled = (tickets.length === 1) ? ((isClosed || isSolved) ? isTicketClosed(tickets[0]) : tickets[0].state === TicketState.Open) : false

    if (role && role.isClient) return null;
    
    if (disabled && isSingleTicket) return null;

    return (
        <ModalForm 
            button={
                <Button icon={icon} loading={loadingTicket} variant={variant} iconSize={variant === "text" ? 30 : undefined} color={color}>{buttonText}</Button>
            }
            title={title} 
            initialValues={{
                message: "",
                closed: isClosed,
                solved: isSolved,
                ticketIds: tickets.map(ticket => ticket._id),
                sendMail: true
            } as ITicketsUpdateStatusRequest}
            onSubmit={async (values) => {
                const res = await callApi(ticketsUpdateStatus(values));
                if (!res || !res.success) return false;
                if (afterAction) afterAction();
                await reloadTicket();
                if (isClosed || isSolved) reloadTickets(prev => (prev || []).filter(ticket => !values.ticketIds.includes(ticket._id)), { revalidate: true });
                return true;
            }}
            sidebar={formik => <Button type="submit" text={title} loading={formik.isSubmitting} color={color} icon={icon} />}
        >
            {
                formik => <CheckBox name="sendMail" label="E-Mail mit Statusänderung an Mandanten versenden" />
            }
        </ModalForm>
    )
}