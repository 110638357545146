import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalType } from "../../config/ModalTypes";
import { NavGroup, NavTab } from "../../components/menu/SideNav";

interface ISidebarSlice {
    isExpanded: boolean,
    rightSidebarExpanded: boolean,
    activeTab: NavTab,
    activeGroup: NavGroup
}

export const SidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        activeGroup: NavGroup.None,
        activeTab: NavTab.Dashboard,
        isExpanded: false,
        rightSidebarExpanded: false
    } as ISidebarSlice,
    reducers: {
        setActiveTab: (state, action: PayloadAction<NavTab>) => {
            state.activeTab = action.payload;
        },
        setActiveGroup: (state, action: PayloadAction<NavGroup>) => {
            state.activeGroup = action.payload;
        },
        setSidebarExpanded: (state, action: PayloadAction<boolean>) => {
            state.isExpanded = action.payload;
        },
        setRightSidebarExpanded: (state, action: PayloadAction<boolean>) => {
            state.rightSidebarExpanded = action.payload;
        }
    }
});

export const { setActiveGroup, setActiveTab, setSidebarExpanded, setRightSidebarExpanded } = SidebarSlice.actions;

export default SidebarSlice.reducer;