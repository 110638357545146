import useSWR from "swr";
import { IGlobalMailTemplate } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useGlobalMailTemplates = () => {
    const { data, isLoading, mutate } = useSWR<Array<IGlobalMailTemplate>>(ApiRoutes.MailTemplates.Global.All);

    return {
        globalMailTemplates: data,
        loadingGlobalMailTemplates: isLoading,
        reloadGlobalMailTemplates: mutate
    }
}