import React from "react";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { ITicketMessageAddAttachment, ITicketMessageAddRequest } from "../../../api/ApiRequests";
import { ITicket } from "../../../types/ApiTypes";
import useApi from "../../../hooks/useApi";
import { ticketsAddMessage } from "../../../api/Api";
import Button from "../../buttons/Button";
import { generateClassName } from "../../../hooks/useAttributes";
import "./TicketChatForm.css";
import LoadingSpinner from "../../loader/LoadingSpinner";
import useModal from "../../../hooks/useModal";
import { ModalType } from "../../../config/ModalTypes";
import FileSelectButton from "../../buttons/FileSelectButton";
import * as yup from "yup";
import Flex from "../../container/Flex";
import UserAvatar from "../../user/profile/UserAvatar";
import TicketChatAttachmentsField from "./TicketChatAttachmentsField";
import { useUser } from "../../../state/swr/user/useUser";
import { useTicketMessage } from "../../../state/swr/tickets/useTicketMessage";
import { useTicketMessages } from "../../../state/swr/tickets/useTicketMessages";
import Editor from "../../richText/Editor";
import TicketMessageMentions from "./TicketMessageMentions";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import WithPermissions from "../../permissions/WithPermissions";


export default function TicketChatForm({ticket}: {ticket: ITicket}) {
    
    const permissions = useTicketPermission("update", ticket);

    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [isTyping, setIsTyping] = React.useState<boolean>(false);
    const [mouseOverForm, setMouseOverForm] = React.useState<boolean>(false);
    const [hover, setHover] = React.useState<boolean>(false);
    const [dragging, setDragging] = React.useState<boolean>(false);
    const [uploadingAttachments, setUploadingAttachments] = React.useState<boolean>(false);

    const { user } = useUser();
    const { reloadMessages } = useTicketMessages(ticket._id);

    const showModal = useModal();
    const callApi = useApi();

    const cardClass = generateClassName("w-100 d-flex flex-column position-relative gap-2 ticket-chat-form position-relative ticket-attachments-drag-drop-field", {
        value: dragging && hover,
        onTrue: "ticket-attachments-drag-drop-field-hover"
    });

    const preventDefaults = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const toggleDrag = (e: any, val: boolean) => {
        preventDefaults(e);
        setDragging(val);
    }

    const toggleHover = (e: any, val: boolean) => {
        if (e.buttons !== 1) setDragging(false);
        setHover(val);
    }

    const ticketMessageFieldClass = generateClassName("ticket-chat-message-input", {
        value: expanded,
        onTrue: "ticket-chat-message-input-expanded"
    })
    return (
        <WithPermissions permissions={permissions}>
            <Formik
                initialValues={{
                    text: "",
                    attachments: [],
                    mentions: [],
                    id: ticket._id
                } as ITicketMessageAddRequest}
                validationSchema={yup.object().shape({
                    text: yup.string().required("Bitte geben Sie eine Nachricht ein.")
                })}
                onSubmit={async (values, actions) => {
                    if (!values.text) return;

                    const realText = values.text.trim();

                    if (!realText) return;
                    
                    const res = await callApi(ticketsAddMessage({...values, text: realText}));

                    if (!res || !res.success) return;
                    
                    await reloadMessages();
                    actions.resetForm();
                }}
                >
                {
                    (formik) => {

                        const onTyping = (e: any) => {
                            formik.handleChange(e);
                        }

                        const onBlur = (e: any) => {
                            if (!mouseOverForm) setExpanded(false);
                            formik.handleBlur(e);
                        }

                        const onFocusChat = () => {
                            setExpanded(true);
                            setIsTyping(true);
                        }

                        const handleNewAttachments = async (files: FileList) => {
                            setUploadingAttachments(true);

                            try {
                                const fileReads: Array<Promise<ITicketMessageAddAttachment>> = [];

                                for (const file of files) {
                                    if (!file) continue;
                                    if (!file.type) continue;

                                    fileReads.push(new Promise((resolve, reject) => {
                                        
                                        const reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        reader.onloadend = () => {
                                            const result = reader.result;
                                            if (!result) return reject();

                                            const stringValue = typeof result === "string" ? result : result.toString();
                                            const rawContent = stringValue.split(',')[1];

                                            resolve({
                                                type: file.type,
                                                content: rawContent,
                                                name: file.name,
                                                size: file.size
                                            });
                                        };
                                    }));
                                }

                                const newAttachments = await Promise.all(fileReads);

                                if (!newAttachments || !newAttachments.length) return;

                                const totalAttachments = [...formik.values.attachments, ...newAttachments];
                                const totalSize = totalAttachments.reduce((prev, curr) => prev += curr.size, 0);

                                if (totalSize > 1000 * 1000 * 50) {
                                    showModal({
                                        text: "Sie können nur maximal 50 MB an diese Nachricht anhängen.",
                                        type: ModalType.Error
                                    });

                                    return;
                                }

                                formik.setFieldValue("attachments", totalAttachments);

                            }
                            catch (err) {
                                console.log(err);
                            }
                            finally {
                                setUploadingAttachments(false);
                                setDragging(false);
                            }
                        }
            
                        const onDroppedFile = async (e: any) => {
                            preventDefaults(e);
                            if (!e || !e.dataTransfer || !e.dataTransfer.files || !e.dataTransfer.files.length) return;
                            const files = e.dataTransfer.files;
                            handleNewAttachments(files);
                        }

                        return (
                            <Form 
                                className="w-100 d-flex flex-column" 
                                onMouseEnter={(e) => {
                                    toggleHover(e, true);
                                    setMouseOverForm(true);
                                }}
                                onMouseOver={() => setMouseOverForm(true)}
                                onMouseLeave={() => {
                                    if (!isTyping) setExpanded(false);
                                    setMouseOverForm(false);
                                    
                                }}
                            >
                                <Flex row className="w-100" align="start">
                                    <UserAvatar user={user} mailAddress={user ? user.mailAddress : "xy@tiggi.de"} />
                                    <div className={cardClass} 
                                        onDrop={onDroppedFile} 
                                        onDragEnter={(e) => { toggleDrag(e, true); toggleHover(e, true) }} 
                                    >
                                        <div className="d-flex flex-column w-100 h-100 position-relative gap-2">
                                            {
                                                (dragging && hover) && (
                                                    <div 
                                                        className="position-absolute top-0 start-0 w-100 h-100 bg-white d-flex flex-row align-items-center justify-content-center w-100 h-100" 
                                                        style={{zIndex: 2}}
                                                        onDrop={onDroppedFile} 
                                                        onDragEnter={(e) => { toggleDrag(e, true); toggleHover(e, true) }} 
                                                        onDragOver={(e) => { toggleDrag(e, true); toggleHover(e, true) }} 
                                                        onDragLeave={(e) => { toggleDrag(e, false); toggleHover(e, false) }} 
                                                    >
                            
                                                        <strong style={{color: "var(--primaryAccent)"}}>Anhang anfügen</strong>
                                                    </div>  
                                                )
                                            }
                                            <Editor 
                                                className={ticketMessageFieldClass}
                                                onChange={val => formik.setFieldValue("text", val)}
                                                content={formik.values.text}
                                                disabled={formik.isSubmitting}
                                                onBlur={onBlur}
                                                onFocus={onFocusChat}
                                            />
                                            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                                                {
                                                    uploadingAttachments 
                                                    ? <LoadingSpinner />
                                                    : <TicketChatAttachmentsField name="attachments" />
                                                }
                                            </div>
                                            <Flex row justify="between" align="start" fullWidth>
                                                <TicketMessageMentions mentions={formik.values.mentions} ticket={ticket} saveMentions={(mentions) => formik.setFieldValue("mentions", mentions)} />
                                                <div className="d-flex flex-row align-items-center justify-content-end gap-2">
                                                    <FileSelectButton accept="*" text="Anhang hinzufügen" handleSelectedFiles={handleNewAttachments} icon="paperclip" />
                                                    <Button className="align-self-end" icon="send" type="submit" text="Senden" loading={formik.isSubmitting} loadingText="Sendet..."/>
                                                </div>
                                            </Flex>
                                        </div>
                                    </div>
                                </Flex> 
                            </Form>
                        )
                    }
                }
            </Formik>
        </WithPermissions>
    )
}