import { IsUnknownOrNonInferrable } from "@reduxjs/toolkit/dist/tsHelpers";
import { match } from "assert";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { isReturnStatement } from "typescript";
import { markAsUntransferable } from "worker_threads";
import { ISortedClientContact } from "../components/clientContacts/ClientContactList";
import { UserRoleColor } from "../app/AppStyles";
import { DatevAddresseeType, DatevDuplicateReason, IAddressee, IClient, IDatevAddresseeAddress, ITenant, ITenantAuthority, ITicket, ITicketAccessHistoryItem, ITicketMessage, IUser, UserRole } from "../types/ApiTypes";
import { useAppSelector } from "./reduxHooks";
import { useUsers } from "../state/swr/user/useUsers";
import { useAddressees } from "../state/swr/addressees/useAddressees";
import { useClientContacts } from "../state/swr/clientContacts/useClientContacts";

export default function useAddresseeUtil() {

    const { clientContacts } = useClientContacts();
    const { addressees } = useAddressees();

    const getAddresseeTypeColor = (a: IAddressee) => a.type === DatevAddresseeType.LegalPerson ? "#F0F0F0" : "#8383FF";

    const addresseeIsProbablyEqual = (a: IAddressee, b: IAddressee) => {
        if (!a || !b) return false;

        if (a._id === b._id) return true;
        if (a.type !== b.type) return false;

        if (a.actualName !== b.actualName) return false;

        const addressA = getAddresseeAddress(a);
        const addressB = getAddresseeAddress(b);

        if (!addressA || !addressB) return true;
        
        return addressA.postal_code === addressB.postal_code;
    }

    const getAddresseeDuplicates = (a: IAddressee): Array<IAddressee> | null | undefined => {
        if (!addressees || !addressees.length) return null;
        if (!a || !a.isDuplicate) return null;

        return addressees.filter(b => b && b._id !== a._id && b.isDuplicate && b.duplicateReason === a.duplicateReason && (a.duplicateReason === DatevDuplicateReason.Mail ? b.originalMail === a.originalMail : b.actualName === a.actualName));
    }

    const getAddresseeAddress = (a: IAddressee): IDatevAddresseeAddress | undefined => {
        if (!a) return;
        if (!a.addresses || !a.addresses.length) return;
        
        const correspondenceAddress = a.addresses.find(a => a.is_correspondence_address);

        if (!!correspondenceAddress) return correspondenceAddress;

        const managementAddress = a.addresses.find(a => a.is_management_address);

        if (!!managementAddress) return managementAddress;

        const mainAddress = a.addresses.find(a => a.is_main_street_address);

        if (!!mainAddress) return mainAddress;
        
        return a.addresses[0];
    }

    const getAddresseeLegalForm = (a: IAddressee): string | null => {
        if (!a) return null;
        if (a.type === DatevAddresseeType.NaturalPerson) return "Natürliche Person";

        if (!a.legalForm) {
            if (a.type === DatevAddresseeType.LegalPerson) return "Rechtliche Einheit";
            else return null;
        }

        if (a.legalForm.friendlyName) return a.legalForm.friendlyName;
        if (a.legalForm.short_name) return a.legalForm.short_name;
        if (a.legalForm.name) return a.legalForm.short_name;
        if (a.legalForm.display_name) return a.legalForm.display_name;
        if (a.legalForm.long_name) return a.legalForm.long_name;

        return null;
    }

    const getSortedClientContacts = (): ISortedClientContact[] => {
        if (!addressees || !addressees.length) {
            if (!clientContacts || !clientContacts.length) return [];
            return clientContacts.map((e: IUser): ISortedClientContact => ({
                addressees: [],
                user: e
            }));
        }

        const noUserItem: ISortedClientContact = {
            addressees: []
        }
        
        const result: ISortedClientContact[] = [];

        for (var addressee of addressees) {
            if (!addressee) continue;
            
            if (addressee.type === DatevAddresseeType.LegalPerson) continue;
            
            if (!addressee.user || !addressee.user._id) {
                noUserItem.addressees.push(addressee);
                continue;
            }
            
            const index = result.findIndex(r => r.user && r.user._id === addressee.user._id);

            if (index < 0) {
                result.push({
                    user: addressee.user,
                    addressees: [addressee]
                })
                continue;
            }

            result[index].addressees.push(addressee);
        }

        result.sort((a, b) => {
            if (!a.user && !b.user) return 0;
            if (!a.user) return -1;
            if (!b.user) return 1;

            return a.user.firstName.localeCompare(b.user.firstName);
        })

        if (result.length > 0) result.push(noUserItem);
        
        return result;
    }

    return {
        getAddresseeLegalForm,
        addresseeIsProbablyEqual,
        getAddresseeTypeColor,
        getAddresseeAddress,
        getAddresseeDuplicates,
        getSortedClientContacts
    }
}