import React from "react";
import Typography from "../text/Typography";
import SidebarDivider from "./Divider";

export interface ISidebarHeaderProps {
    text: string,
    divider?: "top" | "bottom"
}

export default function SidebarHeader({text, divider}: ISidebarHeaderProps) {
    return (
        <>
            {
                divider === "top" && <SidebarDivider />
            }
            <Typography color="primary" size={24} >{text}</Typography>
            {
                divider === "bottom" && <SidebarDivider />
            }
        </>
    )
}