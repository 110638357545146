import React from "react";
import { ITeam, ITicket, IUser, TicketState, UserRole } from "../../../types/ApiTypes";
import Button, { ButtonSize } from "../../buttons/Button";
import "./AssignTicketToOtherTeam.css";
import AssignTicketToOtherTeamButton from "./AssignTicketToOtherTeamButton";
import Expandable from "../../comboBox/Expandable";
import { useTeams } from "../../../state/swr/teams/useTeams";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import WithPermissions from "../../permissions/WithPermissions";

interface IAssignTicketToOtherTeamProps {
    afterAssigningTicket?: () => void,
    ticket: ITicket
}

export default function AssignTicketToOtherTeam({ticket, afterAssigningTicket}: IAssignTicketToOtherTeamProps) {
    const { teams } = useTeams();
    const permissions = useTicketPermission("update", ticket);

    const availableTeams = teams && !!teams.length && teams.filter(t => !ticket.team || t._id !== ticket.team._id);
    
    const hasTeams = availableTeams && !!availableTeams.length;

    return (
        <WithPermissions permissions={permissions}>
            <Expandable
                expander={<Button variant="outline" icon="chevron-right" iconPosition="end" >An Team übergeben</Button>}
            >
                {
                    hasTeams && availableTeams.map(u => <AssignTicketToOtherTeamButton ticket={ticket} key={u._id} team={u}  />)
                }
            </Expandable>
        </WithPermissions>
    );
}