import React from "react";
import { useNavigate } from "react-router-dom";
import { logsGetAll } from "../../api/Api";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import PageLoader from "../../components/loader/PageLoader";
import Table from "../../components/tables/Table";
import TableCell from "../../components/tables/TableCell";
import TableRow from "../../components/tables/TableRow";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import { useAppSelector } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import useModal from "../../hooks/useModal";
import Page from "../../layout/Page";
import { ILogEntry, LogEntryType } from "../../types/ApiTypes";
import LogEntryEventTypeBanner from "../../components/log/LogEntryEventTypeBanner";
import LogEntryTypeBanner, { getColorFromLogEntryType, getIconFromLogEntryType } from "../../components/log/LogEntryTypeBanner";
import DeleteLogsManuallyButton from "../../components/log/DeleteLogsManuallyButton";
import { useLogs } from "../../state/swr/log/useLogs";
import TenantStatusBanner from "../../components/tenant/TenantStatusBanner";
import LogEntrySystemBanner from "../../components/log/LogEntrySystemBanner";
import LogEntryServiceBanner from "../../components/log/LogEntryServiceBanner";
import Flex from "../../components/container/Flex";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import LogEntryForm from "../../components/log/LogEntryForm";
import { useTenants } from "../../state/swr/tenant/useTenants";
import { useTenant } from "../../state/swr/tenant/useTenant";
import { useUser } from "../../state/swr/user/useUser";

export enum LogViewerTenant {
    All = "all",
    Current = "current"
}

export default function LogViewer() {
    const [selectedType, setSelectedType] = React.useState<LogEntryType | undefined>(undefined);
    const [displayedTenant, setDisplayedTenant] = React.useState<LogViewerTenant>(LogViewerTenant.All);

    const [filteredItems, setFilteredItems] = React.useState<ILogEntry[]>([]);

    const { loadingLogs, logs, reloadLogs } = useLogs();

    const { tenant } = useTenant();

    const navigate = useNavigate();
    const showModal = useModal();
        
    React.useEffect(() => {
        if (!logs) return;
        setFilteredItems(logs.filter(l => {
            const matchesType = !selectedType || l.type === selectedType;
            const matchesTenant = displayedTenant === LogViewerTenant.Current ? (l.tenant && tenant && l.tenant._id === tenant._id) : true;

            return matchesTenant && matchesType;
        }));
    }, [selectedType, logs, displayedTenant]);

    if (loadingLogs) return <PageLoader />

    return (
        <Page header="Logbuch" actionButtons={<DeleteLogsManuallyButton />}>
            {
                loadingLogs 
                ? <LoadingSpinner />
                : (
                    <>
                        <TabSwitcher
                            tabs={[{
                                data: "all",
                                label: "Alle",
                                icon: "eye"
                            }, {
                                data: LogEntryType.Error,
                                label: "Fehler",
                                icon: getIconFromLogEntryType(LogEntryType.Error),
                                backColor: getColorFromLogEntryType(LogEntryType.Error)
                            }, {
                                data: LogEntryType.Event,
                                label: "Events",
                                icon: getIconFromLogEntryType(LogEntryType.Event),
                                backColor: getColorFromLogEntryType(LogEntryType.Event)
                            }, {
                                data: LogEntryType.General,
                                label: "Informationen",
                                icon: getIconFromLogEntryType(LogEntryType.General),
                                backColor: getColorFromLogEntryType(LogEntryType.General)
                            }, {
                                data: LogEntryType.Schedule,
                                label: "Zeitplan",
                                icon: getIconFromLogEntryType(LogEntryType.Schedule),
                                backColor: getColorFromLogEntryType(LogEntryType.Schedule)
                            }]}
                            tabQueryParamKey="type"
                            saveActiveTab={t => t === "all" ? setSelectedType(undefined) : setSelectedType(t as LogEntryType)}
                        />
                        <TabSwitcher 
                            size="small"
                            tabs={[{
                                data: LogViewerTenant.All,
                                label: "Alle Tenants",
                            }, {
                                data: LogViewerTenant.Current,
                                label: "Aktueller Tenant",
                            }]}
                            tabQueryParamKey="tenant"
                            saveActiveTab={t => setDisplayedTenant(t as LogViewerTenant)}
                        />
                        <Table 
                            border 
                            headers={[{
                                label: ""
                            }, {
                                label: "Datum"
                            }, {
                                label: "Inhalt / Nachricht",
                                valueKey: "content"
                            }, {
                                label: "Weitere Informationen"
                            }, {
                                label: "System"
                            }, {
                                label: "Typ"
                            }, {
                                label: "Event"
                            }, {
                                label: "Service"
                            }, {
                                label: "Event-ID"
                            }, {
                                label: "Aufruf"
                            }, {
                                label: "Tenant"
                            }]}
                            items={filteredItems}
                            renderItem={l => (
                                <TableRow key={l._id}>
                                    <TableCell>
                                        <LogEntryForm log={l} />
                                    </TableCell>
                                    <TableCell date={l.createdAt} showTime />
                                    <TableCell>
                                        <Flex className="w-100">
                                            <textarea readOnly className="w-100 text-wrap" style={{border: "none", background: "none"}} value={l.content} />
                                        </Flex>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            l.additionalInfo 
                                            ? (
                                                <Flex className="w-100">
                                                    <textarea readOnly className="w-100 text-wrap" style={{border: "none", background: "none"}} value={l.additionalInfo} />
                                                </Flex>

                                            )
                                            : <span>-</span>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <LogEntrySystemBanner log={l} />
                                    </TableCell>
                                    <TableCell>
                                        <LogEntryTypeBanner log={l} />
                                    </TableCell>
                                    <TableCell>
                                        <LogEntryEventTypeBanner log={l} />
                                    </TableCell>
                                    <TableCell>
                                        <LogEntryServiceBanner log={l} />
                                    </TableCell>
                                    <TableCell >
                                        {
                                            l.eventId 
                                            ? (
                                                <Flex style={{maxWidth: "150px", overflow: "auto"}} >
                                                    <span className="text-wrap">{l.eventId}</span>
                                                </Flex>
                                            )
                                            : <span>-</span>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <Flex style={{maxWidth: "150px", overflow: "auto"}} >
                                            <span className="text-wrap">{l.methodName}</span>
                                        </Flex>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            l.tenant && <span>{l.tenant.name}</span>
                                        }
                                    </TableCell>
                                </TableRow>
                            )}
                        />
                    </>        
                )
            }
        </Page>
    )
}