import React, { PropsWithChildren, ReactNode } from "react";
import Flex from "../container/Flex";
import "./Expandable.css";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import useWindowSize from "../../hooks/useWindowSize";

export interface IExpandableProps {
    expander: React.ReactElement,
    children: React.ReactNode | ((close: Function) => React.ReactNode),
    disabled?: boolean,
    noBackground?: boolean,
}

export interface IExpandOrigin {
    top: number,
    left: number,
    bottom: number,
    right: number,
    height: number,
    width: number
}

export default function Expandable({expander, noBackground, children, disabled}: IExpandableProps) {

    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [expandOrigin, setExpandOrigin] = React.useState<IExpandOrigin>();
    const [expandToTop, setExpandToTop] = React.useState<boolean>(false);
    const [expandFromRight, setExpandFromRight] = React.useState<boolean>(false);

    const windowSize = useWindowSize();

    const expanderRef = React.useRef<HTMLDivElement>(null);
    const closeTimer = React.useRef<any>();

    React.useEffect(() => setExpanded(false), [windowSize]);

    const toggleExpanded = () => {
        if (disabled) return;
        if (expanded) return setExpanded(false);
        
        setExpanded(true);

        if (!expanderRef || !expanderRef.current) return;
    
        const location = expanderRef.current.getBoundingClientRect();

        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        setExpandToTop((location.top > (windowHeight / 2)));
        setExpandFromRight((location.left > (windowWidth / 2)));

        setExpandOrigin({
            top: location.top,
            left: location.left,
            bottom: location.bottom,
            right: location.right,
            height: location.height + 5,
            width: location.width
        })
    }

    const expandableContentClass = generateClassName("expandable-content d-flex flex-column gap-0", {
        base: "expandable-content-expand-to-",
        value: expandToTop,
        onTrue: "top",
        standard: "bottom"
    }, {
        base: "expandable-content-expand-from-",
        value: expandFromRight,
        onTrue: "right",
        standard: "left",
    }, {
        value: !noBackground,
        onTrue: "expandable-content-background"
    });

    const openExpander = () => {
        if (disabled) return;
        clearTimeout(closeTimer.current);
        setExpanded(true);
    }

    const closeExpander = () => {
        clearTimeout(closeTimer.current)
        closeTimer.current = setTimeout(() => setExpanded(false), 150);
    }

    return (
        <div 
            className="d-flex flex-column gap-2 expandable" 
            onMouseLeave={closeExpander} 
        >
            <div 
                className="expandable-expander" 
                onClick={toggleExpanded}
                ref={expanderRef}
            >
                {React.cloneElement(expander, {disabled: disabled})}
            </div>
            {
                expanded && expandOrigin && (
                    <div 
                        className={expandableContentClass} 
                        onMouseOver={openExpander} 
                        style={generateStyle({
                            name: "top",
                            value: expandOrigin.top + expandOrigin.height,
                            applyCondition: !expandToTop
                        }, {
                            name: "left",
                            value: expandOrigin.left,
                            applyCondition: !expandFromRight
                        }, {
                            name: "right",
                            value: window.innerWidth - expandOrigin.right,
                            applyCondition: expandFromRight
                        }, {
                            name: "bottom",
                            value: window.innerHeight - expandOrigin.bottom + expandOrigin.height,
                            applyCondition: expandToTop
                        }, {
                            name: "transformOrigin",
                            value: `${expandFromRight ? "right" : "left"} ${expandToTop ? "top" : "bottom"}`,
                        }, {
                            name: "minWidth", 
                            value: expandOrigin.width > 200 ? expandOrigin.width : 200
                        })}
                    >
                        <div className="d-flex flex-column gap-0 w-100 h-100">
                            { 
                                typeof children === "function" 
                                ? children(() => setExpanded(false))
                                : children
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

