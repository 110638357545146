import useSWR from "swr";
import { IClient, IEmployee, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useEmployees = () => {
    const { data, isLoading, mutate } = useSWR<Array<IUser>>(ApiRoutes.User.Employees);

    return {
        employees: data,
        loadingEmployees: isLoading,
        reloadEmployees: mutate
    }
}