import useSWR from "swr";
import { IClient, ICoupon, IFeature, IInvoice, IPlan, IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const usePlans = (serviceId: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IPlan>>(ApiRoutes.Subscriptions.PlansGetForService.getValue({
        param: RouteParam.ServiceId,
        value: serviceId
    }));

    return {
        plans: data,
        loadingPlans: isLoading,
        reloadPlans: mutate
    }
}