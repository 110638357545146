import useSWR from "swr";
import { IClient, IMailTemplate, IUserClients } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";

export const useMailTemplates = () => {
    const { data, isLoading, mutate } = useSWR<Array<IMailTemplate>>(ApiRoutes.MailTemplates.Tenant.All);

    return {
        mailTemplates: data,
        loadingMailTemplates: isLoading,
        reloadMailTemplates: mutate
    }
}