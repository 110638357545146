import { Form, Formik, FormikConsumer } from "formik";
import React from "react";
import { userRequestPhoneNumberAccess, userUpdatePhoneNumber, userVerifyPhoneNumberAccess, userVerifyPhoneNumberUpdate } from "../../../api/Api";
import { IUserPhoneNumberUpdateRequest, IUserPhoneNumberVerifyRequest } from "../../../api/ApiRequests";
import { AppColor } from "../../../app/AppStyles";
import { ModalType } from "../../../config/ModalTypes";
import useApi from "../../../hooks/useApi";
import useModal from "../../../hooks/useModal";
import { Locale } from "../../../locale/Locale";
import { setModal } from "../../../state/slices/modalSlice";
import { IUser } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import LabelButton from "../../buttons/LabelButton";
import FieldWithLabel from "../../formik/FormikField";
import OtpField from "../../formik/OtpField";
import OtpInput from "../../formik/OtpInput";
import LoadingSpinner from "../../loader/LoadingSpinner";
import ModalForm from "../../modal/ModalForm";
import MultiStepWizard from "../../wizard/MultiStepWizard";
import MultiStepWizardPage from "../../wizard/MultiStepWizardPage";
import ModalDialog from "../../modal/ModalDialog";

export default function AddPhoneNumberButton({user}: {user: IUser}) {
    const [otp, setOtp] = React.useState<string>("");
    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    const [loadingOtp, setLoadingOtp] = React.useState<boolean>(false);
    const [initialCodeHasBeenLoaded, setInitialCodeHasBeenLoaded] = React.useState<boolean>(false);

    const callApi = useApi();
    const showModal = useModal();

    const refreshVerificationOtp = async (abortController?: AbortController) => {
        setLoadingOtp(true);

        const res = await callApi(userRequestPhoneNumberAccess(abortController));

        if (res && res.canceled) return;

        if (res && res.success) setInitialCodeHasBeenLoaded(true);
        setLoadingOtp(false);
    }

    React.useEffect(() => {
        if (!modalVisible) return;
        if (initialCodeHasBeenLoaded) return;

        const abortController = new AbortController();
        refreshVerificationOtp(abortController);

        return () => abortController.abort();
    }, [modalVisible]);

    const title = user.phoneNumber ? "Nummer ändern" : "Neue Nummer hinzufügen";

    return (
        <ModalDialog 
            button={<Button text={title} icon="phone" />}
            title={title} 
        >
            {
                closeForm => (
                    <MultiStepWizard initialIndex={0} hideProgressBar>
                        {
                            (nextPage, prevPage) => [
                                <MultiStepWizardPage>
                                    <Formik
                                        initialValues={{
                                            otp: ""
                                        } as IUserPhoneNumberVerifyRequest}
                                        onSubmit={async (values) => {
                                            if (!values) return;

                                            if (!values.otp) {
                                                showModal({
                                                    type: ModalType.Error,
                                                    text: Locale.errors.logInVerification.noOtp
                                                });
                                                return;
                                            }

                                            const res = await callApi(userVerifyPhoneNumberAccess(values));

                                            if (!res) return;

                                            nextPage();
                                        }}
                                    >
                                        {
                                            (formik) => {
                                                const storeOtpAndSubmit = (otp: string) => {
                                                    formik.setFieldValue("otp", otp);
                                                    formik.submitForm();
                                                }

                                                return (
                                                    <Form className="w-100 h-100 d-flex flex-column">
                                                        {
                                                            loadingOtp
                                                            ? (
                                                                <>
                                                                    <span>Bevor Sie auf dieses Feature zugreifen können, müssen Sie sich authentifizieren.</span>
                                                                    <LoadingSpinner text="Code wird versandt..." />
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    <OtpInput saveOtp={storeOtpAndSubmit} text="Bevor Sie auf dieses Feature zugreifen können, müssen Sie sich authentifizieren. Wir haben einen Code an Ihre E-Mail-Adresse versandt. Bitte geben Sie diesen ein." />
                                                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                                                        <LabelButton onClick={async () => await refreshVerificationOtp()} text="Neuen Code anfordern" />
                                                                        <Button type="submit" text="Weiter" loading={formik.isSubmitting} />
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </Form>
                                                )
                                            }
                                        }
                                    </Formik>
                                </MultiStepWizardPage>,
                                <MultiStepWizardPage>
                                    <Formik
                                        initialValues={{
                                            phoneNumber: ""
                                        } as IUserPhoneNumberUpdateRequest}
                                        onSubmit={async (values) => {
                                            if (!values) return;

                                            if (!values.phoneNumber) {
                                                showModal({
                                                    text: "Bitte geben Sie eine Telefonnummer ein.",
                                                    type: ModalType.Error
                                                });
                                                return;
                                            }

                                            const res = await callApi(userUpdatePhoneNumber(values));

                                            if (!res || !res.success) return;

                                            nextPage();
                                        }}
                                    >
                                        {
                                            (formik) => (
                                                <Form className="w-100 d-flex flex-column">
                                                    <FieldWithLabel type="tel" label="Ihre Telefonnummer" name="phoneNumber" />
                                                    <Button type="submit" text="Weiter" loading={formik.isSubmitting} loadingText="Nummer wird überprüft..." />
                                                </Form>
                                            )
                                        }
                                    </Formik>
                                </MultiStepWizardPage>,
                                <MultiStepWizardPage>
                                    <Formik
                                        initialValues={{
                                            otp: ""
                                        } as IUserPhoneNumberVerifyRequest}
                                        onSubmit={async (values) => {
                                            if (!values) return;

                                            if (!values.otp) {
                                                showModal({
                                                    type: ModalType.Error,
                                                    text: Locale.errors.logInVerification.noOtp
                                                });
                                                return;
                                            }

                                            const res = await callApi(userVerifyPhoneNumberUpdate(values), true);

                                            if (!res) return;

                                            closeForm();
                                        }}
                                    >
                                        {
                                            (formik) => {
                                                const storeOtpAndSubmit = (otp: string) => {
                                                    formik.setFieldValue("otp", otp);
                                                    formik.submitForm();
                                                }

                                                return (
                                                    <Form className="w-100 h-100 d-flex flex-column">
                                                        <OtpInput saveOtp={storeOtpAndSubmit} text="Fast geschafft! Aktivieren Sie Ihre Telefonnummer, indem Sie den Code eingeben, welchen wir als SMS an die von Ihnen angegebene Telefonnummer versandt haben." />
                                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                                            <Button type="button" icon="arrow-left" text="Telefonnummer anpassen" disabled={formik.isSubmitting} onClick={prevPage}/>
                                                            <Button type="submit" text="Bestätigen" color="success" loading={formik.isSubmitting} />
                                                        </div>
                                                    </Form>
                                                )
                                            }
                                        }
                                    </Formik>
                                </MultiStepWizardPage>
                            ]
                        }                    
                    </MultiStepWizard>
                )
            }
        </ModalDialog>
    )
}