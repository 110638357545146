import React from "react";
import { useAreasOfResponsibility } from "../../state/swr/areasOfResponsibilities/useAreasOfResponsibility";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import LoadingSpinner from "../loader/LoadingSpinner";
import { IDatevAreaOfResponsibility } from "../../types/ApiTypes";
import WithPermissions from "../permissions/WithPermissions";

export interface IResponsibilitySelectProps {
    value: string,
    label: string,
    saveItem: (v: IDatevAreaOfResponsibility) => void
}

export default function ResponsibilitySelect({saveItem, label, value}: IResponsibilitySelectProps) {
    const { areasOfResponsibility, loadingAreasOfResponsibility, reloadAreasOfResponsibilities } = useAreasOfResponsibility();

    if (loadingAreasOfResponsibility) return <LoadingSpinner />;

    return (
        <WithPermissions permissions={["clientResponsibilities.all.read"]}>
            {
                loadingAreasOfResponsibility
                ? <LoadingSpinner />
                : <SearchableComboBox
                    values={areasOfResponsibility || []}
                    value={value}
                    itemToId={item => item._id}
                    itemToString={i => i.name}
                    onItemClick={saveItem}
                    label={label}
                />
            }
        </WithPermissions>
    )
}