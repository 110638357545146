import React from "react";
import { ICoupon } from "../../types/ApiTypes";
import Pill from "../pills/Pill";
import useCoupon from "../../hooks/useCoupon";

export default function CouponDuration({coupon}: {coupon: ICoupon}) {

    const {
        getDurationFromStripeDuration
    } = useCoupon();

    const getColor = () => {
        switch (coupon.duration) {
            case "forever": return "#9EF8EE";
            case "once": return "#FA7F08";
            case "repeating": return "#348888";
        }
    }

    return (
        <Pill color={getColor()}>
            <span>
                {getDurationFromStripeDuration(coupon.duration)?.key}
                {
                    coupon.duration === "repeating" && ` für ${coupon.repeatsForMonths} Monat(e)`
                }
            </span>
        </Pill>
    )
}