import React from "react";
import { IOfficeAccount } from "../../../types/ApiTypes";
import useApi from "../../../hooks/useApi";
import useDownloads from "../../../hooks/useDownloads";
import LabelButton from "../../buttons/LabelButton";
import { officeAccountsDownloadAllAttachments } from "../../../api/Api";
import Button from "../../buttons/Button";

export interface IDownloadAllAttachmentsButtonProps {
    mailId: string,
    account: IOfficeAccount,
}

export default function DownloadAllAttachmentsButton({account, mailId}: IDownloadAllAttachmentsButtonProps) {

    const {
        downloadFile
    } = useDownloads();

    const callApi = useApi();

    const downloadAllAttachments = async () => {
        const res = await callApi(officeAccountsDownloadAllAttachments({id: account._id, messageId: mailId}));
        if (!res || !res.success) return;
        downloadFile(res.data, res.data.content);
    }

    return (
        <Button variant="text" text="Alle Anhänge herunterladen" icon="download" onClick={downloadAllAttachments} style={{fontSize: "0.8em"}} />
    )
}